import { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import Select from "react-select";

import { Details_Restaurant_Category_List_Get, Details_Rest_Menu_Restaurant_Category_List_Get, Save_Restaurant_Category_Post, Update_Restaurant_Category_Put, Update_Rest_Menu_Restaurant_Category_Put } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

import ModalWrapper from "components/common/ModalWrapper";
import ImageSelector from "components/common/ImageSelector";

const RestaurantCategoryAddEdit = (props) => {
    const { show, hideModal, recordID, handleRefresh, categoryKeyPairList = [], categoryTaxKeyPairList, timingGroupKeyPairList, printerKeyValuePairList, ownedItem, isStealDealCategory } = props;
    const defaultRecordObj = {
        parentCategoryUuid: "",
        title: "",
        description: "",
        sortOrder: 1,
        categoryIconRefId: "",
        timingGroupUuid: "",
        availabilities: [],
        aggregatorAvailabilities: [],
        splitBill: false,
        categoryTaxUuids: [],
        printerUuids: [],
    };

    const [record, setRecord] = useState({ ...defaultRecordObj });
    const [orderAvailability, setOrderAvailability] = useState({ HomeDelivery: false, TakeAway: false, DineIn: false, StealDeal: isStealDealCategory });
    const [aggregatorAvailability, setAggregatorAvailability] = useState({});
    const [isStealDealItem, setIsStealDealItem] = useState(false)

    useEffect(() => {
        if (recordID) {
            const rUrl = ownedItem ? Details_Restaurant_Category_List_Get : Details_Rest_Menu_Restaurant_Category_List_Get;
            callGetApi(MODAL_LOADER, rUrl.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const res = response.responsePacket;
                    handleSetInitialValues(res);
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, [recordID]);

    const handleSetInitialValues = (response) => {
        // set Parent Category Key
        const _selectedParentCategory = categoryKeyPairList.find((obj) => obj.key === response.parentCategoryUuid);

        // Set Timing Group
        const _selectedTimingGroup = timingGroupKeyPairList.find((obj) => obj.key === response.timingGroupUuid);

        // Set Order Availabilities
        for (let i = 0; i < response.availabilities.length; i++) {
            const availabilitiesKey = response.availabilities[i];
            if (availabilitiesKey === "StealDeal") {
                setIsStealDealItem(true);
            }
            if (availabilitiesKey) {
                setOrderAvailability((prev) => ({ ...prev, [availabilitiesKey]: true }));
            }
        }

        // Set Aggregator Availabilities
        for (let i = 0; i < response.aggregatorAvailabilities.length; i++) {
            const aggregatorKey = response.aggregatorAvailabilities[i];
            if (aggregatorKey) {
                setAggregatorAvailability((prev) => ({ ...prev, [aggregatorKey]: true }));
            }
        }

        // Set Parent Category Key Pair
        let _categoryTaxUuids = [];
        for (let i = 0; i < response.categoryTaxUuids.length; i++) {
            const catKey = response.categoryTaxUuids[i];
            for (let j = 0; j < categoryTaxKeyPairList.length; j++) {
                if (catKey === categoryTaxKeyPairList[j].key) {
                    _categoryTaxUuids.push(categoryTaxKeyPairList[j]);
                }
            }
        }

        // Set Printer Key Pair
        let _printerUuids = [];
        if (response.printerUuids) {
            for (let i = 0; i < response.printerUuids.length; i++) {
                const catKey = response.printerUuids[i];
                for (let j = 0; j < printerKeyValuePairList.length; j++) {
                    if (catKey === printerKeyValuePairList[j].key) {
                        _printerUuids.push(printerKeyValuePairList[j]);
                    }
                }
            }
        }

        let recordObj = {
            ...response,
            parentCategoryUuid: _selectedParentCategory || "",
            timingGroupUuid: _selectedTimingGroup || "",
            // sortOrder: record.sortOrder,
            categoryTaxUuids: _categoryTaxUuids.length ? _categoryTaxUuids : "",
            printerUuids: _printerUuids,
        };
        setRecord(recordObj);
    };

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    //Text Check Box Change
    const handleCheckBoxChange = (e, key) => {
        const { id, checked } = e.target;
        if (key === "availabilities") {
            if (!ownedItem && isStealDealItem) {
                return;
            }
            if (id === "StealDeal") {
                if (!ownedItem && !isStealDealItem) {
                    return;
                }
                if (checked) {
                    setOrderAvailability({ HomeDelivery: false, TakeAway: false, DineIn: false, StealDeal: true });
                    setRecord((prev) => ({ ...prev, parentCategoryUuid: "" }));
                } else {
                    setOrderAvailability((prev) => ({ ...prev, [id]: checked }));
                }
            } else {
                if (!ownedItem && !record?.parentAvailabilities.includes(id)) {
                    return;
                }
                if (checked) {
                    setOrderAvailability((prev) => ({ ...prev, StealDeal: false, [id]: checked }));
                } else {
                    setOrderAvailability((prev) => ({ ...prev, [id]: checked }));
                }
            }
            // setOrderAvailability((prev) => ({ ...prev, [id]: checked }));
        } else if (key === "aggregatorAvailabilities") {
            if (!ownedItem && !record?.parentAggregatorAvailabilities.includes(id)) {
                return;
            }
            setAggregatorAvailability({ ...aggregatorAvailability, [id]: checked });
        } else {
            setRecord((prev) => ({ ...prev, [id]: checked }));
        }
    };


    //Text Check Availabilities
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    // IMAGE CHANGE
    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, categoryIconRefId: imageRefId });
    };

    // Check validation
    const checkInputValidation = (recordObj) => {
        if (isEmpty(recordObj.title)) {
            toast.error(`Title must not be empty`, { id: "toast" });
            return false;
        } else if (recordObj.title.length > 100) {
            toast.error(`Title must not be more then 60 character.`, { id: "toast" });
            return false;
        } else if (ownedItem && recordObj.availabilities.length === 0) {
            toast.error(`Order Availabilities must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.sortOrder)) {
            toast.error(`Sort Order must not be empty`, { id: "toast" });
            return false;
        } else if (recordObj.sortOrder < 0) {
            toast.error(`Sort Order must not be negative`, { id: "toast" });
            return false;
        }
        // else if (isEmpty(recordObj.categoryIconRefId)) {
        //     toast.error(`Category icon must not be empty`, { id: "toast" });
        //     return false;
        // }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            let _aggregatorAvailability = [];
            let _orderAvailability = [];
            let _categoryTaxUuids = [];
            let _printerUuids = [];

            for (let key in orderAvailability) {
                if (orderAvailability[key]) {
                    _orderAvailability.push(key);
                }
            }

            for (let key in aggregatorAvailability) {
                if (aggregatorAvailability[key]) {
                    _aggregatorAvailability.push(key);
                }
            }

            for (let i = 0; i < record.categoryTaxUuids.length; i++) {
                const obj = record.categoryTaxUuids[i];
                _categoryTaxUuids.push(obj.key);
            }

            for (let i = 0; i < record.printerUuids.length; i++) {
                const obj = record.printerUuids[i];
                _printerUuids.push(obj.key);
            }

            let recordObj = {
                ...record,
                sortOrder: parseInt(record.sortOrder),
                timingGroupUuid: record.timingGroupUuid.key || "",
                categoryTaxUuids: _categoryTaxUuids,
                parentCategoryUuid: isStealDealCategory ? null : record.parentCategoryUuid?.key || null,
                availabilities: _orderAvailability,
                aggregatorAvailabilities: orderAvailability.HomeDelivery ? _aggregatorAvailability : [],
                printerUuids: _printerUuids
            };

            if (!checkInputValidation(recordObj)) {
                return;
            }

            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Restaurant_Category_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, { id: "toast" });
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, { id: "toast" });
                    }
                });
            } else {
                const rUrl = ownedItem ? Update_Restaurant_Category_Put : Update_Rest_Menu_Restaurant_Category_Put;
                callPutApi(MODAL_LOADER, rUrl.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, { id: "toast" });
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, { id: "toast" });
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    const getCategoryIconFieldset = () => {
        return (
            <fieldset className="my-3">
                <legend>Category Icon *</legend>
                <ImageSelector
                    style={{
                        height: "120px",
                        width: "100%",
                        // borderRadius: "50%",
                        overflow: "hidden",
                    }}
                    owned={ownedItem}
                    handleImageChange={handleImageChange}
                    selectedImage={record.categoryIconRefId}
                />
            </fieldset>
        )
    }

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static" header={`${recordID ? "Update" : "Add"} ${isStealDealCategory ? "Steal Deal" : "Food"} Category`} handleSubmit={handleSubmit}>
            <div className="row">
                <div className="col-lg-6">
                    <fieldset>
                        <legend>Category</legend>
                        <div className="form-group row align-items-center">
                            <div className="col-lg-12 my-2">
                                <label> Title *</label>
                                <input type="text" disabled={!ownedItem} className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} />
                            </div>
                        </div>
                    </fieldset>
                    {!isStealDealCategory &&
                        <fieldset className="my-3">
                            <legend>Order Availabilities *</legend>
                            <div className="row ">
                                <div className="col-lg-6 my-2">
                                    <input type="checkbox" className="input-default me-2" id="DineIn" onChange={(e) => handleCheckBoxChange(e, "availabilities")} checked={orderAvailability?.DineIn} />
                                    <label htmlFor="DineIn">DineIn </label>
                                </div>
                                <div className="col-lg-6 my-2">
                                    <input
                                        type="checkbox"
                                        className="input-default me-2"
                                        id="HomeDelivery"
                                        onChange={(e) => handleCheckBoxChange(e, "availabilities")}
                                        checked={orderAvailability?.HomeDelivery}
                                    />
                                    <label htmlFor="HomeDelivery">Delivery </label>
                                </div>
                                <div className="col-lg-6 my-2">
                                    <input type="checkbox" className="input-default me-2" id="TakeAway" onChange={(e) => handleCheckBoxChange(e, "availabilities")} checked={orderAvailability?.TakeAway} />
                                    <label htmlFor="TakeAway">Takeaway</label>
                                </div>
                                {/* <div className="col-lg-6 my-2">
                                    <input
                                        type="checkbox"
                                        className="input-default me-2"
                                        id="StealDeal"
                                        onChange={(e) => handleCheckBoxChange(e, "availabilities")}
                                        checked={orderAvailability?.StealDeal}
                                    />
                                    <label htmlFor="StealDeal">StealDeal</label>
                                </div> */}
                            </div>
                        </fieldset>
                    }
                    {orderAvailability?.HomeDelivery && (
                        <fieldset className="my-3">
                            <legend>Aggregator Availabilities</legend>
                            <div className="row">
                                <div className="col-lg-6 my-2">
                                    <input
                                        type="checkbox"
                                        className="input-default me-2"
                                        id="Zomato"
                                        onChange={(e) => handleCheckBoxChange(e, "aggregatorAvailabilities")}
                                        checked={aggregatorAvailability.Zomato ? true : false}
                                    />
                                    <label htmlFor="Zomato">Zomato</label>
                                </div>
                                <div className="col-lg-6 my-2">
                                    <input
                                        type="checkbox"
                                        className="input-default me-2"
                                        id="Swiggy"
                                        onChange={(e) => handleCheckBoxChange(e, "aggregatorAvailabilities")}
                                        checked={aggregatorAvailability.Swiggy ? true : false}
                                    />
                                    <label htmlFor="Swiggy">Swiggy</label>
                                </div>
                            </div>
                        </fieldset>
                    )}
                    {isStealDealCategory && getCategoryIconFieldset()}
                </div>
                <div className="col-lg-6">
                    <fieldset>
                        <legend>Other Details</legend>
                        <div className="form-group row align-items-center">
                            {!isStealDealCategory &&
                                <div className="col-lg-12 my-2">
                                    <label>Parent Category (optional)</label>
                                    <Select
                                        isDisabled={orderAvailability.StealDeal || !ownedItem}
                                        options={categoryKeyPairList}
                                        isClearable={true}
                                        value={record.parentCategoryUuid}
                                        onChange={(e) => handleSelectChange(e, "parentCategoryUuid")}
                                    />
                                </div>
                            }
                            {/* <div className="col-lg-12 my-2">
                                <label> Sort Order *</label>
                                <input type="number" className="form-control input-default" id="sortOrder" onChange={handleTextChange} value={record.sortOrder} />
                            </div> */}
                            <div className="col-lg-12 my-2">
                                <label>Timing Group List </label>
                                <Select
                                    options={timingGroupKeyPairList}
                                    isClearable
                                    value={record.timingGroupUuid}
                                    onChange={(e) => handleSelectChange(e, "timingGroupUuid")}
                                />
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>Category Tax (Multi)</label>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    options={categoryTaxKeyPairList}
                                    value={record.categoryTaxUuids}
                                    onChange={(e) => handleSelectChange(e, "categoryTaxUuids")}
                                />
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>Printer (Multi)</label>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    options={printerKeyValuePairList}
                                    value={record.printerUuids}
                                    onChange={(e) => handleSelectChange(e, "printerUuids")}
                                />
                            </div>
                            <div className="col-lg-12 my-2">
                                <input type="checkbox" className="input-default me-2" id="splitBill" disabled={!ownedItem} onChange={handleCheckBoxChange} checked={record.splitBill} />
                                <label htmlFor="splitBill">Split Bill </label>
                            </div>
                            <div className="col-lg-12 my-2">
                                <label> Description </label>
                                <textarea disabled={!ownedItem} className="form-control input-default" id="description" cols="30" rows="4" onChange={handleTextChange} value={record.description} />
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            {!isStealDealCategory && getCategoryIconFieldset()}
        </ModalWrapper>
    );
};

export default RestaurantCategoryAddEdit;
