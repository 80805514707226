import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import Select from "react-select";

import { Details_Advertisement_List_Get, Save_Advertisement_Post, Update_Advertisement_Put } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { DINE_IN, EVENT_LIST, HOME_DELIVERY, MODAL_LOADER, RESERVATION, STEAL_DEAL, TAKEAWAY } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";
import { isEmpty } from "utils/TextUtils";
import ImageSelector from "components/common/ImageSelector";

const selectActionOptions = [
    { label: HOME_DELIVERY, value: HOME_DELIVERY, key: "adSliderAction" },
    { label: DINE_IN, value: DINE_IN, key: "adSliderAction" },
    { label: TAKEAWAY, value: TAKEAWAY, key: "adSliderAction" },
    { label: STEAL_DEAL, value: STEAL_DEAL, key: "adSliderAction" },
    { label: RESERVATION, value: RESERVATION, key: "adSliderAction" },
    { label: EVENT_LIST, value: EVENT_LIST, key: "adSliderAction" },
];
const bannerPositionOptions = [
    { label: "DashboardTop", value: "DashboardTop", key: "adBannerPosition" },
    { label: "DashboardBottom", value: "DashboardBottom", key: "adBannerPosition" },
    { label: HOME_DELIVERY, value: HOME_DELIVERY, key: "adBannerPosition" },
    { label: TAKEAWAY, value: TAKEAWAY, key: "adBannerPosition" },
];

const AdvertisementAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const defaultRecordObj = {
        sliderOrder: "",
        adImageRefId: "",
        adSliderAction: "",
        adBannerPosition: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            let rUrl = Details_Advertisement_List_Get.replaceAll("{recordID}", recordID);
            callGetApi(MODAL_LOADER, rUrl, {}, (response) => {
                const res = response.responsePacket;
                res.adSliderAction = selectActionOptions.find((adSliderAction) => adSliderAction.value === res.adSliderAction);
                res.adBannerPosition = bannerPositionOptions.find((bannerPosition) => bannerPosition.value === res.adBannerPosition);
                setRecord(res);
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    // Option Select Event
    const handleSelect = (e) => {
        setRecord((prev) => ({ ...prev, [e.key]: e }));
    };

    // Image change
    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, adImageRefId: imageRefId });
    };

    const checkInputValidation = () => {
        if (isEmpty(record.adSliderAction)) {
            toast.error(`Ad slider action must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.adBannerPosition)) {
            toast.error(`Ad banner position must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.sliderOrder)) {
            toast.error(`Slider order must not be empty`, { id: "toast" });
            return false;
        } else if (record.sliderOrder < 0) {
            toast.error(`Slider order must not be negative`, { id: "toast" });
            return false;
        } else if (isEmpty(record.adImageRefId)) {
            toast.error(`Banner image must not be empty`, { id: "toast" });
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            recordObj.adSliderAction = recordObj.adSliderAction.value;
            recordObj.adBannerPosition = recordObj.adBannerPosition.value;
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Advertisement_Post, recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Advertisement_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static" header={`${recordID ? "Update" : "Add"} Ad Banner`} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-4 my-2">
                    <label>Ad Slider Action *</label>
                    <Select options={selectActionOptions} onChange={handleSelect} value={record.adSliderAction} />
                </div>
                <div className="col-lg-4 my-2">
                    <label>Ad Banner Position *</label>
                    <Select options={bannerPositionOptions} onChange={handleSelect} value={record.adBannerPosition} />
                </div>
                <div className="col-lg-4 my-2">
                    <label>Slider Order *</label>
                    <input type="number" className="form-control input-default" id="sliderOrder" onChange={handleTextChange} value={record.sliderOrder} />
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-lg-12" style={{ border: "1px solid #ccc" }}>
                    <label>Banner Image *</label>
                    <ImageSelector
                        style={{
                            height: "120px",
                            width: "100%",
                            // borderRadius: "50%",
                            overflow: "hidden",
                        }}
                        handleImageChange={handleImageChange}
                        selectedImage={record.adImageRefId}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default AdvertisementAddEdit;
