import { useCallback, useEffect, useMemo, useState } from "react";

import {
    All_Client_Restaurant_Key_value_List_Post,
    All_Client_Restaurant_Employee_By_Brand_Post,
    DeActivate_Client_Employee_Movement_Put,
    Activate_Client_Employee_Movement_Put,
} from "api/ApiConst";

import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import MoveEmployee from "./MoveEmployee";
import dateUtilities from "utils/DateUtilities";
import Select from "react-select";
import { useSelector } from "react-redux";
import { callPostApi } from "api/ApiCaller";
import { SECTION_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";
import toast from "react-hot-toast";
import { handleActive, handleInactive } from "utils/UserUtils";

const customStyles = {
    control: (provided) => ({
        ...provided,
        width: 150, // Set the desired width
    }),
};

const EmployeeMovementMaster = () => {
    const { brandKeyValuePairs } = useSelector((state) => state.utility);
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [recordID, setRecordID] = useState(null);
    const [showMoveEmployeeModal, setShowMoveEmployeeModal] = useState(false)
    const [brandUuid, setBrandUuid] = useState(null);
    const [restaurantDetails, setRestaurantDetails] = useState({
        restaurantTitle: "",
        restaurantUuid: "",
    })
    const [restaurantKeyValuePairs, setRestaurantKeyValuePairs] = useState([])

    useEffect(() => {
        if (!showMoveEmployeeModal) {
            setRecordID(null);
        }
    }, [showMoveEmployeeModal]);

    useEffect(() => {
        if (brandKeyValuePairs && brandKeyValuePairs.length === 1) {
            setBrandUuid(brandKeyValuePairs[0]);
            getRestaurantKeyValuePairs(brandKeyValuePairs[0].key)
        } else if (brandKeyValuePairs && brandKeyValuePairs.length !== 0) {
            setBrandUuid(brandKeyValuePairs[0]);
            getRestaurantKeyValuePairs(brandKeyValuePairs[0].key)
        }
    }, [])

    // Get Restaurant Key-Value Pair List
    const getRestaurantKeyValuePairs = useCallback((brandUuid) => {
        const rUrl = All_Client_Restaurant_Key_value_List_Post;
        const rObj = {
            brandUuid: brandUuid,
        };
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setRestaurantKeyValuePairs(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    }, [refreshFlag])

    const handleBrandChange = (e) => {
        setBrandUuid(e)
        getRestaurantKeyValuePairs(e.key);
    }

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => <div className={row.active ? "led-green" : "led-red"} title={row.active ? "Active" : "Inactive"} />,
                width: "60px",
                sortable: false,
            },
            {
                id: "employeeName",
                name: "Name",
                selector: (row) => row.firstName + " " + row.lastName,
                sortable: false,
            },
            {
                id: "mobile",
                name: "Mobile",
                selector: (row) => row.mobile,
                sortable: false,
            },
            {
                id: "email",
                name: "Email",
                selector: (row) => row.email,
                sortable: false,
            },
            {
                id: "restaurantTitle",
                name: "Restaurant",
                selector: (row) => row.restaurantTitle,
                sortable: false,
            },
            {
                id: "employeeType",
                name: "Employee Type",
                selector: (row) => row.employeeType,
                sortable: false,
            },
            {
                id: "createdAt",
                name: "Created Time",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                <button className="listBtn me-2" onClick={() => handleEdit(row)} title="Move">
                    <i className='bx bx-move-horizontal'></i>
                </button>
                <button className={`listBtn me-2 ${row.active ? "bg-danger" : "bg-success"}`} title={row.active ? "Inactive" : "Active"} onClick={() => handleActiveInactive(row)}>
                    <i className={`bx ${row.active ? "bx-x-circle" : "bx-check-circle"}`}></i>
                </button>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setRestaurantDetails({
            restaurantTitle: row.restaurantTitle,
            restaurantUuid: row.restaurantUuid,
        });
        setShowMoveEmployeeModal(true);
    };

    // Active-Inactive record
    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_Client_Employee_Movement_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_Client_Employee_Movement_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    return (
        <>
            {PageHeading("Employee List", "Dashboard", "/")}
            <div className="card searchOnlyBlock">
                <div className="d-flex createButton">
                    <Select className="" maxMenuHeight={150} styles={customStyles} options={brandKeyValuePairs} value={brandUuid} onChange={handleBrandChange} />
                </div>
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={All_Client_Restaurant_Employee_By_Brand_Post + "/" + (brandUuid?.key || brandKeyValuePairs && brandKeyValuePairs[0].key)}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                </div>
            </div>
            {showMoveEmployeeModal && <MoveEmployee
                show={showMoveEmployeeModal}
                hideModal={() => setShowMoveEmployeeModal(false)}
                recordID={recordID}
                handleRefresh={handleRefresh}
                restaurantKeyValuePairs={restaurantKeyValuePairs}
                restaurantDetails={restaurantDetails}
            />}
        </>
    );
};

export default EmployeeMovementMaster;