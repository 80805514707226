import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
    Activate_Restaurant_Tax_Put,
    All_Restaurant_Tax_List_Post,
    DeActivate_Restaurant_Tax_Put,
    Delete_Restaurant_Tax_Delete,
    Deleted_Restaurant_Tax_List_POST,
    Revive_Restaurant_Tax_Put,
} from "api/ApiConst";
import { handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import dateUtilities from "utils/DateUtilities";

import CheckboxButton from "components/common/CheckboxButton";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import RestaurantTaxAddEdit from "./RestaurantTaxAddEdit";

export const RestaurantTaxMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [isMenuItemCopy, setIsMenuItemCopy] = useState(false);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
            setIsMenuItemCopy(false);
        }
    }, [showAddUpdateModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "Status",
                name: "",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active" />;
                    } else {
                        return <div className="led-red" title="Inactive" />;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                id: "taxTitle",
                name: "Title",
                selector: (row) => (<span className="parent-owned-flag">
                    {row.owned && <span className="badge bg-primary owned-flag" title="Owned">O</span>}
                    {" "}{row.taxTitle}
                </span>),
                sortable: false,
                // width: "100px",

            },
            {
                id: "taxPercentage",
                name: "Percentage",
                selector: (row) => <>{row.taxPercentage.toFixed(2)}%</>,
                sortable: false,
                left: true,
            },
            {
                id: "createdAt",
                name: "Created Time",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "120px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        {row.owned ?
                            <i className="bx bx-pencil" /> :
                            <i className="mdi mdi-eye-outline" />
                        }
                    </button>
                )}
                {row.owned &&
                    <div className="dropdown">
                        <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                            <i className="bx bx-chevron-down"></i>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right">
                            <>
                                {row.deleted ? (
                                    <li onClick={() => handleReviveData(row)}>
                                        <i className="bx bx-log-out-circle"></i> Revive
                                    </li>
                                ) : (
                                    <>
                                        <li onClick={() => handleDeleteData(row)}>
                                            <i className="bx bx-trash"></i> Delete
                                        </li>
                                        <li onClick={() => handleActiveInactive(row)}>
                                            {row.active ? (
                                                <>
                                                    <i className="bx bx-x-circle"></i> Inactive
                                                </>
                                            ) : (
                                                <>
                                                    <i className="bx bx-check-circle"></i> Active
                                                </>
                                            )}
                                        </li>
                                    </>
                                )}
                            </>
                        </ul>
                    </div>
                }
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setIsMenuItemCopy(!row.owned);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_Restaurant_Tax_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_Restaurant_Tax_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_Restaurant_Tax_Delete.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_Restaurant_Tax_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("Tax", "Dashboard", "/")}
            <div className="card">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_Restaurant_Tax_List_POST : All_Restaurant_Tax_List_Post}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showAddUpdateModal && <RestaurantTaxAddEdit show={showAddUpdateModal} hideModal={() => setShowAddUpdateModal(false)} recordID={recordID} handleRefresh={handleRefresh} isMenuItemCopy={isMenuItemCopy} />}
        </>
    );
};

export default RestaurantTaxMaster;
