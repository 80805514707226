import { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import Select from "react-select";

import { Details_Client_Category_List_Get, Get_Client_Category_Key_Value_List_Post, Get_Client_Tax_Key_Value_List_Post, Get_Timing_Group_Key_Value_Pair_List_Post, Save_Client_Category_Post, Update_Client_Category_Put } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isAlphaNumercSpecialCharSpc, isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

import ModalWrapper from "components/common/ModalWrapper";
import ImageSelector from "components/common/ImageSelector";
import { useSelector } from "react-redux";

const CategoryAddEdit = (props) => {
    const { show, hideModal, recordID, handleRefresh, isStealDealCategory } = props;
    const { brandKeyValuePairs } = useSelector((state) => state.utility);
    const defaultRecordObj = {
        brandUuid: brandKeyValuePairs.length === 1 ? brandKeyValuePairs[0] : "",
        parentCategoryUuid: "",
        title: "",
        description: "",
        sortOrder: 1,
        categoryIconRefId: "",
        timingGroupUuid: "",
        availabilities: [],
        aggregatorAvailabilities: [],
        splitBill: false,
        categoryTaxUuids: [],
    };

    const [record, setRecord] = useState({ ...defaultRecordObj });
    const [orderAvailability, setOrderAvailability] = useState({ HomeDelivery: false, TakeAway: false, DineIn: false, StealDeal: isStealDealCategory });
    const [aggregatorAvailability, setAggregatorAvailability] = useState({});
    const [parentCategoryKeyPairList, setParentCategoryKeyPairList] = useState([]);
    const [timingGroupKeyPairList, setTimingGroupKeyPairList] = useState([]);
    const [categoryTaxKeyPairList, setCategoryTaxKeyPairList] = useState([])
    const [parentCategoryUuid, setParentCategoryUuid] = useState("");
    const [timingGroupUuid, setTimingGroupUuid] = useState("")
    const [categoryTaxUuids, setCategoryTaxUuids] = useState([])

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Client_Category_List_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const res = response.responsePacket;
                    handleSetInitialValues(res);
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, [recordID]);

    const handleSetInitialValues = (response) => {
        // Set brand Key
        const _selectedBrandUuid = brandKeyValuePairs.find((obj) => obj.key === response.brandUuid);
        if (!isStealDealCategory) {
            getParentCategoryKeyPairList(_selectedBrandUuid.key, response.parentCategoryUuid)
        }
        getTimingGroupKeyPairList(_selectedBrandUuid.key, response.timingGroupUuid)
        getCategoryTaxKeyPairList(_selectedBrandUuid.key, response.categoryTaxUuids)
        // set Parent Category Key
        const _selectedParentCategory = parentCategoryKeyPairList.find((obj) => obj.key === response.parentCategoryUuid);

        // Set Timing Group
        const _selectedTimingGroup = timingGroupKeyPairList.find((obj) => obj.key === response.timingGroupUuid);

        // Set Order Availabilities
        for (let i = 0; i < response.availabilities.length; i++) {
            const availabilitiesKey = response.availabilities[i];
            if (availabilitiesKey) {
                setOrderAvailability((prev) => ({ ...prev, [availabilitiesKey]: true }));
            }
        }

        // Set Aggregator Availabilities
        for (let i = 0; i < response.aggregatorAvailabilities.length; i++) {
            const aggregatorKey = response.aggregatorAvailabilities[i];
            if (aggregatorKey) {
                setAggregatorAvailability((prev) => ({ ...prev, [aggregatorKey]: true }));
            }
        }

        // Set Parent Category Key Pair
        let _categoryTaxUuids = [];
        for (let i = 0; i < response.categoryTaxUuids.length; i++) {
            const catKey = response.categoryTaxUuids[i];
            for (let j = 0; j < categoryTaxKeyPairList.length; j++) {
                if (catKey === categoryTaxKeyPairList[j].key) {
                    _categoryTaxUuids.push(categoryTaxKeyPairList[j]);
                }
            }
        }

        let recordObj = {
            ...response,
            brandUuid: _selectedBrandUuid || "",
            parentCategoryUuid: _selectedParentCategory || "",
            timingGroupUuid: _selectedTimingGroup || "",
            // sortOrder: record.sortOrder,
            categoryTaxUuids: _categoryTaxUuids.length ? _categoryTaxUuids : "",
        };
        setRecord(recordObj);
    };

    useEffect(() => {
        if (brandKeyValuePairs.length === 1) {
            if (!isStealDealCategory) {
                getParentCategoryKeyPairList(brandKeyValuePairs[0].key)
            }
            getTimingGroupKeyPairList(brandKeyValuePairs[0].key)
            getCategoryTaxKeyPairList(brandKeyValuePairs[0].key)
        }
    }, [])

    // Get Parent Category Key Pair List
    const getParentCategoryKeyPairList = (brandId, categoryId = "") => {
        let rUrl = Get_Client_Category_Key_Value_List_Post + "/" + brandId;
        const rObj = {};
        callPostApi(null, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                const _parentCategoryKeyValuePair = response.responsePacket.filter(obj => {
                    return obj.key !== recordID;
                });
                setParentCategoryKeyPairList(_parentCategoryKeyValuePair);
                const _categoryUuid = response.responsePacket.find((obj) => obj.key === categoryId);
                setParentCategoryUuid(_categoryUuid || "")
            } else {
                toast.error(response.message);
            }
        });
    };

    // Get Timing Group Key Pair List
    const getTimingGroupKeyPairList = (brandId, timingGroupId = "") => {
        let rUrl = Get_Timing_Group_Key_Value_Pair_List_Post + "/" + brandId;
        const rObj = {};
        callPostApi(null, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setTimingGroupKeyPairList(response.responsePacket);
                const _timingGroupId = response.responsePacket.find((obj) => obj.key === timingGroupId);
                setTimingGroupUuid(_timingGroupId || "");
            } else {
                toast.error(response.message);
            }
        });
    };

    // Get Category Tax Key Pair List
    const getCategoryTaxKeyPairList = (brandId, categoryUuids = []) => {
        let rUrl = Get_Client_Tax_Key_Value_List_Post + "/" + brandId;
        const rObj = {};
        callPostApi(null, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setCategoryTaxKeyPairList(response.responsePacket);
                const selectedCategories = []
                response.responsePacket.map(data => {
                    if (categoryUuids.includes(data.key)) {
                        selectedCategories.push(data)
                    }
                })
                setCategoryTaxUuids(selectedCategories)
            } else {
                toast.error(response.message);
            }
        });
    };

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if( id === "title" && value.length !==0) {
            if(!isAlphaNumercSpecialCharSpc(value)) {
                return;
            }
        }
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    //Text Check Box Change
    const handleCheckBoxChange = (e, key) => {
        const { id, checked } = e.target;
        if (key === "availabilities") {
            if (id === "StealDeal") {
                if (checked) {
                    setOrderAvailability({ HomeDelivery: false, TakeAway: false, DineIn: false, StealDeal: true });
                    setRecord((prev) => ({ ...prev, parentCategoryUuid: "" }));
                    setParentCategoryUuid("");
                } else {
                    setOrderAvailability((prev) => ({ ...prev, [id]: checked }));
                }
            } else {
                if (checked) {
                    setOrderAvailability((prev) => ({ ...prev, StealDeal: false, [id]: checked }));
                } else {
                    setOrderAvailability((prev) => ({ ...prev, [id]: checked }));
                }
            }
            // setOrderAvailability((prev) => ({ ...prev, [id]: checked }));
        } else if (key === "aggregatorAvailabilities") {
            setAggregatorAvailability((prev) => ({ ...prev, [id]: checked }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: checked }));
        }
    };

    //Text Check Availabilities
    const handleSelectChange = (e, id) => {
        if (id === "brandUuid") {
            if (!isStealDealCategory) {
                getParentCategoryKeyPairList(e.key)
            }
            getTimingGroupKeyPairList(e.key)
            getCategoryTaxKeyPairList(e.key)
        }
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    // Parent Category Select Change 
    const handleParentCategorySelect = (e) => {
        setParentCategoryUuid(e);
        if (e) {
            callGetApi(null, Details_Client_Category_List_Get.replaceAll("{recordID}", e?.key), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const res = response.responsePacket;

                    getTimingGroupKeyPairList(record.brandUuid.key, res.timingGroupUuid)
                    getCategoryTaxKeyPairList(record.brandUuid.key, res.categoryTaxUuids)
                    // handleSetInitialValues(res);
                    // Set Order Availabilities
                    for (let i = 0; i < res.availabilities.length; i++) {
                        const availabilitiesKey = res.availabilities[i];
                        if (availabilitiesKey) {
                            setOrderAvailability((prev) => ({ ...prev, [availabilitiesKey]: true }));
                        }
                    }

                    // Set Aggregator Availabilities
                    for (let i = 0; i < res.aggregatorAvailabilities.length; i++) {
                        const aggregatorKey = res.aggregatorAvailabilities[i];
                        if (aggregatorKey) {
                            setAggregatorAvailability((prev) => ({ ...prev, [aggregatorKey]: true }));
                        }
                    }

                } else {
                    toast.error(response.message);
                }
            });
        }
    }

    // IMAGE CHANGE
    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, categoryIconRefId: imageRefId });
    };

    // Check validation
    const checkInputValidation = (recordObj) => {
        if (isEmpty(recordObj.title)) {
            toast.error(`Title must not be empty`,{id: "toast"});
            return false;
        } else if (recordObj.title.length > 100) {
            toast.error(`Title must not be more then 60 character.`,{id: "toast"});
            return false;
        } else if (isEmpty(recordObj.brandUuid)) {
            toast.error(`Brand must not be empty`,{id: "toast"});
            return false;
        } else if (recordObj.availabilities.length === 0) {
            toast.error(`Order Availabilities must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(recordObj.sortOrder)) {
            toast.error(`Sort Order must not be empty`,{id: "toast"});
            return false;
        } else if (recordObj.sortOrder < 0) {
            toast.error(`Sort Order must not be negative`,{id: "toast"});
            return false;
        }
        // else if (isEmpty(recordObj.categoryIconRefId)) {
        //     toast.error(`Category icon must not be empty`,{id: "toast"});
        //     return false;
        // }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            let _aggregatorAvailability = [];
            let _orderAvailability = [];
            let _categoryTaxUuids = [];

            for (let key in orderAvailability) {
                if (orderAvailability[key]) {
                    _orderAvailability.push(key);
                }
            }

            for (let key in aggregatorAvailability) {
                if (aggregatorAvailability[key]) {
                    _aggregatorAvailability.push(key);
                }
            }

            for (let i = 0; i < categoryTaxUuids.length; i++) {
                const obj = categoryTaxUuids[i];
                _categoryTaxUuids.push(obj.key);
            }

            let recordObj = {
                ...record,
                sortOrder: parseInt(record.sortOrder),
                brandUuid: record.brandUuid.key,
                timingGroupUuid: timingGroupUuid?.key || "",
                categoryTaxUuids: _categoryTaxUuids,
                parentCategoryUuid: orderAvailability.StealDeal ? "" : parentCategoryUuid?.key || "",
                availabilities: _orderAvailability,
                aggregatorAvailabilities: orderAvailability.HomeDelivery ? _aggregatorAvailability : [],
            };

            if (!checkInputValidation(recordObj)) {
                return;
            }

            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Client_Category_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message);
                    }
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Client_Category_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message);
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    const getCategoryIconFieldset = () => {
        return (
            <fieldset className="my-3">
                <legend>Category Icon *</legend>
                <ImageSelector
                    style={{
                        height: "120px",
                        width: "100%",
                        // borderRadius: "50%",
                        overflow: "hidden",
                    }}
                    handleImageChange={handleImageChange}
                    selectedImage={record.categoryIconRefId}
                />
            </fieldset>
        )
    }

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`${recordID ? "Update" : "Add"} ${isStealDealCategory ? "Steal Deal" : "Food"} Category`} handleSubmit={handleSubmit}>
            <div className="row">
                <div className="col-lg-6">
                    <fieldset>
                        <legend>Category</legend>
                        <div className="form-group row align-items-center">
                            {!recordID &&
                                <div className="col-lg-12 my-2">
                                    <label>Brand *</label>
                                    <Select options={brandKeyValuePairs} value={record.brandUuid} onChange={(e) => handleSelectChange(e, "brandUuid")} />
                                </div>
                            }
                            <div className="col-lg-12 my-2">
                                <label> Title *</label>
                                <input type="text" maxLength={100} className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} />
                            </div>
                        </div>
                    </fieldset>
                    {!isStealDealCategory &&
                        <fieldset className="my-3">
                            <legend>Order Availabilities *</legend>
                            <div className="row ">
                                <div className="col-lg-6 my-2">
                                    <input type="checkbox" className="input-default me-2" id="DineIn" onChange={(e) => handleCheckBoxChange(e, "availabilities")} checked={orderAvailability?.DineIn} />
                                    <label htmlFor="DineIn">DineIn </label>
                                </div>
                                <div className="col-lg-6 my-2">
                                    <input type="checkbox" className="input-default me-2" id="HomeDelivery" onChange={(e) => handleCheckBoxChange(e, "availabilities")} checked={orderAvailability?.HomeDelivery} />
                                    <label htmlFor="HomeDelivery">Delivery </label>
                                </div>
                                <div className="col-lg-6 my-2">
                                    <input type="checkbox" className="input-default me-2" id="TakeAway" onChange={(e) => handleCheckBoxChange(e, "availabilities")} checked={orderAvailability?.TakeAway} />
                                    <label htmlFor="TakeAway">Takeaway</label>
                                </div>
                                {/* <div className="col-lg-6 my-2">
                                <input type="checkbox" className="input-default me-2" id="StealDeal" onChange={(e) => handleCheckBoxChange(e, "availabilities")} checked={orderAvailability?.StealDeal} />
                                <label htmlFor="StealDeal">StealDeal</label>
                            </div> */}
                            </div>
                        </fieldset>
                    }
                    {orderAvailability?.HomeDelivery &&
                        <fieldset className="my-3">
                            <legend>Aggregator Availabilities</legend>
                            <div className="row">
                                <div className="col-lg-6 my-2">
                                    <input
                                        type="checkbox"
                                        className="input-default me-2"
                                        id="Zomato"
                                        onChange={(e) => handleCheckBoxChange(e, "aggregatorAvailabilities")}
                                        checked={aggregatorAvailability?.Zomato}
                                    />
                                    <label htmlFor="Zomato">Zomato </label>
                                </div>
                                <div className="col-lg-6 my-2">
                                    <input
                                        type="checkbox"
                                        className="input-default me-2"
                                        id="Swiggy"
                                        onChange={(e) => handleCheckBoxChange(e, "aggregatorAvailabilities")}
                                        checked={aggregatorAvailability?.Swiggy}
                                    />
                                    <label htmlFor="Swiggy">Swiggy</label>
                                </div>
                            </div>
                        </fieldset>
                    }
                    {isStealDealCategory && getCategoryIconFieldset()}
                </div>
                <div className="col-lg-6">
                    <fieldset>
                        <legend>Other Details</legend>
                        <div className="form-group row align-items-center">
                            {!isStealDealCategory &&
                                <div className="col-lg-12 my-2">
                                    <label>Parent Category (optional)</label>
                                    <Select isDisabled={orderAvailability.StealDeal} options={parentCategoryKeyPairList} isClearable={true} value={parentCategoryUuid} onChange={handleParentCategorySelect} />
                                </div>
                            }
                            {/* <div className="col-lg-12 my-2">
                                <label> Sort Order *</label>
                                <input type="number" className="form-control input-default" id="sortOrder" onChange={handleTextChange} value={record.sortOrder} />
                            </div> */}
                            <div className="col-lg-12 my-2">
                                <label>Timing Group List </label>
                                <Select options={timingGroupKeyPairList} isClearable value={timingGroupUuid} onChange={(e) => setTimingGroupUuid(e)} />
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>Category Tax (Multi)</label>
                                <Select isMulti closeMenuOnSelect={false} options={categoryTaxKeyPairList} value={categoryTaxUuids} onChange={(e) => setCategoryTaxUuids(e)} />
                            </div>
                            <div className="col-lg-12 my-2">
                                <input type="checkbox" className="input-default me-2" id="splitBill" onChange={handleCheckBoxChange} checked={record.splitBill} />
                                <label htmlFor="splitBill">Split Bill </label>
                            </div>
                            <div className="col-lg-12 my-2">
                                <label> Description </label>
                                <textarea className="form-control input-default" id="description" cols="30" rows="4" onChange={handleTextChange} value={record.description} />
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            {!isStealDealCategory && getCategoryIconFieldset()}

        </ModalWrapper>
    );
};

export default CategoryAddEdit;
