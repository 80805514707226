export const initialRestaurantAddValue = {
  restaurantOwner: {
    firstName: "",
    lastName: "",
    isdCode: "+91",
    mobile: "",
    email: "",
    username: "",
    password: ""
  },
  brandUuid: "",
  restaurantTitle: "",
  iconImageRefId: "",
  gstNumber: "",
  tinNumber: "",
  vatNumber: ""
}
export const initialClientUserRestaurantOwnerDetails = {
  
    firstName: "",
    lastName: "",
    isdCode: "+91",
    mobile: "",
    email: "",
    password:"",
    currencyDetail: {
      currencyId: "" ,
      currencyTitle: "" ,
      currencySubUnitTitle:  "",
      unitSubdivision: 0,
      currencyCode: "" ,
      currencySymbol: "", 
    }
    
}
export const restaurantDefaultRecordObj = {
  address: {
    latitude: 0,
    longitude: 0,
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    pinCode: "",
    addressType: "Office",
    countryUuid: "",
    stateUuid: "",
    cityUuid: "",
    countryTitle: "",
    stateTitle: "",
    cityTitle: "",
  },
  brandUuid: "",
  restaurantTitle: "",
  restaurantDescription: "",
  restaurantLicenseNumber:"",
  iconImageRefId: "",
  restaurantClosedMessage: "",
  gstNumber: "",
  tinNumber: "",
  vatNumber: "",
  bannerImageRefId: "",
  webBannerImageRefIdCsv: "",
  showRestaurantTitle: false,
  showOrderType: false,
  printerImageRefId:"",
};

export const restaurantErrorMessage = {
  orderPrefix: "Order prefix must not be empty",
  restaurantTitle: "Restaurant title must not be empty",
  // restaurantDescription: "Restaurant description must not be empty",
  iconImageRefId: "Restaurant icon must not be empty",
  countryTitle: "Country name must not be empty",
  stateTitle: "Sate name must not be empty",
  cityTitle: "City name must not be empty",
  restaurantClosedMessage: "Restaurant closing message must not be empty",
  bannerImageRefId: "Restaurant banner image must not be empty",
  webBannerImageRefIdCsv: "Restaurant web banner must not be empty",
  gstNumber: "Restaurant GST number must not be empty",
  tinNumber: "Restaurant TIN number must not be empty",
  vatNumber: "Restaurant VAT number must not be empty",
  autoPrintKOT: true,
  autoPrintBOT: true,
  autoAcceptOrder: true,
  showInReservation: true,
  showInLocation: true,
  open: true,
  clubMode: false,
  dineIn: false,
  delivery: false,
  takeaway: true,
  stealDeal: false,
  restaurantTakeAwayDetail: {
    uuid: "228d9ce3-912d-4ec8-b3a0-486be7ac5360",
    active: true,
    createdAt: "2023-07-07T11:03:42.103",
    modifiedAt: "2023-07-07T11:03:42.103",
    deleted: false,
    takeAwayMinimumOrderAmount: 0,
  },
  ownerFullName: "Owner name must not be empty",
  ownerEmail: "Owner email must not be empty",
  ownerMobile: "Owner mobile must not be empty",

  latitude: "Restaurant latitude must not be empty",
  longitude: "Restaurant longitude must not be empty",
  addressLine1: "Restaurant address must not be empty",
  addressLine2: "Restaurant address must not be empty",
  addressLine3: "Restaurant address must not be empty",
  countryUuid: "Please select country",
  stateUuid: "Please select state",
  cityUuid: "Please select city",
  pinCode: "Restaurant pin code must not be empty",
  addressType: "Restaurant address type must not be empty",

  takeAwayMinimumOrderAmount: "Takeaway min. amount must not be empty",

  timingGroupUuid: "Timing group must not be empty",

  deliveryMinimumOrderAmount: "Delivery minimum order amount must not be empty",
  baseDeliveryCharges: "Base Delivery charges must not be empty",
  maximumDistanceForBaseCharges:
    "Maximum distance for base charges must not be empty",
  deliveryChargesPerKM: "Delivery charges per KM must not be empty",
  deliveryRadius: "Delivery radius must not be empty",
};

export const noValidationCheckKeys = {
  restaurantDescription: "",
  autoPrintKOT: true,
  autoPrintBOT: true,
  autoAcceptOrder: true,
  showInReservation: true,
  showInLocation: true,
  open: true,
  clubMode: false,
  dineIn: false,
  delivery: false,
  takeaway: true,
  stealDeal: false,
  addressLine2: "",
  addressLine3: "",
  webBannerImageRefIdCsv: "",
};

export const restaurantLengthErrorMsg = {
  orderPrefix: { errorMessage: "Order prefix must not be more then 10 character.", limit: 10 },
  restaurantTitle: { errorMessage: "Restaurant title must not be more then 50 character.", limit: 50 },
  gstNumber: { errorMessage: "Restaurant GST number must not be more then 50 character.", limit: 50 },
  tinNumber: { errorMessage: "Restaurant TIN number must not be more then 50 character.", limit: 50 },
  vatNumber: { errorMessage: "Restaurant VAT number must not be more then 50 character.", limit: 50 },
}