import { useEffect, useState } from "react";

import $ from "jquery";
import { toast } from "react-hot-toast";

import { callImageUploader, getImageSrcViaRefId } from "utils/UserUtils";

const ImageSelector = ({ style, user = false, selectedImage, handleImageChange, owned = true, favicon = false }) => {
    const [showPreLoader, setShowPreLoader] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [imageRefID, setImageRefID] = useState(null);

    useEffect(() => {
        if (imageRefID) {
            handleImageChange(imageRefID);
            setShowLoader(false);
        }
    }, [imageRefID]);

    const handleImageClick = (e) => {
        if (!owned) {
            return;
        }
        $(e.target.parentElement).find("input[type='file']").trigger("click");
    };

    const handleOnChange = (e) => {
        if (!owned) {
            return;
        }
        var imageTag = $(e.target.parentElement).find("img");
        const file = e.target.files[0];
        const imageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
        const fileType = file?.type;
        if (!e.target.files[0] || e.target.files[0] === undefined) {
            return;
        }

        if (!imageTypes.includes(fileType)) {
            toast.error("invalid file type",{id: "toast"});
            e.target.value = null;
            return;
        }

        var size = e.target.files[0].size / 1000; // Size in KB
        if (size > 2048) {
            toast.error("image size cannot exceed 2048 KBs",{id: "toast"});
            e.target.value = null;
        }
        previewImage(e.target, imageTag);
    };

    const previewImage = (inputTag, imageTag) => {
        if (inputTag.files && inputTag.files[0]) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                let base64 = e.target.result;
                let fileName = $(inputTag).val().split("\\").pop();
                imageTag.attr("src", base64);
                var base64Image = $(inputTag).parent().find(".base64Image");
                if (base64Image !== undefined) {
                    $(base64Image).val(base64);
                }
                try {
                    setShowLoader(true);
                    let imageRefId = await callImageUploader(base64, fileName);
                    setImageRefID(imageRefId);
                    // setShowLoader(false);
                } catch (error) {
                    setShowLoader(false);
                    return;
                }
                handleImageChange(fileName, base64);
            };
            reader.readAsDataURL(inputTag.files[0]);
        }
    };

    const getSelectedImage = () => {
        if (selectedImage === null || selectedImage === undefined || selectedImage === "") {
            if (user) {
                return "/assets/images/users/defaultUser.png";
            }
            return "/assets/images/gallery.png";
        } else {
            if(favicon){
                const link = document.querySelector('link[rel="icon"]');

                if (link) {
                  link.setAttribute('href', getImageSrcViaRefId(selectedImage));
                }
            }else{
                return getImageSrcViaRefId(selectedImage);
            }
        }
    };

    const checkImageLoader = () => {
        if (showPreLoader) {
            setShowPreLoader(false);
        }
    };

    return (
        <div className="imageParentDiv" style={{ ...style, position: "relative" }}>
            <img className="imgAvatarImage" alt="UserImage" onClick={handleImageClick} src={getSelectedImage()} onLoad={checkImageLoader} />
            <input type="hidden" className="avatarImageUrl" value="" />
            <input type="file" disabled={!owned} onChange={handleOnChange} className="form-control input-default avatarImage d-none" />
            <div className={"imageLoaderParent " + (showLoader === true || showPreLoader === true ? "d-flex" : "d-none")}>
                {/* <div className="imageLoader"></div> */}
                <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                </div>
            </div>
        </div>
    );
};

export default ImageSelector;
