import { useEffect, useState } from "react";

import toast from "react-hot-toast";
import Select from "react-select";
import { Button } from "react-bootstrap";

import {
  Details_Client_Category_List_Get,
  Details_Client_Menu_Item_List_Get,
  GET_Client_Steal_Deal_Category_Key_Value_List_Post,
  GET_Client_Sticker_Key_Value_List_Post,
  Get_Client_Category_Key_Value_List_Post,
  Get_Client_Condiment_Key_Value_List_Post,
  Get_Client_Item_Customization_Key_Value_List_Post,
  Get_Client_SubCategory_Key_Value_List_Post,
  Get_Client_Tax_Key_Value_List_Post,
  Get_Timing_Group_Key_Value_Pair_List_Post,
  Save_Client_Menu_Item_Post,
  Update_Client_Menu_Item_Put,
} from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isAlphaNumericSpc, isEmpty } from "utils/TextUtils";
import {
  DINE_IN,
  HOME_DELIVERY,
  MODAL_LOADER,
  STEAL_DEAL,
  SWIGGY,
  TAKEAWAY,
  ZOMATO,
} from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

import ImageSelector from "components/common/ImageSelector";
import ModalWrapper from "components/common/ModalWrapper";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

const MenuItemAddEdit = ({
  show,
  hideModal,
  recordID,
  handleRefresh,
  isStealDealItem,
}) => {
  const { brandKeyValuePairs } = useSelector((state) => state.utility);
  const defaultRecordObj = {
    brandUuid:
      brandKeyValuePairs && brandKeyValuePairs.length === 1
        ? brandKeyValuePairs[0]
        : "",
    categoryUuid: "",
    subCategoryUuid: "",
    title: "",
    description: "",
    nutrients: [
      {
        nutrientName: "",
        nutrientValue: "",
      },
    ],
    ingredients: "",
    imageType: "",
    itemImageRefId: "",
    itemCustomizationUuids: [],
    itemCondimentUuids: [],
    vegNonVeg: "",
    sticker: "",
    sortOrder: 1,
    timingGroupUuid: "",
    offerApplicable: true,
    maximumRetailPrice: 0,
    availabilityPrices: [],
    aggregatorAvailabilityPrices: [],
    itemTaxUuids: [],
    stealDealItemType: "",
    buyingUnit: "",
    quantity: 0,
    consumableUnit: "",
    consumableUnitPostfix: "",
    shortDescription: "",
  };
  const stealDealItemKeyValuePairs = [
    { label: "Glass", value: "Glass", key: "Glass" },
    { label: "Peg", value: "Peg", key: "Peg" },
    { label: "Shot", value: "Shot", key: "Shot" },
    { label: "Portion", value: "Portion", key: "Portion" },
    { label: "Piece", value: "Piece", key: "Piece" },
    { label: "Bottle", value: "Bottle", key: "Bottle" },
  ];
  const defaultAvailabilityPrices = [
    {
      menuAvailabilityType: DINE_IN,
      checked: false,
      price: 0,
    },
    {
      menuAvailabilityType: HOME_DELIVERY,
      checked: false,
      price: 0,
    },
    {
      menuAvailabilityType: TAKEAWAY,
      checked: false,
      price: 0,
    },

    {
      menuAvailabilityType: STEAL_DEAL,
      checked: isStealDealItem,
      price: 0,
    },
  ];
  const defaultAggregatorAvailabilityPrices = [
    {
      menuAvailabilityType: ZOMATO,
      checked: false,
      price: 0,
    },
    {
      menuAvailabilityType: SWIGGY,
      checked: false,
      price: 0,
    },
  ];
  const nutrientsOptions = [
    {
      nutrientName: "",
      nutrientValue: "",
    },
  ];
  const vegNonVegOptions = ["Veg", "NonVeg", "Vegan", "None"];
  const imageTypeOptions = ["SMALL", "BIG"];
  const [record, setRecord] = useState({ ...defaultRecordObj });
  const [availabilityPrices, setAvailabilityPrices] = useState(
    Array.from(defaultAvailabilityPrices)
  );
  const [aggregatorAvailabilityPrices, setAggregatorAvailabilityPrices] =
    useState(Array.from(defaultAggregatorAvailabilityPrices));
  const [nutrients, setNutrients] = useState(Array.from(nutrientsOptions));
  const [categoryUuid, setCategoryUuid] = useState("");
  const [itemTaxUuids, setItemTaxUuids] = useState([]);
  const [itemCondimentUuids, setItemCondimentUuids] = useState([]);
  const [itemCustomizationUuids, setItemCustomizationUuids] = useState([]);
  const [timingGroupUuid, setTimingGroupUuid] = useState("");
  const [sticker, setSticker] = useState("");

  const [categoryKeyValuePairList, setCategoryKeyValuePairList] = useState([]);
  const [subCategoryKeyValuePairList, setSubCategoryKeyValuePairList] =
    useState([]);
  const [taxKeyValuePairList, setTaxKeyValuePairList] = useState([]);
  const [condimentsKeyValuePairs, setCondimentsKeyValuePairs] = useState([]);
  const [customizationsKeyValuePairs, setCustomizationsKeyValuePairs] =
    useState([]);
  const [timingGroupKeyPairList, setTimingGroupKeyPairList] = useState([]);
  const [stickerKeyValuePairList, setStickerKeyValuePairList] = useState([]);

  const currencyDetail = new Cookies().get("currencyDetail");

  useEffect(() => {
    if (recordID) {
      callGetApi(
        MODAL_LOADER,
        Details_Client_Menu_Item_List_Get.replaceAll("{recordID}", recordID),
        {},
        (response) => {
          if (response.errorCode === API_SUCCESS) {
            const res = response.responsePacket;
            // ------- SETTING INITIAL VALUES -------

            // Set brand Key
            const _selectedBrandUuid = brandKeyValuePairs.find(
              (obj) => obj.key === res.brandUuid
            );

            getCategoryKeyValuePairs(res.brandUuid, false, res.categoryUuid);
            getItemTaxKeyPairList(res.brandUuid, res.itemTaxUuids);
            getCondimentsKeyValuePairs(res.brandUuid, res.itemCondimentUuids);
            getCustomizationsKeyValuePairs(
              res.brandUuid,
              res.itemCustomizationUuids
            );
            getTimingGroupKeyPairList(res.brandUuid, res.timingGroupUuid);
            getStickerKeyValuePairs(res.brandUuid, res.sticker);

            // Set Category
            const _categoryUuid = categoryKeyValuePairList.find(
              (obj) => obj.key === res.categoryUuid
            );

            // Set tax
            let _taxUuids = [];
            if (res.itemTaxUuids && res.itemTaxUuids.length !== 0) {
              _taxUuids = Array.from(taxKeyValuePairList).filter(
                (data) =>
                  res.itemTaxUuids.findIndex((obj) => obj === data.key) !==
                  -1 && data
              );
            }

            // set Condiments
            let _itemCondimentUuids = [];
            if (res.itemCondimentUuids && res.itemCondimentUuids.length !== 0) {
              _itemCondimentUuids = Array.from(condimentsKeyValuePairs).map(
                (data) =>
                  res.itemCondimentUuids.findIndex(
                    (obj) => obj === data.key
                  ) !== -1 && data
              );
            }

            // set customization
            let _itemCustomizationUuids = [];
            if (
              res.itemCustomizationUuids &&
              res.itemCustomizationUuids.length !== 0
            ) {
              _itemCustomizationUuids = Array.from(
                customizationsKeyValuePairs
              ).map(
                (data) =>
                  res.itemCustomizationUuids.findIndex(
                    (obj) => obj === data.key
                  ) !== -1 && data
              );
            }

            // Set Timing Group
            const _selectedTimingGroup = timingGroupKeyPairList.find(
              (obj) => obj.key === res.timingGroupUuid
            );

            // Set Sticker
            const _sticker = stickerKeyValuePairList.find(
              (obj) => obj.key === res.sticker
            );

            let _stealDealItemType = "";
            let _buyingUnit = "";
            let _consumableUnit = "";
            let _consumableUnitPostfix = "";

            handleCategoryChange(res.categoryUuid, res.subCategoryUuid, true);

            // Set Availability Prices
            let _availabilityPrices = [...availabilityPrices];
            if (res.availabilityPrices && res.availabilityPrices.length !== 0) {
              _availabilityPrices = _availabilityPrices.map((data) => {
                const foundIndex = res.availabilityPrices.findIndex(
                  (obj) =>
                    obj.menuAvailabilityType === data.menuAvailabilityType
                );
                if (foundIndex !== -1) {
                  data.checked = true;
                  data.price = res.availabilityPrices[foundIndex].price;
                } else {
                  data.checked = false;
                }
                return data;
              });
              if (
                res.availabilityPrices.find(
                  (obj) => obj.menuAvailabilityType === "StealDeal"
                )
              ) {
                _stealDealItemType = stealDealItemKeyValuePairs.find(
                  (obj) => obj.key === res.stealDealItemType
                );
                _buyingUnit = stealDealItemKeyValuePairs.find(
                  (obj) => obj.key === res.buyingUnit
                );
                _consumableUnit = stealDealItemKeyValuePairs.find(
                  (obj) => obj.key === res.consumableUnit
                );
                _consumableUnitPostfix = stealDealItemKeyValuePairs.find(
                  (obj) => obj.key === res.consumableUnitPostfix
                );
              }
            }
            setAvailabilityPrices(_availabilityPrices);

            // Set Aggregator Availability Prices
            let _aggregatorAvailability = [...aggregatorAvailabilityPrices];
            if (
              !isEmpty(res.aggregatorAvailabilityPrices) &&
              res.aggregatorAvailabilityPrices.length !== 0
            ) {
              _aggregatorAvailability = _aggregatorAvailability.map((data) => {
                const foundIndex = res.aggregatorAvailabilityPrices.findIndex(
                  (obj) =>
                    obj.menuAvailabilityType === data.menuAvailabilityType
                );
                if (foundIndex !== -1) {
                  data.checked = true;
                  data.price =
                    res.aggregatorAvailabilityPrices[foundIndex].price;
                } else {
                  data.checked = false;
                }
                return data;
              });
            }
            setAggregatorAvailabilityPrices(_aggregatorAvailability);
            if (!isEmpty(res.aggregatorAvailabilityPrices)) {
              setNutrients(res.nutrients);
            }

            let recordObj = {
              ...res,
              brandUuid: _selectedBrandUuid || "",
              timingGroupUuid: _selectedTimingGroup || "",
              categoryUuid: _categoryUuid || "",
              itemTaxUuids: _taxUuids.length !== 0 ? _taxUuids : [],
              itemCondimentUuids:
                _itemCondimentUuids.length !== 0 ? _itemCondimentUuids : [],
              itemCustomizationUuids:
                _itemCustomizationUuids.length !== 0
                  ? _itemCustomizationUuids
                  : [],
              stealDealItemType: _stealDealItemType,
              buyingUnit: _buyingUnit,
              consumableUnit: _consumableUnit,
              consumableUnitPostfix: _consumableUnitPostfix,
              sticker: _sticker === undefined ? "" : _sticker,
            };
            setRecord(recordObj);
          } else {
            toast.error(response.message);
          }
        }
      );
    }
  }, [recordID]);

  useEffect(() => {
    if (brandKeyValuePairs && brandKeyValuePairs.length === 1) {
      getCategoryKeyValuePairs(brandKeyValuePairs[0].key);
      getItemTaxKeyPairList(brandKeyValuePairs[0].key);
      getCondimentsKeyValuePairs(brandKeyValuePairs[0].key);
      getCustomizationsKeyValuePairs(brandKeyValuePairs[0].key);
      getTimingGroupKeyPairList(brandKeyValuePairs[0].key);
      getStickerKeyValuePairs(brandKeyValuePairs[0].key);
    }
  }, []);

  //Text Change Event
  const handleTextChange = (e) => {
    const { id, value, type } = e.target;
    if (id === "title" && value.length !== 0) {
      if (!isAlphaNumericSpc(value)) {
        return;
      }
    }
    if (id === "maximumRetailPrice") {
      if (value.length > 7) {
        return;
      }
    }
    if (type === "number") {
      setRecord((prev) => ({ ...prev, [id]: parseFloat(value) }));
    } else {
      setRecord((prev) => ({ ...prev, [id]: value }));
    }
  };

  //Text Check Availabilities
  const handleSelectChange = (e, id) => {
    setRecord((prev) => ({ ...prev, [id]: e }));
    if (id === "brandUuid") {
      getCategoryKeyValuePairs(e.key);
      getItemTaxKeyPairList(e.key);
      getCondimentsKeyValuePairs(e.key);
      getCustomizationsKeyValuePairs(e.key);
      getTimingGroupKeyPairList(e.key);
      getStickerKeyValuePairs(e.key);
    }
  };

  // Get Category Key Pair List
  const getCategoryKeyValuePairs = (
    brandUuId,
    isStealDeal,
    categoryUuid = ""
  ) => {
    let rUrl = "";
    if (isStealDealItem) {
      rUrl = GET_Client_Steal_Deal_Category_Key_Value_List_Post;
    } else {
      rUrl = Get_Client_Category_Key_Value_List_Post;
    }
    rUrl = rUrl + "/" + brandUuId;
    const rObj = {};
    callPostApi(null, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setCategoryKeyValuePairList(response.responsePacket);
        const _categoryUuid = response.responsePacket.find(
          (obj) => obj.key === categoryUuid
        );
        setCategoryUuid(_categoryUuid || "");
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Sub-Category Key Pair List
  const getSubCategoryKeyValuePairs = (categoryUuid, subCategoryId) => {
    const rUrl = Get_Client_SubCategory_Key_Value_List_Post.replaceAll(
      "{categoryID}",
      categoryUuid
    );
    callGetApi(null, rUrl, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setSubCategoryKeyValuePairList(response.responsePacket);
        // Set Sub Category
        if (subCategoryId) {
          setRecord((prev) => ({
            ...prev,
            subCategoryUuid: response.responsePacket.find(
              (obj) => obj.key === subCategoryId
            ),
          }));
        }
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Tax Key Pair List
  const getItemTaxKeyPairList = (brandUuId, taxes = []) => {
    let rUrl = Get_Client_Tax_Key_Value_List_Post + "/" + brandUuId;
    const rObj = {};
    callPostApi(null, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setTaxKeyValuePairList(response.responsePacket);
        const selectedTaxes = [];
        response.responsePacket.map((data) => {
          if (taxes.includes(data.key)) {
            selectedTaxes.push(data);
          }
        });
        setItemTaxUuids(selectedTaxes);
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Condiments Key Pair List
  const getCondimentsKeyValuePairs = (brandUuId, condiments = []) => {
    const rUrl = Get_Client_Condiment_Key_Value_List_Post + "/" + brandUuId;
    const rObj = {};
    callPostApi(null, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setCondimentsKeyValuePairs(response.responsePacket);
        const selectedCondiments = [];
        response.responsePacket.map((data) => {
          if (condiments.includes(data.key)) {
            selectedCondiments.push(data);
          }
        });
        setItemCondimentUuids(selectedCondiments);
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Customizations Key Pair List
  const getCustomizationsKeyValuePairs = (brandUuId, customizations = []) => {
    const rUrl =
      Get_Client_Item_Customization_Key_Value_List_Post + "/" + brandUuId;
    const rObj = {};
    callPostApi(null, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setCustomizationsKeyValuePairs(response.responsePacket);
        const selectedCustomization = [];
        response.responsePacket.map((data) => {
          if (customizations.includes(data.key)) {
            selectedCustomization.push(data);
          }
        });
        setItemCustomizationUuids(selectedCustomization);
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Timing Group Key Pair List
  const getTimingGroupKeyPairList = (brandUuId, timingGroup = "") => {
    let rUrl = Get_Timing_Group_Key_Value_Pair_List_Post + "/" + brandUuId;
    const rObj = {};
    callPostApi(null, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setTimingGroupKeyPairList(response.responsePacket);
        const _timingGroup = response.responsePacket.find(
          (obj) => obj.key === timingGroup
        );
        setTimingGroupUuid(_timingGroup || "");
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Sticker Key Value Pair
  const getStickerKeyValuePairs = (brandUuId, stickerUuid = "") => {
    const rUrl = GET_Client_Sticker_Key_Value_List_Post + "/" + brandUuId;
    const rObj = {};
    callPostApi(null, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setStickerKeyValuePairList(response.responsePacket);
        const _sticker = response.responsePacket.find(
          (obj) => obj.key === stickerUuid
        );
        setSticker(_sticker || "");
      } else {
        toast.error(response.message);
      }
    });
  };

  // Category change
  const handleCategoryChange = (
    categoryId,
    subCategoryId = null,
    isChange = false
  ) => {
    if (!isChange) {
      const rUrl = Details_Client_Category_List_Get.replaceAll(
        "{recordID}",
        categoryId
      );
      callGetApi(null, rUrl, {}, (response) => {
        if (response.errorCode === API_SUCCESS) {
          const res = response.responsePacket;
          getTimingGroupKeyPairList(res.brandUuid, res.timingGroupUuid);
          getItemTaxKeyPairList(record.brandUuid.key, res.categoryTaxUuids);

          // Set Availability Prices
          let _availabilityPrices = [...availabilityPrices];
          if (res.availabilities.length !== 0) {
            _availabilityPrices = _availabilityPrices.map((data) => {
              const foundIndex = res.availabilities.findIndex(
                (obj) => obj === data.menuAvailabilityType
              );
              if (foundIndex !== -1) {
                data.checked = true;
                data.price = 0;
              } else {
                data.checked = false;
              }
              return data;
            });
            if (res.availabilities.find((obj) => obj === "StealDeal")) {
            }
          }
          setAvailabilityPrices(_availabilityPrices);

          //Set Aggregator Availability Prices
          let _aggregatorAvailability = [...aggregatorAvailabilityPrices];
          if (
            !isEmpty(res.aggregatorAvailabilities) &&
            res.aggregatorAvailabilities.length !== 0
          ) {
            _aggregatorAvailability = _aggregatorAvailability.map((data) => {
              const foundIndex = res.aggregatorAvailabilities.findIndex(
                (obj) => obj === data.menuAvailabilityType
              );
              if (foundIndex !== -1) {
                data.checked = true;
                data.price = 0;
              } else {
                data.checked = false;
              }
              return data;
            });
          }
          setAggregatorAvailabilityPrices(_aggregatorAvailability);
        } else {
          toast.error(response.message);
        }
      });
      setRecord((prev) => ({ ...prev, subCategoryUuid: "" }));
    }

    // get sub-category on Category Change
    getSubCategoryKeyValuePairs(categoryId, subCategoryId);
  };


  // Availability Price changes
  const handleAvailabilityPrices = (e, availType, checkOrPrice) => {
    if (e.target.id === "title") {
      if (e.target.value.length > 7) {
        return;
      }
    }
    let _availabilityPrices = [...availabilityPrices];
    if (checkOrPrice === "check") {
      if (isStealDealItem && recordID) {
        return;
      }
      if (availType === "StealDeal") {
        setRecord({ ...record, categoryUuid: "", subCategoryUuid: "" });
        setCategoryUuid("");
        _availabilityPrices.map((data) => {
          if (e.target.checked && data.menuAvailabilityType === "StealDeal") {
            data.checked = e.target.checked;
          } else {
            data.checked = false;
            data.price = "";
          }
          return data;
        });
      } else {
        _availabilityPrices.map((data) => {
          if (e.target.checked && data.menuAvailabilityType === "StealDeal") {
            data.checked = false;
            data.price = 0;
          } else {
            if (data.menuAvailabilityType === availType) {
              if (e.target.checked) {
                data.checked = true;
                data.price = record.maximumRetailPrice;

              } else {
                data.checked = false;
                data.price = 0;
              }
            }
          }
          return data;
        });
      }

      setAvailabilityPrices(_availabilityPrices);
    } else {
      _availabilityPrices.map((data) => {
        if (data.menuAvailabilityType === availType) {
          data.price = parseFloat(e.target.value);
        }
        return data;
      });
      setAvailabilityPrices(_availabilityPrices);
    }
  };


  // const handleAvailabilityPrices = (e, availType, checkOrPrice) => {
  //   if (e.target.id === "title") {
  //     if (e.target.value.length > 7) {
  //       return;
  //     }
  //   }
  //   console.log(checkOrPrice);
  //   console.log(availType);
  //   let _availabilityPrices = [...availabilityPrices];
  //   if (checkOrPrice === "check") {
  //     if (isStealDealItem && recordID) {
  //       return;
  //     }
  //     if (availType === "StealDeal") {
  //       console.log("hello");
  //       setRecord({ ...record, categoryUuid: "", subCategoryUuid: "" });
  //       setCategoryUuid("");
  //       _availabilityPrices = _availabilityPrices.map((data) => {
  //         if (e.target.checked && data.menuAvailabilityType === "StealDeal") {
  //           return { ...data, checked: e.target.checked, price: record.maximumRetailPrice };
  //         } else {
  //           return { ...data, checked: false, price: 0 };
  //         }
  //       });
  //     } else {
  //       _availabilityPrices = _availabilityPrices.map((data) => {
  //         if (e.target.checked && data.menuAvailabilityType === "StealDeal") {
  //           return { ...data, checked: false, price: 0 };
  //         } else {
  //           return {
  //             ...data,
  //             checked: data.menuAvailabilityType === availType ? e.target.checked : false,
  //             price: data.menuAvailabilityType === availType && e.target.checked ? record.maximumRetailPrice : 0,
  //           };
  //         }
  //       });
  //     }
  //   } else {
  //     _availabilityPrices = _availabilityPrices.map((data) => {
  //       if (data.menuAvailabilityType === availType) {
  //         return { ...data, price: parseFloat(e.target.value) };
  //       }
  //       return data;
  //     });
  //   }
  //   setAvailabilityPrices(_availabilityPrices);
  // };


  // Aggregator Availability Prices changes
  const handleAggregatorAvailabilityPrices = (e, availType, checkOrPrice) => {
    if (e.target.id === "title") {
      if (e.target.value.length > 7) {
        return;
      }
    }
    let _aggregatorAvailabilityPrices = [...aggregatorAvailabilityPrices];
    if (checkOrPrice === "check") {
      _aggregatorAvailabilityPrices.map((data) => {
        if (data.menuAvailabilityType === availType) {
          if (e.target.checked) {
            data.checked = true;
          } else {
            data.checked = false;
            data.price = 0;
          }
        }
        return data;
      });
      setAggregatorAvailabilityPrices(_aggregatorAvailabilityPrices);
    } else {
      _aggregatorAvailabilityPrices.map((data) => {
        if (data.menuAvailabilityType === availType) {
          data.price = parseFloat(e.target.value);
        }
        return data;
      });
      setAggregatorAvailabilityPrices(_aggregatorAvailabilityPrices);
    }
  };

  // Add New Nutrient row
  const handleAddMoreNutrientRow = () => {
    setNutrients((prev) => [
      ...prev,
      JSON.parse(JSON.stringify(nutrientsOptions[0])),
    ]);
  };

  // Nutrients text Value change
  const handleNutrientsChange = (e, i, nameOrValue) => {
    const clone = Array.from(nutrients);
    if (nameOrValue === "name") {
      clone[i].nutrientName = e.target.value;
    } else {
      clone[i].nutrientValue = e.target.value;
    }
    setNutrients(clone);
  };

  // remove Nutrient row
  const handleRemoveNutrient = (i) => {
    const clone = Array.from(nutrients);
    clone.splice(i, 1);
    setNutrients(clone);
  };

  // IMAGE CHANGE
  const handleImageChange = (imageRefId) => {
    setRecord({ ...record, itemImageRefId: imageRefId });
  };

  // Check validation
  const checkInputValidation = (recordObj) => {
    if (isEmpty(recordObj.brandUuid)) {
      toast.error(`Brand must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.title)) {
      toast.error(`Title must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.maximumRetailPrice)) {
      toast.error(`Max Retail Price must not be empty`, { id: "toast" });
      return false;
    } else if (recordObj.maximumRetailPrice < 0) {
      toast.error(`Max Retail Price must not be negative`, { id: "toast" });
      return false;
    } else if (recordObj.availabilityPrices.length === 0) {
      toast.error(`Availabilities must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.categoryUuid)) {
      toast.error(`Category must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.sortOrder)) {
      toast.error(`Sort Order must not be empty`, { id: "toast" });
      return false;
    }
    //  else if (recordObj.itemTaxUuids.length === 0) {
    //   toast.error(`Category tax must not be empty`, { id: "toast" });
    //   return false;
    // } 
    else if (isEmpty(recordObj.vegNonVeg)) {
      toast.error(`Item Type must not be empty`, { id: "toast" });
      return false;
    } else if (recordObj.imageType === "") {
      toast.error(`Image Type must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.itemImageRefId)) {
      toast.error(`Image Ref ID must not be empty`, { id: "toast" });
      return false;
    }
    return true;
  };

  // check validation for steal deal
  const checkStealDealInputValidation = (recordObj) => {
    if (isEmpty(recordObj.stealDealItemType)) {
      toast.error(`Item Type must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.buyingUnit)) {
      toast.error(`Buying unit must not be empty,{id: "toast"}`);
      return false;
    } else if (isEmpty(recordObj.quantity)) {
      toast.error(`Quantity must not be empty`, { id: "toast" });
      return false;
    } else if (recordObj.quantity < 0) {
      toast.error(`Quantity must not be negative`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.consumableUnit)) {
      toast.error(`Consumable unit must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.consumableUnitPostfix)) {
      toast.error(`Consumable unit Postfix must not be empty`, { id: "toast" });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    try {
      let _availabilityPrices = availabilityPrices.reduce((acc, curr) => {
        if (curr.checked) {
          acc.push({
            menuAvailabilityType: curr.menuAvailabilityType,
            price: curr.price,
          });
        }
        return acc;
      }, []);

      let _aggregatorAvailability = aggregatorAvailabilityPrices.reduce(
        (acc, curr) => {
          if (curr.checked) {
            acc.push({
              menuAvailabilityType: curr.menuAvailabilityType,
              price: curr.price,
            });
          }
          return acc;
        },
        []
      );

      let _itemCustomizationUuids = itemCustomizationUuids.reduce(
        (acc, curr) => {
          acc.push(curr.key);
          return acc;
        },
        []
      );

      let _taxUuids = itemTaxUuids.reduce((acc, curr) => {
        acc.push(curr.key);
        return acc;
      }, []);

      let _itemCondimentUuids = itemCondimentUuids.reduce((acc, curr) => {
        acc.push(curr.key);
        return acc;
      }, []);

      let _subCategory =
        subCategoryKeyValuePairList.length !== 0 ? record.subCategoryUuid : "";

      let recordObj = {
        ...record,
        sortOrder: parseInt(record.sortOrder),
        brandUuid: record.brandUuid?.key || "",
        categoryUuid: categoryUuid?.key || "",
        timingGroupUuid: timingGroupUuid?.key || "",
        availabilityPrices: _availabilityPrices,
        aggregatorAvailabilityPrices: availabilityPrices.find(
          (obj) => obj.menuAvailabilityType === HOME_DELIVERY && obj.checked
        )
          ? _aggregatorAvailability
          : [],
        itemCustomizationUuids: _itemCustomizationUuids.filter(
          (item) => item !== null && item !== undefined
        ),
        itemTaxUuids: _taxUuids,
        subCategoryUuid: _subCategory?.key || null,
        itemCondimentUuids: _itemCondimentUuids.filter(
          (item) => item !== null && item !== undefined
        ),
        sticker: sticker.key || "",
      };

      if (!checkInputValidation(recordObj)) {
        return;
      }

      if (
        recordObj.availabilityPrices.find(
          (obj) => obj.menuAvailabilityType === "StealDeal"
        )
      ) {
        if (!checkStealDealInputValidation(recordObj)) {
          return;
        }
        recordObj.stealDealItemType = recordObj.stealDealItemType.key;
        recordObj.buyingUnit = recordObj.buyingUnit.key;
        recordObj.consumableUnit = recordObj.consumableUnit.key;
        recordObj.consumableUnitPostfix = recordObj.consumableUnitPostfix.key;
      } else {
        delete recordObj.stealDealItemType;
        delete recordObj.buyingUnit;
        delete recordObj.consumableUnit;
        delete recordObj.consumableUnitPostfix;
      }

      let _nutrients = [];
      if (recordID) {
        for (let i = 0; i < nutrients.length; i++) {
          if (nutrients[i].nutrientName === "") {
            toast.error("Please Provide Name for all the nutrients");
            return;
          }
          _nutrients.push(nutrients[i]);
        }

        recordObj.nutrients = _nutrients;
      } else {
        recordObj.nutrients = _nutrients;
      }

      if (!recordID || recordID === "") {
        let rUrl = Save_Client_Menu_Item_Post;
        callPostApi(MODAL_LOADER, rUrl, recordObj, {}, (response) => {
          if (response.errorCode === API_SUCCESS) {
            toast.success(response.message, { id: "toast" });
            hideModal();
            handleRefresh();
          } else {
            toast.error(response.message, { id: "toast" });
          }
        });
      } else {
        let rUrl = Update_Client_Menu_Item_Put.replaceAll(
          "{recordID}",
          recordID
        );
        callPutApi(MODAL_LOADER, rUrl, recordObj, {}, (response) => {
          if (response.errorCode === API_SUCCESS) {
            toast.success(response.message, { id: "toast" });
            hideModal();
            handleRefresh();
          } else {
            toast.error(response.message, { id: "toast" });
          }
        });
      }
    } catch (error) {
      return;
    }
  };

  return (
    <ModalWrapper
      show={show}
      hide={hideModal}
      backdrop="static"
      header={`${recordID ? "Update" : "Add"} ${isStealDealItem ? "Steal Deal" : "Food"
        } Item`}
      handleSubmit={handleSubmit}
      size="xl"
    >
      <div className="row">
        <div className="col-lg-7">
          <fieldset>
            <legend>Basic Details</legend>
            <div className="form-group row align-items-center">
              {!recordID && (
                <div className="col-lg-12 my-2">
                  <label>Brand *</label>
                  <Select
                    isDisabled={!recordID ? false : true}
                    options={brandKeyValuePairs}
                    value={record.brandUuid}
                    onChange={(e) => handleSelectChange(e, "brandUuid")}
                  />
                </div>
              )}
              <div className="col-lg-12 my-2">
                <label> Title *</label>
                <input
                  type="text"
                  maxLength={100}
                  className="form-control input-default"
                  id="title"
                  onChange={handleTextChange}
                  value={record.title}
                />
              </div>
              <div className={`col-lg-${isStealDealItem ? 6 : 12} my-2`}>
                <label> Max Retail Price *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control input-default"
                    id="maximumRetailPrice"
                    onChange={handleTextChange}
                    value={record.maximumRetailPrice}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text bg-red">
                      {currencyDetail?.currencySymbol}
                    </span>
                  </div>
                </div>
              </div>
              {isStealDealItem &&
                availabilityPrices.map(
                  (data, i) =>
                    data.menuAvailabilityType === STEAL_DEAL && (
                      <div
                        className="col-lg-6 my-2"
                        key={data.menuAvailabilityType}
                      >
                        <label>Availability Price </label>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control input-default"
                            id="title"
                            onChange={(e) =>
                              handleAvailabilityPrices(
                                e,
                                data.menuAvailabilityType,
                                "price"
                              )
                            }
                            value={data.price}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text bg-red">
                              {currencyDetail?.currencySymbol}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </fieldset>
          {!isStealDealItem && (
            <fieldset className="my-4">
              <legend>Availability Prices *</legend>
              <div className="row">
                {availabilityPrices.map(
                  (data, i) =>
                    data.menuAvailabilityType !== STEAL_DEAL && (
                      <div
                        className="my-2 col-6"
                        key={data.menuAvailabilityType}
                      >
                        <div className="row align-items-center">
                          <div className="col-6">
                            <input
                              type="checkbox"
                              maxLength={10}
                              className="input-default me-2"
                              id={data.menuAvailabilityType}
                              onChange={(e) =>
                                handleAvailabilityPrices(
                                  e,
                                  data.menuAvailabilityType,
                                  "check"
                                )
                              }
                              checked={data.checked}
                            />
                            <label htmlFor={data.menuAvailabilityType}>
                              {data.menuAvailabilityType}
                            </label>
                          </div>
                          <div className="col-6">
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control input-default"
                                id="title"
                                placeholder="Price"
                                disabled={!data.checked}
                                onChange={(e) =>
                                  handleAvailabilityPrices(
                                    e,
                                    data.menuAvailabilityType,
                                    "price"
                                  )
                                }
                                value={data.price}
                              />
                              {console.log(data)}
                              <div className="input-group-append">
                                <span className="input-group-text bg-red">
                                  {currencyDetail?.currencySymbol}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </fieldset>
          )}
          {isStealDealItem && (
            <fieldset className="my-4">
              <legend>Steal deal Details *</legend>
              <div className="form-group row align-items-center">
                <div className="col-lg-4 my-2">
                  <label>Item Type *</label>
                  <Select
                    options={stealDealItemKeyValuePairs}
                    value={record.stealDealItemType}
                    onChange={(e) => handleSelectChange(e, "stealDealItemType")}
                  />
                </div>
                <div className="col-lg-4 my-2">
                  <label> Buying Unit *</label>
                  <Select
                    options={stealDealItemKeyValuePairs}
                    value={record.buyingUnit}
                    onChange={(e) => handleSelectChange(e, "buyingUnit")}
                  />
                </div>
                <div className="col-lg-4 my-2">
                  <label> Quantity *</label>
                  <input
                    type="number"
                    className="form-control input-default"
                    id="quantity"
                    onChange={handleTextChange}
                    value={record.quantity}
                  />
                </div>
                <div className="col-lg-6 my-2">
                  <label> Consumable Unit *</label>
                  <Select
                    options={stealDealItemKeyValuePairs}
                    value={record.consumableUnit}
                    onChange={(e) => handleSelectChange(e, "consumableUnit")}
                  />
                </div>
                <div className="col-lg-6 my-2">
                  <label> Consumable Unit Postfix *</label>
                  <Select
                    options={stealDealItemKeyValuePairs}
                    value={record.consumableUnitPostfix}
                    onChange={(e) =>
                      handleSelectChange(e, "consumableUnitPostfix")
                    }
                  />
                </div>
                <div className="col-lg-12 my-2">
                  <label> Short Description </label>
                  <textarea
                    className="form-control input-default"
                    id="shortDescription"
                    cols="30"
                    rows="4"
                    onChange={handleTextChange}
                    value={record.shortDescription}
                  />
                </div>
              </div>
            </fieldset>
          )}
          {availabilityPrices[1].checked && (
            <fieldset className="my-4">
              <legend>Aggregator Availability Prices</legend>
              <div className="row">
                {aggregatorAvailabilityPrices.map((data, i) => (
                  <div className="my-2 col-6" key={data.menuAvailabilityType}>
                    <div className="row align-items-center">
                      <div className="col-6">
                        <input
                          type="checkbox"
                          className="input-default me-2"
                          id={data.menuAvailabilityType}
                          onChange={(e) =>
                            handleAggregatorAvailabilityPrices(
                              e,
                              data.menuAvailabilityType,
                              "check"
                            )
                          }
                          checked={data.checked}
                        />
                        <label htmlFor={data.menuAvailabilityType}>
                          {data.menuAvailabilityType}
                        </label>
                      </div>
                      <div className="col-6">
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control input-default"
                            id="title"
                            placeholder="Price"
                            disabled={!data.checked}
                            onChange={(e) =>
                              handleAggregatorAvailabilityPrices(
                                e,
                                data.menuAvailabilityType,
                                "price"
                              )
                            }
                            value={data.price}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text bg-red">
                              {currencyDetail?.currencySymbol}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
          )}

          {recordID && (
            <fieldset className="my-4">
              <legend>Nutrients</legend>
              {nutrients.map((data, i) => (
                <div className="my-2" key={i}>
                  <div className="row">
                    <div className="col-lg-11">
                      <div className="row">
                        <div className="col-6">
                          <input
                            type="text"
                            className="form-control input-default"
                            id="title"
                            placeholder="Name"
                            onChange={(e) =>
                              handleNutrientsChange(e, i, "name")
                            }
                            value={data.nutrientName}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            className="form-control input-default"
                            id="title"
                            placeholder="Value"
                            onChange={(e) =>
                              handleNutrientsChange(e, i, "value")
                            }
                            value={data.nutrientValue}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1">
                      <Button onClick={(e) => handleRemoveNutrient(i)}>
                        <i className="bx bx-trash" />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <Button onClick={handleAddMoreNutrientRow}> + Add New Row</Button>
            </fieldset>
          )}
        </div>
        <div className="col-lg-5">
          <fieldset>
            <legend>Other Details</legend>
            <div className="form-group row align-items-center">
              <div className="col-lg-12 my-2">
                <label>Category *</label>
                <Select
                  options={categoryKeyValuePairList}
                  value={categoryUuid}
                  onChange={(e) => {
                    handleCategoryChange(e.key);
                    setCategoryUuid(e);
                  }}
                />
              </div>
              {!isEmpty(categoryUuid) &&
                !isStealDealItem &&
                subCategoryKeyValuePairList.length !== 0 && (
                  <div className="col-lg-12 my-2">
                    <label>Sub Category</label>
                    <Select
                      options={subCategoryKeyValuePairList}
                      value={record.subCategoryUuid}
                      onChange={(e) => handleSelectChange(e, "subCategoryUuid")}
                    />
                  </div>
                )}
              {/* <div className="col-lg-4 my-2">
                                <label> Sort Order *</label>
                                <input type="number" className="form-control input-default" id="sortOrder" onChange={handleTextChange} value={record.sortOrder} />
                            </div> */}
              <div className="col-lg-12 my-2">
                <label>Item Taxes (Multi) *</label>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  options={taxKeyValuePairList}
                  value={itemTaxUuids}
                  onChange={(e) => setItemTaxUuids(e)}
                />
              </div>
              {!isStealDealItem && (
                <>
                  <div className="col-lg-12 my-2">
                    <label>Condiments (Multi)</label>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      options={condimentsKeyValuePairs}
                      value={itemCondimentUuids}
                      onChange={(e) => setItemCondimentUuids(e)}
                    />
                  </div>
                  <div className="col-lg-12 my-2">
                    <label>Customizations (Multi)</label>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      options={customizationsKeyValuePairs}
                      value={itemCustomizationUuids}
                      onChange={(e) => setItemCustomizationUuids(e)}
                    />
                  </div>
                  <div className="col-lg-12 my-2">
                    <label>Timing Group List</label>
                    <Select
                      isClearable
                      options={timingGroupKeyPairList}
                      value={timingGroupUuid}
                      onChange={(e) => setTimingGroupUuid(e)}
                    />
                  </div>
                  <div className="col-lg-12 my-2">
                    <label>Sticker</label>
                    <Select
                      isClearable
                      options={stickerKeyValuePairList}
                      value={sticker}
                      onChange={(e) => setSticker(e)}
                    />
                  </div>
                </>
              )}
              {recordID && (
                <div className="col-xl-12 my-2">
                  <label> Ingredients</label>
                  <textarea
                    className="form-control input-default"
                    id="ingredients"
                    onChange={handleTextChange}
                    value={record.ingredients}
                  />
                </div>
              )}
              <div className="col-lg-12 my-3">
                <fieldset>
                  <legend>Item Type</legend>
                  <div className="d-flex">
                    {vegNonVegOptions.map((data) => (
                      <div key={data} className="me-3 form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="vegNonVeg"
                          id={data}
                          checked={record.vegNonVeg === data}
                          onChange={(e) =>
                            setRecord({ ...record, vegNonVeg: data })
                          }
                        />
                        <label htmlFor={data}>{data}</label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              {!isStealDealItem && (
                <div className="col-lg-12 my-2">
                  <div className="form-check">
                    <label htmlFor="offerApplicable">Offer Applicable</label>
                    <input
                      type="checkbox"
                      name="offerApplicable"
                      id="offerApplicable"
                      className="form-check-input"
                      checked={record.offerApplicable}
                      onChange={(e) =>
                        setRecord({
                          ...record,
                          offerApplicable: e.target.checked,
                        })
                      }
                    />
                  </div>
                </div>
              )}
              <div className="col-xl-12 my-2">
                <label> Description </label>
                <textarea
                  className="form-control input-default"
                  id="description"
                  onChange={handleTextChange}
                  value={record.description}
                />
              </div>
            </div>
          </fieldset>
          <fieldset className="my-4">
            <div className="col-lg-12 my-2">
              <div className="d-flex">
                <label className="me-3">Image Type:</label>
                {imageTypeOptions.map((data) => (
                  <div key={data} className="me-2 form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="imageType"
                      id={data}
                      checked={record.imageType === data}
                      onChange={(e) =>
                        setRecord({ ...record, imageType: data })
                      }
                    />
                    <label htmlFor={data}>{data}</label>
                  </div>
                ))}
              </div>
            </div>
            <legend>Image</legend>
            <ImageSelector
              style={{
                height: "120px",
                width: "100%",
                // borderRadius: "50%",
                overflow: "hidden",
              }}
              handleImageChange={handleImageChange}
              selectedImage={record.itemImageRefId}
            />
          </fieldset>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default MenuItemAddEdit;
