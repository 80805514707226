import {
    HIDE_BULK_INVOICE_DOWNLOAD_MODAL,
    HIDE_MODAL_LOADING,
    HIDE_PAGE_LOADING,
    HIDE_SECTION_LOADING,
    SHOW_BULK_INVOICE_DOWNLOAD_MODAL,
    SHOW_MODAL_LOADING,
    SHOW_PAGE_LOADING,
    SHOW_SECTION_LOADING,
    UPDATE_BRAND_KEY_VALUE_PAIRS,
} from "../actions";
const defaultObj = {
    showSectionLoader: false,
    showPageLoader: false,
    showModalLoader: false,
    bulkInvoiceDownloadModal: false,
};

export const UtilityReducer = (state = defaultObj, action) => {
    switch (action.type) {
        case SHOW_SECTION_LOADING:
            return {
                ...state,
                showSectionLoader: true,
            };
        case HIDE_SECTION_LOADING:
            return {
                ...state,
                showSectionLoader: false,
            };
        case SHOW_PAGE_LOADING:
            return {
                ...state,
                showPageLoader: true,
            };
        case HIDE_PAGE_LOADING:
            return {
                ...state,
                showPageLoader: false,
            };
        case SHOW_MODAL_LOADING:
            return {
                ...state,
                showModalLoader: true,
            };
        case HIDE_MODAL_LOADING:
            return {
                ...state,
                showModalLoader: false,
            };
        case SHOW_BULK_INVOICE_DOWNLOAD_MODAL:
            return {
                ...state,
                bulkInvoiceDownloadModal: true,
            };
        case HIDE_BULK_INVOICE_DOWNLOAD_MODAL:
            return {
                ...state,
                bulkInvoiceDownloadModal: false,
            };
        case UPDATE_BRAND_KEY_VALUE_PAIRS:
            return {
                ...state,
                brandKeyValuePairs: action.payload,
            };
        default:
            return state;
    }
};
