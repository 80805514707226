import { memo, useEffect, useState } from 'react'

import toast from 'react-hot-toast';

import { Apply_Coupon_Code_Put, Get_Coupon_Code_Key_Pair_List_Post } from 'api/ApiConst';
import { callPostApi, callPutApi } from 'api/ApiCaller';

import { API_SUCCESS } from 'api/ResponseCodes';
import { MODAL_LOADER } from 'utils/constants';
import ModalWrapper from 'components/common/ModalWrapper'

const ApplyNewCoupon = ({ show, hideModal, recordID, handleRemoveApplyCoupon, handleRefreshOrderDtls, handleRefresh }) => {
    const [availableCouponCodeList, setAvailableCouponCodeList] = useState([]);
    const [refreshFlag, setRefreshFlag] = useState(0);

    useEffect(() => {
        if (recordID) {
            callPostApi(MODAL_LOADER, Get_Coupon_Code_Key_Pair_List_Post.replaceAll("{recordID}", recordID), {}, {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    setAvailableCouponCodeList(response.responsePacket);
                }
            });
        }
    }, [recordID, refreshFlag]);

    // handle Apply Coupon Code
    const handleApplyCoupon = (row) => {
        const toastId="applyCoupon";
        callPutApi(MODAL_LOADER, Apply_Coupon_Code_Put.replaceAll("{couponCode}", row.couponCode).replaceAll("{recordID}", recordID), {}, {}, (response) => {

            if (response.errorCode === API_SUCCESS) {
                toast.success(response.message, {id: "toast"})
                handleRefreshOrderDtls();
                handleRefresh();
                hideModal();
            } else {
                toast.error(response.message, {id: "toast"})
            }
        }, error => {
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : error.message, { id:toastId });
        });
    }

    const handleRemoveCoupon = async () => {
        const couponRemoved = await handleRemoveApplyCoupon();
        if (couponRemoved) {
            handleRefreshOrderDtls()
            handleRefresh();
            hideModal();
        }
    }

    return (
        <ModalWrapper show={show} hide={hideModal}  backdrop="static" header={"Select Coupon"} size="ms">
            {availableCouponCodeList.length !== 0 && availableCouponCodeList.map((coupon, i) =>
                <div className={`card cms-bg py-2 mb-0 #adb23d3d #bbc0433d`} key={"coupon-" + i}>
                    <div className="card-body">
                        <div className=' d-flex justify-content-between align-items-center'>
                            <div>
                                <h3 className="card-title">{coupon.title}</h3>
                                <h6 className="card-subtitle my-2">Coupon Code: {coupon.couponCode}</h6>
                                <h6 className="card-subtitle">Min Order Value: {coupon.minOrderValue}</h6>
                            </div>
                            <div>
                                <a className='btn bg-info text-white py-1 cursor' onClick={() => handleApplyCoupon(coupon)}>Apply</a>
                                {/* {coupon.applied ?
                                    <a className='btn bg-warning text-white py-1 cursor' onClick={handleRemoveCoupon}>Remove</a>
                                    :
                                    <a className='btn bg-info text-white py-1 cursor' onClick={() => handleApplyCoupon(coupon)}>Apply</a>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ModalWrapper>
    )
}

export default memo(ApplyNewCoupon)