import React, { useCallback, useEffect, useMemo, useState } from "react";

import { toast } from "react-hot-toast";

import {
    Activate_LocalState_PUT,
    All_LocalState_List_POST,
    CountryList_LocalCountry_POST,
    DeActivate_LocalState_PUT,
    Delete_LocalState_DELETE,
    Deleted_LocalState_List_POST,
    Revive_LocalState_PUT,
} from "api/ApiConst";
import { callPostApi } from "api/ApiCaller";
import { handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import { SECTION_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

import CheckboxButton from "components/common/CheckboxButton";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import LocalStateAddEdit from "./LocalStateAddEdit";

const LocalStateMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [countryOptionsList, setCountryOptionsList] = useState([]);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    useEffect(() => {
        getCountryKeyValuePairList();
    }, []);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const getCountryKeyValuePairList = () => {
        callPostApi(SECTION_LOADER, CountryList_LocalCountry_POST, {}, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                const options = response.responsePacket.map((country) => ({
                    value: country.key,
                    label: country.value,
                    key: "countryUuid",
                }));
                setCountryOptionsList(options);
            } else {
                toast.error(response.message);
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => <div className={row.active ? "led-green" : "led-red"} title={row.active ? "Active" : "Inactive"} />,
                width: "60px",
                sortable: false,
            },
            {
                id: "fullName",
                name: "Full Name",
                selector: (row) => row.title,
                sortable: false,
            },
            {
                id: "countryName",
                name: "Country Name",
                selector: (row) => row.countryTitle,
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => <div className="custom-column-action">{getActionButtons(row)}</div>,
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )}
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_LocalState_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_LocalState_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_LocalState_DELETE.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_LocalState_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("Local State List", "Dashboard", "/")}
            <div className="card">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_LocalState_List_POST : All_LocalState_List_POST}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showAddUpdateModal && (
                <LocalStateAddEdit show={showAddUpdateModal} hideModal={() => setShowAddUpdateModal(false)} recordID={recordID} handleRefresh={handleRefresh} countryOptionsList={countryOptionsList} />
            )}
        </>
    );
};

export default LocalStateMaster;
