import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { Details_Client_Cuisine_List_Get, Save_Client_Cuisine_Post, Update_Client_Cuisine_Put } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { API_SUCCESS } from "api/ResponseCodes";
import {  isAlphaNumericSpc, isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";
import ImageSelector from "components/common/ImageSelector";
import Select from "react-select";
import { useSelector } from "react-redux";

const CuisineAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const { brandKeyValuePairs } = useSelector((state) => state.utility);
    const defaultRecordObj = {
        cuisineTitle: "",
        brandUuid: brandKeyValuePairs.length === 1 ? brandKeyValuePairs[0] : "",
        cuisineImageRefId: "",
        viewOrder: 1,
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Client_Cuisine_List_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const res = response.responsePacket;
                    setRecord(res);
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if( id === "cuisineTitle") {
            if (!isAlphaNumericSpc(value) && value.length!== 0) {
                return;
            }
        }
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    //Select Text Change Event
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    // IMAGE CHANGE
    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, cuisineImageRefId: imageRefId });
    };

    const checkInputValidation = () => {
        if (isEmpty(record.cuisineImageRefId)) {
            toast.error(`Cuisine image must not be empty`, { id: "toast" });
            return false;
        } else if (!recordID && isEmpty(record.brandUuid)) {
            toast.error(`Brand must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.cuisineTitle)) {
            toast.error(`Cuisine title must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.viewOrder)) {
        } else if (record.cuisineTitle.length < 3) {
            toast.error(`Cuisine title must be more than 2 characters`, { id: "toast" });
            return false;
        } else if (isEmpty(record.viewOrder)) {
            toast.error(`Cuisine order must not be empty`, { id: "toast" });
            return false;
        } else if (record.viewOrder < 0) {
            toast.error(`Cuisine order must not be negative`, { id: "toast" });
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            recordObj.brandUuid = record.brandUuid?.key || "";
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Client_Cuisine_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Client_Cuisine_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`${recordID ? "Update" : "Add"} Cuisine`} size="ms" handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12 my-3">
                    <ImageSelector
                        style={{
                            height: "120px",
                            width: "100%",
                            // borderRadius: "50%",
                            overflow: "hidden",
                        }}
                        handleImageChange={handleImageChange}
                        selectedImage={record.cuisineImageRefId}
                    />
                </div>
                {!recordID &&
                    <div className="col-lg-12 my-2">
                        <label>Brand *</label>
                        <Select options={brandKeyValuePairs} value={record.brandUuid} onChange={(e) => handleSelectChange(e, "brandUuid")} />
                    </div>
                }
                <div className="col-lg-12 my-2">
                    <label> Cuisine Title *</label>
                    <input type="text" maxLength={25} className="form-control input-default" id="cuisineTitle" onChange={handleTextChange} value={record.cuisineTitle} />
                </div>
                {/* <div className="col-lg-12 my-2">
                    <label> Cuisine View Order *</label>
                    <input type="number" className="form-control input-default" id="viewOrder" onChange={handleTextChange} value={record.viewOrder} />
                </div> */}
            </div>
        </ModalWrapper>
    );
};

export default CuisineAddEdit;
