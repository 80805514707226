import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { Add_LocalCountry_POST, Get_LocalCountry_Detail_GET, Update_LocalCountry_PUT } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isChar, isEmpty, isValidIsdCode } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";

const LocalCountryAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const defaultRecordObj = {
        title: "",
        shortName: "",
        phoneCode: "",
        dateOfBirthTimeStamp: "",
        currencySymbol: "",
        currencyCode: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Get_LocalCountry_Detail_GET.replaceAll("{recordID}", recordID), {}, (response) => {
                const res = response.responsePacket;
                setRecord(res);
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const {id, value} = e.target
        if( id === "shortName") {
            if (!isChar(value) && value.length !== 0) {
                return;
            }
        }
        if( id === "title") {
            if (!isChar(value) && value.length !== 0) {
                return;
            }
        }
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.shortName)) {
            toast.error(`Country short name must not be empty`,{id: "toast"});
            return false;
        } else if (record.shortName.length > 3) {
            toast.error(`Country short name must not be more then 3 character`,{id: "toast"});
            return false;
        } else if (isEmpty(record.title)) {
            toast.error(`Country name must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(record.phoneCode)) {
            toast.error(`Phone code must not be empty`,{id: "toast"});
            return false;
        } else if (!isValidIsdCode(record.phoneCode)) {
            toast.error(`Invalid phone code`,{id: "toast"});
            return false;
        }  else if (isEmpty(record.currencySymbol)) {
            toast.error(`Currency symbol must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(record.currencyCode)) {
            toast.error(`Currency code must not be empty`,{id: "toast"});
            return false;
        }
        return true;
        //  else if (record.phoneCode.length > 6) {
        //     toast.error(`Phone code must not be more then 6 character`,{id: "toast"});
        //     return false;
        // }
        //  else if (record.currencySymbol.length > 5) {
        //     toast.error(`Currency symbol must not be more then 5 character`,{id: "toast"});
        //     return false;
        // } 
        //  else if (record.currencyCode.length > 10) {
        //     toast.error(`Currency code must not be more then 10 character`,{id: "toast"});
        //     return false;
        // }

    };

    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Add_LocalCountry_POST, recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(MODAL_LOADER, Update_LocalCountry_PUT.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`${recordID ? "Update" : "Add"} Country`} size="ms" handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-6">
                    <label>Country Short Name *</label>
                    <input type="text" maxLength={3}className="form-control input-default" id="shortName" onChange={handleTextChange} value={record.shortName} />
                </div>
                <div className="col-lg-6">
                    <label>Country Name *</label>
                    <input type="text" maxLength={20}className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} />
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-4">
                    <label>Phone Code *</label>
                    <input type="text" className="form-control input-default" id="phoneCode" onChange={handleTextChange} value={record.phoneCode} />
                </div>
                <div className="col-4">
                    <label>Currency Symbol *</label>
                    <input type="text" maxLength={10} className="form-control input-default" id="currencySymbol" onChange={handleTextChange} value={record.currencySymbol} />
                </div>
                <div className="col-4">
                    <label>Currency Code *</label>
                    <input type="text" maxLength={10} className="form-control input-default" id="currencyCode" onChange={handleTextChange} value={record.currencyCode} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default LocalCountryAddEdit;
