import { toast } from "react-hot-toast";

import {
  Activate_Client_Restaurant_Delivery_Put,
  Activate_Client_Restaurant_DineIn_Put,
  Activate_Client_Restaurant_Schedule_Put,
  Activate_Client_Restaurant_Steal_Deal_Put,
  Activate_Client_Restaurant_Takeaway_Put,
  CityList_LocalCity_POST,
  DeActivate_Client_Restaurant_Delivery_Put,
  DeActivate_Client_Restaurant_DineIn_Put,
  DeActivate_Client_Restaurant_Schedule_Put,
  DeActivate_Client_Restaurant_Steal_Deal_Put,
  DeActivate_Client_Restaurant_Takeaway_Put,
  OnOff_Client_Restaurant,
  OnOff_Client_Restaurant_Club_Mode,
  StateList_LocalState_POST,
  Update_Client_Restaurant_Address,
  Update_Client_Restaurant_Configuration,
  Update_Client_Restaurant_Images,
  Update_Client_Restaurant_Put,
  Update_Client_Restaurant_Tax_Number,
  Update_Client_Restaurant_Timing,
  Update_Client_User_Restaurant_Owner_Details_Put,
} from "api/ApiConst";
import { callPostApi, callPutApi } from "api/ApiCaller";
import {
  noValidationCheckKeys,
  restaurantErrorMessage,
  restaurantLengthErrorMsg,
} from "./restaurantInitialValues";
import { API_SUCCESS } from "api/ResponseCodes";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

export const getStateListByCountry = (countryUuid) => {
  return new Promise((resolve, reject) => {
    let rUrl = StateList_LocalState_POST;
    let rObj = { countryUuid };
    callPostApi(
      // MODAL_LOADER,
      null,
      rUrl,
      rObj,
      {},
      (response) => {
        if (response.errorCode === API_SUCCESS) {
          // setStateList(response.responsePacket)
          resolve(response.responsePacket);
        } else {
          reject(response);
          toast.error(response.message, { id: "error" });
        }
      },
      (error) => {
        reject(error);
        toast.error(error?.message, { id: "error" });
      }
    );
  });
};

export const getCityListByState = (stateUuid) => {
  return new Promise((resolve, reject) => {
    let rUrl = CityList_LocalCity_POST;
    let rObj = { stateUuid };
    callPostApi(
      // MODAL_LOADER,
      null,
      rUrl,
      rObj,
      {},
      (response) => {
        if (response.errorCode === API_SUCCESS) {
          // setCityList(response.responsePacket)
          resolve(response.responsePacket);
        } else {
          reject(response);
          toast.error(response.message, { id: "error" });
        }
      },
      (error) => {
        reject(error);
        toast.error(error.message, { id: "error" });
      }
    );
  });
};

const isValidated = (rObj) => {
  for (let key in rObj) {
    if (key in noValidationCheckKeys) {
      continue;
    }
    else {
      //   if (isEmpty(rObj[key])) {
      //     toast.error(restaurantErrorMessage[key], { id: "error" });
      //     return false;
      //   }
      if (key === "gstNumber" && rObj[key].length > 0) {
        if (key === "gstNumber" && rObj[key].length < 15) {
          toast.error("GST number must be 15 characters", { id: "error" });
          return false;
        }
      }
      // if (key === "tinNumber" && rObj[key].length > 0) {
      //   if (key === "tinNumber" && rObj[key].length < 15) {
      //     toast.error("Tin number must be more than 10 characters", { id: "error" });
      //     return false;
      //   }
      // }
      //  if (key === "vatNumber" && rObj[key].length > 0) {
      //   if (key === "vatNumber" && rObj[key].length < 10) {
      //     toast.error("Tin number must be more than 10 characters", { id: "error", });
      //   }
      // }
      //  else if (key in restaurantLengthErrorMsg) {
      //   if (rObj[key].length > restaurantLengthErrorMsg[key].limit) {
      //     toast.error(restaurantLengthErrorMsg[key].errorMessage, {
      //       id: "error",
      //     });
      //     return false;
      //   }
      // }
      // }
    }
  }
  return true;
};

const callUpdateApi = (rUrl, rObj) => {
  return new Promise((resolve, reject) => {
    if (isValidated(rObj)) {
      callPutApi(
        MODAL_LOADER,
        rUrl,
        rObj,
        {},
        (response) => {
          if (response.errorCode === API_SUCCESS) {
            toast.success(response.message, { id: "error" });
            resolve(response);

          } else {
            resolve(response);
            toast.error(response.message, { id: "error" });
          }
        },
        (error) => {
          reject(error);
        }
      );
    }
  });
};

export const openCloseRestaurant = (recordID, rObj) => {
  let rUrl = OnOff_Client_Restaurant.replaceAll("{recordID}", recordID);
  return callUpdateApi(rUrl, rObj);
};

export const enableTakeaway = (recordID, rObj) => {
  let rUrl = Activate_Client_Restaurant_Takeaway_Put.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};
export const disableTakeaway = (recordID, rObj) => {
  let rUrl = DeActivate_Client_Restaurant_Takeaway_Put.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};

export const enableStealDeal = (recordID, rObj) => {
  let rUrl = Activate_Client_Restaurant_Steal_Deal_Put.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};

export const disableStealDeal = (recordID, rObj) => {
  let rUrl = DeActivate_Client_Restaurant_Steal_Deal_Put.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};

export const enableDineIn = (recordID, rObj) => {
  let rUrl = Activate_Client_Restaurant_DineIn_Put.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};

export const disableDineIn = (recordID, rObj) => {
  let rUrl = DeActivate_Client_Restaurant_DineIn_Put.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};

export const enableDelivery = (recordID, rObj) => {
  let rUrl = Activate_Client_Restaurant_Delivery_Put.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};

export const disableDelivery = (recordID, rObj) => {
  let rUrl = DeActivate_Client_Restaurant_Delivery_Put.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};

export const clubModeOnOff = (recordID, rObj) => {
  let rUrl = OnOff_Client_Restaurant_Club_Mode.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};

export const updateRestaurantImages = (recordID, rObj) => {
  let rUrl = Update_Client_Restaurant_Images.replaceAll("{recordID}", recordID);
  return callUpdateApi(rUrl, rObj);
};

export const updateRestaurantConfigurations = (recordID, rObj) => {
  let rUrl = Update_Client_Restaurant_Configuration.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};

export const updateRestaurantTaxNumbers = (recordID, rObj) => {
  let rUrl = Update_Client_Restaurant_Tax_Number.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};

export const updateRestaurantDetails = (recordID, rObj) => {
  let rUrl = Update_Client_Restaurant_Put.replaceAll("{recordID}", recordID);
  return callUpdateApi(rUrl, rObj);
};
export const updateOwnerDetails = (recordID, rObj) => {
  let rUrl = Update_Client_User_Restaurant_Owner_Details_Put.replaceAll("{uuid}", recordID);
  return callUpdateApi(rUrl, rObj);
};

export const updateRestaurantLocation = (recordID, rObj) => {
  let rUrl = Update_Client_Restaurant_Address.replaceAll(
    "{recordID}",
    recordID
  );
  return callUpdateApi(rUrl, rObj);
};

export const updateRestaurantTiming = (recordID, rObj) => {
  let rUrl = Update_Client_Restaurant_Timing.replaceAll("{recordID}", recordID);
  return callUpdateApi(rUrl, rObj);
};

export const activateScheduleOrder = (recordID, rObj) => {
  let rUrl = Activate_Client_Restaurant_Schedule_Put.replaceAll("{recordID}", recordID);
  return callUpdateApi(rUrl, rObj);
};

export const deactivateScheduleOrder = (recordID, rObj) => {
  let rUrl = DeActivate_Client_Restaurant_Schedule_Put.replaceAll("{recordID}", recordID);
  return callUpdateApi(rUrl, rObj);
};
