import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { Details_Client_Tax_List_Get, Save_Client_Tax_Post, Update_Client_Tax_Put } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

import ModalWrapper from "components/common/ModalWrapper";
import Select from "react-select";
import { useSelector } from "react-redux";

const TaxAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const { brandKeyValuePairs } = useSelector((state) => state.utility);
    const defaultRecordObj = {
        brandUuid: brandKeyValuePairs.length === 1 ? brandKeyValuePairs[0] : "",
        taxTitle: "",
        taxDescription: "",
        taxPercentage: 0,
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Client_Tax_List_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const res = response.responsePacket;
                    setRecord(res);
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (id === "taxPercentage" && value.length !== 0) {
            if (value.length > 5) {
                return;
            }
        }
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseFloat(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    //Select Text Change Event
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    const checkInputValidation = () => {
        if (!recordID && isEmpty(record.brandUuid)) {
            toast.error(`Brand must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(record.taxTitle)) {
            toast.error(`Tax title must not be empty`, { id: "toast" });
            return false;
        }
        else if (record.taxPercentage !== 0) {
            if (isEmpty(record.taxPercentage)) {
                toast.error(`Tax percentage must not be empty`, { id: "toast" });
                return false;
            }
        }

        else if (record.taxPercentage < 0) {
            toast.error(`Tax percentage must not be negative`, { id: "toast" });
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            recordObj.brandUuid = record.brandUuid?.key || "";
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Client_Tax_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, { id: "toast" });
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, { id: "toast" });
                    }
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Client_Tax_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, { id: "toast" });
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, { id: "toast" });
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static" header={`${recordID ? "Update" : "Add"} Tax`} size="ms" handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                {!recordID &&
                    <div className="col-lg-12 my-2">
                        <label>Brand *</label>
                        <Select options={brandKeyValuePairs} value={record.brandUuid} onChange={(e) => handleSelectChange(e, "brandUuid")} />
                    </div>
                }
                <div className="col-lg-12 my-2">
                    <label>Tax Title *</label>
                    <input type="text" maxLength={20} className="form-control input-default" id="taxTitle" onChange={handleTextChange} value={record.taxTitle} />
                </div>
                <div className="col-lg-12 my-2">
                    <label>Tax Percentage *</label>
                    <div className="input-group">
                        <div className="input-group-append">
                            <span className="input-group-text bg-red">%</span>
                        </div>
                        <input type="number" className="form-control input-default" id="taxPercentage" onChange={handleTextChange} value={record.taxPercentage} />
                    </div>
                </div>
                <div className="col-lg-12 my-2">
                    <label>Tax Description </label>
                    <textarea className="form-control input-default" id="taxDescription" cols="30" rows="2" onChange={handleTextChange} value={record.taxDescription} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default TaxAddEdit;
