import Cookies from "universal-cookie";
import { LOGIN, LOGOUT } from "../actions";
const cookies = new Cookies();

const defaultObj = {
  isAuthenticated:
    cookies.get("authToken") && cookies.get("authToken") !== undefined
      ? cookies.get("authToken")
      : null,
  authToken:
    cookies.get("authToken") !== undefined ? cookies.get("authToken") : null,
  user: cookies.get("user") !== undefined ? cookies.get("user") : null,
  currencyDetail: cookies.get("currencyDetail") || {
    currencyTitle: "Rupees",
    currencySubUnitTitle: "Paisa",
    unitSubdivision: 100,
    currencyCode: "INR",
    currencySymbol: "₹",
  },
};

export const UserReducers = (state = defaultObj, action) => {
  switch (action.type) {
    case LOGIN:
      var expireDate = new Date();
      expireDate.setDate(expireDate.getDate() + 30);
      cookies.set("authToken", action.payload.accessToken, {
        path: "/",
        expires: expireDate,
      });
      cookies.set("user", action.payload, { path: "/", expires: expireDate });
      cookies.set("currencyDetail", action.payload.currencyDetail, {
        path: "/",
        expires: expireDate,
      });
      return {
        authToken: action.payload.accessToken,
        isAuthenticated: true,
        user: action.payload,
        currencyDetail: action.payload.currencyDetail,
      };
    case LOGOUT:
      cookies.remove("authToken", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("currencyDetail", { path: "/" });
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};
