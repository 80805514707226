import { useCallback, useEffect, useMemo, useState } from 'react'

import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

import {
    Activate_Client_Event_Put,
    All_Client_Event_List_Post,
    All_Client_Restaurant_Key_value_List_Post,
    DeActivate_Client_Event_Put,
    Delete_Client_Event_Delete,
    Deleted_Client_Event_List_Post,
    Revive_Client_Event_Put,
    Switch_To_Paid_UnPaid_Client_Event_Put
} from 'api/ApiConst';
import { callPostApi, callPutApi } from 'api/ApiCaller';
import { handleActive, handleDelete, handleInactive, handleRevive } from 'utils/UserUtils';
import { API_SUCCESS } from 'api/ResponseCodes';
import dateUtilities from 'utils/DateUtilities';
import { SECTION_LOADER } from 'utils/constants';

import EventAddEdit from './EventAddEdit';
import CheckboxButton from 'components/common/CheckboxButton';
import PaginationDataTable from 'components/common/PaginationDataTable';
import CustomButton from 'components/common/CustomButton'
import PageHeading from 'components/common/PageHeading'

const EventMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [restaurantKeyValuePairs, setRestaurantKeyValuePairs] = useState([]);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    useEffect(() => {
        // Get Restaurant Key Pair List
        const rUrl = All_Client_Restaurant_Key_value_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setRestaurantKeyValuePairs(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    }, [])

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => <div className={row.active ? "led-green" : "led-red"} title={row.active ? "Active" : "Inactive"} />,
                width: "60px",
                sortable: false,
            },
            {
                id: "eventTitle",
                name: "Title",
                selector: (row) => <Link onClick={() => handleEdit(row)} title='View Event Ticket'>{row.eventTitle}</Link>,
                sortable: false,
            },
            {
                id: "brandTitle",
                name: "Brand",
                selector: (row) => row.brandTitle,
                sortable: false,
            },
            {
                id: "restaurantTitle",
                name: "Restaurant",
                selector: (row) => row.restaurantTitle,
                sortable: false,
            },
            {
                id: "contactNumber",
                name: "Contact No.",
                selector: (row) => row.contactNumber,
                sortable: false,
            },
            {
                id: "createdAt",
                name: "Created Time",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {/* {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )} */}
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                    <li onClick={() => handlePaidUnPaid(row)}>
                                        {row.paid ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Switch UnPaid
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Switch Paid
                                            </>
                                        )}
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_Client_Event_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_Client_Event_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    const handlePaidUnPaid = (row) => {
        let rUrl = Switch_To_Paid_UnPaid_Client_Event_Put.replaceAll("{recordID}", row.uuid)
        callPutApi(SECTION_LOADER, rUrl, {}, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                toast.success(response.message, {id: "toast"});
                handleRefresh();
            } else {
                toast.error(response.message, {id: "toast"});
            }
        });
    }

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_Client_Event_Delete.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_Client_Event_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("Event List", "Dashboard", "/")}
            <div className="card">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_Client_Event_List_Post : All_Client_Event_List_Post}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showAddUpdateModal && <EventAddEdit
                show={showAddUpdateModal}
                hideModal={() => setShowAddUpdateModal(false)}
                recordID={recordID}
                handleRefresh={handleRefresh}
                restaurantKeyValuePairs={restaurantKeyValuePairs}
            />}
        </>
    )
}

export default EventMaster