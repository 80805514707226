import React, { memo, useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import Select from "react-select";

import {
    Details_Client_Restaurant_List_Get,
    Get_Client_User_Restaurant_Owner_Details_GET,
    Get_Timing_Group_Key_Value_Pair_List_Post,
} from "api/ApiConst";
import {
    activateScheduleOrder,
    clubModeOnOff,
    deactivateScheduleOrder,
    disableDelivery,
    disableDineIn,
    disableStealDeal,
    disableTakeaway,
    enableDelivery,
    enableDineIn,
    enableStealDeal,
    enableTakeaway,
    getCityListByState,
    getStateListByCountry,
    openCloseRestaurant,
    updateRestaurantTiming,
} from "./restaurantUtils";
import { callGetApi, callPostApi } from "api/ApiCaller";
import { MODAL_LOADER } from "utils/constants";
import { Button, Nav, Tab } from "react-bootstrap";
import { API_SUCCESS } from "api/ResponseCodes";

import RestaurantDetails from "./RestaurantDetails";
import TaxNumberUpdate from "./TaxNumberUpdate";
import RestaurantConfiguration from "./RestaurantConfiguration";
import RestaurantLocation from "./RestaurantLocation";
import ModalWrapper from "components/common/ModalWrapper";
import RestaurantImages from "./RestaurantImages";
import {
    restaurantDefaultRecordObj,
    initialClientUserRestaurantOwnerDetails,
} from "./restaurantInitialValues";
import { isAlphaNumercSpecialCharSpc, isAlphaNumeric } from "utils/TextUtils";
import OwnerDetails from "./OwnerDetails";
import Cookies from "universal-cookie";

// const imgStyle = { height: "auto", width: "100px", overflow: "hidden", }

export const RestaurantEdit = ({
    show,
    recordID,
    hideModal,
    countryList,
    timingGroupKeyPairList,
    currencyOptionsList,
}) => {
    const [record, setRecord] = useState({ ...restaurantDefaultRecordObj });
    const [recordOwnerDetails, setRecordOwnerDetails] = useState({ ...initialClientUserRestaurantOwnerDetails, });
    const [activeTab, setActiveTab] = useState("RestaurantDetails");
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [ownerUuid, setOwnerUuid] = useState("");
    const {
        address,
        restaurantDineInDetail,
        restaurantStealDealDetail,
        restaurantTakeAwayDetail,
        restaurantDeliveryDetail,
    } = record;
    const currencyDetail = new Cookies().get("currencyDetail");

    // const { address, restaurantDineInDetail, restaurantStealDealDetail, restaurantTakeAwayDetail, restaurantDeliveryDetail } = record;

    useEffect(() => {
        // recordID is restaurantUuid
        if (recordID) {
            callGetApi(
                MODAL_LOADER,
                Details_Client_Restaurant_List_Get.replaceAll("{recordID}", recordID),
                {},
                (response) => {
                    const res = response.responsePacket;
                    getOwnerDetails(res.ownerUuid);
                    // let _restaurantDineInDetail = { ...record["restaurantDineInDetail"] }
                    let _restaurantDineInDetail = {};
                    let _restaurantDeliveryDetail = {};
                    let _restaurantTakeAwayDetail = {};
                    let _restaurantStealDealDetail = {};
                    if (res.dineIn) {
                        _restaurantDineInDetail = {
                            ...res.restaurantDineInDetail,
                            timingGroupUuid: timingGroupKeyPairList.find(
                                (obj) =>
                                    obj.key === res?.restaurantDineInDetail?.timingGroupUuid
                            ),
                        };
                    }

                    if (res.delivery) {
                        _restaurantDeliveryDetail = {
                            ...res.restaurantDeliveryDetail,
                            timingGroupUuid: timingGroupKeyPairList.find(
                                (obj) =>
                                    obj.key === res?.restaurantDeliveryDetail?.timingGroupUuid
                            ),
                        };
                    }

                    if (res.takeaway) {
                        _restaurantTakeAwayDetail = {
                            ...res.restaurantTakeAwayDetail,
                            timingGroupUuid: timingGroupKeyPairList.find(
                                (obj) =>
                                    obj.key === res?.restaurantTakeAwayDetail?.timingGroupUuid
                            ),
                        };
                    }

                    if (res.stealDeal) {
                        _restaurantStealDealDetail = {
                            ...res.restaurantStealDealDetail,
                            timingGroupUuid: timingGroupKeyPairList.find(
                                (obj) =>
                                    obj.key === res?.restaurantStealDealDetail?.timingGroupUuid
                            ),
                        };
                    }

                    let timingGroupUuid = "";
                    if (res.timingGroupUuid) {
                        timingGroupUuid = timingGroupKeyPairList.find(
                            (obj) => obj.key === res?.timingGroupUuid
                        );
                    }

                    setRecord((prev) => ({
                        ...prev,
                        ...res,
                        restaurantDineInDetail: _restaurantDineInDetail,
                        restaurantDeliveryDetail: _restaurantDeliveryDetail,
                        restaurantTakeAwayDetail: _restaurantTakeAwayDetail,
                        restaurantStealDealDetail: _restaurantStealDealDetail,
                        timingGroupUuid: timingGroupUuid,
                    }));

                    // Set Default Address
                    const obj = {
                        countryTitle: res?.address?.countryTitle || "",
                        stateTitle: res?.address?.stateTitle || "",
                        cityTitle: res?.address?.cityTitle || "",
                    };
                    initialCountrySateAndCityValues(countryList, obj);
                }
            );
        }
    }, [recordID]);

    const getOwnerDetails = (ownerUuid) => {
        callGetApi(
            MODAL_LOADER,
            Get_Client_User_Restaurant_Owner_Details_GET.replaceAll(
                "{uuid}",
                ownerUuid
            ),
            {},
            (response) => {
                setRecordOwnerDetails(response.responsePacket);
            }
        );
    };

    // Address Select Change
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, address: { ...prev.address, [id]: e } }));
    };

    const initialCountrySateAndCityValues = (_countryList, tempObj) => {
        const selectedCountry = _countryList.find(
            (currentCountry, i) =>
                currentCountry.label.toLowerCase() ===
                tempObj.countryTitle.toLowerCase()
        );
        if (selectedCountry) {
            handleSelectChange(selectedCountry, "countryUuid");

            // get State List
            getStateListByCountry(selectedCountry.key).then((_stateList) => {
                const selectedState = _stateList.find(
                    (currentSate, i) =>
                        currentSate.label.toLowerCase() === tempObj.stateTitle.toLowerCase()
                );
                if (selectedState) {
                    setStateList(_stateList);
                    handleSelectChange(selectedState, "stateUuid");

                    // get City List
                    getCityListByState(selectedState.key).then((_cityList) => {
                        const selectedCity = _cityList.find(
                            (currentCity, i) =>
                                currentCity.label.toLowerCase() ===
                                tempObj.cityTitle.toLowerCase()
                        );
                        if (selectedCity) {
                            setCityList(_cityList);
                            handleSelectChange(selectedCity, "cityUuid");
                        }
                    });
                }
            });
        }
    };

    // Text Change of record
    const handleTextChange = (e) => {
        const { id, value } = e.target;

        if (id === "orderPrefix" && value.length !== 0) {
            if (!isAlphaNumercSpecialCharSpc(value)) {
                return;
            }
        }
        if (id === "restaurantTitle" && value.length !== 0) {
            if (!isAlphaNumercSpecialCharSpc(value)) {
                return;
            }
        }
        if (id === "gstNumber" && value.length !== 0) {
            if (!isAlphaNumeric(value)) {
                return;
            }
        }
        if (id === "tinNumber" && value.length !== 0) {
            if (!isAlphaNumeric(value)) {
                return;
            }
        }
        if (id === "vatNumber" && value.length !== 0) {
            if (!isAlphaNumeric(value)) {
                return;
            }
        }
        if (id === "restaurantLicenseNumber" && value.length !== 0) {
            if (!isAlphaNumeric(value)) {
                return;
            }
        }

        setRecord((prev) => ({ ...prev, [id]: value }));
    };

    // select Text Change of record
    const handleSelectTextChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    // Checkbox Change of record
    const handleCheckedChange = (e) => {
        const { id, checked } = e.target;
        setRecord((prev) => ({ ...prev, [id]: checked }));
    };

    const orderTypeChangeValueChange = (orderType, id, value) => {
        if (orderType === "Delivery") {
            setRecord((prev) => ({
                ...prev,
                restaurantDeliveryDetail: {
                    ...prev.restaurantDeliveryDetail,
                    [id]: value,
                },
            }));
        } else if (orderType === "TakeAway") {
            setRecord((prev) => ({
                ...prev,
                restaurantTakeAwayDetail: {
                    ...prev.restaurantTakeAwayDetail,
                    [id]: value,
                },
            }));
        } else if (orderType === "DineIn") {
            setRecord((prev) => ({
                ...prev,
                restaurantDineInDetail: { ...prev.restaurantDineInDetail, [id]: value },
            }));
        } else if (orderType === "StealDeal") {
            setRecord((prev) => ({
                ...prev,
                restaurantStealDealDetail: {
                    ...prev.restaurantStealDealDetail,
                    [id]: value,
                },
            }));
        }
    };

    // Text Change of record in Order type
    const handleOrderTypeTextChange = (e, orderType) => {
        const { id, value, type } = e.target;
        if (type === "number") {
            orderTypeChangeValueChange(orderType, id, parseFloat(value));
        } else {
            orderTypeChangeValueChange(orderType, id, value);
        }
    };

    // Text Change of record in Order type
    const handleOrderTypeSelectChange = (e, orderType, id) => {
        const value = e;
        orderTypeChangeValueChange(orderType, id, value);
    };

    // API call for club mode
    const handleUpdateClubMode = async () => {
        const rObj = {};
        const response = await clubModeOnOff(recordID, rObj);
        if (response.errorCode === API_SUCCESS) {
            setRecord((prev) => ({ ...prev, clubMode: !prev.clubMode }));
        }
    };
    // API call for club mode
    const handleActiveDeactiveteScheduleOrder = async (e) => {
        const { checked } = e.target;
        const rObj = {};
        if (checked) {
            const response = await activateScheduleOrder(recordID, rObj);
            if (response.errorCode === API_SUCCESS) {
                setRecord((prev) => ({ ...prev, schedule: !prev.schedule }));
            }
        }
        if (!checked) {
            const response = await deactivateScheduleOrder(recordID, rObj);
            if (response.errorCode === API_SUCCESS) {
                setRecord((prev) => ({ ...prev, schedule: !prev.schedule }));
            }
        }
    };

    // API call for ON/OFF restaurant
    const handleUpdateRestaurantStatus = async () => {
        const rObj = {};
        const response = await openCloseRestaurant(recordID, rObj);
        if (response.errorCode === API_SUCCESS) {
            setRecord((prev) => ({ ...prev, open: !prev.open }));
        }
    };

    // API call for enable takeaway restaurant
    const handleEnableTakeaway = async () => {
        const { takeAwayMinimumOrderAmount, timingGroupUuid } =
            restaurantTakeAwayDetail;
        const _timingGroupUuid = timingGroupUuid?.key || "";
        const _takeAwayMinimumOrderAmount = takeAwayMinimumOrderAmount || "";
        const rObj = {
            takeAwayMinimumOrderAmount: _takeAwayMinimumOrderAmount,
            timingGroupUuid: _timingGroupUuid,
        };
        const response = await enableTakeaway(recordID, rObj);
        if (response.errorCode === API_SUCCESS) {
            setRecord((prev) => ({ ...prev, takeaway: prev.takeaway }));
        }
    };

    // API call for disable takeaway restaurant
    const handleDisableTakeaway = async () => {
        const rObj = {};
        const response = await disableTakeaway(recordID, rObj);
        if (response.errorCode === API_SUCCESS) {
            setRecord((prev) => ({ ...prev, takeaway: prev.takeaway }));
        }
    };

    // API call for disable StealDeal
    const handleEnableStealDeal = async () => {
        const timingGroupUuidKey =
            restaurantStealDealDetail?.timingGroupUuid?.key || "";
        const rObj = { timingGroupUuid: timingGroupUuidKey };
        const response = await enableStealDeal(recordID, rObj);
        if (response.errorCode === API_SUCCESS) {
            setRecord((prev) => ({ ...prev, stealDeal: prev.stealDeal }));
        }
    };
    // API call for disable StealDeal
    const handleDisableStealDeal = async () => {
        const rObj = {};
        const response = await disableStealDeal(recordID, rObj);
        if (response.errorCode === API_SUCCESS) {
            setRecord((prev) => ({ ...prev, stealDeal: prev.stealDeal }));
        }
    };

    // API call for disable StealDeal
    const handleEnableDineIn = async () => {
        const timingGroupUuidKey =
            restaurantDineInDetail?.timingGroupUuid?.key || "";
        const rObj = { timingGroupUuid: timingGroupUuidKey };
        const response = await enableDineIn(recordID, rObj);
        if (response.errorCode === API_SUCCESS) {
            setRecord((prev) => ({ ...prev, dineIn: prev.dineIn }));
        }
    };

    // API call for disable DineIn
    const handleDisableDineIn = async () => {
        const rObj = {};
        const response = await disableDineIn(recordID, rObj);
        if (response.errorCode === API_SUCCESS) {
            setRecord((prev) => ({ ...prev, dineIn: prev.dineIn }));
        }
    };

    // API call for disable StealDeal
    const handleEnableDelivery = async () => {
        const rObj = {
            deliveryMinimumOrderAmount:
                restaurantDeliveryDetail?.deliveryMinimumOrderAmount || "",
            baseDeliveryCharges: restaurantDeliveryDetail?.baseDeliveryCharges || "",
            maximumDistanceForBaseCharges:
                restaurantDeliveryDetail?.maximumDistanceForBaseCharges || "",
            deliveryChargesPerKM:
                restaurantDeliveryDetail?.deliveryChargesPerKM || "",
            deliveryRadius: restaurantDeliveryDetail?.deliveryRadius || "",
            timingGroupUuid: restaurantDeliveryDetail?.timingGroupUuid?.key || "",
        };
        const response = await enableDelivery(recordID, rObj);
        if (response.errorCode === API_SUCCESS) {
            setRecord((prev) => ({ ...prev, delivery: prev.delivery }));
        }
    };

    // API call for disable StealDeal
    const handleDisableDelivery = async () => {
        const rObj = {};
        const response = await disableDelivery(recordID, rObj);
        if (response.errorCode === API_SUCCESS) {
            setRecord((prev) => ({ ...prev, delivery: prev.delivery }));
        }
    };

    // API call for disable StealDeal
    const handleUpdateRestaurantTiming = async () => {
        const timingGroupUuid = record?.timingGroupUuid?.key || "";
        const rObj = { timingGroupUuid };
        updateRestaurantTiming(recordID, rObj);
    };

    const getDeliveryDetail = () => {
        return (
            <fieldset className="my-3">
                <legend>
                    <label>
                        <input
                            type="checkbox"
                            id="delivery"
                            checked={record.delivery}
                            onChange={handleCheckedChange}
                        />{" "}
                        Home Delivery
                    </label>{" "}
                </legend>
                {!record.delivery ? (
                    <span>
                        if you want to enable the Home Delivery order type availability for
                        this restaurant, check upper checkbox & Click on Submit button
                    </span>
                ) : (
                    <div className="row">
                        <div className="col-lg-6 my-2">
                            <label>Minimum Order Amount </label>
                            <div className="input-group">
                                <input
                                    type="number"
                                    className="form-control input-default"
                                    maxLength={7}
                                    id="deliveryMinimumOrderAmount"
                                    value={restaurantDeliveryDetail?.deliveryMinimumOrderAmount}
                                    onChange={(e) => handleOrderTypeTextChange(e, "Delivery")}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text bg-red">
                                        {currencyDetail?.currencySymbol}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>Base Delivery Charges</label>
                            <div className="input-group">
                                <input
                                    type="number"
                                    maxLength={7}
                                    className="form-control input-default"
                                    id="baseDeliveryCharges"
                                    value={restaurantDeliveryDetail?.baseDeliveryCharges}
                                    onChange={(e) => handleOrderTypeTextChange(e, "Delivery")}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text bg-red">
                                        {currencyDetail?.currencySymbol}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>Maximum Distance For Base Charges</label>
                            <input
                                type="number"
                                className="form-control input-default"
                                id="maximumDistanceForBaseCharges"
                                value={restaurantDeliveryDetail?.maximumDistanceForBaseCharges}
                                onChange={(e) => handleOrderTypeTextChange(e, "Delivery")}
                            />
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>Delivery Charges Per KM </label>
                            <div className="input-group">
                                <input
                                    type="number"
                                    maxLength={7}
                                    className="form-control input-default"
                                    id="deliveryChargesPerKM"
                                    value={restaurantDeliveryDetail?.deliveryChargesPerKM}
                                    onChange={(e) => handleOrderTypeTextChange(e, "Delivery")}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text bg-red">
                                        {currencyDetail?.currencySymbol}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>Delivery Radius </label>
                            <input
                                type="number"
                                className="form-control input-default"
                                id="deliveryRadius"
                                value={restaurantDeliveryDetail?.deliveryRadius}
                                onChange={(e) => handleOrderTypeTextChange(e, "Delivery")}
                            />
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>Timing Group List *</label>
                            <Select
                                options={timingGroupKeyPairList}
                                value={restaurantDeliveryDetail?.timingGroupUuid}
                                onChange={(e) =>
                                    handleOrderTypeSelectChange(e, "Delivery", "timingGroupUuid")
                                }
                            />
                        </div>
                    </div>
                )}

                <Button
                    variant="primary"
                    className="float-end"
                    onClick={
                        record.delivery ? handleEnableDelivery : handleDisableDelivery
                    }
                >
                    Submit
                </Button>
            </fieldset>
        );
    };

    const restaurantDetailTabContent = () => {
        return (
            <>

                {/* Restaurant Details  */}
                <RestaurantDetails
                    handleTextChange={handleTextChange}
                    record={record}
                    recordID={recordID}
                />

                {/* Schedule Order  */}
                <fieldset className="my-3">
                    <legend>Schedule Order</legend>
                    <div className="row">
                        <div className="col-lg-4 my-2">
                            <input
                                type="checkbox"
                                className="input-default"
                                id="autoPrintKOT"
                                onChange={(e) => handleActiveDeactiveteScheduleOrder(e)}
                                checked={record?.schedule}
                            />
                            <label className="ms-2" htmlFor="autoPrintKOT"> Schedule Order is &nbsp;
                                <b
                                    className={record.schedule ? "text-success" : "text-danger"} >
                                    {record?.schedule === true ? "Enable" : "Disable"} </b>
                            </label>
                        </div>
                    </div>
                </fieldset>

                {/* Dine In Timing */}
                <fieldset className="my-3">
                    <legend>
                        <label>
                            <input
                                type="checkbox"
                                id="dineIn"
                                checked={record.dineIn}
                                onChange={handleCheckedChange}
                            />
                            Dine In
                        </label>
                    </legend>

                    <div className="row d-flex align-items-center">
                        {!record.dineIn ? (
                            <span className="col-lg-10">
                                if you want to enable the DineIn order type availability for
                                this restaurant, check upper checkbox & Click on Submit button
                            </span>
                        ) : (
                            <div className="col-lg-6 my-2">
                                <label>Timing Group List *</label>
                                <Select
                                    options={timingGroupKeyPairList}
                                    value={restaurantDineInDetail?.timingGroupUuid}
                                    onChange={(e) =>
                                        handleOrderTypeSelectChange(e, "DineIn", "timingGroupUuid")
                                    }
                                />
                            </div>
                        )}
                        <div className={`col-lg-${record.dineIn ? 6 : 2} my-2`}>
                            <Button
                                variant="primary"
                                className="float-end"
                                onClick={
                                    record.dineIn ? handleEnableDineIn : handleDisableDineIn
                                }
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </fieldset>

                {/* Delivery */}
                {getDeliveryDetail()}

                {/* Oder Take away */}
                <fieldset className="my-3">
                    <legend>
                        <label>
                            <input
                                type="checkbox"
                                id="takeaway"
                                checked={record.takeaway}
                                onChange={handleCheckedChange}
                            />{" "}
                            Take Away
                        </label>
                    </legend>
                    <div className="row align-items-center">
                        {!record.takeaway ? (
                            <span className="col-lg-10">
                                if you want to enable the Take away order type availability for
                                this restaurant, check upper checkbox & Click on Submit button
                            </span>
                        ) : (
                            <>
                                <div className="col-lg-4 my-2">
                                    <label>Minimum Order Amount </label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className="form-control input-default"
                                            id="takeAwayMinimumOrderAmount"
                                            onChange={(e) => handleOrderTypeTextChange(e, "TakeAway")}
                                            value={
                                                restaurantTakeAwayDetail?.takeAwayMinimumOrderAmount
                                            }
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text bg-red">
                                                {currencyDetail?.currencySymbol}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 my-2">
                                    <label>Timing Group List *</label>
                                    <Select
                                        options={timingGroupKeyPairList}
                                        value={restaurantTakeAwayDetail?.timingGroupUuid}
                                        onChange={(e) =>
                                            handleOrderTypeSelectChange(
                                                e,
                                                "TakeAway",
                                                "timingGroupUuid"
                                            )
                                        }
                                    />
                                </div>
                            </>
                        )}
                        <div className={`col-lg-${record.takeaway ? 4 : 2} my-2 `}>
                            <Button
                                variant="primary"
                                className="float-end"
                                onClick={
                                    record.takeaway ? handleEnableTakeaway : handleDisableTakeaway
                                }
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </fieldset>

                {/* Steal Deal Timing */}
                <fieldset className="my-3">
                    <legend>
                        <label>
                            <input
                                type="checkbox"
                                id="stealDeal"
                                checked={record.stealDeal}
                                onChange={handleCheckedChange}
                            />{" "}
                            Steal Deal
                        </label>
                    </legend>
                    <div className="row align-items-center">
                        {!record.stealDeal ? (
                            <span className="col-lg-10">
                                if you want to enable the Steal Deal order type availability for
                                this restaurant, check upper checkbox & Click on Submit button
                            </span>
                        ) : (
                            <div className="col-lg-6 my-2">
                                <label>Timing Group List *</label>
                                <Select
                                    options={timingGroupKeyPairList}
                                    value={restaurantStealDealDetail.timingGroupUuid}
                                    onChange={(e) =>
                                        handleOrderTypeSelectChange(
                                            e,
                                            "StealDeal",
                                            "timingGroupUuid"
                                        )
                                    }
                                />
                            </div>
                        )}
                        <div className={`col-lg-${record.stealDeal ? 6 : 2} my-2 `}>
                            <Button
                                variant="primary"
                                className="float-end"
                                onClick={
                                    record.stealDeal
                                        ? handleEnableStealDeal
                                        : handleDisableStealDeal
                                }
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </fieldset>

                {/* Tax numbers */}
                <fieldset className="my-3">
                    <legend>Tax Numbers</legend>
                    <TaxNumberUpdate
                        handleTextChange={handleTextChange}
                        record={record}
                        recordID={recordID}
                    />
                </fieldset>

                {/* Configuration */}
                <fieldset className="my-3">
                    <legend>Configuration</legend>
                    <RestaurantConfiguration
                        handleCheckedChange={handleCheckedChange}
                        record={record}
                        recordID={recordID}
                    />
                </fieldset>

                {/* Club Mode  */}
                <fieldset className="my-3">
                    <legend>Club Mode</legend>
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            {/* <input type="checkbox" className="input-default" id="clubMode" checked={record.clubMode} onChange={handleCheckedChange} /> */}
                            <label className="ms-2" htmlFor="clubMode">
                                now, Club Mode is{" "}
                                <b className={record.clubMode ? "text-success" : "text-danger"}>
                                    {record.clubMode ? "Enable" : "Disable"}
                                </b>
                                , for change status click on button{" "}
                            </label>
                        </div>
                        <div className="col-lg-4 my-2">
                            <Button
                                className={`float-end mb-3 ${record.clubMode ? "bg-danger" : "bg-success"
                                    }`}
                                onClick={handleUpdateClubMode}
                                variant="primary"
                            >
                                {!record.clubMode ? "Enable" : "Disable"} Club Mode
                            </Button>
                        </div>
                    </div>
                </fieldset>

                {/* Restaurant Open/Close */}
                <fieldset className="my-3">
                    <legend>Restaurant</legend>
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            {/* <input type="checkbox" className="input-default" id="open" checked={record.open} onChange={handleCheckedChange} /> */}
                            <label className="ms-2" htmlFor="open">
                                now, Restaurant is{" "}
                                <b className={record.open ? "text-success" : "text-danger"}>
                                    {record.open ? "Open" : "Close"}
                                </b>
                                , for change status click on button
                            </label>
                        </div>
                        <div className="col-lg-4 my-2">
                            <Button
                                className={`float-end mb-3 ${record.open ? "bg-danger" : "bg-success"
                                    }`}
                                onClick={handleUpdateRestaurantStatus}
                                variant="primary"
                            >
                                {!record.open ? "Open" : "Close"} Restaurant
                            </Button>
                        </div>
                    </div>
                </fieldset>

                {/* Restaurant Timing */}
                <fieldset className="my-3">
                    <legend>Restaurant Timing</legend>
                    <div className="row align-items-center">
                        <div className="col-lg-6 my-2">
                            <label>Timing Group List *</label>
                            <Select
                                options={timingGroupKeyPairList}
                                value={record?.timingGroupUuid}
                                onChange={(e) => handleSelectTextChange(e, "timingGroupUuid")}
                            />
                        </div>
                        <div className="col-lg-6 my-2">
                            <Button
                                variant="primary"
                                className="float-end my-2"
                                onClick={handleUpdateRestaurantTiming}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </fieldset>
            </>
        );
    };

    const getTabsContent = () => {
        if (activeTab === "RestaurantDetails") {
            return restaurantDetailTabContent();
        }
        if (activeTab === "OwnerDetails") {

            return (
                <OwnerDetails
                    recordOwnerDetails={recordOwnerDetails}
                    currencyOptionsList={currencyOptionsList}
                />
            );
        }
        if (activeTab === "Location") {
            return (
                <RestaurantLocation
                    address={address}
                    setRecord={setRecord}
                    recordID={recordID}
                    countryList={countryList}
                    setStateList={setStateList}
                    stateList={stateList}
                    setCityList={setCityList}
                    cityList={cityList}
                />
            );
        }
        if (activeTab === "Images") {
            return (
                <RestaurantImages
                    record={record}
                    setRecord={setRecord}
                    recordID={recordID}
                />
            );
        }
    };

    return (
        <>
            <div className="multi-modals">
                <ModalWrapper
                    show={show}
                    backdrop="static"
                    hide={hideModal}
                    header="Update Restaurant Details"
                    centered={true}
                    size="lg"
                    dialogClassName="right-modal-dialog"
                    contentClassName="right-modal-content"
                >
                    <Tab.Container
                        activeKey={activeTab}
                        onSelect={(key) => setActiveTab(key)}
                    >
                        <Nav variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="RestaurantDetails">
                                    Restaurant Details
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="OwnerDetails">Owner Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Location">Location</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"Images"}>Images</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <div>
                                <Tab.Pane eventKey={activeTab}>{getTabsContent()}</Tab.Pane>
                            </div>
                        </Tab.Content>
                    </Tab.Container>
                </ModalWrapper>
            </div>
        </>
    );
};

export default memo(RestaurantEdit);
