import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useReactToPrint } from "react-to-print";
import toast from "react-hot-toast";
import Select from "react-select";
import DataTable from "react-data-table-component";
import Cookies from "universal-cookie";

import { callPostApi } from "api/ApiCaller";
import { Key_Value_List_Restaurant_Aggregator_POST, Key_Value_List_Restaurant_Payment_Mode_POST, Restaurant_Availability_List_Post, User_Restaurant_Order_Report_POST, User_Restaurant_Today_Order_Report_POST, } from "api/ApiConst";

import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import { MODAL_LOADER, SECTION_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";
import dateUtilities from "utils/DateUtilities";

const defaultObj = {
    startDateTimeStamp: 0,
    endDateTimeStamp: 0,
    paymentType: { label: "All Payment", value: "AllPayment" },
    aggregatorType: { label: "All Aggregator", value: "AllAggregator" },
    // aggregatorUuid: "b01f5481-3a21-4040-81d9-99dd22ed3ee1",
    orderType: { label: "All POS", value: "AllPos" },
};

const orderTypeOptions = [
    { label: "All POS", value: "AllPos" },
    { label: "Dine In", value: "DineIn" },
    { label: "Take Away", value: "TakeAway" },
    { label: "Home Delivery", value: "HomeDelivery" },
    { label: "Steal Deal", value: "StealDeal" },
];

const aggregatorTypeOptions = [
    { label: "All Aggregator", value: "AllAggregator" },
];

const customStyles = {
    control: (provided) => ({
        ...provided,
        width: 135, // Set the desired width
    }),
};

export const RestaurantOrderReportMaster = () => {
    const [record, setRecord] = useState({ ...defaultObj });
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [dummyData, setDummyData] = useState([])
    const [showDateInputs, setShowDateInputs] = useState(true);
    const [today, setToday] = useState(false);
    const [todaySaleReportList, setTodaySaleReportList] = useState([]);
    const [saleReportList, setSaleReportList] = useState([]);
    const [aggregatorOptionsList, setAggregatorOptionsList] = useState([]);
    const [selectedAggregatorOptionsList, setSelectedAggregatorOptionsList] = useState()
    const [creditDetails, setCreditDetails] = useState("");
    const [paymentModeKeyValuePairList, setPaymentModeKeyValuePairList] = useState({ label: "All Payment", value: "AllPayment" })
    const [orderTypeKeyValuePairList, setOrderTypeKeyValuePairList] = useState({ label: "All POS", value: "AllPos" })
    const [exportCSV, setExportCSV] = useState(true)
    const componentRef = useRef();
    const currencyDetail = new Cookies().get("currencyDetail");

    useEffect(() => {
        getOrderTypeKeyValuePairList();
        getAggregatorKeyValuePairList();
        getPaymentModeKeyValuePairLIst();
    }, []);

    const getOrderTypeKeyValuePairList = () => {
        let rUrl = Restaurant_Availability_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            const res = response.responsePacket
            if (response.errorCode === API_SUCCESS) {
                const options = res.map((data) => ({
                    key: data?.key,
                    value: data?.value,
                    label: data?.label,
                }));
                setOrderTypeKeyValuePairList([{ label: "All POS", value: "AllPos" }, ...options]);
            } else {
                toast.error(response.message);
            }
        });
    }
    const getPaymentModeKeyValuePairLIst = () => {
        let rUrl = Key_Value_List_Restaurant_Payment_Mode_POST;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            const res = response.responsePacket
            if (response.errorCode === API_SUCCESS) {
                const options = res.map((data) => ({
                    key: data?.key,
                    value: data?.value,
                    label: data?.label,
                }));
                setPaymentModeKeyValuePairList([{ label: "All Payment", value: "AllPayment" }, ...options]);
            } else {
                toast.error(response.message);
            }
        });
    }

    const getAggregatorKeyValuePairList = () => {
        const rUrl = Key_Value_List_Restaurant_Aggregator_POST;
        const rObj = {};
        callPostApi("", rUrl, rObj, {}, response => {
            if (response.errorCode === API_SUCCESS) {
                const res = response.responsePacket;
                const options = res.map((data) => ({
                    key: data.key,
                    value: data.value,
                    label: data.label,
                }));
                setAggregatorOptionsList(options);
            } else {
                toast.error(response.message, { id: "toast" });
            }
        })
    }

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => {
            const dynamicColumns = dummyData.map((data) => ({
                id: data,
                name: data,
                wrap: true,
                right: true,
                width: "90px",
                selector: (row) => row[data] ? row[data] + " " + currencyDetail?.currencySymbol
                    : "0" + " " + currencyDetail?.currencySymbol,
            }));

            return [
                // {
                //     id: "invoiceNumber",
                //     name: "Invoice Id",
                //     selector: (row) => row.invoiceNumber,
                //     sortable: false,
                //     wrap: true,
                //     center: true,
                //     width: "90px"
                // },
                {
                    id: "orderId",
                    name: "Invoice No.",
                    selector: (row) => row?.restaurantOrderNumber && row?.restaurantOrderNumber ?
                        row?.restaurantOrderNumber : row.orderId,
                    sortable: false,
                    wrap: true,
                    width: "100px"
                },
                {
                    id: "restaurantTitle",
                    name: "Restaurant",
                    selector: (row) => <>{row.restaurantTitle}</>,
                    wrap: true,
                    sortable: false,
                },
                {
                    id: "orderType",
                    name: "Type",
                    wrap: true,
                    selector: (row) => (<>{row.orderType} </>),
                    width: "120px",
                    wrap: true
                },
                // {
                //     id: "orderStatus",
                //     name: "Status",
                //     selector: (row) => row?.orderStatus,
                //     wrap: true,
                //     width: "120px"
                // },
                {
                    id: "orderedAtTimeStamp",
                    name: "Order Date",
                    selector: (row) => dateUtilities.formattedDate(row.orderedAtTimeStamp, "dd-MM-yyyy, hh:mm aa"),
                    sortable: false,
                    width: "120px",
                    wrap: true
                },
                {
                    id: "orderSettledDateTimeStamp",
                    name: "Settlement Time",
                    selector: (row) => dateUtilities.formattedDate(row.orderSettledDateTimeStamp, "dd-MM-yyyy, hh:mm aa"),
                    sortable: false,
                    width: "120px",
                    wrap: true
                },
                {
                    id: "userCustomerFirstName",
                    name: "Customer Name",
                    selector: (row) => (<>{row?.userCustomerFirstName ? row?.userCustomerFirstName + " " + row?.userCustomerLastName : "N/A"} </>),
                    wrap: true,
                    width: "120px"
                },
                {
                    id: "userCustomerMobile",
                    name: "Mobile",
                    selector: (row) => (<>{row.userCustomerMobile ? row.userCustomerMobile : "N/A"} </>),
                    wrap: true,
                    width: "120px"
                },
                {
                    id: "orderTotal",
                    name: "Total Amount",
                    selector: (row) => (<>{row.orderTotal ? row.orderTotal?.toFixed(2) + " " + currencyDetail?.currencySymbol : 0.00 + " " + currencyDetail?.currencySymbol} </>),
                    sortable: false,
                    right: true,
                    wrap: true,
                    // width: "128px",
                },
                {
                    id: "vatItemTotal",
                    name: "VAT Item Total",
                    selector: (row) => (<>{row.vatItemTotal ? row.vatItemTotal?.toFixed(2) + " " + currencyDetail?.currencySymbol : 0.00 + " " + currencyDetail?.currencySymbol} </>),
                    sortable: false,
                    right: true,
                    wrap: true,
                    // width: "135px",
                },
                {
                    id: "vatTotal",
                    name: "VAT Total",
                    selector: (row) => (<>{row.vatTotal ? row.vatTotal?.toFixed(2) + " " + currencyDetail?.currencySymbol : 0.00 + " " + currencyDetail?.currencySymbol} </>),
                    sortable: false,
                    right: true,
                    wrap: true,
                    width: "90px",
                },
                {
                    id: "gstTotal",
                    name: "GST Total",
                    selector: (row) => (<>{row.gstTotal ? row.gstTotal.toFixed(2) + " " + currencyDetail?.currencySymbol : 0.00 + " " + currencyDetail?.currencySymbol} </>),
                    sortable: false,
                    right: true,
                    wrap: true,
                    width: "90px",
                },
                {
                    id: "gstItemTotal",
                    name: "GST Item Total",
                    selector: (row) => (<>{row.gstItemTotal ? row.gstItemTotal?.toFixed(2) + " " + currencyDetail?.currencySymbol : 0.00 + " " + currencyDetail?.currencySymbol} </>),
                    sortable: false,
                    right: true,
                    wrap: true,
                    // width: "135px",
                },
                {
                    id: "discount",
                    name: "Discount",
                    selector: (row) => (<>{row.discount ? row.discount?.toFixed(2) + " " + currencyDetail?.currencySymbol : 0.00 + " " + currencyDetail?.currencySymbol} </>),
                    sortable: false,
                    right: true,
                    wrap: true,
                    width: "90px"
                },
                ...dynamicColumns,
                {
                    id: "deliveryFee",
                    name: "D-fee",
                    selector: (row) => (<>{row.deliveryFee ? row.deliveryFee?.toFixed(2) + " " + currencyDetail?.currencySymbol : 0.00 + " " + currencyDetail?.currencySymbol} </>),
                    sortable: false,
                    right: true,
                    width: "70px",
                    wrap: true,
                },

            ];
        },
        [handleRefresh,]
    );

    const handleTextChange = (e) => {
        const { id, value } = e.target;
        setRecord((prevRecord) => ({ ...prevRecord, [id]: value, }));
    }

    const handlePreviousReport = () => {
        setToday(!today);
        setShowDateInputs(true);
    }

    // Select function
    const handleSelectOrderTypeChange = (e, id) => {
        setRecord((prev) => ({ ...prev, orderType: e }));
        // setOrderAvailability(e)
    };
    const handleSelectPaymentTypeChange = (e, id) => {
        setRecord((prev) => ({ ...prev, paymentType: e }));
    };

    // Option Select Event
    const handleSelect = (e) => {
        setRecord((prev) => ({ ...prev, aggregatorType: e }));
    };
    const handleTodayReport = () => {
        setToday(!today);
        setShowDateInputs(false);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: creditDetails?.invoiceNo,
        // pageStyle: "print",
        pageStyle: `@media print {
            @page {
              size: 500mm 500mm;
              margin: 0;
            }
          }`,
    }
    );

    const handleDynamicPaymentMode = (res) => {
        let tempObj = {};
        let totals = {
            invoiceNumber: "Total",
            userCustomerMobile: " ",
            orderTotal: 0,
            vatItemTotal: 0,
            totalVat: 0,
            gstTotal: 0,
            gstItemTotal: 0,
        };
        res.map((obj) => {
            obj.payment.map((item) => {
                if (tempObj[item.paymentMode]) {
                    tempObj[item.paymentMode] += item.amount;
                } else {
                    tempObj[item.paymentMode] = item.amount;
                }
                obj[item.paymentMode] = item.amount
            })
            totals.orderTotal += obj.orderTotal || 0
            totals.vatItemTotal += obj.vatItemTotal || 0
            totals.totalVat += obj.vatTotal || 0
            totals.gstTotal += obj.gstTotal || 0
            totals.gstItemTotal += obj.gstItemTotal || 0
        });
        const columnSummary = { ...tempObj, ...totals };
        setDummyData(Object.keys(tempObj));
        return columnSummary
    }

    const handleGetReport = () => {
        let recordObj = { ...record, }

        if (today) {
            let rObj = { ...record }
            rObj.orderType = recordObj.orderType.value;
            rObj.paymentType = recordObj.paymentType.value;
            rObj.aggregatorType = recordObj.aggregatorType.value;
            delete rObj.endDateTimeStamp;
            delete rObj.startDateTimeStamp;

            let rUrl = User_Restaurant_Today_Order_Report_POST;
            if (rObj.aggregatorType && rObj.orderType && rObj.paymentType) {
                callPostApi(MODAL_LOADER, rUrl, rObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        const res = response.responsePacket;
                        const colSummary = handleDynamicPaymentMode(res);


                        toast.success(response.message, { id: "toast" });

                        handleRefresh();

                        if (res == "") {
                            return;
                        }
                        setTodaySaleReportList([...res, colSummary]);

                    } else {
                        toast.error(response.message, { id: "toast" });
                    }
                });
            }
        } else {
            let rObj = { ...record }
            rObj.startDateTimeStamp = new Date(rObj.startDateTimeStamp).getTime();
            rObj.endDateTimeStamp = new Date(rObj.endDateTimeStamp).getTime();

            const diff = rObj.endDateTimeStamp - rObj.startDateTimeStamp;
            const daysDifference = diff / (1000 * 60 * 60 * 24);
            // if (daysDifference <= 31) {
                rObj.aggregatorType = recordObj.aggregatorType.value;
                rObj.orderType = recordObj.orderType.value;
                rObj.paymentType = recordObj.paymentType.value;
                let rUrl = User_Restaurant_Order_Report_POST;
                if ( rObj.orderType && rObj.startDateTimeStamp && rObj.endDateTimeStamp) {
                    callPostApi(MODAL_LOADER, rUrl, rObj, {}, (response) => {
                        if (response.errorCode === API_SUCCESS) {
                            const res = response.responsePacket;
                            const colSummary = handleDynamicPaymentMode(res);

                            toast.success(response.message, { id: "toast" });
                            handleRefresh();

                            if (res == "") {
                                return;
                            }
                            setSaleReportList([...res, colSummary]);

                        } else {
                            toast.error(response.message, { id: "toast" });
                        }
                    });
                } else {
                    toast.error("All Fields must be filled", { id: "toast" });
                }
            // }
            // else {
            //     toast.error("Days cannot be more than 31 days");
            // }
        }
    }
    // convert data into CSV format
    function convertArrayOfObjectsToCSV(csvData) {
        const sortedCsvData = csvData?.sort((a, b) => {
            if (a.restaurantOrderNumber === undefined) return 1; // Move undefined to the end of the array
            if (b.restaurantOrderNumber === undefined) return -1; // Move undefined to the end of the array

            const numA = parseInt(a.restaurantOrderNumber.substring(4)); // Extract numeric part from string a
            const numB = parseInt(b.restaurantOrderNumber.substring(4)); // Extract numeric part from string b
            return numA - numB;
          });
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(today ? todaySaleReportList[0] : saleReportList[0]);

        let keyArray = columns.map((item) => item.name);
        let keyArrayId = columns.map((item) => item.id);
        result = '';
        result += keyArray.join(columnDelimiter);
        result += lineDelimiter;
        sortedCsvData?.forEach(item => {
            if(item?.orderedAtTimeStamp && item?.orderedAtTimeStamp !== undefined){
                item.orderedAtTimeStamp = dateUtilities.formattedDate(item.orderedAtTimeStamp, "dd-MM-yyyy, hh:mm aa") ?? item?.orderedAtTimeStamp;
                item.orderedAtTimeStamp = item.orderedAtTimeStamp.replace(',', ':');
            }
            if(item?.orderSettledDateTimeStamp && item?.orderSettledDateTimeStamp !== undefined){
                item.orderSettledDateTimeStamp = dateUtilities.formattedDate(item.orderSettledDateTimeStamp,  "dd-MM-yyyy, hh:mm aa") ?? item?.orderedAtTimeStamp;
                item.orderSettledDateTimeStamp = item.orderSettledDateTimeStamp.replace(',', ':');
            }
            if(item?.restaurantOrderNumber && (item?.restaurantOrderNumber !== undefined || item?.restaurantOrderNumber !== null)){
                item.orderId = item.restaurantOrderNumber || "";
            }
            let ctr = 0;
            keyArrayId?.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                if(item[key] === undefined) {
                    result += "";
                } else {
                    result += item[key];
                }
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    }
    function downloadCSV(csvData) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(csvData);
        if (csv == null) return;
        const filename = 'export.csv';
        if (!csv?.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    const Export = ({ onExport }) => <button className="btn btn-success me-2 " onClick={e => onExport(e?.target?.value)}>Download Excel</button>;
    const exportCSVActionsMemo = useMemo(() =>
    
        <Export onExport={() =>
            downloadCSV(today ? todaySaleReportList : saleReportList)}
        />
        , [saleReportList, todaySaleReportList]
    );
    return (
        <>
            {PageHeading("Sale Report List", "Menu", "/")}
            <div className="card">
                <div className="card-body tableView" >

                    <div className="d-flex createButton">
                        <CustomButton
                            handleClick={handleTodayReport}
                            title="Today"
                            buttonClass="bg-secondary "
                        />
                        <CustomButton
                            handleClick={handlePreviousReport}
                            title="Previous"
                            buttonClass="bg-secondary ms-1"
                        />
                        <Select
                            className="ms-1"
                            maxMenuHeight={150}
                            styles={customStyles}
                            options={orderTypeKeyValuePairList}
                            value={record.orderType}
                            onChange={(e) => handleSelectOrderTypeChange(e)}
                        />

                        {showDateInputs && (
                            <>
                                <input
                                    type="date"
                                    className="form-control ms-1"
                                    id="startDateTimeStamp"
                                    onChange={handleTextChange}
                                    value={record.startDateTimeStamp}
                                />
                                <input
                                    type="date"
                                    className="form-control ms-1"
                                    id="endDateTimeStamp"
                                    onChange={handleTextChange}
                                    value={record.endDateTimeStamp}
                                />
                            </>
                        )}
                    </div>

                    <CustomButton
                        title="Get Report"
                        buttonClass="bg-success"
                        handleClick={handleGetReport}
                    />
                </div>
                {(saleReportList.length > 0 || todaySaleReportList.length > 0) && <CustomButton
                    title="Download PDF"
                    buttonClass="bg-primary me-3 pdf-downloadbtn" style={{top:"87px"}}
                    handleClick={handlePrint}>
                </CustomButton>}
                {today ?
    
                    <div ref={componentRef}>
                        <DataTable className="reportDataTable"
                            columns={columns}
                            data={todaySaleReportList}
                            actions={todaySaleReportList.length > 0 ? exportCSV && exportCSVActionsMemo : false}
                        />
                    </div>
                    :
                    <div ref={componentRef}>
                        <DataTable className="reportDataTable"
                            actions={saleReportList.length > 0 ? exportCSV && exportCSVActionsMemo : false}
                            columns={columns}
                            data={saleReportList}
                        />
                    </div>
                }
            </div >
        </>
    );
};

export default RestaurantOrderReportMaster;
