import { useCallback, useEffect, useMemo, useState } from "react";

import { toast } from "react-hot-toast";

import {
  All_Restaurant_Voucher_List_Post,
  Send_Message_Restaurant_Voucher_Put,
} from "api/ApiConst";
import dateUtilities from "utils/DateUtilities";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";
import { callPutApi } from "api/ApiCaller";
import { isEmpty, isMobile } from "utils/TextUtils";

import PaginationDataTable from "components/common/PaginationDataTable";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import ModalWrapper from "components/common/ModalWrapper";
import RestaurantVoucherAddEdit from "./RestaurantVoucherAddEdit";
import Cookies from "universal-cookie";

const RestaurantVoucherMaster = () => {
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [recordID, setRecordID] = useState(null);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  const currencyDetail = new Cookies().get("currencyDetail");

  useEffect(() => {
    if (!showSendMessageModal) {
      setRecordID(null);
    }
  }, [showSendMessageModal]);

  const handleRefresh = useCallback(() => {
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag]);

  const viewExpireDateCol = (row) => {
    var currentDate = new Date();
    return (
      <span
        className={`${
          row.validTillTimeStamp > currentDate.getTime()
            ? "text-success"
            : "text-danger"
        }`}
      >
        {dateUtilities.formattedDate(row.validTillTimeStamp, "dd-MM-yyyy")}
      </span>
    );
    // if (row.validTillTimeStamp > currentDate.getTime()) {
    // } else {
    //     return <span className="text-danger">{dateUtilities.formattedDate(row.validTillTimeStamp, "dd-MM-yyyy")}</span>
    // }
  };

  const columns = useMemo(
    () => [
      {
        id: "Status",
        name: "",
        selector: (row) => (
          <div
            className={row.active ? "led-green" : "led-red"}
            title={row.active ? "Active" : "Inactive"}
          />
        ),
        width: "60px",
        sortable: false,
      },
      {
        id: "voucherCode",
        name: "Code",
        selector: (row) => row.voucherCode,
        width: "80px",
        sortable: false,
      },
      {
        id: "voucherAmount",
        name: "Amount",
        selector: (row) =>
          row.voucherAmount.toFixed(2) + " " + currencyDetail?.currencySymbol,
        sortable: false,
        right: true,
        width: "100px",
      },
      {
        id: "voucherPaymentType",
        name: "Payment Type",
        selector: (row) => row.voucherPaymentType,
        sortable: false,
      },
      {
        id: "consumed",
        name: "Consumed",
        selector: (row) => (
          <span className={`${row.consumed ? "text-success" : "text-danger"}`}>
            {row.consumed ? "Consumed" : "Not Consumed"}
          </span>
        ),
        sortable: false,
      },
      {
        id: "consumedBy",
        name: "Consumed By",
        selector: (row) => row?.consumedBy,
        sortable: false,
      },
      {
        id: "transactionId",
        name: "Txn#",
        selector: (row) => (row.transactionId ? row.transactionId : "-"),
        sortable: false,
      },
      {
        id: "validTillTimeStamp",
        name: "Expiry Date",
        selector: (row) => viewExpireDateCol(row),
        sortable: false,
        width: "110px",
      },
      {
        id: "createdAt",
        name: "Created Time",
        selector: (row) =>
          dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
        sortable: false,
        width: "140px",
      },
      {
        id: "action",
        name: "",
        selector: (row) => getActionButtons(row),
        width: "80px",
        sortable: false,
      },
    ],
    [handleRefresh]
  );

  const getActionButtons = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <button
          className="listBtn ms-3"
          title="Send Message"
          onClick={() => handleSendMessage(row)}
        >
          <i className="bx bxl-messenger" />
        </button>
      </div>
    );
  };

  // Send Message
  const handleSendMessage = (row) => {
    setRecordID(row.uuid);
    setShowSendMessageModal(true);
  };

  const checkInputValidation = () => {
    if (isEmpty(mobileNumber)) {
      toast.error("Mobile Number must not be empty.", { id: "toast" });
      return false;
    } else if (!isMobile(mobileNumber)) {
      toast.error("Please Provide Valid Mobile Number", { id: "toast" });
      return false;
    }
    return true;
  };

  const handleSubmitSendMessage = () => {
    if (!checkInputValidation()) {
      return;
    }

    let recordObj = {
      mobileNumber: mobileNumber,
      voucherUuid: recordID,
    };
    recordObj.voucherUuid = recordID;
    callPutApi(
      MODAL_LOADER,
      Send_Message_Restaurant_Voucher_Put.replaceAll("{recordID}", recordID),
      recordObj,
      {},
      (response) => {
        if (response.errorCode === API_SUCCESS) {
          toast.success(response.message, {id: "toast"});
          setMobileNumber("");
          setRecordID(null);
          setShowSendMessageModal();
          handleRefresh();
        } else {
          toast.error(response.message);
        }
      }
    );
  };

  const handleTextChange = (e) => {
    const { value } = e.target;
    if (value.length > 10) {
      return;
    }
    setMobileNumber(parseInt(value));
  };

  const sendMessageModal = () => {
    return (
      <ModalWrapper
        backdrop="static"
        show={showSendMessageModal}
        hide={() => setShowSendMessageModal(false)}
        header={`Send Message`}
        size={"md"}
        handleSubmit={handleSubmitSendMessage}
      >
        <div className="form-group row mb-3">
          <div className="col-lg-12">
            <label>Mobile Number *</label>
            <input
              type="number"
              className="form-control input-default"
              id="mobileNumber"
              onChange={handleTextChange}
              value={mobileNumber}
            />
          </div>
        </div>
      </ModalWrapper>
    );
  };

  return (
    <>
      {PageHeading("Voucher List", "Dashboard", "/")}
      <div className="card searchOnlyBlock">
        <CustomButton
          handleClick={() => setShowAddUpdateModal(true)}
          title="Create New"
          iconClass="bx bx-plus"
          buttonClass="createButton"
        />
        <div className="card-body tableView">
          <PaginationDataTable
            paginationUrl={All_Restaurant_Voucher_List_Post}
            serverSidePagination={true}
            search={true}
            pagination={false}
            columns={columns}
          />
        </div>
      </div>
      {showAddUpdateModal && (
        <RestaurantVoucherAddEdit
          show={showAddUpdateModal}
          hideModal={() => setShowAddUpdateModal(false)}
          handleRefresh={handleRefresh}
        />
      )}
      {showSendMessageModal && sendMessageModal()}
    </>
  );
};

export default RestaurantVoucherMaster;
