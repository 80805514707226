import React, { useEffect, useState } from 'react'

import { toast } from 'react-hot-toast';

import { All_Client_Restaurant_Key_value_List_Post, Details_Client_Coupon_Code_Get, Get_Client_Brand_Item_Key_Value_List_Post, Get_Client_Category_Key_Value_List_Post, Get_Client_Item_Key_Value_List_Post, Save_Client_Coupon_Code_Post, Update_Client_Coupon_Code_Put } from 'api/ApiConst';
import { callGetApi, callPostApi, callPutApi } from 'api/ApiCaller';
import { API_SUCCESS } from 'api/ResponseCodes';
import { getDOB, getTimeFromDate, isAlphaNumercSpecialCharSpc, isEmpty } from 'utils/TextUtils';
import { MODAL_LOADER } from 'utils/constants';

import ModalWrapper from 'components/common/ModalWrapper'
import Select from 'react-select';
import { useSelector } from 'react-redux';

const discountTypeKeyValuePairs = [
    { label: "Value Discount", value: "", key: "ValueDiscount" },
    { label: "Percentage Discount", value: "Percentage Discount", key: "PercentageDiscount" },
    { label: "Free Item", value: "Free Item", key: "FreeItemDiscount" },
    // { label: "Item Discount", value: "Item Discount", key: "ItemDiscount" },
    // { label: "Category Discount", value: "Category Discount", key: "CategoryDiscount" },
    { label: "Delivery Fee", value: "Delivery Fee", key: "DeliveryFeeDiscount" },
];

const discountOnKeyValuePairs = [
    { label: "All", value: "None", key: "None" },
    { label: "Item Discount", value: "ItemDiscount", key: "ItemDiscount" },
    { label: "Category Discount", value: "Category Discount", key: "CategoryDiscount" },
];

const CouponCodeAddEdit = ({ show, hideModal, recordID, handleRefresh, couponType }) => {
    const { brandKeyValuePairs } = useSelector((state) => state.utility);
    const defaultRecordObj = {
        brandUuid: "",
        title: "",
        description: "",
        startDateTimeStamp: 0,
        endDateTimeStamp: 0,
        discountType: "",
        discountOn: "",
        minOrderValue: 0,
        discountValue: 0,
        maxDiscountAmount: 0,
        maxUsePerUser: 0,
        availabilityList: [],
        applicableOnAllRestaurants: false,
        categoryUuids: [],
        userCustomerUuid: "",
        staffCoupon: false,
    }
    const [record, setRecord] = useState(defaultRecordObj);
    const [availabilityList, setAvailabilityList] = useState({ HomeDelivery: false, TakeAway: false, DineIn: false, StealDeal: false });
    const [restaurantKeyValuePairs, setRestaurantKeyValuePairs] = useState([])
    const [menuItemKeyValuePairs, setMenuItemKeyValuePairs] = useState([])
    const [restaurantUuids, setRestaurantUuids] = useState([])
    const [menuItemUuidsObj, setMenuItemUuidsObj] = useState({
        menuItemUuid: "",
        menuItemUuids: [],
    });
    const [categoryKeyValuePairList, setCategoryKeyValuePairList] = useState([]);
    const [categoryUuids, setCategoryUuids] = useState([])

    useEffect(() => {
        if (brandKeyValuePairs.length === 1) {
            getRestaurantKeyValuePairs(brandKeyValuePairs[0].key)
            getMenuItemKeyValuePairs(brandKeyValuePairs[0].key)
            getCategoryKeyValuePairs(brandKeyValuePairs[0].key)
        }
    }, [])

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Client_Coupon_Code_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const res = response.responsePacket;
                    // get restaurant key Pair
                    getRestaurantKeyValuePairs(res.brandUuid, res.restaurantUuids)
                    getMenuItemKeyValuePairs(res.brandUuid, res)
                    if (res.discountOn === "CategoryDiscount") {
                        getCategoryKeyValuePairs(res.brandUuid, res.applicableOnUuids)
                    }
                    handleSetInitialValues(res)
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, [recordID]);

    const handleSetInitialValues = (response) => {
        // Set brand Key
        const _selectedBrandUuid = brandKeyValuePairs.find((obj) => obj.key === response.brandUuid);

        // set discountOn key
        const _selectedDiscountOn = discountOnKeyValuePairs.find((obj) => obj.key === response.discountOn);

        // set discountType key
        const _selectedDiscountType = discountTypeKeyValuePairs.find((obj) => obj.key === response.discountType);

        // Set Availabilities
        for (let i = 0; i < response.availabilityList.length; i++) {
            const availabilitiesKey = response.availabilityList[i];
            if (availabilitiesKey) {
                setAvailabilityList((prev) => ({ ...prev, [availabilitiesKey]: true }));
            }
        }

        const _categoryUuids = [];
        if (response.discountType === "CategoryDiscount") {
            response.applicableOnUuids.map(data => {
                if (categoryKeyValuePairList.includes(data.key)) {
                    _categoryUuids.push(data)
                }
            })
        }
        let recordObj = {
            ...response,
            brandUuid: _selectedBrandUuid || "",
            discountType: _selectedDiscountType || "",
            discountOn: _selectedDiscountOn || "",
            categoryUuids: _categoryUuids,
            startDateTimeStamp: getDOB(response.startDateTimeStamp),
            endDateTimeStamp: getDOB(response.endDateTimeStamp),
            userCustomerUuid: "",
        };
        setRecord(recordObj);
    }

    // Get Restaurant Key-Value Pair List
    const getRestaurantKeyValuePairs = (brandUuid, restaurantUuids = []) => {
        const rUrl = All_Client_Restaurant_Key_value_List_Post;
        const rObj = {
            brandUuid: brandUuid,
        };
        callPostApi(null, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setRestaurantKeyValuePairs(response.responsePacket);
                const selectedRestaurants = []
                response.responsePacket.map(data => {
                    if (restaurantUuids.includes(data.key)) {
                        selectedRestaurants.push(data)
                    }
                })
                setRestaurantUuids(selectedRestaurants)
            } else {
                toast.error(response.message);
            }
        });
    }

    // Get Category Key Pair List
    const getCategoryKeyValuePairs = (brandUuid, category = []) => {
        const rUrl = Get_Client_Category_Key_Value_List_Post + "/" + brandUuid;
        const rObj = {};
        callPostApi(null, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setCategoryKeyValuePairList(response.responsePacket);
                const selectedCategory = [];
                response.responsePacket.map(data => {
                    if (category.includes(data.key)) {
                        selectedCategory.push(data)
                    }
                })
                setCategoryUuids(selectedCategory);
            } else {
                toast.error(response.message);
            }
        });
    };

    // Get Item Key-Value Pair List
    const getMenuItemKeyValuePairs = (brandUuid, res = null) => {
        const rUrl = Get_Client_Brand_Item_Key_Value_List_Post + "/" + brandUuid;
        callPostApi(null, rUrl, {}, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setMenuItemKeyValuePairs(response.responsePacket);
                if (res !== null) {
                    let _menuItemUuid = "";
                    let _menuItemUuids = [];
                    if (res.discountOn === "ItemDiscount") {
                        response.responsePacket.map(data => {
                            if (res.applicableOnUuids.includes(data.key)) {
                                _menuItemUuids.push(data)
                            }
                        })
                    } else {

                        if (res.discountType === "FreeItemDiscount") {
                            _menuItemUuid = response.responsePacket.find((obj) => obj.key === response.menuItemUuid);
                        }
                        // else if (res.discountType === "ItemDiscount") {
                        //     response.responsePacket.map(data => {
                        //         if (res.applicableOnUuids.includes(data.key)) {
                        //             _menuItemUuids.push(data)
                        //         }
                        //     })
                        // }
                    }
                    setMenuItemUuidsObj({
                        menuItemUuid: _menuItemUuid,
                        menuItemUuids: _menuItemUuids,
                    })
                }
            } else {
                toast.error(response.message);
            }
        });
    }

    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (id === "title" && value.length !==0 ){
            if(!isAlphaNumercSpecialCharSpc(value)) {
                return;
            }
        }
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseFloat(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    //Select Text Change Event
    const handleSelectChange = (e, id) => {
        if (id === "brandUuid") {
            getRestaurantKeyValuePairs(e.key)
            getMenuItemKeyValuePairs(e.key)
            getCategoryKeyValuePairs(e.key)
            setRecord((prev) => ({ ...prev, [id]: e }));
            setRestaurantUuids([]);
        } else if (id === "menuItemUuid") {
            let _menuItemUuid = "";
            let _menuItemUuids = [];
            if (record.discountOn?.key === "ItemDiscount") {
                _menuItemUuid = ""; _menuItemUuids = e;
            } else {
                _menuItemUuid = e; _menuItemUuids = [];
            }
            setMenuItemUuidsObj({ menuItemUuid: _menuItemUuid, menuItemUuids: _menuItemUuids, })
            // setMenuItemUuidsObj({
            //     menuItemUuid: record.discountType?.key == "ItemDiscount" ? "" : e,
            //     menuItemUuids: record.discountType?.key == "ItemDiscount" ? e : [],
            // })
        } else if (id === "discountType" && e.key === "DeliveryFeeDiscount") {
            setRecord((prev) => ({ ...prev, [id]: e }));
            setAvailabilityList((prev) => ({ ...prev, HomeDelivery: true }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: e }));
        }
    };

    // discountOn Select Change
    const handleDiscountOnSelectChange = (e) => {
        setRecord((prev) => ({
            ...prev, discountOn: e,
            discountType: (e.key === "ItemDiscount" || e.key === "CategoryDiscount") && record.discountType?.key === "FreeItemDiscount" ? "" : record.discountType,
        }));
        if (e.key === "None") {
            setRecord((prev) => ({ ...prev, discountOn: e, categoryUuids: [], }));
            setMenuItemUuidsObj({ menuItemUuid: "", menuItemUuids: [], });
            setCategoryUuids([]);
        } else if (e.key === "ItemDiscount") {
            setRecord((prev) => ({ ...prev, discountOn: e, discountValue: 0, maxDiscountAmount: 0, categoryUuids: [], }));
            setMenuItemUuidsObj({ ...menuItemUuidsObj, menuItemUuid: "", })
            setCategoryUuids([]);
        } else if (e.key === "CategoryDiscount") {
            setRecord((prev) => ({ ...prev, discountOn: e, maxDiscountAmount: 0, }));
            setMenuItemUuidsObj({ menuItemUuid: "", menuItemUuids: [], })
        }
    }

    // Discount Type Select Text Change 
    const handleDiscountTypeChange = (e) => {
        setRecord((prev) => ({ ...prev, "discountType": e }));
        if (e.key === "ValueDiscount") {
            setRecord((prev) => ({ ...prev, discountType: e, categoryUuids: [], maxDiscountAmount: 0, }));
            setMenuItemUuidsObj({ menuItemUuid: "", menuItemUuids: [], });
            setCategoryUuids([])
        } else if (e.key === "PercentageDiscount") {
            setRecord((prev) => ({ ...prev, discountType: e, categoryUuids: [], }));
            setMenuItemUuidsObj({ menuItemUuid: "", menuItemUuids: [], });
            setCategoryUuids([])
        } else if (e.key === "FreeItemDiscount") {
            setRecord((prev) => ({ ...prev, discountType: e, discountValue: 0, maxDiscountAmount: 0, categoryUuids: [], }));
            setMenuItemUuidsObj({ ...menuItemUuidsObj, menuItemUuids: [], })
            setCategoryUuids([])
        } else if (e.key === "ItemDiscount") {
            setRecord((prev) => ({ ...prev, discountType: e, discountValue: 0, maxDiscountAmount: 0, categoryUuids: [], }));
            setMenuItemUuidsObj({ ...menuItemUuidsObj, menuItemUuid: "", });
            setCategoryUuids([])
        } else if (e.key === "CategoryDiscount") {
            setRecord((prev) => ({ ...prev, discountType: e, maxDiscountAmount: 0, }));
            setMenuItemUuidsObj({ menuItemUuid: "", menuItemUuids: [], });
        } else if (e.key === "DeliveryFeeDiscount") {
            setAvailabilityList((prev) => ({ ...prev, HomeDelivery: true, StealDeal: false }));
            setRecord((prev) => ({ ...prev, discountType: e, maxDiscountAmount: 0, }));
            setMenuItemUuidsObj({ menuItemUuid: "", menuItemUuids: [], });
            setCategoryUuids([])
        }
    }

    // Filter Item Options // *remove Free Item option when discountOn = ItemDiscount || CategoryDiscount
    const filterOptions = (options) => {
        // Filter out the free Item option from the options array
        if (record.discountOn?.key === "ItemDiscount" || record.discountOn?.key === "CategoryDiscount") {
            return options.filter(option => option.key !== "FreeItemDiscount")
        }
        return options;
    };

    //Text Check Box Change
    const handleCheckBoxChange = (e, key) => {
        const { id, checked } = e.target;
        if (key === "availabilities") {
            if (record.discountType?.key === "DeliveryFeeDiscount" && id === "HomeDelivery" && !checked) {
                return;
            }
            setAvailabilityList((prev) => ({ ...prev, [id]: checked }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: checked }));
            setRestaurantUuids([]);
        }
    };

    const checkInputValidation = (recordObj) => {
        if (!recordID && isEmpty(recordObj.brandUuid)) {
            toast.error(`Brand must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.title)) {
            toast.error(`Title must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.discountOn)) {
            toast.error(`Discount On must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.discountType)) {
            toast.error(`Discount Type must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.minOrderValue)) {
            toast.error(`Minimum Order value must not be empty`, { id: "toast" });
            return false;
        } else if (recordObj.maxUsePerUser < 0) {
            toast.error(`Maximum use per user must not be negative`, { id: "toast" });
            return false;
        } else if (recordObj.discountType) {
            if (recordObj.discountType === "ValueDiscount" || recordObj.discountType === "PercentageDiscount") {
                if (isEmpty(recordObj.discountValue)) {
                    toast.error(`Discount value must not be empty`, { id: "toast" });
                    return false;
                } else if (recordObj.discountValue < 0) {
                    toast.error(`Discount value must not be negative`, { id: "toast" });
                    return false;
                }
            }
            if (recordObj.discountType === "PercentageDiscount" || recordObj.discountType === "DeliveryFeeDiscount") {
                if (isEmpty(recordObj.maxDiscountAmount)) {
                    toast.error(`Maximum Discount amount must not be empty`, { id: "toast" });
                    return false;
                } else if (recordObj.maxDiscountAmount < 0) {
                    toast.error(`Maximum Discount amount must not be negative`, { id: "toast" });
                    return false;
                }
            }
            if (recordObj.discountType === "FreeItemDiscount" && isEmpty(recordObj.menuItemUuid)) {
                toast.error(`Menu Item must not be empty`, { id: "toast" });
                return false;
            }
            if (recordObj.discountOn === "ItemDiscount" && recordObj.applicableOnUuids.length === 0) {
                toast.error(`Menu Item must not be empty`, { id: "toast" });
                return false;
            }
            // if (recordObj.discountType === "ItemDiscount" && recordObj.applicableOnUuids.length === 0) {
            //     toast.error(`Menu Item must not be empty`, { id: "toast" });
            //     return false;
            // }
            // if (recordObj.discountType === "CategoryDiscount" && recordObj.applicableOnUuids.length === 0) {
            //     toast.error(`Category must not be empty`, { id: "toast" });
            //     return false;
            // }
            if (recordObj.discountOn === "CategoryDiscount" && recordObj.applicableOnUuids.length === 0) {
                toast.error(`Category must not be empty`, { id: "toast" });
                return false;
            }
        }
        if (!recordObj.applicableOnAllRestaurants && recordObj.restaurantUuids.length === 0) {
            toast.error(`Restaurant must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.startDateTimeStamp)) {
            toast.error(`Start Date must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.endDateTimeStamp)) {
            toast.error(`End Date must not be empty`, { id: "toast" });
            return false;
        } else if (recordObj.availabilityList.length === 0) {
            toast.error(`Availability must not be empty`, { id: "toast" });
            return false;
        } else if (couponType === "customer" && isEmpty(record.userCustomerUuid)) {
            toast.error(`Customer must not be empty`, { id: "toast" });
            return false;
        }

        if (recordObj.startDateTimeStamp) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            var startDate = new Date(recordObj.startDateTimeStamp);
            startDate.setHours(0, 0, 0, 0);
            if (currentDate.getTime() > startDate.getTime()) {
                toast.error(`Start Date must not be previous day date.`, { id: "toast" });
                return false;
            }
        }

        if (recordObj.endDateTimeStamp) {
            var startDate = new Date(recordObj.startDateTimeStamp);
            startDate.setHours(0, 0, 0, 0);

            var endDate = new Date(recordObj.endDateTimeStamp);
            endDate.setHours(0, 0, 0, 0);

            if (startDate.getTime() > endDate.getTime()) {
                toast.error(`End Date must be greater to Start date.`, { id: "toast" });
                return false;
            }
        }
        return true;
    };

    const handleSubmit = () => {
        try {

            let _availabilityList = [];
            let _restaurantUuids = [];
            let _applicableOnUuids = [];
            if (record.discountOn?.key === "ItemDiscount") {
                for (let i = 0; i < menuItemUuidsObj.menuItemUuids.length; i++) {
                    const obj = menuItemUuidsObj.menuItemUuids[i];
                    _applicableOnUuids.push(obj.key);
                }
            } else if (record.discountOn?.key === "CategoryDiscount") {
                for (let i = 0; i < categoryUuids.length; i++) {
                    const obj = categoryUuids[i];
                    _applicableOnUuids.push(obj.key);
                }
            }
            // if (record.discountType?.key === "ItemDiscount") {
            //     for (let i = 0; i < menuItemUuidsObj.menuItemUuids.length; i++) {
            //         const obj = menuItemUuidsObj.menuItemUuids[i];
            //         _applicableOnUuids.push(obj.key);
            //     }
            // } else if (record.discountType?.key === "CategoryDiscount") {
            //     for (let i = 0; i < categoryUuids.length; i++) {
            //         const obj = categoryUuids[i];
            //         _applicableOnUuids.push(obj.key);
            //     }
            // }

            for (let key in availabilityList) {
                if (availabilityList[key]) {
                    _availabilityList.push(key);
                }
            }

            for (let i = 0; i < restaurantUuids.length; i++) {
                const obj = restaurantUuids[i];
                _restaurantUuids.push(obj.key);
            }

            let recordObj = {
                ...record,
                brandUuid: record.brandUuid.key || "",
                discountType: record.discountType?.key || "",
                discountOn: record.discountOn?.key || "",
                restaurantUuids: record.applicableOnAllRestaurants ? [] : _restaurantUuids,
                menuItemUuid: menuItemUuidsObj.menuItemUuid?.key || "",
                availabilityList: _availabilityList,
                applicableOnUuids: _applicableOnUuids,
                staffCoupon: couponType === "staff" ? true : false,
                userCustomerUuid: couponType === "customer" ? record.userCustomerUuid?.key : null,
            };

            if (!checkInputValidation(recordObj)) {
                return;
            }
            recordObj.startDateTimeStamp = getTimeFromDate(recordObj.startDateTimeStamp);
            recordObj.endDateTimeStamp = getTimeFromDate(recordObj.endDateTimeStamp);
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Client_Coupon_Code_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Client_Coupon_Code_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            }
        } catch (error) {
            return;
        }
    }

    return (
        <ModalWrapper show={show} hide={hideModal}  backdrop="static" header={`${recordID ? "Update" : "Add"} ${couponType === "customer" ? " Customer" : couponType === "staff" ? "Staff" : ""} Coupon Code`} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-3 my-2">
                    <label>Brand *</label>
                    <Select options={brandKeyValuePairs} value={record.brandUuid} onChange={(e) => handleSelectChange(e, "brandUuid")} />
                </div>
                <div className="col-lg-3 my-2">
                    <label>Title *</label>
                    <input type="text" maxLength={25} className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} />
                </div>
                <div className="col-lg-3 my-2">
                    <label>Discount On *</label>
                    <Select options={discountOnKeyValuePairs} value={record.discountOn} onChange={handleDiscountOnSelectChange} />
                </div>
                <div className="col-lg-3 my-2">
                    <label>Discount Type *</label>
                    <Select options={filterOptions(discountTypeKeyValuePairs)} value={record.discountType} onChange={handleDiscountTypeChange} />
                </div>
                <div className="col-lg-3 my-2">
                    <label>Minimum Order Value </label>
                    <input type="number" className="form-control input-default" id="minOrderValue" onChange={handleTextChange} value={record.minOrderValue} />
                </div>

                <div className="col-lg-3 my-2">
                    <label>Maximum Use Per User </label>
                    <input type="number" className="form-control input-default" id="maxUsePerUser" onChange={handleTextChange} value={record.maxUsePerUser} />
                </div>
                <div className="col-lg-3 my-2">
                    <label>Discount Value </label>
                    <input disabled={(record.discountType?.key !== "ValueDiscount" && record.discountType?.key !== "PercentageDiscount")} type="number" className="form-control input-default" id="discountValue" onChange={handleTextChange} value={record.discountValue} />
                </div>
                <div className="col-lg-3 my-2">
                    <label>Max Discount Amount </label>
                    <input disabled={record.discountType?.key !== "PercentageDiscount" && record.discountType?.key !== "DeliveryFeeDiscount"} type="number" className="form-control input-default" id="maxDiscountAmount" onChange={handleTextChange} value={record.maxDiscountAmount} />
                </div>
                {(record.discountType?.key === "FreeItemDiscount" || record.discountOn?.key === "ItemDiscount") &&
                    <div className="col-lg-6 my-2">
                        <label>Menu {record.discountType?.key === "FreeItemDiscount" ? "Item" : record.discountOn?.key === "ItemDiscount" ? "Items" : ""} *</label>
                        {/* <Select isDisabled={record.discountType?.key !== "FreeItemDiscount" && record.discountType?.key !== "ItemDiscount"} isMulti={record.discountType?.key == "ItemDiscount"} options={menuItemKeyValuePairs} value={record.discountType?.key == "ItemDiscount" ? menuItemUuidsObj.menuItemUuids : menuItemUuidsObj.menuItemUuid} onChange={(e) => handleSelectChange(e, "menuItemUuid")} /> */}
                        <Select isDisabled={record.discountType?.key !== "FreeItemDiscount" && record.discountOn?.key !== "ItemDiscount"} isMulti={record.discountOn?.key === "ItemDiscount"} options={menuItemKeyValuePairs} value={record.discountOn?.key == "ItemDiscount" ? menuItemUuidsObj.menuItemUuids : menuItemUuidsObj.menuItemUuid} onChange={(e) => handleSelectChange(e, "menuItemUuid")} />
                    </div>
                }
                {record.discountOn?.key === "CategoryDiscount" &&
                    <div className="col-lg-6 my-2">
                        <label>Category *</label>
                        {/* <Select isDisabled={record.discountType?.key !== "CategoryDiscount"} isMulti options={categoryKeyValuePairList} value={categoryUuids} onChange={(e) => setCategoryUuids(e)} /> */}
                        <Select isDisabled={record.discountOn?.key !== "CategoryDiscount"} isMulti options={categoryKeyValuePairList} value={categoryUuids} onChange={(e) => setCategoryUuids(e)} />
                    </div>
                }
                <div className="col-lg-4 my-2">
                    <input type="checkbox" className="input-default me-2" id="applicableOnAllRestaurants" onChange={(e) => handleCheckBoxChange(e, "record")} checked={record?.applicableOnAllRestaurants} />
                    <label htmlFor="applicableOnAllRestaurants">Apply For All Restaurant</label>
                </div>
                <div className="col-lg-8 my-2">
                    <label>Restaurant *</label>
                    <Select isDisabled={record.applicableOnAllRestaurants} isMulti closeMenuOnSelect={false} options={restaurantKeyValuePairs} value={restaurantUuids} onChange={(e) => setRestaurantUuids(e)} />
                </div>
                <div className={`col-lg-${couponType === "customer" ? 4 : 6} my-2`}>
                    <label>Start Date *</label>
                    <input type="date" className="form-control" id="startDateTimeStamp" onChange={handleTextChange} value={record.startDateTimeStamp} />
                </div>
                <div className={`col-lg-${couponType === "customer" ? 4 : 6} my-2`}>
                    <label>End Date *</label>
                    <input type="date" className="form-control" id="endDateTimeStamp" onChange={handleTextChange} value={record.endDateTimeStamp} />
                </div>
                {couponType === "customer" &&
                    <div className="col-lg-4 my-2">
                        <label>Customer *</label>
                        <Select options={[]} value={record.userCustomerUuid} onChange={(e) => handleSelectChange(e, "userCustomerUuid")} />
                    </div>
                }
            </div>
            <fieldset className="my-3">
                <legend>Availabilities *</legend>
                <div className="row">
                    <div className="col-lg-4 my-2">
                        <input type="checkbox" className="input-default me-2" id="DineIn" onChange={(e) => handleCheckBoxChange(e, "availabilities")} checked={availabilityList?.DineIn} />
                        <label htmlFor="DineIn">DineIn </label>
                    </div>
                    <div className="col-lg-4 my-2">
                        <input type="checkbox" className="input-default me-2" id="HomeDelivery" onChange={(e) => handleCheckBoxChange(e, "availabilities")} checked={availabilityList?.HomeDelivery} />
                        <label htmlFor="HomeDelivery">Delivery </label>
                    </div>
                    <div className="col-lg-4 my-2">
                        <input type="checkbox" className="input-default me-2" id="TakeAway" onChange={(e) => handleCheckBoxChange(e, "availabilities")} checked={availabilityList?.TakeAway} />
                        <label htmlFor="TakeAway">Takeaway</label>
                    </div>
                </div>
            </fieldset>
            <div className="form-group row mb-1">
                <div className="col-lg-12">
                    <label>Description </label>
                    <textarea className="form-control input-default" id="description" cols="30" rows="2" onChange={handleTextChange} value={record.description} />
                </div>
            </div>
        </ModalWrapper>
    )
}

export default CouponCodeAddEdit