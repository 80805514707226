import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { callGetApi } from "api/ApiCaller";
import { Get_Order_Details_Get } from "api/ApiConst";

import { API_SUCCESS } from "api/ResponseCodes";
import PageHeading from "components/common/PageHeading";
import dateUtilities from "utils/DateUtilities";
import {
  QUANTITY_SYMBOL,
  defaultCouponObj,
  tablePriceCellStyle,
  SECTION_LOADER,
  DINE_IN,
  HOME_DELIVERY,
} from "utils/constants";
import Cookies from "universal-cookie";

const OrderDetails = () => {
  const { orderedId } = useParams();
  const defaultRecordObj = {
    userCustomerFirstName: "",
    userCustomerLastName: "",
    userCustomerIsdCode: "",
    userCustomerMobile: "",
    totalTax: "",
    taxes: [],
    taxDiscountAmount: "",
    tableNumber: "",
    subTotal: 0,
    orderTotal: 0,
    orderId: "",
    foodOrderItemList: [],
    couponDiscountAmount: 0,
    cookingInstruction: "",
    orderType: "",
    deliveryAddress: "",
  };

  const [record, setRecord] = useState(defaultRecordObj);
  const [applyCouponDtl, setApplyCouponDtl] = useState(defaultCouponObj);
  const [foodOrderItemList, setFoodOrderItemList] = useState([]);
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [taxes, setTaxes] = useState([]);
  const [orderStatusLogTimeList, setOrderStatusLogTimeList] = useState([]);

  const currencyDetail = new Cookies().get("currencyDetail");
  useEffect(() => {
    if (orderedId) {
      callGetApi(
        SECTION_LOADER,
        Get_Order_Details_Get.replaceAll("{recordID}", orderedId),
        {},
        (response) => {
          if (response.errorCode === API_SUCCESS) {
            const { foodOrderItemList, taxes, ...result } =
              response.responsePacket;

            setFoodOrderItemList(foodOrderItemList); // food order Items List
            setTaxes(taxes || []); // Applied Item Taxes List

            // calculated Total Item Quantity
            const totalQuantity = foodOrderItemList.reduce(
              (total, item) => total + item.quantity,
              0
            );
            setTotalItemsQty(totalQuantity);

            // set Coupon Code Details
            if (result.couponCode) {
              setApplyCouponDtl({
                couponCode: result.couponCode || null,
                couponDiscountAmount: result.couponDiscountAmount || 0,
                taxDiscountAmount: result.taxDiscountAmount || 0,
              });
            }

            if (result.orderStatusLogDto) {
              setOrderStatusLogTimeList(
                result.orderStatusLogDto["orderStatusLogTimeList"]
              );
            }

            setRecord({ ...result }); // set response record data
          }
        }
      );
    }
  }, []);

  // View Order Driver Details || OrderStatus is HomeDelivery
  const viewDriverDetails = () => {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body mb-0">
            <fieldset>
              <legend>Driver Details</legend>
              <span>No Driver has been assigned yet</span>
            </fieldset>
          </div>
        </div>
      </div>
    );
  };

  // View Customer Details
  const viewCustomerDetails = () => {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <fieldset>
              <legend>Customer Details</legend>
              <table className="table table-striped mb-0">
                <tbody>
                  <tr>
                    <td className="w-50 fw-medium text-secondary fs-6">Name</td>
                    <td className="w-50 text-secondary">
                      {record.userCustomerFirstName +
                        " " +
                        record.userCustomerLastName}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 fw-medium text-secondary fs-6">
                      Mobile Number
                    </td>
                    <td className="w-50 text-secondary">
                      {record.userCustomerIsdCode +
                        " " +
                        record.userCustomerMobile}
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
      </div>
    );
  };

  // View Order Delivery Address Details || OrderStatus is HomeDelivery
  const viewOrderDeliveryAddress = () => {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <fieldset>
              <legend>Delivery Address</legend>
              <table className="table table-striped mb-0">
                <tbody>
                  <tr>
                    <td className="w-25 fw-medium text-secondary fs-6">
                      <i className="mdi mdi-map-marker" />
                      Address
                    </td>
                    <td className="w-75 text-secondary">
                      {record?.deliveryAddress}
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
      </div>
    );
  };

  // View Order Tip Details
  const viewOrderTipDetails = () => {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <fieldset>
              <legend>Tip</legend>
              <table className="table table-striped mb-0">
                <tbody>
                  <tr>
                    <td className="w-50 fw-medium text-secondary fs-6">
                      Tip Amount
                    </td>
                    <td className="w-50 text-secondary">
                      {record?.tipAmount
                        ? record?.tipAmount.toFixed(2)
                        : "No tip on this order"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
      </div>
    );
  };

  // View Order Details
  const viewOrderDetails = () => {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <fieldset>
              <legend>Order Details</legend>
              <table className="table table-striped mb-0">
                <tbody>
                  <tr>
                    <td className="w-50 fw-medium text-secondary fs-6">
                     Invoice No.
                    </td>
                    <td className="w-50 text-secondary">{record?.restaurantOrderNumber && record?.restaurantOrderNumber ?
                      record?.restaurantOrderNumber : record?.orderId}</td>
                  </tr>
                  {/* <tr>
                                                <td className='w-50 fw-medium text-secondary fs-6'>Invoice #</td>
                                                <td className='w-50 text-secondary' className='text-end'>18124</td>
                                            </tr> */}
                  <tr>
                    <td className="w-50 fw-medium text-secondary fs-6">
                      Ordered At
                    </td>
                    <td className="w-50 text-secondary">
                      {dateUtilities.formattedDate(
                        record?.createdAt,
                        "dd-MM-yyyy, hh:mm aa"
                      )}
                    </td>
                  </tr>
                  {record.orderType === DINE_IN && (
                    <tr>
                      <td className="w-50 fw-medium text-secondary fs-6">
                        Table Number
                      </td>
                      <td className="w-50 text-secondary">
                        {record.tableNumber}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="w-50 fw-medium text-secondary fs-6">
                      Order Status
                    </td>
                    <td className="w-50 text-secondary">
                      {record?.orderStatus}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 fw-medium text-secondary fs-6">
                      Order Total
                    </td>
                    <td className="w-50 text-secondary">
                      {record.orderTotal.toFixed(2) +
                        " " +
                        currencyDetail?.currencySymbol}
                    </td>
                  </tr>
                  {record?.couponCode && (
                    <tr>
                      <td className="w-50 fw-medium text-secondary fs-6">
                        Coupon {record?.couponCode}
                      </td>
                      <td className="w-50 text-secondary">
                        {(
                          record?.couponDiscountAmount +
                          record.taxDiscountAmount
                        ).toFixed(2) +
                          " " +
                          currencyDetail?.currencySymbol}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
      </div>
    );
  };

  // View Order Status update List
  const viewOrderStatusUpdateList = () => {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <fieldset>
              <legend>Order Status</legend>
              <table className="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>Sr#</th>
                    <th>Status</th>
                    <th className="text-end">Updated At</th>
                  </tr>
                </thead>
                <tbody>
                  {orderStatusLogTimeList.length !== 0 &&
                    orderStatusLogTimeList.map((order, i) => (
                      <tr key={i}>
                        <td className="text-right text-secondary">{i + 1}</td>
                        <td className="text-secondary">{order?.orderStatus}</td>
                        <td className="text-end text-secondary">
                          {dateUtilities.formattedDate(
                            order?.modifiedAtTimeStamp,
                            "dd-MM-yyyy, hh:mm aa"
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
      </div>
    );
  };

  // view the Item Customizations
  const viewItemCustomizations = (row) => {
    const _itemCustomizations = [];
    let _customizations = row.itemCustomizations;
    if (_customizations && _customizations.length !== 0) {
      for (let i = 0; i < _customizations.length; i++) {
        let _customizationOptions = _customizations[i].customizationOptions;
        for (let j = 0; j < _customizationOptions.length; j++) {
          _itemCustomizations.push(_customizationOptions[j]);
        }
      }
    }
    return _itemCustomizations;
  };

  // View Applied Coupon Code Table Row
  const viewAppliedCouponCodeDtl = () => {
    if (applyCouponDtl.couponCode) {
      return (
        <tr>
          <td colSpan={4}></td>
          <td colSpan={2} className="fw-semibold">
            <span>
              Coupon Discount {"("}
              {applyCouponDtl.couponCode}
              {")"}
            </span>
          </td>
          <td colSpan={1} className="fw-semibold" style={tablePriceCellStyle}>
            {"(-)"}
            {(
              applyCouponDtl.couponDiscountAmount +
              applyCouponDtl?.taxDiscountAmount
            ).toFixed(2) +
              " " +
              currencyDetail?.currencySymbol}
          </td>
        </tr>
      );
    }
  };

  // View order food Items Table List
  const viewOrderFoodItemsTableList = () => {
    return (
      <div className="card">
        <div className="card-body">
          <table className="table table-striped mb-0">
            <thead>
              <tr>
                <th>Sr#</th>
                <th>Title</th>
                <th>customization</th>
                <th>Rating</th>
                <th>Selling Price</th>
                <th>Qty.</th>
                <th className="text-end">Total</th>
              </tr>
            </thead>
            <tbody>
              {foodOrderItemList.length !== 0 &&
                foodOrderItemList.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item?.title}</td>
                      <td>
                        <ul className="list-group w-100">
                          {viewItemCustomizations(item).length !== 0 &&
                            viewItemCustomizations(item).map(
                              (customization, j) => (
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center p-1 px-2"
                                  key={i + "-" + j}
                                >
                                  <span>{customization.title}</span>
                                  <span>
                                    {customization.price.toFixed(2) +
                                      " " +
                                      currencyDetail?.currencySymbol}
                                  </span>
                                </li>
                              )
                            )}
                        </ul>
                      </td>
                      <td></td>
                      <td>
                        {item?.itemPrice.toFixed(2) +
                          " " +
                          currencyDetail?.currencySymbol}
                      </td>
                      <td>{item?.quantity + " " + QUANTITY_SYMBOL}</td>
                      <td style={tablePriceCellStyle}>
                        {(item?.itemPrice * item?.quantity).toFixed(2) +
                          " " +
                          currencyDetail?.currencySymbol}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td colSpan={4}></td>
                <td>
                  <h6 className="text-end">Item Total</h6>
                </td>
                <td className="h6">{totalItemsQty + " " + QUANTITY_SYMBOL}</td>
                <td className="h6" style={tablePriceCellStyle}>
                  {record?.subTotal.toFixed(2) +
                    " " +
                    currencyDetail?.currencySymbol}
                </td>
              </tr>
              {viewAppliedCouponCodeDtl()}
              <tr>
                <td colSpan={3}></td>
                <td colSpan={2}>
                  <div className="d-flex align-items-baseline">
                    <h6 className="me-4 mb-0 text-nowrap">Total Tax</h6>
                    <ul className="list-group w-100">
                      {taxes.length !== 0 &&
                        taxes.map((tax, i) => {
                          return (
                            <li
                              className="list-group-item d-flex justify-content-between align-items-center p-1 px-2"
                              key={"item-taxes-" + i}
                            >
                              <span>
                                {tax.taxTitle}{" "}
                                {"( " + tax.taxPercentage.toFixed(2) + "% )"}
                              </span>
                              <span>
                                {tax.taxAmount.toFixed(2) +
                                  " " +
                                  currencyDetail?.currencySymbol}
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </td>
                <td colSpan={1}></td>
                <td
                  colSpan={1}
                  className="h6 fw-semibold"
                  style={tablePriceCellStyle}
                >
                  {record.totalTax &&
                    "(+)" +
                    record.totalTax.toFixed(2) +
                    " " +
                    currencyDetail?.currencySymbol}
                </td>
              </tr>
              <tr>
                <td colSpan={4}></td>
                <td>
                  <h6 className="text-end">Order Total</h6>
                </td>
                <td></td>
                <td colSpan={1} className="h6" style={tablePriceCellStyle}>
                  {record.orderTotal.toFixed(2) +
                    " " +
                    currencyDetail?.currencySymbol}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <>
      {PageHeading(`${record.orderType} Order Details`, "Dashboard", "/")}
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            {record.orderType === HOME_DELIVERY && viewDriverDetails()}
            {viewCustomerDetails()}
            {record.orderType === HOME_DELIVERY && viewOrderDeliveryAddress()}
            {viewOrderTipDetails()}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            {viewOrderDetails()}
            {viewOrderStatusUpdateList()}
          </div>
        </div>
        <div className="col-lg-12">{viewOrderFoodItemsTableList()}</div>
      </div>
    </>
  );
};

export default OrderDetails;
