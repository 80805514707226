import { useCallback, useEffect, useMemo, useState } from "react";

import {
    Activate_Restaurant_Delivery_Offer_PUT,
    All_Restaurant_Delivery_Offer_List_POST,
    DeActivate_Restaurant_Delivery_Offer_PUT,
    Delete_Restaurant_Delivery_Offer_DELETE,
    Deleted_Restaurant_Delivery_Offer_List_POST,
    Get_Delivery_Offer_Item_Key_Value_List_Post,
    Revive_Restaurant_Delivery_Offer_PUT,
} from "api/ApiConst";
import { handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";

import CheckboxButton from "components/common/CheckboxButton";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import DeliveryOfferAddEdit from "./DeliveryOfferAddEdit";
import dateUtilities from "utils/DateUtilities";
import { callPostApi } from "api/ApiCaller";
import { DINE_IN, HOME_DELIVERY, SECTION_LOADER, STEAL_DEAL, TAKEAWAY, TYPE_AVAILABILITIES } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";
import toast from "react-hot-toast";

const DeliveryOfferMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [itemKeyValuePairList, setItemKeyValuePairList] = useState([])
    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    useEffect(() => {
        // item Key Value Pair List
        let rUrl = Get_Delivery_Offer_Item_Key_Value_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setItemKeyValuePairList(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    }, [])


    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => <div className={row.active ? "led-green" : "led-red"} title={row.active ? "Active" : "Inactive"} />,
                width: "60px",
                sortable: false,
            },
            {
                id: "alertMinAmountRange",
                name: "Min Amount",
                selector: (row) => row?.alertMinAmountRange,
                sortable: false,
                width: "150px"
            },
            {
                id: "alertMaxAmountRange",
                name: "Max Amount",
                selector: (row) => row.alertMaxAmountRange,
                sortable: false,
                width: "150px"
            },
            {
                id: "minOrderValue",
                name: "Min Order Value",
                selector: (row) => row?.minOrderValue,
                sortable: false,
                width: "150px"
            },
            {
                id: "freeItem",
                name: "Free Item",
                selector: (row) => row.freeItemTitle,
                sortable: false,
                wrap: true,
            },
            {
                id: "freeItemQty",
                name: "Qty",
                selector: (row) => row.freeItemQty,
                sortable: false,
                width: "60px"
            },
            {
                id: "availabilities",
                name: "Availability",
                selector: (row) =>
                    TYPE_AVAILABILITIES.map((type, i) =>
                        (type !== STEAL_DEAL && type !== DINE_IN) && (row.availabilities.find((data) => data.toLowerCase() === type.toLowerCase()) ? (
                            <span className="badge-available" title={type} key={row.title + "-" + type + "-" + i}>
                                {type === DINE_IN ? "Di" : type === HOME_DELIVERY ? "De" : type === TAKEAWAY ? "Ta" : "Sd"}
                            </span>
                        ) : (
                            <span className="badge-unavailable" title={type} key={row.title + "-" + type + "-" + i}>
                                {type === DINE_IN ? "Di" : type === HOME_DELIVERY ? "De" : type === TAKEAWAY ? "Ta" : "Sd"}
                            </span>
                        ))
                    ),
                sortable: false,
                width: "120px"
            },
            // {
            //     id: "createdAt",
            //     name: "Created Time",
            //     selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
            //     sortable: false,
            // },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil" />
                    </button>
                )}
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_Restaurant_Delivery_Offer_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_Restaurant_Delivery_Offer_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_Restaurant_Delivery_Offer_DELETE.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_Restaurant_Delivery_Offer_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("Delivery Offer List", "Dashboard", "/")}
            <div className="card">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_Restaurant_Delivery_Offer_List_POST : All_Restaurant_Delivery_Offer_List_POST}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showAddUpdateModal && <DeliveryOfferAddEdit show={showAddUpdateModal} hideModal={() => setShowAddUpdateModal(false)} recordID={recordID} handleRefresh={handleRefresh} itemKeyValuePairList={itemKeyValuePairList} />}
        </>
    );
};

export default DeliveryOfferMaster;
