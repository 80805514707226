import { callGetApi, callPutApi } from 'api/ApiCaller';
import { Get_Order_Bill_Settlement_Get, Order_Bill_Settlement_Put } from 'api/ApiConst';
import { API_SUCCESS } from 'api/ResponseCodes';
import ModalWrapper from 'components/common/ModalWrapper'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import Select from "react-select";
import Cookies from 'universal-cookie';
import dateUtilities from 'utils/DateUtilities';
import { isEmpty } from 'utils/TextUtils';
import { DINE_IN, MODAL_LOADER } from 'utils/constants';

const SettleBill = ({ show, hideModal, recordID, handleRefresh, selectedOrderDtls, aggregatorOptionsList }) => {
    const defaultDetailsObj = {
        userCustomerFirstName: "",
        userCustomerLastName: "",
        userCustomerIsdCode: "",
        userCustomerMobile: "",
        orderId: "",
        orderTotal: 0,
        orderType: "",
        createdAt: "",
        tableNumber: "",
    }

    const defaultRecordObj = {
        wallet: 0,
        loyaltyBalance: 0,
        byCash: 0,
        byCreditCard: 0,
        byPayTm: 0,
        otherPaymentTypeJson: "",
        byAllowance: 0,
        note: "",
        aggregatorUuid: "b01f5481-3a21-4040-81d9-99dd22ed3ee1",
    }

    const [detailsRecord, setDetailRecord] = useState(defaultDetailsObj)
    const [record, setRecord] = useState(defaultRecordObj);
    const [selectedAggregatorOptionsList, setSelectedAggregatorOptionsList] = useState({ label: "Pos", value: "Pos", key: "b01f5481-3a21-4040-81d9-99dd22ed3ee1" })

    const currencyDetail = new Cookies().get("currencyDetail");
    useEffect(() => {
        callGetApi(MODAL_LOADER, Get_Order_Bill_Settlement_Get.replaceAll("{recordID}", recordID), {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                const res = response.responsePacket;
                setDetailRecord(res);
            }
        })
    }, [recordID])


    // View order Customer Details 
    const viewCustomerDetails = () => {
        const getFullName = (_detailsRecord) => {
            if (_detailsRecord && _detailsRecord.userCustomerFirstName) {
                return _detailsRecord.userCustomerFirstName + " " + _detailsRecord.userCustomerLastName
            } else {
                return "N/A"
            }
            
        }
        const getMobileNumber = (_detailsRecord) => {
            if (_detailsRecord && _detailsRecord.userCustomerMobile) {
                return _detailsRecord.userCustomerIsdCode + " " + _detailsRecord.userCustomerMobile
            } else {
                return "N/A"
            }
            
        }
        return (
            <fieldset className='px-0 pb-0'>
                <legend>Customer Details</legend>
                <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1 px-2">
                        Name <span>{getFullName(detailsRecord)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1 px-2">
                        Phone <span>{getMobileNumber(detailsRecord)}</span>
                    </li>
                </ul>
            </fieldset>
        )
    }

    // View Balance Details Wallet || Loyalty Balance
    const viewBalanceDetails = () => {
        return (
            <fieldset className='px-0 pb-0 my-3'>
                <legend>Balance Details</legend>
                <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1 px-2">
                        Wallet Balance <span>{100.00 + " " + currencyDetail?.currencySymbol}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1 px-2">
                        Loyalty Balance <span>{100.00 + " " + currencyDetail?.currencySymbol}</span>
                    </li>
                </ul>
            </fieldset>
        )
    }

    // view Order details
    const viewOrderDetails = () => {
        return (
            <fieldset className='px-0 pb-0'>
                <legend>Order Details</legend>
                <ul className="list-group p-0">
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                        Invoice No. <span>{detailsRecord?.restaurantOrderNumber && detailsRecord?.restaurantOrderNumber ? 
                    detailsRecord?.restaurantOrderNumber:detailsRecord.orderId}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                        OrderedAt <span>{dateUtilities.formattedDate(detailsRecord?.createdAt, "dd-MM-yyyy, hh:mm aa")}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                        Order Total <span>{detailsRecord.orderTotal && detailsRecord.orderTotal.toFixed(2) + " " + currencyDetail?.currencySymbol}</span>
                    </li>
                    {detailsRecord.orderType === DINE_IN &&
                        <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                            Table Number <span>{detailsRecord.tableNumber}</span>
                        </li>
                    }
                </ul>
            </fieldset>
        )
    }

    // Text Change
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (type === "number") {
            if (id === "wallet" && value.length !== 0) {
                if (value.length > 7) {
                    return;
                }
            } else if (id === "loyaltyBalance" && value.length !== 0) {
                if (value.length > 7) {
                    return;
                }
            } else if (id === "byCash" && value.length !== 0) {
                if (value.length > 7) {
                    return;
                }
            } else if (id === "byCreditCard" && value.length !== 0) {
                if (value.length > 7) {
                    return;
                }
            } else if (id === "byPayTm" && value.length !== 0) {
                if (value.length > 7) {
                    return;
                }
            } else if (id === "byAllowance" && value.length !== 0) {
                if (value.length > 7) {
                    return;
                }
            }
        }
        setRecord(pre => ({ ...pre, [id]: value }))
    }

    const checkInputValidation = () => {

        if (record.wallet < 0) {
            toast.error("Wallet Amount must not be negative", { id: "toast" });
            return false;
        } else if (record.loyaltyBalance < 0) {
            toast.error("Loyalty Balance must not be negative", { id: "toast" });
            return false;
        } else if (record.byCash < 0) {
            toast.error("Cash Amount must not be negative", { id: "toast" });
            return false;
        } else if (record.byCreditCard < 0) {
            toast.error("Credit Card Amount must not be negative", { id: "toast" });
            return false;
        } else if (record.byPayTm < 0) {
            toast.error("PayTm Amount must not be negative", { id: "toast" });
            return false;
        } else if (record.byAllowance < 0) {
            toast.error("Allowance Amount must not be negative", { id: "toast" });
            return false;
        } else if (record.byAllowance > 0) {
            if (record.note.length === 0) {
                toast.error("Note must not be empty in case of Allowance Amount", { id: "toast" });
                return false;
            }
        }
        else if (isEmpty(record.aggregatorUuid)) {
            toast.error("Aggregator option must not be empty.", { id: "toast" })
            return false;
        }
        else if (isEmpty(record.wallet) && isEmpty(record.loyaltyBalance) && isEmpty(record.byCash) && isEmpty(record.byCreditCard) && isEmpty(record.byPayTm) && isEmpty(record.byAllowance)) {
            toast.error("Fill at least one amount filed.", { id: "toast" })
            return false;
        }
        return true;
    }

    // Option Select Event
    const handleSelect = (e) => {

        setRecord({ ...record, aggregatorUuid: e.key });
        setSelectedAggregatorOptionsList({
            value: e.value,
            label: e.label,
            ...e
        });
    };

    const handleSubmit = () => {
        try {
            if (!checkInputValidation()) {
                return;
            }

            callPutApi(MODAL_LOADER, Order_Bill_Settlement_Put.replaceAll("{recordID}", recordID), record, {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    toast.success(response.message, { id: "toast" });
                    hideModal();
                    handleRefresh();
                } else {
                    toast.error(response.message, { id: "toast" });
                }
            }, error => {
                toast.error(error?.response?.data?.message ? error?.response?.data?.message : error.message, { id: "toastId" });
            }
            )
        } catch (error) {
            return;
        }
    }

    return (
        <ModalWrapper show={show} backdrop="static" hide={hideModal} header={"Pay Later Dine in Order Settlement"} handleSubmit={handleSubmit} >
            <div className="row">
                <div className="col-lg-6 my-2">
                    {viewCustomerDetails()}
                    {viewBalanceDetails()}
                </div>
                <div className="col-lg-6 my-2">
                    {viewOrderDetails()}
                </div>
                <div className="col-lg-12">
                    <div className="form-group row mb-0">
                        <div className="col-lg-6 my-2">
                            <label>Wallet *</label>
                            <input type="number" className="form-control input-default" id="wallet" onChange={handleTextChange} value={record.wallet} />
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>Loyalty Point *</label>
                            <input type="number" className="form-control input-default" id="loyaltyBalance" onChange={handleTextChange} value={record.loyaltyBalance} />
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>Cash *</label>
                            <input type="number" className="form-control input-default" id="byCash" onChange={handleTextChange} value={record.byCash} />
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>Credit Card *</label>
                            <input type="number" className="form-control input-default" id="byCreditCard" onChange={handleTextChange} value={record.byCreditCard} />
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>PayTm *</label>
                            <input type="number" className="form-control input-default" id="byPayTm" onChange={handleTextChange} value={record.byPayTm} />
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>Allowance *</label>
                            <input type="number" className="form-control input-default" id="byAllowance" onChange={handleTextChange} value={record.byAllowance} />
                        </div>
                        <div className="form-group mb-3 row">
                            <div className="col-lg-4">
                                <label>Order From</label>
                                <Select options={aggregatorOptionsList} onChange={(e) => handleSelect(e)} value={selectedAggregatorOptionsList} />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <label>Note *</label>
                            <textarea className="form-control input-default" id="note" cols="10" rows="2" onChange={handleTextChange} value={record.note} />
                        </div>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default SettleBill