import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { Details_Restaurant_Table_List_Get, Save_Restaurant_Table_Post, Update_Restaurant_Table_Put } from "api/ApiConst";
import { API_SUCCESS } from "api/ResponseCodes";
import ModalWrapper from "components/common/ModalWrapper";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

const defaultRecordObj = {
    tableNumber: "",
    sittingCapacity: "",
    maximumCapacity: "",
};
const RestaurantTableAddEdit = (props) => {
    const { show, hideModal, recordID, handleRefresh } = props;

    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Restaurant_Table_List_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    setRecord(response.responsePacket);
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    // Check validation
    const checkInputValidation = (recordObj) => {
        if (isEmpty(recordObj.tableNumber)) {
            toast.error(`Table Number must not be empty`, { id: "toast" });
            return false;
        }
        if (isEmpty(recordObj.sittingCapacity)) {
            toast.error(`Sitting Capacity must not be empty`, { id: "toast" });
            return false;
        }
        if (isEmpty(recordObj.maximumCapacity)) {
            toast.error(`Maximum Capacity must not be empty`, { id: "toast" });
            return false;
        }
        return true;
    };

    //Submit Item Customization Form
    const handleSubmit = async () => {
        try {
            let recordObj = {
                ...record,
            };

            if (!checkInputValidation(recordObj)) {
                return;
            }

            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Restaurant_Table_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Restaurant_Table_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`${recordID ? "Update" : "Add"} Table`} size={"md"} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12 mb-3">
                    <label>Table Number</label>
                    <input type="text" className="form-control input-default" id="tableNumber" onChange={handleTextChange} value={record.tableNumber} />
                </div>
                <div className="col-md-6 mb-3">
                    <label>Sitting Capacity</label>
                    <input type="number" min={0} className="form-control input-default" id="sittingCapacity" onChange={handleTextChange} value={record.sittingCapacity} />
                </div>
                <div className="col-md-6">
                    <label>Maximum Capacity</label>
                    <input type="number" min={0} className="form-control input-default" id="maximumCapacity" onChange={handleTextChange} value={record.maximumCapacity} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default RestaurantTableAddEdit;
