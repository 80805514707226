import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { updateRestaurantConfigurations } from "./restaurantUtils";

export const RestaurantConfiguration = ({ record, handleCheckedChange, recordID }) => {
    const handleSaveChange = () => {
        let rObj = {
            autoPrintKOT: record.autoPrintKOT,
            autoPrintBOT: record.autoPrintBOT,
            autoAcceptOrder: record.autoAcceptOrder,
            showInReservation: record.showInReservation,
            showInLocation: record.showInLocation,
            showItemImage: record.showItemImage,
            showRestaurantTitle: record.showRestaurantTitle,
            showOrderType: record.showOrderType
        };
        updateRestaurantConfigurations(recordID, rObj);
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-4 my-2">
                    <input type="checkbox" className="input-default" id="autoPrintKOT" onChange={handleCheckedChange} checked={record.autoPrintKOT} />
                    <label className="ms-2" htmlFor="autoPrintKOT">Auto Print KOT </label>
                </div>
                <div className="col-lg-4 my-2">
                    <input type="checkbox" className="input-default" id="autoPrintBOT" onChange={handleCheckedChange} checked={record.autoPrintBOT} />
                    <label className="ms-2" htmlFor="autoPrintBOT">Auto Print BOT </label>
                </div>
                <div className="col-lg-4 my-2">
                    <input type="checkbox" className="input-default" id="autoAcceptOrder" onChange={handleCheckedChange} checked={record.autoAcceptOrder} />
                    <label className="ms-2" htmlFor="autoAcceptOrder">Auto Accept Order </label>
                </div>
                <div className="col-lg-4 my-2">
                    <input type="checkbox" className="input-default" id="showInReservation" onChange={handleCheckedChange} checked={record.showInReservation} />
                    <label className="ms-2" htmlFor="showInReservation">Show In Reservation </label>
                </div>
                <div className="col-lg-4 my-2">
                    <input type="checkbox" className="input-default" id="showInLocation" onChange={handleCheckedChange} checked={record.showInLocation} />
                    <label className="ms-2" htmlFor="showInLocation">Show In Location </label>
                </div>
                <div className="col-lg-4 my-2">
                    <input type="checkbox" className="input-default" id="showItemImage" onChange={handleCheckedChange} checked={record.showItemImage} />
                    <label className="ms-2" htmlFor="showItemImage">Show Item Image</label>
                </div>
                <div className="col-lg-4 my-2">
                    <input type="checkbox" className="input-default" id="showRestaurantTitle" onChange={handleCheckedChange} checked={record.showRestaurantTitle} />
                    <label className="ms-2" htmlFor="showRestaurantTitle">Show Restaurant Name</label>
                </div>
                <div className="col-lg-4 my-2">
                    <input type="checkbox" className="input-default" id="showOrderType" onChange={handleCheckedChange} checked={record.showOrderType} />
                    <label className="ms-2" htmlFor="showOrderType">Show Order Type</label>
                </div>
            </div>
            <Button className="mb-3 float-end" onClick={handleSaveChange} variant="primary">
                Save Changes
            </Button>
        </>
    );
};

export default RestaurantConfiguration;
