import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import Select from "react-select";

import {
  Details_Client_Item_Customization_List_Get,
  Save_Client_Item_Customization_Post,
  Update_Client_Item_Customization_Put,
} from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

import ModalWrapper from "components/common/ModalWrapper";
import MultiImageSelector from "./MultiImageSelector";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

const customizationKeyValuePairs = [
  {
    label: "Single Selection",
    value: "Single Selection",
    key: "SingleSelection",
  },
  { label: "Multi Selection", value: "Multi Selection", key: "MultiSelection" },
  { label: "Fixed Selection", value: "Fixed Selection", key: "FixedSelection" },
];

const ItemCustomizationAddEdit = (props) => {
  const { show, hideModal, recordID, handleRefresh } = props;
  const { brandKeyValuePairs } = useSelector((state) => state.utility);
  const defaultRecordObj = {
    brandUuid: brandKeyValuePairs.length === 1 ? brandKeyValuePairs[0] : "",
    // restaurantUuid: "",
    title: "",
    description: "",
    sortOrder: 1,
    customizationType: "",
    minimumSelection: 0,
    maximumSelection: 0,
    selectionNumber: 0,
  };

  const [record, setRecord] = useState({ ...defaultRecordObj });
  const [customizationOptions, setCustomizationOptions] = useState([
    {
      sequenceNumber: 0,
      title: "",
      price: 0,
      active: false,
      iconRefId: "",
      syncOnUrbanPiper: false,
    },
  ]);

  const currencyDetail = new Cookies().get("currencyDetail");

  useEffect(() => {
    if (recordID) {
      callGetApi(
        MODAL_LOADER,
        Details_Client_Item_Customization_List_Get.replaceAll(
          "{recordID}",
          recordID
        ),
        {},
        (response) => {
          if (response.errorCode === API_SUCCESS) {
            const res = response.responsePacket;
            handleSetInitialValues(res);
          } else {
            toast.error(response.message);
          }
        }
      );
    }
  }, [recordID]);

  const handleSetInitialValues = (response) => {
    // Set brand Key
    const _selectedBrandUuid = brandKeyValuePairs.find(
      (obj) => obj.key === response.brandUuid
    );

    // Set Customization Type
    const _selectedCustomizationType = customizationKeyValuePairs.find(
      (obj) => obj.key === response.customizationType
    );

    let recordObj = {
      ...response,
      brandUuid: _selectedBrandUuid || "",
      // restaurantUuid: _selectedRestaurantUuid || "", ToDo
      customizationType: _selectedCustomizationType || "",
      selectionNumber:
        _selectedCustomizationType?.key === "FixedSelection"
          ? response.maximumSelection
          : 0,
      // sortOrder: record.sortOrder,
    };
    setRecord(recordObj);
    setCustomizationOptions(response?.customizationOptions);
  };

  //Text Change Event
  const handleTextChange = (e, options = null, index = null) => {
    const { id, value, type } = e.target;
    if (options === null) {
      if (type === "number") {
        setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
      } else {
        setRecord((prev) => ({ ...prev, [id]: value }));
      }
    } else {
      let _customizationOptions = [...customizationOptions];
      _customizationOptions[index] = {
        ..._customizationOptions[index],
        [id]: type === "number" ? parseInt(value) : value,
      };
      setCustomizationOptions(_customizationOptions);
    }
  };

  //Text Check Box Change
  const handleCheckBoxChange = (e, key, index) => {
    const { id, checked } = e.target;
    if (key !== null) {
      let _customizationOptions = [...customizationOptions];
      _customizationOptions[index] = {
        ..._customizationOptions[index],
        [id]: checked,
      };
      setCustomizationOptions(_customizationOptions);
    }
  };

  //Text Check Availabilities
  const handleSelectChange = (e, id) => {
    setRecord((prev) => ({ ...prev, [id]: e }));
  };

  // IMAGE CHANGE
  const handleImageChange = (imageRefId, index) => {
    let _customizationOptions = [...customizationOptions];
    _customizationOptions[index] = {
      ..._customizationOptions[index],
      iconRefId: imageRefId,
    };
    setCustomizationOptions(_customizationOptions);
  };

  // Add More Customization Options
  const handleAddMoreCustomizeOptions = () => {
    setCustomizationOptions([
      ...customizationOptions,
      {
        sequenceNumber: 0,
        title: "",
        price: 0,
        active: false,
        iconRefId: "",
        syncOnUrbanPiper: false,
      },
    ]);
  };

  // Remove Customization Options
  const handleRemoveOption = (index) => {
    let _customizationOptions = [...customizationOptions];
    _customizationOptions.splice(index, 1);
    setCustomizationOptions(_customizationOptions);
  };

  // Check validation
  const checkInputValidation = (recordObj) => {
    if (isEmpty(recordObj.brandUuid)) {
      toast.error(`Brand must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.title)) {
      toast.error(`Title must not be empty`, { id: "toast" });
      return false;
    } else if (recordObj.title.length > 100) {
      toast.error(`Title must not be more then 100 character.`, {
        id: "toast",
      });
      return false;
    } else if (isEmpty(recordObj.customizationType)) {
      toast.error(`Customization Type must not be empty`, { id: "toast" });
      return false;
    } else if (
      recordObj.customizationType === "FixedSelection" &&
      isEmpty(recordObj.selectionNumber)
    ) {
      toast.error(`Selection Number must not be empty`, { id: "toast" });
      return false;
    } else if (recordObj.selectionNumber < 0) {
      toast.error(`Selection Number must not be negative`, { id: "toast" });
      return false;
    }
    return true;
  };

  // check Validations for customize options
  const checkCustomizationValidation = () => {
    if (customizationOptions.length === 0) {
      toast.error("At least one Customization option must be add", {
        id: "toast",
      });
      return false;
    } else {
      for (var i = 0; i < customizationOptions.length; i++) {
        // if ((customizationOptions[i].title)) {
        //     if (isEmpty(customizationOptions[i].title)) {
        //         toast.error("Customization title must not be empty.",{id: "toast"})
        //         return false
        //     } else if (customizationOptions[i].title.length < 3) {
        //         toast.error("Customization title must be 3 characters or more.",{id: "toast"})
        //         return false
        //     }
        // }
        if (isEmpty(customizationOptions[i].title)) {
          toast.error("Customization title must not be empty.", {
            id: "toast",
          });
          return false;
        } else if (customizationOptions[i].title.length < 3) {
          toast.error("Customization title must be 3 or more characters.", {
            id: "toast",
          });
          return false;
        } else if (isEmpty(customizationOptions[i].price)) {
          toast.error("Customization Price must not be empty.", {
            id: "toast",
          });
          return false;
        } else if (customizationOptions[i].price < 0) {
          toast.error("Price must not be negative.", { id: "toast" });
          return false;
        }
      }
    }
    return true;
  };

  //Submit Item Customization Form
  const handleSubmit = async () => {
    try {
      var selectionNumberObj = {
        minimumSelection: 0,
        maximumSelection: 0,
      };
      if (record.customizationType.key === "SingleSelection") {
        selectionNumberObj = {
          minimumSelection: 1,
          maximumSelection: 1,
        };
      } else if (record.customizationType.key === "MultiSelection") {
        selectionNumberObj = {
          minimumSelection: 1,
          maximumSelection: -1,
        };
      } else {
        selectionNumberObj = {
          minimumSelection: record.selectionNumber,
          maximumSelection: record.selectionNumber,
        };
      }
      let recordObj = {
        ...record,
        ...selectionNumberObj,
        sortOrder: parseInt(record.sortOrder),
        brandUuid: record.brandUuid.key,
        // restaurantUuid: record.restaurantUuid.key, >>> Todo
        customizationType: record.customizationType.key,
        customizationOptions: customizationOptions,
      };

      if (!checkInputValidation(recordObj)) {
        return;
      }

      if (!checkCustomizationValidation()) {
        return;
      }

      delete recordObj.selectionNumber;
      if (!recordID || recordID === "") {
        callPostApi(
          MODAL_LOADER,
          Save_Client_Item_Customization_Post,
          recordObj,
          {},
          (response) => {
            if (response.errorCode === API_SUCCESS) {
              toast.success(response.message, {id: "toast"});
              hideModal();
              handleRefresh();
            } else {
              toast.error(response.message, {id: "toast"});
            }
          }
        );
      } else {
        callPutApi(
          MODAL_LOADER,
          Update_Client_Item_Customization_Put.replaceAll(
            "{recordID}",
            recordID
          ),
          recordObj,
          {},
          (response) => {
            if (response.errorCode === API_SUCCESS) {
              toast.success(response.message, {id: "toast"});
              hideModal();
              handleRefresh();
            } else {
              toast.error(response.message);
            }
          }
        );
      }
    } catch (error) {
      return;
    }
  };

  return (
    <ModalWrapper
      show={show}
      hide={hideModal}
      backdrop="static"
      header={`${recordID ? "Update" : "Add"} Item Customization`}
      handleSubmit={handleSubmit}
    >
      <fieldset>
        <legend>Basic Details</legend>
        <div className="form-group row align-items-center">
          {!recordID && (
            <div className="col-lg-6 my-2">
              <label>Brand *</label>
              <Select
                isDisabled={!recordID ? false : true}
                options={brandKeyValuePairs}
                value={record.brandUuid}
                onChange={(e) => handleSelectChange(e, "brandUuid")}
              />
            </div>
          )}
          <div className="col-lg-6 my-2">
            <label> Title *</label>
            <input
              type="text"
              className="form-control input-default"
              id="title"
              onChange={handleTextChange}
              value={record.title}
            />
          </div>
          {/* <div className="col-lg-4 my-2">
                        <label> Sort Order *</label>
                        <input type="number" className="form-control input-default" id="sortOrder" onChange={handleTextChange} value={record.sortOrder} />
                    </div> */}

          <div className="col-lg-6 my-2">
            <label>Customization Type *</label>
            <Select
              options={customizationKeyValuePairs}
              value={record.customizationType}
              onChange={(e) => handleSelectChange(e, "customizationType")}
            />
          </div>
          {record.customizationType?.key === "FixedSelection" && (
            <div className="col-lg-6 my-2">
              <label> Selection Number*</label>
              <input
                type="number"
                className="form-control input-default"
                id="selectionNumber"
                onChange={handleTextChange}
                value={record.selectionNumber}
              />
            </div>
          )}
          <div
            className={`col-lg-${
              recordID && record.customizationType?.key !== "FixedSelection"
                ? 12
                : !recordID &&
                  record.customizationType?.key === "FixedSelection"
                ? 12
                : 6
            } my-2`}
          >
            <label> Description </label>
            <textarea
              className="form-control input-default"
              id="description"
              cols="30"
              rows="2"
              onChange={handleTextChange}
              value={record.description}
            />
          </div>
        </div>
      </fieldset>

      <fieldset className="my-3">
        <legend>Customization Options</legend>
        {customizationOptions.length !== 0 &&
          customizationOptions.map((customizeOption, i) => (
            <div className="my-3" key={i}>
              <div className="row">
                <div className="col-lg-11">
                  <div className="row">
                    <div className="col-2 customization-options-images">
                      <MultiImageSelector
                        selectedImage={customizeOption.iconRefId}
                        index={i}
                        handleImageSelect={handleImageChange}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control input-default"
                        placeholder="Title"
                        id="title"
                        onChange={(e) =>
                          handleTextChange(e, "customizationOptions", i)
                        }
                        value={customizeOption.title}
                      />
                    </div>
                    <div className="col-4">
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control input-default"
                          placeholder="Price"
                          id="price"
                          onChange={(e) =>
                            handleTextChange(e, "customizationOptions", i)
                          }
                          value={customizeOption.price}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text bg-red">
                            {currencyDetail?.currencySymbol}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1">
                  <Button onClick={(e) => handleRemoveOption(i)}>
                    <i className="bx bx-trash" />
                  </Button>
                </div>
              </div>
            </div>
          ))}
        <Button onClick={handleAddMoreCustomizeOptions}> + Add New Row</Button>
      </fieldset>
    </ModalWrapper>
  );
};

export default ItemCustomizationAddEdit;
