import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { Add_Role_POST, Get_Role_Detail_GET, Update_Role_PUT } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isEmpty, isValidJsonString } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";

const RoleAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const defaultRecordObj = {
        title: "",
        additionalDataJson: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Get_Role_Detail_GET.replaceAll("{recordID}", recordID), {}, (response) => {
                const res = response.responsePacket;
                setRecord(res);
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.title)) {
            toast.error(`Role title must not be empty`, { id: "toast" });
            return false;
        } else if (record.title.length > 100) {
            toast.error(`Role title length not be more then 100 character.`, { id: "toast" });
            return false;
        }

        if (record.additionalDataJson) {
            if (!isValidJsonString(record.additionalDataJson)) {
                toast.error(`Additional data must be in json format.`, { id: "toast" });
                return false;
            }
        }

        return true;
    };

    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Add_Role_POST, recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Role_PUT.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper  backdrop="static"  show={show} hide={hideModal} header={`${recordID ? "Update" : "Add"} Role`} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Title *</label>
                    <input type="text" className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} />
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Additional Data</label>
                    <textarea className="form-control input-default" id="additionalDataJson" onChange={handleTextChange} value={record.additionalDataJson}></textarea>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default RoleAddEdit;
