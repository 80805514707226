import { useEffect, useState } from 'react'

import { toast } from 'react-hot-toast';
import Select from 'react-select';

import { Details_Client_Event_Get, Save_Client_Event_Post, Update_Client_Event_Put } from 'api/ApiConst';
import { callGetApi, callPostApi, callPutApi } from 'api/ApiCaller';
import { isEmpty, isMobile } from 'utils/TextUtils';
import { MODAL_LOADER } from 'utils/constants';
import { API_SUCCESS } from 'api/ResponseCodes';

import ModalWrapper from 'components/common/ModalWrapper'
import ImageSelector from 'components/common/ImageSelector';

import { Button } from "react-bootstrap";
import EventTicketMaster from './event-ticket/EventTicketMaster';

const defaultRecordObj = {
    restaurantUuid: "",
    eventTitle: "",
    eventDescription: "",
    eventSharingContent: "",
    eventImageRefId: "",
    contactNumber: "",
    startTimeDateTimeStamp: "",
    endTimeDateTimeStamp: "",
    paid: false
}
const EventAddEdit = (props) => {
    const { show, hideModal, recordID, handleRefresh, restaurantKeyValuePairs } = props;

    const [record, setRecord] = useState({ ...defaultRecordObj });
    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Client_Event_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                const res = response.responsePacket;
                handleSetInitialValues(res);
            });
        }
    }, [recordID]);


    const handleSetInitialValues = (response) => {
        const selectedRestaurant = restaurantKeyValuePairs.find((currentRestaurant) =>
            currentRestaurant.key === response.restaurantUuid);
        let recordObj = {
            ...response,
            restaurantUuid: selectedRestaurant || "",
        };
        setRecord(recordObj);
    }

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, checked, type } = e.target;

        if (id == "contactNumber") {
            if (value.length > 10) {
                return;
            }
        }
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
        } else if (type === "checkbox") {
            setRecord((prev) => ({ ...prev, [id]: checked }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    // Time Change Event
    const handleTimeChange = (e) => {
        const { id, value } = e.target;
        const date = new Date(value);
        date.setSeconds(0);
        date.setMilliseconds(0);
        setRecord((prev) => ({ ...prev, [id]: date.getTime() }));
    };

    //Select Change Event
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    // Image change
    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, eventImageRefId: imageRefId });
    };

    const formattedDateTime = (timestamp) => {
        if (isEmpty(timestamp)) {
            return "";
        }
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, "0"); // Month is 0-indexed
        const day = `${date.getDate()}`.padStart(2, "0");
        const hours = `${date.getHours()}`.padStart(2, "0");
        const minutes = `${date.getMinutes()}`.padStart(2, "0");
        var finalDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
        return finalDateString;
    };

    const checkInputValidation = (recordObj) => {
        if (isEmpty(recordObj.eventImageRefId)) {
            toast.error(`Image must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.restaurantUuid)) {
            toast.error(`Restaurant must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.eventTitle)) {
            toast.error(`Event title must not be empty`, { id: "toast" });
            return false;
        } else if (recordObj.eventTitle.length > 100) {
            toast.error(`Event title must not be more then 100 character.`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.eventSharingContent)) {
            toast.error(`Sharing content must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.contactNumber)) {
            toast.error(`Contact must not be empty`, { id: "toast" });
            return false;
        } else if (!isMobile(recordObj.contactNumber)) {
            toast.error(`Please Provide Valid Contact Number`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.startTimeDateTimeStamp)) {
            toast.error(`Start Time must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.endTimeDateTimeStamp)) {
            toast.error(`End Time must not be empty`, { id: "toast" });
            return false;
        } else if (new Date(recordObj.startTimeDateTimeStamp) > new Date(recordObj.endTimeDateTimeStamp)) {
            toast.error(`Start Time must not be greater to End Time`, { id: "toast" });
            return false;
        }
        //  else if (isEmpty(recordObj.eventDescription)) {
        //     toast.error(`Description must not be empty`, { id: "toast" });
        //     return false;
        // }
        return true;
    }

const getNameType = (firstName) => {
    console.log(firstName);
} 
    const handleSubmit = () => {
        try {
            let recordObj = {
                ...record,
                restaurantUuid: record.restaurantUuid?.key || "",
                countryUuid: record.countryUuid?.key || "",
                stateUuid: record.stateUuid?.key || "",
                cityUuid: record.cityUuid?.key || "",
            };
            if (!checkInputValidation(recordObj)) {
                return;
            }

            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Client_Event_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, { id: "toast" });
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, { id: "toast" });
                    }
                });
            } else {
                delete recordObj.restaurantUuid;
                callPutApi(MODAL_LOADER, Update_Client_Event_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, { id: "toast" });
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, { id: "toast" });
                    }
                });
            }
        } catch (error) {
            return;
        }
    }

    const viewEventAddEditForm = () => {
        return (
            <>
                <div className="form-group row mb-3">
                    <div className="col-lg-12">
                        <ImageSelector
                            style={{
                                height: "120px",
                                width: "120px",
                                overflow: "hidden",
                            }}
                            handleImageChange={handleImageChange}
                            selectedImage={record.eventImageRefId}
                        />
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <div className="col-lg-6">
                        <label>Restaurant *</label>
                        <Select isDisabled={!isEmpty(recordID)} options={restaurantKeyValuePairs} value={record.restaurantUuid} onChange={(e) => handleSelectChange(e, "restaurantUuid")} />
                    </div>
                    <div className="col-lg-6">
                        <label>Event Title *</label>
                        <input type="text" className="form-control input-default" id="eventTitle" onChange={handleTextChange} value={record.eventTitle} />
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <div className="col-lg-8 my-2">
                        <label>Sharing Content *</label>
                        <input type="text" className="form-control input-default" id="eventSharingContent" onChange={handleTextChange} value={record.eventSharingContent} />
                    </div>
                    <div className="col-lg-4 my-2">
                        <label>Contact Number *</label>
                        <input type="number" className="form-control input-default" id="contactNumber" onChange={handleTextChange} value={record.contactNumber} min={10} max={10} />
                    </div>
                    <div className="col-lg-12 my-2">
                        <input type="checkbox" disabled={!isEmpty(recordID)} className="input-default" id="paid" onChange={handleTextChange} checked={record.paid} aria-describedby="eventFreeOrNot" />
                        <label className="ms-2" htmlFor='paid'>Paid </label>
                        <div id="eventFreeOrNot" className="h6 text-secondary info-msg">checkbox is checked then the Event will be chargeable else event is free.</div>
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <div className="col-lg-6 my-2">
                        <label>Start Time *</label>
                        <input type="datetime-local" className="form-control input-default" id="startTimeDateTimeStamp" onChange={handleTimeChange} value={formattedDateTime(record.startTimeDateTimeStamp)} />
                    </div>
                    <div className="col-lg-6 my-2">
                        <label>End Time *</label>
                        <input type="datetime-local" className="form-control input-default" id="endTimeDateTimeStamp" onChange={handleTimeChange} value={formattedDateTime(record.endTimeDateTimeStamp)} />
                    </div>
                </div>
                <div className="form-group row mb-0">
                    <div className="col-lg-12 my-2">
                        <label>Description </label>
                        <textarea className="form-control input-default" id="eventDescription" onChange={handleTextChange} value={record.eventDescription} cols="30" rows="2" />
                    </div>
                </div>
                <Button variant="primary" className='float-end mt-0' onClick={handleSubmit}>Submit</Button>
            </>
        )
    }

    const getTabsView = () => {
        return (
            <>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="event-details-tab" data-bs-toggle="tab" data-bs-target="#event-details" type="button" role="tab" aria-controls="event-details" aria-selected="true">Event Details</button>
                    </li>
                    {recordID && record.paid && <li className="nav-item" role="presentation">
                        <button className="nav-link" id="event-tickets-tab" data-bs-toggle="tab" data-bs-target="#event-tickets" type="button" role="tab" aria-controls="event-tickets" aria-selected="false">Event Tickets</button>
                    </li>}
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="event-details" role="tabpanel" aria-labelledby="event-details-tab">
                        {viewEventAddEditForm()}
                    </div>
                    <div className="tab-pane fade" id="event-tickets" role="tabpanel" aria-labelledby="event-tickets-tab">
                        <EventTicketMaster eventID={recordID} eventTitle={record.eventTitle} />
                    </div>
                </div>
            </>
        )
    }

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static" header={`${recordID ? "Update" : "Add"} Event`} centered={true}
            dialogClassName="right-modal-dialog" contentClassName="right-modal-content"
        >
            {recordID ? getTabsView() : viewEventAddEditForm()}
        </ModalWrapper>
    )
}

export default EventAddEdit