import React, { memo, useState } from "react";

import Select from "react-select";
import { Button, Modal } from "react-bootstrap";

import { getCityListByState, getStateListByCountry, updateRestaurantLocation } from "components/master/client/restaurant-setup/restaurant/restaurantUtils";

import MapAddressPicker from "components/common/MapAddressPicker";

export const UserRestaurantLocation = ({ address, setRecord, recordID, countryList, stateList, setStateList, cityList, setCityList }) => {

    const [openMap, setOpenMap] = useState(false);

    // toggle Map modal/PopUp
    const toggleMapAddressPicker = () => setOpenMap((prev) => !prev);

    // Address Select Change
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, address: { ...prev.address, [id]: e } }));
    };

    // Address Text Change
    const handleAddressTextChange = (e) => {
        const { id, value } = e.target;
        setRecord((prev) => ({ ...prev, address: { ...prev.address, [id]: value } }));
    };

    const handleCountryChange = (e) => {
        handleSelectChange(e, "countryUuid");
        getStateListByCountry(e.key).then((_stateList) => setStateList(_stateList));
        setCityList([])
        // Reset Sate and city
        setRecord((prev) => ({ ...prev, address: { ...prev.address, stateUuid: "" } }));
        setRecord((prev) => ({ ...prev, address: { ...prev.address, cityUuid: "" } }));
    };

    const handleStateChange = (e) => {
        handleSelectChange(e, "stateUuid");
        getCityListByState(e.key).then((_cityList) => setCityList(_cityList));
        // Reset city
        setRecord((prev) => ({ ...prev, address: { ...prev.address, cityUuid: "" } }));
    };

    const handleCityChange = (e) => {
        handleSelectChange(e, "cityUuid");
    };

    const handleSaveChange = () => {
        const rObj = {
            latitude: address.latitude,
            longitude: address.longitude,
            addressLine1: address.addressLine1,
            pinCode: address.pinCode,
            countryUuid: address.countryUuid,
            stateUuid: address.stateUuid,
            cityUuid: address.cityUuid,
            addressLine2: address.addressLine2,
            addressLine3: address.addressLine3,
            addressType: address.addressType,
        };
        // const rObj = { ...address }
        rObj.countryUuid = address?.countryUuid?.key || "";
        rObj.stateUuid = address?.stateUuid?.key || "";
        rObj.cityUuid = address?.cityUuid?.key || "";
        updateRestaurantLocation(recordID, rObj);
    };

    const initialCountrySateAndCityValues = (_countryList, tempObj) => {
        const selectedCountry = _countryList.find((currentCountry, i) => currentCountry.label.toLowerCase() === tempObj.countryTitle.toLowerCase());
        if (selectedCountry) {
            handleSelectChange(selectedCountry, "countryUuid");

            // get State List
            getStateListByCountry(selectedCountry.key).then((_stateList) => {
                const selectedState = _stateList.find((currentSate, i) => currentSate.label.toLowerCase() === tempObj.stateTitle.toLowerCase());
                if (selectedState) {
                    setStateList(_stateList);
                    handleSelectChange(selectedState, "stateUuid");

                    // get City List
                    getCityListByState(selectedState.key).then((_cityList) => {
                        const selectedCity = _cityList.find((currentCity, i) => currentCity.label.toLowerCase() === tempObj.cityTitle.toLowerCase());
                        if (selectedCity) {
                            setCityList(_cityList);
                            handleSelectChange(selectedCity, "cityUuid");
                        }
                    });
                }
            });
        }
    };

    const locationCallBack = (loc) => {
        toggleMapAddressPicker();
        const obj = {
            countryTitle: loc.addressCountry,
            stateTitle: loc.addressState,
            cityTitle: loc.addressCity,
        };
        if (countryList.length > 0) {
            initialCountrySateAndCityValues(countryList, obj);
        }
        const newAddress = {
            latitude: loc.addressLat,
            longitude: loc.addressLng,
            addressLine1: loc.addressText,
            pinCode: loc.addressPostalCode,
        };
        setRecord((prev) => ({ ...prev, address: { ...prev.address, ...newAddress } }));
    };

    const currentLocation = {
        addressLat: address.latitude,
        addressLng: address.longitude,
        addressCountry: address.countryTitle,
        addressState: address.stateTitle,
        addressCity: address.cityTitle,
        addressPostalCode: address.pinCode,
    };

    return (
        <>
            <fieldset className="mt-3">
                {/* <Button variant="primary" className="float-end" onClick={toggleMapAddressPicker}>
                    Select New Address
                </Button> */}
                <legend>Location</legend>
                <div className="form-group row mb-3 w-100">
                    <div className="col-lg-6 my-2">
                        <label>Latitude </label>
                        <span className='d-block'>{address?.latitude.toFixed(3)}</span>
                        {/* <input type="text" className="form-control input-default" id="latitude" onChange={handleAddressTextChange} value={address?.latitude.toFixed(3)} /> */}
                    </div>
                    <div className="col-lg-6 my-2">
                        <label>Longitude </label>
                        <span className='d-block'>{address?.longitude.toFixed(3)}</span>
                        {/* <input type="text" className="form-control input-default" id="longitude" onChange={handleAddressTextChange} value={address?.longitude.toFixed(3)} /> */}
                    </div>
                    <div className="col-lg-8 my-2">
                        <label>Address </label>
                        <span className='d-block'>{address?.addressLine1}</span>
                        {/* <input type="text" className="form-control input-default" id="addressLine1" onChange={handleAddressTextChange} value={address?.addressLine1} /> */}
                    </div>
                    <div className="col-lg-4 my-2">
                        <label>Pin Code </label>
                        <span className='d-block'>{address?.pinCode}</span>
                        {/* <input type="text" className="form-control input-default" id="pinCode" onChange={handleAddressTextChange} value={address?.pinCode} /> */}
                    </div>
                    <div className="col-lg-4 my-2">
                        <label>Country </label>
                        <span className='d-block'>{address?.countryTitle}</span>
                        {/* <Select options={countryList} value={address?.countryUuid} onChange={handleCountryChange} /> */}
                    </div>
                    <div className="col-lg-4 my-2">
                        <label>State </label>
                        <span className='d-block'>{address?.stateTitle}</span>
                        {/* <Select options={stateList} value={address?.stateUuid} onChange={handleStateChange} /> */}
                    </div>
                    <div className="col-lg-4 my-2">
                        <label>City </label>
                        <span className='d-block'>{address?.cityTitle}</span>
                        {/* <Select options={cityList} value={address?.cityUuid} onChange={handleCityChange} /> */}
                    </div>
                    {/* <div className="col-lg-4 my-2">
                        <label>Address Type *</label>
                        <input type="text" className="form-control input-default" id="addressType" onChange={handleAddressTextChange} value={address.addressType} />
                    </div> */}
                </div>
                {/* <Button variant="primary" className="float-end" onClick={handleSaveChange}>
                    Save Changes
                </Button> */}
            </fieldset>

            <Modal show={openMap} onHide={toggleMapAddressPicker} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Map</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="w-100 h-75">
                        <MapAddressPicker currentLocation={currentLocation} locationCallBack={locationCallBack} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default memo(UserRestaurantLocation);
