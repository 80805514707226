import React from 'react'

const WorkInProgress = () => {
    return (
        <div className="text-center mt-5">
            <h3> <center>Work In Progress...</center></h3>
        </div>
    )
}

export default WorkInProgress