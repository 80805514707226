import React, { useCallback, useMemo, useState } from "react";

import { All_Client_Setting_List_Post } from "api/ApiConst";

import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import ClientSettingAddEdit from "./ClientSettingAddEdit";

const ClientSettingMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "settingKey",
                name: "Setting Key",
                selector: (row) => row?.key,
                sortable: false,
            },
            {
                id: "Setting Value",
                name: "Setting Value",
                selector: (row) => row?.value,
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                    <i className="bx bx-pencil"></i>
                </button>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.key);
        setShowAddUpdateModal(true);
    };

    return (
        <>
            {PageHeading("Setting List", "Dashboard", "/")}
            <div className="card">
                <div className="card-body tableView">
                    <PaginationDataTable paginationUrl={All_Client_Setting_List_Post} serverSidePagination={false} search={false} pagination={false} columns={columns} />
                </div>
            </div>
            {showAddUpdateModal && <ClientSettingAddEdit show={showAddUpdateModal} hideModal={() => setShowAddUpdateModal(false)} recordID={recordID} handleRefresh={handleRefresh} />}
        </>
    );
};

export default ClientSettingMaster;
