import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";

import { updateRestaurantImages } from "./restaurantUtils";

import ImageSelector from "components/common/ImageSelector";
import RestaurantMultiImageSelector from "./RestaurantMultiImageSelector";
import toast from "react-hot-toast";

const imgStyle = { height: "auto", width: "100%", overflow: "hidden" };

export const RestaurantImages = ({ record, setRecord, recordID }) => {
    const [webBanners, setWebBanners] = useState([])
    const handleImageChange = (imageRefId, id) => {
        setRecord((prev) => ({ ...prev, [id]: imageRefId }));
    };

    useEffect(() => {
        if (record.webBannerImageRefIdCsv) {
            const webBannerImages = record.webBannerImageRefIdCsv.split(",");
            setWebBanners(webBannerImages)
        }
    }, [])

    const handleSaveChanges = () => {
        if (webBanners.length !== 0 && webBanners.includes("")) {
            toast.error("Web banner images must not be empty");
            return;
        }
        const _webBanners = webBanners.filter(elm => elm);
        const rObj = {
            iconImageRefId: record.iconImageRefId,
            bannerImageRefId: record.bannerImageRefId,
            printerImageRefId: record.printerImageRefId,
            webBannerImageRefIdCsv: _webBanners.length !== 0 ? _webBanners.toString() : "",
        };
        updateRestaurantImages(recordID, rObj);
    };

    const handleWebBannerImageChange = (imageRefId, i) => {
        const _webBanners = [...webBanners];
        _webBanners[i] = imageRefId;
        setWebBanners(_webBanners);
    }

    const handleRemoveBannerImage = (i) => {
        // console.log("index -------- ", i);
        const _webBanners = [...webBanners];
        _webBanners.splice(i, 1);
        setWebBanners(_webBanners);
    }

    const handleMoreWebBanners = () => {
        const _webBanners = [...webBanners];
        _webBanners.push("");
        setWebBanners(_webBanners)
    }

    return (
        <>
            <fieldset className="mt-3">
                <legend>Restaurant Image</legend>
                <div className="row gx-2">
                    <div className="col-lg-4">
                        <div className="restaurant-images p-2 card border mb-0">
                            <label>Icon*</label>
                            <ImageSelector style={imgStyle} handleImageChange={(imageRefId) => handleImageChange(imageRefId, "iconImageRefId")} selectedImage={record.iconImageRefId} />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="restaurant-images p-2 card border mb-0">
                            <label>Banner*</label>
                            <ImageSelector style={imgStyle} handleImageChange={(imageRefId) => handleImageChange(imageRefId, "bannerImageRefId")} selectedImage={record.bannerImageRefId} />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="restaurant-images p-2 card border mb-0">
                            <label>Printer logo*</label>
                            <ImageSelector style={imgStyle} handleImageChange={(imageRefId) => handleImageChange(imageRefId, "printerImageRefId")} selectedImage={record.printerImageRefId} />
                        </div>
                    </div>
                </div>
                <fieldset className="my-2">
                    <legend>Web Banners</legend>
                    <div className="row gx-2">
                        <div className="col-lg-10">
                            <div className="d-flex overflow-auto">
                                {webBanners.length !== 0 && webBanners.map((image, i) =>
                                    <RestaurantMultiImageSelector selectedImage={image} index={i} handleImageSelect={handleWebBannerImageChange} handleRemoveBannerImage={handleRemoveBannerImage} />
                                )}
                            </div>
                        </div>
                        <div className="col-lg-2"><Button onClick={handleMoreWebBanners}> + Add More</Button></div>
                    </div>
                </fieldset>
                <Button className="mt-3 float-end " onClick={handleSaveChanges} variant="primary">
                    Save Changes
                </Button>
            </fieldset>
        </>
    );
};

export default RestaurantImages;
