import { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { Add_Restaurant_Staff_Employee_POST, Get_Restaurant_Staff_Employee_Detail_GET, Update_Restaurant_Staff_Employee_PUT } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { getDOB, getTimeFromDate, isAlphaNumercSpecialChar, isAlphaNumercSpecialCharSpc, isEmail, isEmpty, isMobile, isValidIsdCode } from "utils/TextUtils";

import ImageSelector from "components/common/ImageSelector";
import ModalWrapper from "components/common/ModalWrapper";
import { MODAL_LOADER } from "utils/constants";
import Select from "react-select";

const employeeTypeKeyValuePairs = [
  { label: "Waiter", value: "Waiter", key: "Waiter" },
  { label: "Chef", value: "Chef", key: "Chef" },
  { label: "Bartender", value: "Bartender", key: "Bartender" },
  { label: "Cashier", value: "Cashier", key: "Cashier" },
  { label: "Receptionist", value: "Receptionist", key: "Receptionist" },
  { label: "Marketing Manager", value: "MarketingManager", key: "MarketingManager" },
]

const RestaurantEmployeeAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
  const defaultRecordObj = {
    firstName: "",
    lastName: "",
    gender: "Male",
    mobile: "",
    isdCode: "+91",
    email: "",
    password: "",
    photoImageRefId: "",
    employeeType: "",
    dateOfBirthTimeStamp: "",
  };
  const [record, setRecord] = useState(defaultRecordObj);
  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
    if (recordID) {
      callGetApi(MODAL_LOADER, Get_Restaurant_Staff_Employee_Detail_GET.replaceAll("{recordID}", recordID), {}, (response) => {
        const res = response.responsePacket;
        const _employeeType = employeeTypeKeyValuePairs.find((obj) => obj.key === res.employeeType);
        const recordObj = {
          ...res,
          employeeType: _employeeType || "",
          dateOfBirthTimeStamp: getDOB(res.dateOfBirthTimeStamp),
        }
        setRecord(recordObj);
      });
    }
  }, [recordID]);

  //Text Change Event
  const handleTextChange = (e) => {
    const {id, value} = e.target
    
    // if(id === "mobile" && value.length !== 0)
    // if (!isMobile(value)) {
    //     return ;
    // } 
    if(id === "firstName" && value.length !== 0)
    if (value.length >20) {
        return ;
    } 
    if(id === "firstName" && value.length !== 0)
    if (!isAlphaNumercSpecialCharSpc(value)) {
        return ;
    }
    if(id === "lastName" && value.length !== 0)
    if (value.length >20) {
        return ;
    } 
    if(id === "lastName" && value.length !== 0)
    if (!isAlphaNumercSpecialCharSpc(value)) {
        return ;
    }
    if(id === "mobile" && value.length !== 0)
    if (value.length >10) {
        return ;
    } 
    if(id === "password" && value.length !== 0)
    if (!isAlphaNumercSpecialChar(value)) {
        return ;
    } 
    if(id === "email" && value.length !== 0)
    if (value.length >50) {
        return ;
    } 
    setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  // Image change
  const handleImageChange = (imageRefId) => {

    setRecord({ ...record, photoImageRefId: imageRefId });
  };

  // Select change
  const handleSelectChange = (e) => {
    setRecord({ ...record, employeeType: e });
  };

  const checkInputValidation = (recordObj) => {
    if (isEmpty(recordObj.photoImageRefId)) {
      toast.error(`Image must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.firstName)) {
        toast.error(`First Name must not be empty`, { id: "toast" });
        return false;
    } else if (recordObj.firstName.length < 3) {
        toast.error(`First Name must be more then 2 character.`, { id: "toast" });
        return false;
    } else if (isEmpty(recordObj.lastName)) {
        toast.error(`Last Name must not be empty`, { id: "toast" });
        return false;
    } else if (recordObj.lastName.length < 3) {
        toast.error(`Last Name must  be more then 2 character.`, { id: "toast" });
        return false;
    } else if (isEmpty(recordObj.isdCode)) {
        toast.error(`ISD Code must not be empty`, { id: "toast" });
        return false;
    } else if (!isValidIsdCode(recordObj.isdCode)) {
        toast.error(`Please Provide Valid ISD Code`, { id: "toast" });
        return false;
    } else if (isEmpty(recordObj.mobile)) {
        toast.error(`Mobile Number must not be empty`, { id: "toast" });
        return false;
    } else if (!isMobile(recordObj.mobile)) {
        toast.error(`Please Provide Valid Mobile Number`, { id: "toast" });
        return false;
    } else if (isEmpty(recordObj.email)) {
      toast.error(`Email must not be empty`, { id: "toast" });
      return false;
  } else if (recordObj.email && !isEmail(recordObj.email)) {
        toast.error(`Please Provide Valid Email`, { id: "toast" });
        return false;
    } else if (isEmpty(recordObj.employeeType)) {
        toast.error(`Employee Type must not be empty`, { id: "toast" });
        return false;
    }

    if (!recordID) {
      if (isEmpty(recordObj.password)) {
          toast.error(`Password must not be empty`, { id: "toast" });
          return false;
      }
    }
    if (recordObj.dateOfBirthTimeStamp) {
      var currentDate = new Date();
      var dobDate = new Date(recordObj.dateOfBirthTimeStamp);
      if (dobDate.getTime() > currentDate.getTime()) {
        toast.error(`Please Provide Valid date Of Birth`, { id: "toast" });
        return false;
      }
    }
    return true;
  };

  //Submit Employee Form
  const handleSubmit = async () => {
    try {

      let recordObj = {
        ...record,
        employeeType: record.employeeType.key || "",
        dateOfBirthTimeStamp: getTimeFromDate(record.dateOfBirthTimeStamp)
      };

      if (!checkInputValidation(recordObj)) {
        return;
      }

      if (!recordID || recordID === "") {
        callPostApi(MODAL_LOADER, Add_Restaurant_Staff_Employee_POST, recordObj, {}, (response) => {
          toast.success(response.message, {id: "toast"});
          hideModal();
          handleRefresh();
        });
      } else {
        callPutApi(MODAL_LOADER, Update_Restaurant_Staff_Employee_PUT.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
          toast.success(response.message, {id: "toast"});
          hideModal();
          handleRefresh();
        });
      }
    } catch (error) {
      return;
    }
  };

  return (
    <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`${recordID ? "Update" : "Add"} Employee`} handleSubmit={handleSubmit}>
      <div className="form-group row mb-3">
        <div className="col-lg-12">
          <ImageSelector
            style={{
              height: "120px",
              width: "120px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
            user={true}
            handleImageChange={handleImageChange}
            selectedImage={record.photoImageRefId}
          />
        </div>
      </div>
      <div className="form-group row mb-3">
        <div className="col-lg-6">
          <label>First Name *</label>
          <input type="text" maxLength={20} className="form-control input-default" id="firstName" onChange={handleTextChange} value={record.firstName} />
        </div>
        <div className="col-lg-6">
          <label>Last Name *</label>
          <input type="text" maxLength={20} className="form-control input-default" id="lastName" onChange={handleTextChange} value={record.lastName} />
        </div>
      </div>
      <div className="form-group row mb-3">
        <div className="col-lg-6  mb-3 ">
          <div className="row g-1">
            <div className="col-4">
              <label>ISD Code *</label>
              <input type="text" className="form-control input-default" id="isdCode" onChange={handleTextChange} value={record.isdCode} />
            </div>
            <div className="col-8">
              <label>Mobile *</label>
              <input type="number" className="form-control input-default" id="mobile" onChange={handleTextChange} value={record.mobile} />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <label>Email *</label>
          <input type="email" className="form-control input-default" id="email" onChange={handleTextChange} value={record.email} />
        </div>
        <div className="col-lg-6">
          <label className="d-block">Gender</label>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              id="male"
              defaultValue="male"
              checked={record.gender?.toLowerCase() === "male"}
              onChange={(e) => setRecord({ ...record, gender: "Male" })}
            />
            <label className="form-check-label" htmlFor="male">
              Male
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              id="female"
              defaultValue="female"
              checked={record.gender?.toLowerCase() === "female"}
              onChange={(e) => setRecord({ ...record, gender: "Female" })}
            />
            <label className="form-check-label" htmlFor="female">
              Female
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              id="other"
              defaultValue="other"
              checked={record.gender?.toLowerCase() === "other"}
              onChange={(e) => setRecord({ ...record, gender: "Other" })}
            />
            <label className="form-check-label" htmlFor="other">
              Other
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <label>Employee Type *</label>
          <Select options={employeeTypeKeyValuePairs} value={record.employeeType} onChange={handleSelectChange} />
        </div>
      </div>
      <div className="form-group row mb-3">
        {!recordID && <div className="col-lg-6 mb-3 ">
          <label>Password *</label>
          <div className="input-group auth-pass-inputgroup">
          <input type={showPassword ? "text" : "password"}  maxLength={16} className="form-control input-default" id="password" onChange={handleTextChange} value={record.password} />
          <button className="btn btn-light " type="button" id="password-addon" onClick={() => setShowPassword(!showPassword)}>
                                                            {showPassword ? <i className="mdi mdi-eye-off-outline" /> : <i className="mdi mdi-eye-outline" />}
                                                        </button>
          </div>
        </div>}
        <div className="col-lg-6">
          <label>Date Of Birth *</label>
          <input className="form-control" type="date" id="dateOfBirthTimeStamp" value={record.dateOfBirthTimeStamp} onChange={handleTextChange} max={new Date().toISOString().split('T')[0]} />
        </div>
      </div>
      <div className="form-group mb-3 row">

      </div>
    </ModalWrapper>
  );
};

export default RestaurantEmployeeAddEdit;
