import { useEffect, useState } from 'react'

import toast from 'react-hot-toast';

import {
    CountryList_LocalCountry_POST,
    Get_Restaurant_Profile_Details_Get
} from 'api/ApiConst';
import { callGetApi, callPostApi } from 'api/ApiCaller';
import { SECTION_LOADER } from 'utils/constants';
import { API_SUCCESS } from 'api/ResponseCodes';

import { restaurantDefaultRecordObj } from "./restaurantDefaultValues";
import PageHeading from 'components/common/PageHeading'
import RestaurantProfileImages from './RestaurantProfileImages';
import UserRestaurantLocation from './UserRestaurantLocation';
import UserRestaurantDetails from './UserRestaurantDetails';

const RestaurantProfile = () => {
    const [record, setRecord] = useState({ ...restaurantDefaultRecordObj });
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    useEffect(() => {
        // getCountryList()
        const rUrl = Get_Restaurant_Profile_Details_Get;
        callGetApi(SECTION_LOADER, rUrl, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                const res = response.responsePacket;
                setRecord((prev) => ({
                    ...prev, ...res,
                }));
            } else {
                toast.errorCode(response.message);
            }
        })
    }, [])

    // Get Country List
    const getCountryList = () => {
        let rUrl = CountryList_LocalCountry_POST;
        let rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setCountryList(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    };

    // Text Change of record
    const handleTextChange = (e) => {
        const { id, value } = e.target;
        setRecord((prev) => ({ ...prev, [id]: value }));
    };

    // Checkbox Change of record
    const handleCheckedChange = (e) => {
        const { id, checked } = e.target;
        setRecord((prev) => ({ ...prev, [id]: checked }));
    };

    // Text Change of record in Order type
    const handleOrderTypeTextChange = (e, orderType) => {
        const { id, value, type } = e.target;
        if (type === "number") {
            availabilityTypeTextChange(orderType, id, parseFloat(value));
        } else {
            availabilityTypeTextChange(orderType, id, value);
        }
    };

    // Text Change of record in Order type
    const handleOrderTypeSelectChange = (e, orderType, id) => {
        const value = e;
        availabilityTypeTextChange(orderType, id, value);
    };

    // Availability Type (DineIn/Delivery/TakeAway/StealDeal) values change
    const availabilityTypeTextChange = (orderType, id, value) => {
        if (orderType === "Delivery") {
            setRecord((prev) => ({ ...prev, restaurantDeliveryDetail: { ...prev.restaurantDeliveryDetail, [id]: value } }));
        } else if (orderType === "TakeAway") {
            setRecord((prev) => ({ ...prev, restaurantTakeAwayDetail: { ...prev.restaurantTakeAwayDetail, [id]: value } }));
        } else if (orderType === "DineIn") {
            setRecord((prev) => ({ ...prev, restaurantDineInDetail: { ...prev.restaurantDineInDetail, [id]: value } }));
        } else if (orderType === "StealDeal") {
            setRecord((prev) => ({ ...prev, restaurantStealDealDetail: { ...prev.restaurantStealDealDetail, [id]: value } }));
        }
    };

    // select Text Change of record
    const handleSelectTextChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    // View User Restaurant Owner details
    const viewOwnerDetails = () => {
        return (
            <fieldset>
                {/* <legend>Own Details</legend> */}
                <div className="form-group row">
                    <div className="col-lg-12 my-2">
                        <label>Full Name</label>
                        <span className='d-block'>{record?.ownerFullName}</span>
                    </div>
                    <div className="col-lg-12 my-2">
                        <label>Mobile</label>
                        <span className='d-block'>+91{record?.ownerMobile}</span>
                    </div>
                    <div className="col-lg-12 my-2">
                        <label>Email</label>
                        <span className='d-block'>{record?.ownerEmail}</span>
                    </div>
                </div>
            </fieldset>
        )
    }

    // view Tabs
    const getTabView = () => {
        return (
            <>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="restaurant-tab" data-bs-toggle="tab" data-bs-target="#restaurant" type="button" role="tab" aria-controls="restaurant" aria-selected="true">Restaurant Details</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="location-tab" data-bs-toggle="tab" data-bs-target="#location" type="button" role="tab" aria-controls="location" aria-selected="false">Location</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="images-tab" data-bs-toggle="tab" data-bs-target="#images" type="button" role="tab" aria-controls="images" aria-selected="false">Images</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="restaurant" role="tabpanel" aria-labelledby="restaurant-tab">
                        <UserRestaurantDetails
                            handleTextChange={handleTextChange}
                            record={record}
                            handleCheckedChange={handleCheckedChange}
                            handleOrderTypeTextChange={handleOrderTypeTextChange}
                            handleOrderTypeSelectChange={handleOrderTypeSelectChange}
                            handleSelectTextChange={handleSelectTextChange}
                        />
                    </div>
                    <div className="tab-pane fade" id="location" role="tabpanel" aria-labelledby="location-tab">
                        <UserRestaurantLocation address={record.address} setRecord={setRecord} countryList={countryList} setStateList={setStateList} stateList={stateList} setCityList={setCityList} cityList={cityList} />
                    </div>
                    <div className="tab-pane fade" id="images" role="tabpanel" aria-labelledby="images-tab">
                        <RestaurantProfileImages record={record} setRecord={setRecord} />
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {PageHeading("Profile Details", "Dashboard", "/")}
            <div className="row">
                <div className="col-lg-3 mb-0">
                    <div className="card">
                        <div className="card-body tableView">
                            {viewOwnerDetails()}
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 mb-0">
                    <div className="card">
                        <div className="card-body tableView">
                            {getTabView()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RestaurantProfile