import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { callGetApi, callPutApi } from 'api/ApiCaller';
import { Details_Client_Website_CMS_Get, Update_Client_Website_CMS_Put } from 'api/ApiConst';
import ModalWrapper from 'components/common/ModalWrapper';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { MODAL_LOADER } from 'utils/constants';

const CMSAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const [record, setRecord] = useState("");

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Client_Website_CMS_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                setRecord(response.responsePacket);
            });
        }
    }, [recordID]);

    const handleSaveCMS = () => {
        try {
            let data = {
                websiteCmsValue: record,
            };
            let url = Update_Client_Website_CMS_Put.replaceAll("{recordID}", recordID);
            callPutApi(MODAL_LOADER, url, data, {}, (response) => {
                toast.success(response.message, {id: "toast"});
                hideModal();
                handleRefresh();
            });
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`Update ${recordID} Details`} handleSubmit={handleSaveCMS}>
            <div className="form-group col-lg-12">
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: {
                            items: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "|", "indent", "outdent", "|", "imageUpload", "blockQuote", "mediaEmbed", "undo", "redo"],
                        },
                    }}
                    data={record}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setRecord(data);
                    }}
                />
            </div>
        </ModalWrapper>
    );
}

export default CMSAddEdit