import React, { useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";

import {
    Activate_UserAdmin_PUT,
    All_UserAdmin_List_POST,
    AssignRoles_UserAdmin_PUT,
    Assigned_RolesList_UserAdmin_POST,
    DeActivate_UserAdmin_PUT,
    Delete_UserAdmin_DELETE,
    Deleted_UserAdmin_List_POST,
    Revive_UserAdmin_PUT,
} from "api/ApiConst";
import { handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import dateUtilities from "utils/DateUtilities";

import ChangePasswordModal from "components/common/ChangePasswordModal";
import PageHeading from "components/common/PageHeading";
import AssignRoles from "components/common/AssignRoles";
import PaginationDataTable from "components/common/PaginationDataTable";
import CustomButton from "components/common/CustomButton";
import CheckboxButton from "components/common/CheckboxButton";
import UserAdminAddEdit from "./UserAdminAddEdit";

const UserAdminMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showAssignRoleModal, setShowAssignRoleModal] = useState(false);

    useEffect(() => {
        if (!showAddUpdateModal && !showAssignRoleModal && !showChangePasswordModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal, showAssignRoleModal, showChangePasswordModal]);

    const handleRefresh = () => {
        setRefreshFlag(refreshFlag + 1);
    };

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => <div className={row.active ? "led-green" : "led-red"} title={row.active ? "Active" : "Inactive"} />,
                width: "60px",
                sortable: false,
            },
            // {
            //     id: "image",
            //     selector: (row) => (
            //         <div className="profile-img">
            //             <img src={getImageSrcViaRefId(row.photoImageRefId)} alt={row.firstName} />
            //         </div>
            //     ),
            //     width: "100px",
            //     sortable: false,
            // },
            {
                id: "name",
                name: "Name",
                selector: (row) => <Link to={`/${row.uuid}`}>{row.firstName + " " + row.lastName}</Link>,
                sortable: false,
            },
            {
                id: "userName",
                name: "User Name",
                selector: (row) => row.username,
                sortable: false,
            },
            {
                id: "mobile",
                name: "Mobile No.",
                selector: (row) => row.isdCode + " " + row.mobile,
                sortable: false,
            },
            {
                id: "email",
                name: "Email",
                selector: (row) => row.email,
                sortable: false,
            },
            {
                id: "dateOfBirthTimeStamp",
                name: "DOB",
                selector: (row) => dateUtilities.formattedDate(row.dateOfBirthTimeStamp, "dd-MM-yyyy"),
                sortable: false,
            },
            {
                id: "createdAt",
                name: "Created Time",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "100px",
                sortable: false,
            },
        ],
        [refreshFlag]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )}
                <div className="dropdown">
                    <button className="actionBtn" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                    <li onClick={() => handleChangePassword(row)}>
                                        <i className="bx bxs-key"></i> Change Password
                                    </li>
                                    <li onClick={() => handleAssignRole(row)}>
                                        <i className="bx bxs-user-plus"></i> Assign Role
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    const handleToggleAddUpdateModal = () => {
        setShowAddUpdateModal((prev) => !prev);
    };

    const handleToggleAssignRoleModal = () => {
        setShowAssignRoleModal((prev) => !prev);
    };

    const handleToggleChangePasswordModal = () => {
        setShowChangePasswordModal((prev) => !prev);
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        handleToggleAddUpdateModal();
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_UserAdmin_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_UserAdmin_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_UserAdmin_DELETE.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_UserAdmin_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Change Password
    const handleChangePassword = (row) => {
        setRecordID(row.uuid);
        handleToggleChangePasswordModal();
    };

    //Assign Roles
    const handleAssignRole = (row) => {
        setRecordID(row.uuid);
        handleToggleAssignRoleModal();
    };

    //Submit Password function
    const handleSubmitPassword = () => {
        // let requestObj = {
        //     password: password,
        // };
        // let url = APIConst.ClientUserPlayer_ChangePassword_PUT.replaceAll("{userPlayerId}", dataPwdModel.recordId);
        // apiCaller.callPutApi(
        //     url,
        //     requestObj,
        //     {},
        //     (response) => {
        //         if (response.success) {
        //             toast.success(response.message);
        //         } else {
        //             toast.error(response.message);
        //         }
        //     },
        //     (error) => {
        //         toast.error(error.response.data.message);
        //     }
        // );
    };

    return (
        <>
            {PageHeading("User Admin List", "Dashboard", "/")}
            <div className="card">
                <CustomButton handleClick={handleToggleAddUpdateModal} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_UserAdmin_List_POST : All_UserAdmin_List_POST}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showChangePasswordModal && <ChangePasswordModal show={showChangePasswordModal} close={() => setShowChangePasswordModal(false)} handleSubmitPassword={handleSubmitPassword} />}
            {showAddUpdateModal && <UserAdminAddEdit show={showAddUpdateModal} hideModal={handleToggleAddUpdateModal} recordID={recordID} handleRefresh={handleRefresh} />}
            {showAssignRoleModal && (
                <AssignRoles
                    show={showAssignRoleModal}
                    hideModal={handleToggleAssignRoleModal}
                    recordID={recordID}
                    handleRefresh={handleRefresh}
                    assignRole={AssignRoles_UserAdmin_PUT}
                    getAssignedRole={Assigned_RolesList_UserAdmin_POST}
                />
            )}
        </>
    );
};

export default UserAdminMaster;
