import OrderCount from "components/common/OrderCount";

const dashboard = {
    title: "Dashboard",
    link: "/",
    icon: "bx bx-home-circle",
    multi: false,
    role: "",
};

export const admin = [
    { ...dashboard },
    // {
    //     "title": "User Admin",
    //     "link": "admin-user-admin",
    //     "icon": "bx bx-user",
    //     "multi": false
    // },
    {
        title: "User Client",
        link: "admin-user-client",
        icon: "bx bx-user",
        multi: false,
    },
    {
        title: "User Customer",
        link: "admin-user-customer",
        icon: "bx bx-user",
        multi: false,
    },
    {
        title: "Locality",
        multi: true,
        keyword: "locality",
        icon: "bx bx-map",
        linkList: [
            {
                title: "Local Country",
                link: "locality-local-country",
                icon: "bx bx-map-pin",
                multi: false,
            },
            {
                title: "Local State",
                link: "locality-local-state",
                icon: "bx bx-map-alt",
                multi: false,
            },
            {
                title: "Local City",
                link: "locality-local-city",
                icon: "bx bx-globe",
                multi: false,
            },
        ],
    },
    {
        title: "Setting",
        multi: true,
        icon: "bx bx-wrench",
        keyword: "admin-setting",
        linkList: [
            {
                title: "Project Setting",
                link: "admin-setting-project",
                icon: "bx bx-code-block",
                multi: false,
            },
        ],
    },
    {
        title: "CMS",
        link: "admin-cms",
        icon: "bx bx-laptop",
        multi: false,
    },
    {
        title: "Currency",
        link: "super-admin-currency",
        icon: "bx bx-money",
        multi: false
    },
    {
        title: "Client",
        link: "super-admin-client",
        icon: "bx bx-user",
        multi: false
    },
    {
        title: "Role",
        link: "super-admin-role",
        icon: "bx bx-certification",
        multi: false
    },
    {
        title: "Module",
        link: "super-admin-module",
        icon: "bx bx-list-ul",
        multi: false
    }
];

export const clientAdmin = [
    { ...dashboard },
    {
        title: "Brand",
        link: "client-brand",
        icon: "bx bx-check-shield",
        multi: false,
    },
    {
        title: "Restaurant Setup",
        multi: true,
        icon: "bx bx-restaurant",
        keyword: "client-restaurant",
        linkList: [
            {
                title: "Tax",
                link: "client-restaurant-tax",
                icon: "bx bx-file",
                multi: false,
            },
            {
                title: "Cuisine",
                link: "client-restaurant-cuisine",
                icon: "bx bx-food-tag",
                multi: false,
            },
            {
                title: "Sticker",
                link: "client-restaurant-sticker",
                icon: "bx bx-sticker",
                multi: false,
            },
            {
                title: "Timing Group",
                link: "client-timing-group",
                icon: "bx bx-time",
                multi: false,
            },
            {
                title: "Restaurant ",
                link: "client-restaurant",
                icon: "bx bx-restaurant",
                multi: false,
            },
            {
                title: "Event",
                link: "client-event",
                icon: "bx bx-calendar-event",
                multi: false,
            },
        ],
    },
    {
        title: "Menu",
        icon: "bx bx-menu",
        keyword: "client-menu",
        multi: true,
        linkList: [
            {
                title: "Category",
                link: "client-menu-category",
                keyword: "menu-category",
                icon: "bx bx-code-block",
                multi: true,
                linkList: [
                    {
                        title: "Steal Deal",
                        link: "client-menu-steal-deal-category",
                        icon: "bx bxs-drink",
                        multi: false,
                    },
                    {
                        title: "Food",
                        link: "client-menu-non-steal-deal-category",
                        icon: "bx bxs-dish",
                        multi: false,
                    },
                ]
            },
            {
                title: "Item Customization",
                link: "client-menu-customization",
                icon: "bx bx-customize",
                multi: false,
            },
            {
                title: "Condiments",
                link: "client-menu-condiments",
                icon: "bx bx-customize",
                multi: false,
            },
            {
                title: "Item",
                link: "client-menu-item",
                keyword: "menu-item",
                icon: "bx bx-food-tag",
                multi: true,
                linkList: [
                    {
                        title: "Steal Deal",
                        link: "client-menu-steal-deal-item",
                        icon: "bx bxs-wine",
                        multi: false,
                    },
                    {
                        title: "Food",
                        link: "client-menu-non-steal-deal-item",
                        icon: "bx bxs-dish",
                        multi: false,
                    },
                ]
            },
        ],
    },
    {
        title: "Complementary Head",
        link: "client-complementary-head",
        icon: "bx bxs-gift",
        multi: false,
    },
    {
        title: "Setting",
        link: "client-setting",
        icon: "bx bx-wrench",
        multi: false,
    },
    {
        title: "Feedback",
        link: "client-feedback",
        icon: "bx bx-comment-dots",
        multi: false,
    },
    {
        title: "Customer Review",
        link: "client-customer-review",
        icon: "bx bx-receipt text-red",
        multi: false,
    },
    {
        title: "Payment Mode",
        keyword: "payment-mode",
        icon: "bx bxs-wallet-alt text-red",
        multi: true,
        linkList: [
            {
                title: "Payment Transaction",
                link: "client-payment-transaction",
                icon: "bx bx-credit-card text-red",
                multi: false,
            },
        ],
    },
    {
        title: "OTP",
        link: "client-otp-list",
        icon: "bx bxs-message-rounded",
        multi: false,
    },
    {
        title: "Notification",
        link: "client-notification",
        icon: "bx bxs-notification",
        multi: false,
    },
    {
        title: "Promotion",
        keyword: "client-promotion",
        icon: "bx bx-rocket",
        multi: true,
        linkList: [
            {
                title: "Dine In Cash back",
                link: "client-dine-in-cash-back",
                icon: "bx bx-food-tag",
                multi: false,
            },
            {
                title: "QR Code Cash back",
                link: "client-qr-code-cash-back",
                icon: "bx bxs-barcode",
                multi: false,
            },
            {
                title: "Coupon Code",
                link: "client-coupon-code",
                icon: "bx bxs-coupon",
                multi: false,
            },
            {
                title: "Customer Coupon",
                link: "client-customer-coupon",
                icon: "bx bxs-discount",
                multi: false,
            },
            {
                title: "Staff Coupon",
                link: "client-staff-coupon",
                icon: "bx bxs-discount",
                multi: false,
            },
            {
                title: "Ad Banner",
                link: "client-ad-banner",
                icon: "bx bx-image-add",
                multi: false,
            },
        ],
    },
    {
        title: "Reports",
        link: "client-reports",
        icon: "bx bxs-report text-red",
        multi: false,
    },
    {
        title: "Employee Movement",
        link: "client-employee-movement",
        icon: "bx bx-transfer-alt",
        multi: false,
    },
    {
        title: "CMS",
        link: "client-cms",
        icon: "bx bx-laptop",
        multi: false,
    },
    // {
    //     title: "Voucher",
    //     link: "client-voucher",
    //     icon: "bx bxs-coupon",
    //     multi: false
    // }
];

export const restaurantAdmin = [
    { ...dashboard },
    {
        title: "Voucher",
        link: "restaurant-voucher",
        icon: "bx bxs-coupon",
        multi: false,
    },
    {
        title: "Printer",
        link: "restaurant-printer",
        icon: "bx bxs-printer",
        multi: false,
    },
    {
        title: "Restaurant Setup",
        multi: true,
        icon: "bx bx-restaurant",
        keyword: "restaurant-setup",
        linkList: [
            {
                title: "Aggregator",
                link: "restaurant-aggregator",
                icon: "bx bxs-coupon",
                multi: false,
            },
            {
                title: "Payment Mode",
                link: "restaurant-payment-mode",
                icon: "mdi mdi-cash",
                multi: false,
            },
            {
                title: "Table",
                link: "restaurant-table",
                icon: "bx bx-shape-circle",
                multi: false,
            },
            {
                title: "Tax",
                link: "restaurant-setup-tax",
                icon: "bx bx-file",
                multi: false,
            },
            // {
            //     title: "Cuisine",
            //     link: "restaurant-setup-cuisine-list",
            //     icon: "bx bx-food-tag",
            //     multi: false,
            // },
            // {
            //     title: "Sticker",
            //     link: "restaurant-setup-sticker",
            //     icon: "bx bx-sticker",
            //     multi: false,
            // },
            {
                title: "Timing Group",
                link: "restaurant-setup-timing-group",
                icon: "bx bx-time",
                multi: false,
            },
            // {
            //     title: "Restaurant ",
            //     link: "restaurant-setup-list",
            //     icon: "bx bx-restaurant",
            //     multi: false,
            // },
            // {
            //     title: "Event",
            //     link: "restaurant-setup-event",
            //     icon: "bx bx-calendar-event",
            //     multi: false,
            // },
        ],
    },
    {
        title: "Menu",
        icon: "bx bx-menu",
        keyword: "restaurant-menu",
        multi: true,
        linkList: [
            {
                title: "Category",
                link: "restaurant-menu-category",
                icon: "bx bx-code-block",
                keyword: "restaurant-menu-category",
                multi: true,
                linkList: [
                    {
                        title: "Steal Deal",
                        link: "restaurant-menu-steal-deal-category",
                        icon: "bx bxs-drink",
                        multi: false,
                    },
                    {
                        title: "Food",
                        link: "restaurant-menu-non-steal-deal-category",
                        icon: "bx bxs-dish",
                        multi: false,
                    },
                ]
            },
            {
                title: "Item Customization",
                link: "restaurant-menu-customization",
                icon: "bx bx-customize",
                multi: false,
            },
            {
                title: "Condiments",
                link: "restaurant-menu-condiments",
                icon: "bx bx-customize",
                multi: false,
            },
            {
                title: "Item",
                link: "restaurant-menu-item",
                icon: "bx bx-food-tag",
                multi: true,
                keyword: "restaurant-menu-item",
                linkList: [
                    {
                        title: "Steal Deal",
                        link: "restaurant-menu-steal-deal-item",
                        icon: "bx bxs-wine",
                        multi: false,
                    },
                    {
                        title: "Food",
                        link: "restaurant-menu-non-steal-deal-item",
                        icon: "bx bxs-dish",
                        multi: false,
                    },
                ]
            },
        ],
    },
    {
        title: "Complementary Head",
        link: "restaurant-complementary-head",
        icon: "bx bxs-gift",
        multi: false,
    },
    {
        title: "Staff",
        multi: true,
        icon: "bx bxs-group",
        keyword: "restaurant-staff",
        linkList: [
            {
                title: "Employee",
                link: "restaurant-employee",
                icon: "bx bx-user",
                multi: false,
            },
            {
                title: "Delivery Boy",
                link: "restaurant-delivery-boy",
                icon: "bx bx-user",
                multi: false,
            },
            {
                title: "Track Delivery Boy",
                link: "restaurant-track-delivery-boy",
                icon: "bx bx-user",
                multi: false,
            },
        ],
    },
    {
        title: "Offers",
        multi: true,
        icon: "bx bxs-offer",
        keyword: "restaurant-offers",
        linkList: [
            {
                title: "Happy Hours",
                link: "restaurant-happy-hours-offer",
                icon: "bx bxs-watch",
                multi: false,
            },
            {
                title: "Delivery Offer",
                link: "restaurant-delivery-offer",
                icon: "bx bxs-package",
                multi: false,
            },
        ],
    },
    {
        title: "Report",
        multi: true,
        icon: "bx bx-file",
        keyword: "restaurant-report",
        linkList: [
            {
                title: "Sale Report",
                link: "restaurant-sale-report",
                icon: "bx bx-file",
                multi: false,
            },
            {
                title: "Order Report",
                link: "restaurant-order-report",
                icon: "bx bx-file",
                multi: false,
            },
        ]
    },
    {
        title: <OrderCount title={"ORDERS"} id={"order-count"} />,
        multi: true,
        icon: "bx bxs-cart-alt",
        keyword: "restaurant-orders",
        linkList: [
            {
                title: <OrderCount title={"Dine In"} id={"dineInOrderCount"} />,
                link: "restaurant-dineIn-orders",
                icon: "mdi mdi-silverware",
                multi: false,
            },
            {
                title: <OrderCount title={"Delivery"} id={"deliveryInOrderCount"} />,
                link: "restaurant-delivery-orders",
                icon: "mdi mdi-moped",
                multi: false,
            },
            {
                title: <OrderCount title={"TakeAway"} id={"takeAwayInOrderCount"} />,
                link: "restaurant-takeaway-orders",
                icon: "bx bxs-shopping-bag-alt",
                multi: false,
            },
        ],
    },
    {
        title: "History",
        link: "restaurant-order-history",
        icon: "mdi mdi-history",
        multi: false,
    },

];
