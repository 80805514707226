import { All_List_Keys_Admin_Website_CMS_Post } from 'api/ApiConst'
import PageHeading from 'components/common/PageHeading'
import React, { useCallback, useEffect, useState } from 'react'
import AdminCmsAddEdit from './AdminCmsAddEdit'
import { callPostApi } from 'api/ApiCaller'
import { PAGE_LOADER } from 'utils/constants'

const AdminCmsMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [list, setList] = useState([]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        callPostApi(PAGE_LOADER, All_List_Keys_Admin_Website_CMS_Post, {}, {}, (response) => {
            setList(response.responsePacket);
        });
    };

    const handleEdit = (row) => {
        setRecordID(row);
        setShowAddUpdateModal(true);
    };

    return (
        <>
            {PageHeading("CMS", "Dashboard", "/")}
            <div className="card">
                <div className="card-body tableView row cms-bg">
                    {list.map((data) => (
                        <div className="col-lg-4" key={data}>
                            <div className="card" style={{ cursor: "pointer" }} onClick={() => handleEdit(data)}>
                                <div className="card-body">
                                    <h5 className="mb-0">{data}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {showAddUpdateModal && <AdminCmsAddEdit show={showAddUpdateModal} hideModal={() => setShowAddUpdateModal(false)} recordID={recordID} handleRefresh={handleRefresh} />}
        </>
    )
}

export default AdminCmsMaster