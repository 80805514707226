import React, { useState } from "react";

import { toast } from "react-hot-toast";

import { Add_Module_POST, Update_Module_PUT } from "api/ApiConst";
import { callPostApi, callPutApi } from "api/ApiCaller";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";

const ModuleAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const defaultRecordObj = {
        title: "",
        additionalDataJson: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);

    //Text Change Event
    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.title)) {
            toast.error(`Module title must not be empty`, { id: "toast" });
            return false;
        } else if (record.title.length > 100) {
            toast.error(`Module title length not be more then 100 character.`, { id: "toast" });
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Add_Module_POST, recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Module_PUT.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} backdrop="static"  hide={hideModal} header={`${recordID ? "Update" : "Add"} Module`} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Title *</label>
                    <input type="text" className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} />
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Additional Data Json *</label>
                    <textarea className="form-control input-default" id="additionalDataJson" onChange={handleTextChange} value={record.additionalDataJson}></textarea>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default ModuleAddEdit;
