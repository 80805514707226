import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { Details_Restaurant_Printer_List_Get, Save_Restaurant_Printer_Post, Update_Restaurant_Printer_Put } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

import ModalWrapper from "components/common/ModalWrapper";

const RestaurantPrinterAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const defaultRecordObj = {
        name: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Restaurant_Printer_List_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const res = response.responsePacket;
                    setRecord(res);
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value } = e.target;
        setRecord((prev) => ({ ...prev, [id]: value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.name)) {
            toast.error(`Printer Name must not be empty`, { id: "toast" });
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Restaurant_Printer_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Restaurant_Printer_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} backdrop="static"  hide={hideModal} header={`${recordID ? "Update" : "Add"} Printer`} size="ms" handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12 my-2">
                    <label>Name *</label>
                    <input type="text" className="form-control input-default" id="name" onChange={handleTextChange} value={record.name} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default RestaurantPrinterAddEdit;
