import { toast } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

import { API_SUCCESS } from "api/ResponseCodes";
import { Download_File_GET, Upload_File_POST } from "api/ApiConst";
import { callDeleteApi, callPostApi, callPutApi, updateURL } from "api/ApiCaller";
import { isEmpty } from "./TextUtils";
import { MODAL_LOADER, SECTION_LOADER } from "./constants";

export const getRoleTypeString = (roleType) => {
    if (isEmpty(roleType)) {
        return "";
    }
    if (roleType === "ROLE_SUPER_ADMIN") {
        return "Super Admin";
    }
    if (roleType === "ROLE_ADMIN") {
        return "Admin";
    }
    return roleType;
};

//Action Active
export const handleActive = (url, refreshTable) => {
    callPutApi(SECTION_LOADER, url, {}, {}, (response) => {
        if (response.errorCode === API_SUCCESS) {
            toast.success(response.message, { id: "toast" });
            refreshTable();
        } else {
            toast.error(response.message, { id: "toast" });
        }
    });
};

//  Action Inactive
export const handleInactive = (url, refreshTable) => {
    callPutApi(SECTION_LOADER, url, {}, {}, (response) => {
        if (response.errorCode === API_SUCCESS) {
            toast.success(response.message, { id: "toast" });
            refreshTable();
        } else {
            toast.error(response.message, { id: "toast" });
        }
    });
};

//   Action Delete
export const handleDelete = (url, refreshTable) => {
    confirmAlert({
        title: "Alert ?",
        message: "Do you want to delete this record ?",
        icon: "warning",
        overlayClassName: "overlay-custom-class-name",
        buttons: [
            {
                label: "Yes",
                onClick: () => {
                    callDeleteApi(SECTION_LOADER, url, {}, (response) => {
                        if (response.errorCode === API_SUCCESS) {
                            toast.success(response.message, { id: "toast" });
                            refreshTable();
                        } else {
                            toast.error(response.message, { id: "toast" });
                        }
                    });
                },
            },
            {
                label: "No",
            },
        ],
    });
};

// Action Revive
export const handleRevive = (url, refreshTable) => {
    callPutApi(SECTION_LOADER, url, {}, {}, (response) => {
        if (response.errorCode === API_SUCCESS) {
            toast.success(response.message, { id: "toast" });
            refreshTable();
        } else {
            toast.error(response.message, { id: "toast" });
        }
    });
};

export const userNameImageObject = (userData) => {
    return {
        fullName: userData.firstName + " " + userData.lastName,
        imageUrl: userData.photoImageUrl,
    };
};

export const callImageUploader = async (base64, fileName) => {
    const res = await fetch(base64);
    const blob = await res.blob();
    let formData = new FormData();
    formData.append("file", blob, fileName);
    return callImageUploaderApi(formData);
};

const callImageUploaderApi = async (formData) => {
    return new Promise((resolve, reject) => {
        callPostApi(
            null,
            Upload_File_POST,
            formData,
            {},
            (response) => {
                if (response.errorCode === API_SUCCESS) {
                    resolve(response.responsePacket);
                    return response.responsePacket;
                } else {
                    reject(null);
                    toast.error(response.message, { id: "toast" });
                }
            },
            (error) => {
                reject(null);
                toast.error(error, { id: "toast" });
                return null;
            }
        );
    });
};

export const getImageSrcViaRefId = (refId) => {
    if (refId) {
        return updateURL(Download_File_GET.replaceAll("{attachemntId}", refId));
    }
    // return "/assets/images/users/defaultUser.png"
};


export const getQRCodeImage = async (rUrl) => {
    if (rUrl) {
        return new Promise((resolve, reject) => {
            callPostApi(SECTION_LOADER, rUrl, {}, {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    resolve(response.responsePacket)
                    return response.responsePacket;
                    // return <img src={`data:image/png;base64, ${response.responsePacket}`} alt="QR Code" className="my-2" style={{ height: "92px", width: "auto" }} />
                } else {
                    reject(null);
                    toast.error(response.message, { id: "toast" });
                }
            });
        })
    }
}

// Update Order Status
export const handleUpdateOrderStatus = (url, refreshDetails) => {
    const toastId="updateOrderStatus";
    callPutApi(MODAL_LOADER, url, {}, {}, (response) => {
        if (response.errorCode === API_SUCCESS) {
            toast.success(response.message, { id:toastId });
            refreshDetails();
        } else {
            toast.error(response.message, { id:toastId });
        }
    }, error => {
        toast.error(error?.response?.data?.message ? error?.response?.data?.message : error.message, { id:toastId });
    });
}