import React, { useEffect, useState } from 'react'
import PageHeading from 'components/common/PageHeading';

const defaultMarkLocations = [
    ["Jaipur Airport", 26.8289491, 75.8030429, 4],
    ["Maharana Pratap Airport Udaipur", 24.6198825, 73.8875679, 5],
    ["Jodhpur Airport", 26.2644443, 73.0479649, 3],
    ["Amer Fort Jaipur", 26.9854913, 75.8487705, 2],
    ["Patrika Gate, Jaipur", 26.8419012, 75.7977177, 1],
];

const TrackDeliveryBoyMaster = () => {
    const [deliveryBoyMarkLocations, setDeliveryBoyMarkLocations] = useState([...defaultMarkLocations])

    const getDeliverBoyMarkedLocation = () => {
        const map = new window.google.maps.Map(document.getElementById("track-delivery-boy"), {
            zoom: 5,
            // center: new window.google.maps.LatLng(26.9115959, 75.707171),
            center: new window.google.maps.LatLng(28.6139, 75.707171),
            mapTypeId: window.google.maps.MapTypeId.TERRAIN
            // center: { lat: 19.7239166, lng: 60.9545331 },
        });
        getDeliveryBoyLocationMarker(map)
    }

    const getDeliveryBoyLocationMarker = (map) => {
        const image = {
            url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
            // This marker is 20 pixels wide by 32 pixels high.
            size: new window.google.maps.Size(20, 32),
            // The origin for this image is (0, 0).
            origin: new window.google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new window.google.maps.Point(0, 32),
        };

        const shape = {
            coords: [1, 1, 1, 20, 18, 20, 18, 1],
            type: "poly",
        };

        for (let i = 0; i < deliveryBoyMarkLocations.length; i++) {
            const beach = deliveryBoyMarkLocations[i];

            new window.google.maps.Marker({
                position: { lat: beach[1], lng: beach[2] },
                map,
                icon: image,
                shape: shape,
                title: beach[0],
                zIndex: beach[3],
            });
        }
    }

    useEffect(() => {
        // window.initMap = getDeliverBoyMarkedLocation;
        getDeliverBoyMarkedLocation()
    }, [])
    return (
        <>
            {PageHeading("Track Delivery Boy", "Dashboard", "/")}
            <div className="card">
                <div className="card-body tableView m-0">
                    <div id="track-delivery-boy" style={{ height: "98vh" }}>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrackDeliveryBoyMaster