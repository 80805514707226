import { useEffect, useState } from 'react'

import Cookies from 'universal-cookie';
import toast from 'react-hot-toast';
import { Table } from 'react-bootstrap';

import { callGetApi, callPutApi } from 'api/ApiCaller';
import { API_SUCCESS } from 'api/ResponseCodes';

import { isEmpty } from 'utils/TextUtils';
import dateUtilities from 'utils/DateUtilities';
import ModalWrapper from 'components/common/ModalWrapper'
import { Accept_Order_Items_Status_Put, Delete_Order_Item_Put, Get_Order_Details_Get, Get_Order_History_Get, Print_Bill_Put, Reject_Order_Items_Status_Put, Update_Order_Status_Put } from 'api/ApiConst';
import { DINE_IN, HOME_DELIVERY, MODAL_LOADER, ORDER_ITEM_STATUS_ACCEPTED, ORDER_ITEM_STATUS_PENDING, ORDER_ITEM_STATUS_READY, ORDER_PAYMENT_STATUS_COD, ORDER_POINT_OF_SELL_CUSTOMER_APP, ORDER_POINT_OF_SELL_CUSTOMER_WEB, ORDER_STATUS_DELIVERED, ORDER_STATUS_DISPATCHED, ORDER_STATUS_ORDERED, ORDER_STATUS_READY, ORDER_STATUS_SERVED, QUANTITY_SYMBOL, DOLLAR_SYMBOL, TAKEAWAY, defaultCouponObj, tablePriceCellStyle } from 'utils/constants';


const HistoryShortOrderDetails = ({ show, hideModal, recordID, toggleApplyCouponModal, toggleShowComplementaryHeadModal = null, toggleSettleBillModal, handleRemoveApplyCoupon, handleRefreshOrderDtls, refreshOrderDtls, setSelectedOrderDtls = null, handleRefresh }) => {
    const defaultRecordObj = {
        userCustomerFirstName: "",
        userCustomerLastName: "",
        userCustomerIsdCode: "",
        userCustomerMobile: "",
        totalTax: "",
        taxes: [],
        taxDiscountAmount: "",
        tableNumber: "",
        subTotal: 0,
        orderTotal: 0,
        orderType: "",
        orderId: "",
        foodOrderItemList: [],
        couponDiscountAmount: 0,
        cookingInstruction: "",
        pointOfSell: "",
        orderStatus: "",
        paymentStatus: "",
    }
    const [record, setRecord] = useState(defaultRecordObj);
    const [applyCouponDtl, setApplyCouponDtl] = useState(defaultCouponObj)
    const [checkedItems, setCheckedItems] = useState([]);
    const [totalItemsQty, setTotalItemsQty] = useState(0);
    const [foodOrderItemList, setFoodOrderItemList] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [orderItemsStatus, setOrderItemsStatus] = useState([]);

    const currencyDetail = new Cookies().get("currencyDetail");

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Get_Order_History_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const { foodOrderItemList, taxes, ...result } = response.responsePacket;

                    if (setSelectedOrderDtls) {
                        setSelectedOrderDtls(response.responsePacket);
                    }

                    setFoodOrderItemList(foodOrderItemList); // food order Items List
                    setTaxes(taxes || []); // Applied Item Taxes List

                    // calculated Total Item Quantity
                    const totalQuantity = foodOrderItemList.reduce((total, item) => total + item.quantity, 0);
                    setTotalItemsQty(totalQuantity)

                    // check Order Items Status
                    const _orderItemsStatus = foodOrderItemList.filter(obj => obj.orderItemStatus === "Pending");
                    setOrderItemsStatus(_orderItemsStatus);

                    // set Coupon Code Details
                    if (result.couponCode) {
                        setApplyCouponDtl({
                            couponCode: result.couponCode || null,
                            couponDiscountAmount: result.couponDiscountAmount || 0,
                            taxDiscountAmount: result.taxDiscountAmount || 0,
                        });
                    }

                    setRecord({ ...result }); // set response record data
                }
            })
        }
    }, [recordID, refreshOrderDtls]);

    // Model Header custom Button for  (Print Bill | Apply Coupon | Complementary | Split Bill)
    const modalCustomButtons = () => {
        // return (
        //     <div className="d-flex model-header-btn">
        //         {/* {record.orderStatus.toUpperCase() === ORDER_STATUS_DELIVERED && */}
        //             <button className='btn bg-secondary border-0 rounded px-2 py-0 me-2 text-white' onClick={handlePrintBill}><i className='bx bx-printer' /> Print Bill</button>
        //         {/* } */}

        //         {record.paymentStatus === ORDER_PAYMENT_STATUS_COD && record.orderStatus.toUpperCase() !== ORDER_STATUS_DELIVERED &&
        //             <button className='btn bg-success border-0 rounded px-2 py-0 me-2' onClick={handleApplyCoupon}><i className='mdi mdi-percent-outline' /> Apply Coupon</button>
        //         }
        //         {record.paymentStatus === ORDER_PAYMENT_STATUS_COD && record.orderStatus.toUpperCase() === ORDER_STATUS_SERVED &&
        //             <button className='btn bg-warning border-0 rounded px-2 py-0 me-2' onClick={toggleShowComplementaryHeadModal}><i className='bx bxs-gift' /> Complementary</button>
        //         }

        //         {/* OrderType === DineIn && PaymentStatus=== CashOnDelivery && OrderStatus=== Served => Settle Bill */}
        //         {record.orderType === DINE_IN && record.paymentStatus === ORDER_PAYMENT_STATUS_COD && record.orderStatus.toUpperCase() === ORDER_STATUS_SERVED &&
        //             <button className='btn bg-success border-0 rounded px-2 py-0 me-2' onClick={toggleSettleBillModal}> <i className='bx bxs-captions' /> Settle Bill</button>
        //         }

        //         {/* OrderType === TakeAway && PaymentStatus=== CashOnDelivery && OrderStatus=== Ready => Settle Bill */}
        //         {record.orderType === TAKEAWAY && record.paymentStatus === ORDER_PAYMENT_STATUS_COD && record.orderStatus.toUpperCase() === ORDER_STATUS_READY &&
        //             <button className='btn bg-success border-0 rounded px-2 py-0 me-2' onClick={toggleSettleBillModal}> <i className='bx bxs-captions' /> Settle Bill</button>
        //         }

        //         {/* OrderType !== HomeDelivery && PaymentStatus=== CashOnDelivery && OrderStatus=== Dispatched => Settle Bill */}
        //         {record.orderType === HOME_DELIVERY && record.paymentStatus === ORDER_PAYMENT_STATUS_COD && record.orderStatus.toUpperCase() === ORDER_STATUS_DISPATCHED &&
        //             <button className='btn bg-success border-0 rounded px-2 py-0 me-2' onClick={toggleSettleBillModal}> <i className='bx bxs-captions' /> Settle Bill</button>
        //         }
        //     </div>
        // )
    }

    const handleApplyCoupon = () => {
        if (isEmpty(applyCouponDtl.couponCode)) {
            toggleApplyCouponModal()
        }
    }

    // View Applied Coupon Code Table Row
    const viewAppliedCouponCodeDtl = () => {
        if (applyCouponDtl.couponCode) {
            return (<tr className="bg-secondary text-white rounded">
                <td colSpan={2}></td>
                <td colSpan={4}>
                    {record?.orderStatus.toUpperCase() !== ORDER_STATUS_DELIVERED &&
                        <span className='me-2 background-red px-2 text-center rounded cursor' onClick={handleRemoveCoupon}>X</span>
                    }
                    <span>Coupon Discount {"("}{applyCouponDtl.couponCode}{")"}</span>
                </td>
                <td colSpan={1} style={tablePriceCellStyle}>
                    {"(-)"}{(applyCouponDtl.couponDiscountAmount + applyCouponDtl?.taxDiscountAmount).toFixed(2) + " " + currencyDetail?.currencySymbol}
                </td>
            </tr>)
        }
    }

    // check-uncheck Table Checkbox
    const handleItemsCheckbox = (e) => {
        const { id, checked } = e.target;
        let _checkedItems = [...checkedItems];
        let _foodOrderItemList = [...foodOrderItemList];

        if (id === "all") {
            if (_checkedItems.length === _foodOrderItemList.length) {
                _checkedItems = [];
            } else {
                for (let i = 0; i < _foodOrderItemList.length; i++) {
                    if (!_checkedItems.includes(_foodOrderItemList[i]["uuid"])) {
                        _checkedItems.push(_foodOrderItemList[i]["uuid"]);
                    }
                }
            }
        } else {
            if (_checkedItems.includes(id)) {
                const index = _checkedItems.indexOf(id);
                if (index !== -1) {
                    _checkedItems.splice(index, 1);
                }
            } else {
                _checkedItems.push(id);
            }
        }
        setCheckedItems(_checkedItems);
    }

    // view the Item Customizations
    const viewItemCustomizations = (row) => {
        const _itemCustomizations = [];
        let _customizations = row.itemCustomizations;
        if (_customizations && _customizations.length !== 0) {
            for (let i = 0; i < _customizations.length; i++) {
                let _customizationOptions = _customizations[i].customizationOptions;
                for (let j = 0; j < _customizationOptions.length; j++) {
                    _itemCustomizations.push(_customizationOptions[j]);
                }
            }
        }
        return _itemCustomizations;
    }

    // remove apply coupon
    const handleRemoveCoupon = async () => {
        const couponRemoved = await handleRemoveApplyCoupon();
        if (couponRemoved) {
            setApplyCouponDtl(defaultCouponObj);
            handleRefresh();
        }
    };

    // Print Bill
    const handlePrintBill = () => {
        callPutApi(null, Print_Bill_Put.replaceAll("{recordID}", recordID), {}, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                toast.success(response.message , {id: "toast"})
            } else {
                toast.error(response.message , {id: "toast"})
            }
        })
    }

    // View order Customer Details 
    const viewCustomerDetails = () => {
        return (
            <fieldset className='px-0 pb-0'>
                <legend>Customer Details</legend>
                <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1 px-2">
                        Name <span>{record.userCustomerFirstName + " " + record.userCustomerLastName}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1 px-2">
                        Phone <span>{record.userCustomerIsdCode + record.userCustomerMobile}</span>
                    </li>
                </ul>
            </fieldset>
        )
    }

    // view Order details
    const viewOrderDetails = () => {
        return (
            <fieldset className='px-0 pb-0'>
                <legend>Order Details</legend>
                <ul className="list-group p-0">
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                        Invoice No. <span>{record?.restaurantOrderNumber && record?.restaurantOrderNumber ? 
                    record?.restaurantOrderNumber: record.orderId}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                        Order Time <span>{dateUtilities.formattedDate(record?.createdAt, "dd-MM-yyyy, hh:mm aa")}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                        Total Amount <span>{record.orderTotal && record.orderTotal.toFixed(2) + " " + currencyDetail?.currencySymbol}</span>
                    </li>
                    {record.orderType === DINE_IN &&
                        <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                            Table Number <span>{record.tableNumber}</span>
                        </li>
                    }
                </ul>
            </fieldset>
        )
    }

    // view order Food Items List
    const viewOrderFoodItemsTableList = () => {
        return (
            <Table>
                <thead className='border-bottom border-secondary '>
                    <tr>
                        <th>
                            {(record.pointOfSell === ORDER_POINT_OF_SELL_CUSTOMER_WEB || record.pointOfSell === ORDER_POINT_OF_SELL_CUSTOMER_APP) &&
                                orderItemsStatus.length !== 0 &&
                                <input type="checkbox" id="all" onChange={handleItemsCheckbox} checked={checkedItems.length === foodOrderItemList.length} />
                            }
                        </th>
                        <th>Server</th>
                        <th>Title</th>
                        <th>Customization</th>
                        <th>Selling Price</th>
                        <th>Qty.</th>
                        <th style={tablePriceCellStyle}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {foodOrderItemList.length !== 0 && foodOrderItemList.map((item, i) => {
                        return (<tr key={i}>
                            <td>
                                {/* Point of Sell CustomerApp | CustomerApp AND Ordered item Status is Pending then show Check Box */}
                                {(record.pointOfSell === ORDER_POINT_OF_SELL_CUSTOMER_WEB || record.pointOfSell === ORDER_POINT_OF_SELL_CUSTOMER_APP) &&
                                    item.orderItemStatus === ORDER_ITEM_STATUS_PENDING && <input type="checkbox" id={item.uuid} onChange={handleItemsCheckbox} checked={checkedItems.includes(item.uuid)} />}

                                {/* Ordered Item Status is Accepted | Ready AND Order Status is Not Served | Delivered */}
                                {(item.orderItemStatus === ORDER_ITEM_STATUS_ACCEPTED || item.orderItemStatus === ORDER_ITEM_STATUS_READY) &&
                                    (record.orderStatus.toUpperCase() !== ORDER_STATUS_SERVED &&
                                        (record?.orderStatus).toUpperCase() !== ORDER_STATUS_DELIVERED) && <span className='background-red text-white rounded py-0 px-1 cursor' onClick={() => handleDeleteOrderItem(item)}><i className='bx bx-trash' style={{ fontSize: "11px" }} /></span>}
                            </td>
                            <td>{item?.serverName}</td>
                            <td>{item?.title}</td>
                            <td>
                                <ul className="list-group w-100">
                                    {viewItemCustomizations(item).length !== 0 && viewItemCustomizations(item).map((customization, j) =>
                                        <li className='list-group-item d-flex justify-content-between align-items-center p-1 px-2' key={i + "-" + j}>
                                            <span>{customization.title}</span>
                                            <span>{customization.price.toFixed(2) + " " + currencyDetail?.currencySymbol}</span>
                                        </li>
                                    )}
                                </ul></td>
                            <td>{item?.itemPrice.toFixed(2) + " " + currencyDetail?.currencySymbol}</td>
                            <td>{item?.quantity + " " + QUANTITY_SYMBOL}</td>
                            <td style={tablePriceCellStyle}>{(item?.itemPrice * item?.quantity).toFixed(2) + " " + currencyDetail?.currencySymbol}</td>
                        </tr>)
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={4}>
                            {(record.pointOfSell === ORDER_POINT_OF_SELL_CUSTOMER_WEB || record.pointOfSell === ORDER_POINT_OF_SELL_CUSTOMER_APP) &&
                                orderItemsStatus.length !== 0 &&
                                <>
                                    <button className='bg-success border-0 rounded py-1 px-2 align-items-baseline' id="ACCEPT_ITEMS" onClick={handleAcceptRejectOrderItems}><i className='bx bx-check' /> Accept</button>
                                    <button className='bg-danger border-0 rounded py-1 px-2 align-items-baseline text-white ms-2' id="REJECT_ITEMS" onClick={handleAcceptRejectOrderItems}><i className='bx bx-x' /> Reject</button>
                                </>
                            }
                            {/* {orderItemsStatus.length !== 0 &&
                                <>
                                    <button className='bg-success border-0 rounded py-1 px-2 align-items-baseline' id="ACCEPT_ITEMS" onClick={handleAcceptRejectOrderItems}><i className='bx bx-check' /> Accept</button>
                                    <button className='bg-danger border-0 rounded py-1 px-2 align-items-baseline text-white ms-2' id="REJECT_ITEMS" onClick={handleAcceptRejectOrderItems}><i className='bx bx-x' /> Reject</button>
                                </>
                            } */}
                        </td>
                        <td className='h6'>Item Total</td>
                        <td className='h6'>{totalItemsQty + " " + QUANTITY_SYMBOL}</td>
                        <td className='h6' style={tablePriceCellStyle}>{record?.subTotal.toFixed(2) + " " + currencyDetail?.currencySymbol}</td>
                    </tr>
                    {viewAppliedCouponCodeDtl()}
                    <tr>
                        <td colSpan={2}></td>
                        <td colSpan={3}>
                            <div className="d-flex align-items-baseline">
                                <h6 className='me-4 mb-0 text-nowrap'>Total Tax</h6>
                                <ul className="list-group w-100">
                                    {taxes.length !== 0 && taxes.map((tax, i) => {
                                        return (
                                            <li className='list-group-item d-flex justify-content-between align-items-center p-1 px-2' key={"item-taxes-" + i}>
                                                <span>{tax.taxTitle} {"( " + tax.taxPercentage.toFixed(2) + "% )"}</span>
                                                <span>{tax.taxAmount.toFixed(2) + " " + currencyDetail?.currencySymbol}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </td>
                        <td colSpan={1}></td>
                        <td colSpan={1} className='h6' style={tablePriceCellStyle}>
                            {record.totalTax && "(+)" + record.totalTax.toFixed(2) + " " + currencyDetail?.currencySymbol}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4}></td>
                        <td><h6 className='text-end'>Total Amount</h6></td>
                        <td></td>
                        <td colSpan={1} className='h6' style={tablePriceCellStyle}>
                            {record.orderTotal.toFixed(2) + " " + currencyDetail?.currencySymbol}
                        </td>
                    </tr>
                </tfoot>
            </Table>
        )
    }

    // Accept|Reject Food Order Items 
    const handleAcceptRejectOrderItems = (e) => {
        try {
            const acceptReject = e.target.id;
            if (checkedItems.length === 0) {
                toast.error(`Select at least one or more Items.`, { id: "toast" })
                return;
            }
            let rUrl = "";
            if (acceptReject === "ACCEPT_ITEMS") {
                rUrl = Accept_Order_Items_Status_Put;
            }

            if (acceptReject === "REJECT_ITEMS") {
                rUrl = Reject_Order_Items_Status_Put;
            }

            const recordObj = {
                foodOrderItemUuids: checkedItems
            }

            callPutApi(MODAL_LOADER, rUrl.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    toast.success(response.message, {id: "toast"});
                    handleRefreshOrderDtls();
                }
            })

        } catch (error) {
            return;
        }
    }

    // Delete Accepted Food Order Item
    const handleDeleteOrderItem = (row) => {
        const rUrl = Delete_Order_Item_Put.replaceAll("{recordID}", recordID + "/" + row.uuid);
        callPutApi(MODAL_LOADER, rUrl, {}, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                toast.success(response.message, {id: "toast"});
                handleRefreshOrderDtls();
                handleRefresh();
            }
        })
    }

    return (
        <ModalWrapper show={show} hide={hideModal}  backdrop="static" header={`Order Details`} modalCustomButtons={modalCustomButtons}>
            <div className="row">
                <div className="col-lg-6 my-2">
                    {viewCustomerDetails()}
                </div>
                <div className="col-lg-6 my-2">
                    {viewOrderDetails()}
                </div>
                <div className="col-lg-12 mt-3">
                    <div className='table-responsive ' style={{ height: "52vh" }}>
                        {viewOrderFoodItemsTableList()}
                    </div>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default HistoryShortOrderDetails