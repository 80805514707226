import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { Details_Project_Setting_Get, Update_Project_Setting_Put } from "api/ApiConst";
import { callGetApi, callPutApi } from "api/ApiCaller";
import {isEmpty, isNumeric } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";

const ProjectSettingAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const [record, setRecord] = useState({
        settingValue: ""
    });

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Project_Setting_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                const res = response.responsePacket;
                let recordObj = {
                    settingValue: res
                }
                setRecord(recordObj);
            });
        }
    }, [recordID]);

    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (type === "number") {
            setRecord(pre => ({ ...pre, [id]: parseFloat(value) }))
        }
        setRecord(pre => ({ ...pre, [id]: value }))
    }

    const handleSaveCMS = () => {
        try {
            if (isEmpty(record.settingValue)) {
                toast.error("Setting Value must not be empty.",{id: "toast"})
                return;
            }
            if (!isNumeric(record.settingValue)) {
                toast.error("Setting Value must be a number.",{id: "toast"})
                return;
            }
            let url = Update_Project_Setting_Put.replaceAll("{recordID}", recordID);
            callPutApi(MODAL_LOADER, url, record, {}, (response) => {
                toast.success(response.message, {id: "toast"});
                hideModal();
                handleRefresh();
            });
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`Update ${recordID} Details`} size="ms" handleSubmit={handleSaveCMS}>
            <div className="form-group col-lg-12">
                <label>Setting Value *</label>
                <input type="text" maxLength={10} className="form-control input-default" id="settingValue" onChange={handleTextChange} value={record.settingValue} />
            </div>
        </ModalWrapper>
    );
};

export default ProjectSettingAddEdit;
