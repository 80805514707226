import React, { useState } from 'react'
import ModalWrapper from './ModalWrapper'
import { isEmpty } from 'utils/TextUtils'
import toast from 'react-hot-toast'
import { callPutApi } from 'api/ApiCaller'
import { MODAL_LOADER } from 'utils/constants'
import { API_SUCCESS } from 'api/ResponseCodes'

const defaultRecordObj = {
  newPassword: ""
}
const ChangeUserPassword = ({ show, hideModal, handleRefresh, rUrl }) => {
  const [record, setRecord] = useState({ ...defaultRecordObj })

  const handleTextChange = (e) => {
    const { id, value } = e.target;
    setRecord((pre) => ({ ...pre, [id]: value }))
  }

  const checkInputValidation = () => {
    if (isEmpty(record.newPassword)) {
      toast.error("Password must not be empty.",{id: "toast"})
      return false;
    }
    return true;
  }

  const handleSubmit = () => {
    try {
      if (!checkInputValidation()) {
        return;
      }
      callPutApi(MODAL_LOADER, rUrl, record, {}, (response) => {
        if (response.errorCode === API_SUCCESS) {
          toast.success(response.message , {id: "toast"})
          hideModal();
          handleRefresh();
        } else {
          toast.error(response.message)
        }
      })
    } catch (error) {
      return;
    }
  }

  return (
    <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={``} size={"md"} handleSubmit={handleSubmit} centered>
      <div className="form-group">
        <h2 className='text-center my-2'>Change password !!</h2>
        <p className='text-center my-2'>Please enter new password for this branch user</p>
        <input type="text" className="form-control input-default" placeholder="Password here" id="newPassword" onChange={handleTextChange} value={record.newPassword} />
      </div>
    </ModalWrapper>
  )
}

export default ChangeUserPassword