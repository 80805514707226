import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
    Activate_Restaurant_Table_Put,
    All_Restaurant_Table_List_Post,
    DeActivate_Restaurant_Table_Put,
    Delete_Restaurant_Table_Delete,
    Deleted_Restaurant_Table_List_POST,
    Get_Table_Qr_Code_Post,
    Revive_Restaurant_Table_Put,
    UnMerge_Restaurant_Table_List_PUT,
} from "api/ApiConst";
import { getQRCodeImage, handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import dateUtilities from "utils/DateUtilities";

import CheckboxButton from "components/common/CheckboxButton";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import RestaurantTableAddEdit from "./RestaurantTableAddEdit";
import RestaurantTableMerge from "./RestaurantTableMerge";
import { PAGE_LOADER } from "utils/constants";
import { callPutApi } from "api/ApiCaller";
import { toast } from "react-hot-toast";
import { API_SUCCESS } from "api/ResponseCodes";
import { confirmAlert } from "react-confirm-alert";
import QrCodeImage from "components/common/QrCodeImage";

const RestaurantTableMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [showMergeModal, setShowMergeModal] = useState(false);


    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "Status",
                name: "",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active" />;
                    } else {
                        return <div className="led-red" title="Inactive" />;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                id: "tableNumber",
                name: "Table Number",
                selector: (row) => (
                    <span className="parent-owned-flag">
                        {row.tableMergedInTableUuid && (
                            <span className="badge bg-primary owned-flag" title="Merged">
                                M
                            </span>
                        )}{" "}{row.tableNumber}
                    </span>
                ),
                sortable: false,
            },
            {
                id: "qrCode",
                name: "QR Code",
                selector: (row) => <QrCodeImage rUrl={Get_Table_Qr_Code_Post.replaceAll("{recordID}", row?.qrCode)} />,
                sortable: false,
                center: true,
            },
            {
                id: "sittingCapacity",
                name: "Sitting Capacity",
                selector: (row) => row.sittingCapacity,
                sortable: false,
            },
            {
                id: "maximumCapacity",
                name: "Maximum Capacity",
                selector: (row) => row.maximumCapacity,
                sortable: false,
            },
            {
                id: "createdAt",
                name: "Created Time",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
                center: true,
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "120px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {row.uuid === row.tableMergedInTableUuid && (
                    <button className="listBtn me-2" title="Un-Merge" onClick={() => handleUnMerge(row.uuid)}>
                        <i className="bx bx-unlink" />
                    </button>
                )}
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )}
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_Restaurant_Table_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_Restaurant_Table_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    const handleUnMerge = (uuid) => {
        confirmAlert({
            title: "Un-Merge ?",
            message: "Do you want to UnMerge this Table ?",
            icon: "warning",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        callPutApi(PAGE_LOADER, UnMerge_Restaurant_Table_List_PUT.replaceAll("{recordID}", uuid), {}, {}, (response) => {
                            if (response.errorCode === API_SUCCESS) {
                                toast.success(response.message, {id: "toast"});
                                handleRefresh();
                            } else {
                                toast.error(response.message, {id: "toast"});
                            }
                        });
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_Restaurant_Table_Delete.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_Restaurant_Table_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);
    return (
        <>
            {PageHeading("Table List", "Dashboard", "/")}
            <div className="card">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <CustomButton handleClick={() => setShowMergeModal(true)} style={{ left: "150px" }} st title="Merge Tables" iconClass="bx bx-link" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_Restaurant_Table_List_POST : All_Restaurant_Table_List_Post}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showAddUpdateModal && <RestaurantTableAddEdit show={showAddUpdateModal} hideModal={() => setShowAddUpdateModal(false)} recordID={recordID} handleRefresh={handleRefresh} />}
            {showMergeModal && <RestaurantTableMerge show={showMergeModal} hideModal={() => setShowMergeModal(false)} handleRefresh={handleRefresh} />}
        </>
    );
};

export default RestaurantTableMaster;
