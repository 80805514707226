import { useState } from "react";

import { toast } from "react-hot-toast";

import { Save_Voucher_Restaurant_Post } from "api/ApiConst";
import { callPostApi } from "api/ApiCaller";
import { getTimeFromDate, isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

import ModalWrapper from "components/common/ModalWrapper";
import Select from "react-select";
import Cookies from "universal-cookie";

const defaultRecordObj = {
  voucherAmount: "",
  validTillTimeStamp: "",
  voucherPaymentType: "",
  otherVoucherPaymentType: "",
};

const paymentTypeKeyValuePair = [
  { label: "Credit & debit card", value: "Credit & debit card" },
  { label: "Cash", value: "Cash" },
  { label: "Bank Transfer", value: "Bank Transfer" },
  { label: "Cryptocurrency", value: "Cryptocurrency" },
  { label: "Mobile Wallet", value: "Mobile Wallet" },
  { label: "Other", value: "Other" },
];

const RestaurantVoucherAddEdit = (props) => {
  const currencyDetail = new Cookies().get("currencyDetail");
  const { show, hideModal, handleRefresh } = props;

  const [record, setRecord] = useState({ ...defaultRecordObj });

  //Text Change Event
  const handleTextChange = (e) => {
    const { id, value, type } = e.target;
    if (id === "voucherAmount" && value.length !== 0) {
      if (value.length > 7) {
        return;
      }
    }
    if (type === "number") {
      setRecord((prev) => ({ ...prev, [id]: parseFloat(value) }));
    } else {
      setRecord((prev) => ({ ...prev, [id]: value }));
    }
  };

  const handleSelectChange = (e) => {
    setRecord((prev) => ({ ...prev, voucherPaymentType: e }));
  };

  const checkInputValidation = () => {
    if (isEmpty(record.voucherAmount)) {
      toast.error("Amount must not be empty.", { id: "toast" });
      return false;
    } else if (record.voucherAmount < 0) {
      toast.error("Amount must not be negative", { id: "toast" });
      return false;
    } else if (isEmpty(record.validTillTimeStamp)) {
      toast.error("Expiry Date must not be empty.", { id: "toast" });
      return false;
    } else if (isEmpty(record.voucherPaymentType)) {
      toast.error("Payment Type must not be empty", { id: "toast" });
      return false;
    }

    if (record.validTillTimeStamp) {
      var currentDate = new Date();
      var validTillTimeStamp = new Date(record.validTillTimeStamp);
      if (validTillTimeStamp.getTime() < currentDate.getTime()) {
        toast.error(`Please Provide Valid Expiry Date`, { id: "toast" });
        return false;
      }
    }

    if (record.voucherPaymentType?.value === "Other") {
      if (isEmpty(record.otherVoucherPaymentType)) {
        toast.error("Other Payment Type must not be empty", { id: "toast" });
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    if (!checkInputValidation()) {
      return;
    }

    let recordObj = record;
    recordObj.validTillTimeStamp = getTimeFromDate(
      recordObj.validTillTimeStamp
    );
    recordObj.voucherPaymentType =
      record.voucherPaymentType?.value === "Other"
        ? record.otherVoucherPaymentType
        : record.voucherPaymentType?.value;
    delete recordObj.otherVoucherPaymentType;
    callPostApi(
      MODAL_LOADER,
      Save_Voucher_Restaurant_Post,
      recordObj,
      {},
      (response) => {
        if (response.errorCode === API_SUCCESS) {
          toast.success(response.message, {id: "toast"});
          hideModal();
          handleRefresh();
        } else {
          toast.error(response.message, {id: "toast"});
        }
      }
    );
  };

  return (
    <ModalWrapper
      show={show}
      backdrop="static"
      hide={hideModal}
      header={`Add Voucher`}
      size={"md"}
      handleSubmit={handleSubmit}
    >
      <div className="form-group row mb-3">
        <div className="col-lg-6 my-2">
          <label>Amount *</label>
          <div className="input-group">
            <input
              type="number"
              maxLength={7}
              className="form-control input-default"
              id="voucherAmount"
              onChange={handleTextChange}
              value={record.voucherAmount}
            />
            <div className="input-group-append">
              <span className="input-group-text bg-red">
                {currencyDetail?.currencySymbol}
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-6 my-2">
          <label>Expiry Date *</label>
          <input
            type="date"
            className="form-control input-default"
            id="validTillTimeStamp"
            onChange={handleTextChange}
            value={record.validTillTimeStamp}
          />
        </div>
        <div className="col-lg-12 my-2">
          <label>Payment Type *</label>
          <Select
            options={paymentTypeKeyValuePair}
            value={record.voucherPaymentType}
            onChange={handleSelectChange}
          />
        </div>
        {record.voucherPaymentType?.value === "Other" && (
          <div className="col-lg-12 mt-2">
            <label>Other Payment Type *</label>
            <input
              type="string"
              className="form-control input-default"
              id="otherVoucherPaymentType"
              onChange={handleTextChange}
              value={record.otherVoucherPaymentType}
            />
            <small
              className="form-text text-muted"
              style={{ fontSize: "12px" }}
            >
              *Please specify the <b>'Other'</b> payment type in the above
              field.
            </small>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

export default RestaurantVoucherAddEdit;
