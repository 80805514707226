import { useCallback, useEffect, useMemo, useState } from "react";

import { All_Client_Voucher_List_Post } from "api/ApiConst";
import dateUtilities from "utils/DateUtilities";

import VoucherAddEdit from "./VoucherAddEdit";
import SendMessage from "./SendMessage";
import PaginationDataTable from "components/common/PaginationDataTable";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";

const VoucherMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [showSendMessageModal, setShowSendMessageModal] = useState(false);

    useEffect(() => {
        if (!showSendMessageModal) {
            setRecordID(null);
        }
    }, [showSendMessageModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => <div className={row.active ? "led-green" : "led-red"} title={row.active ? "Active" : "Inactive"} />,
                width: "60px",
                sortable: false,
            },
            {
                id: "voucherCode",
                name: "Code",
                selector: (row) => row.voucherCode,
                sortable: false,
            },
            {
                id: "voucherAmount",
                name: "Amount",
                selector: (row) => row.voucherAmount,
                sortable: false,
            },
            {
                id: "voucherPaymentType",
                name: "Payment Type",
                selector: (row) => row.voucherPaymentType,
                sortable: false,
            },
            {
                id: "consumed",
                name: "Consumed",
                selector: (row) => (row.consumed ? <span className="text-success">Consumed</span> : <span className="text-danger">Not Consumed</span>),
                sortable: false,
            },
            {
                id: "transactionId",
                name: "Txn#",
                selector: (row) => (row.transactionId ? row.transactionId : "-"),
                sortable: false,
            },
            {
                id: "validTillTimeStamp",
                name: "Expiry Date",
                selector: (row) => dateUtilities.formattedDate(row.validTillTimeStamp, "dd-MM-yyyy"),
                sortable: false,
            },
            {
                id: "createdAt",
                name: "Created Time",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <li onClick={() => handleSendMessage(row)}>
                            <i className="bx bxl-messenger"></i> Send Message
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    // Send Message
    const handleSendMessage = (row) => {
        setRecordID(row.uuid);
        setShowSendMessageModal(true);
    };

    return (
        <>
            {PageHeading("Voucher List", "Dashboard", "/")}
            <div className="card searchOnlyBlock">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable paginationUrl={All_Client_Voucher_List_Post} serverSidePagination={true} search={true} pagination={false} columns={columns} />
                </div>
            </div>
            {showAddUpdateModal && <VoucherAddEdit show={showAddUpdateModal} hideModal={() => setShowAddUpdateModal(false)} handleRefresh={handleRefresh} />}
            {showSendMessageModal && <SendMessage show={showSendMessageModal} hideModal={() => setShowSendMessageModal(false)} recordID={recordID} handleRefresh={handleRefresh} />}
        </>
    );
};

export default VoucherMaster;
