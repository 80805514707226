import { callGetApi } from 'api/ApiCaller';
import { Get_Order_Count_Get } from 'api/ApiConst';
import { API_SUCCESS } from 'api/ResponseCodes';
import React from 'react'
import Cookies from 'universal-cookie';
import { ORDER_COUNT_DELAY } from 'utils/constants';

let count = 0;
const OrderCount = (props) => {
    const authToken = new Cookies().get("authToken");
    const userDataCookies = new Cookies().get("user");

    const getOrderCount = () => {
        if (authToken) {
            if (userDataCookies?.userType === "ROLE_RESTAURANT_ADMIN") {
                callGetApi("", Get_Order_Count_Get, {}, response => {
                    if (response.errorCode === API_SUCCESS) {
                        const { takeAwayInOrderCount, dineInOrderCount, deliveryInOrderCount } = response.responsePacket;
                        const totalCount = takeAwayInOrderCount + dineInOrderCount + deliveryInOrderCount;
                        const ele = document.getElementById(props?.id)

                        if (ele) {
                            if (props?.id === "order-count") {

                                ele.innerText = totalCount || 0
                            }
                            if (props?.id === "takeAwayInOrderCount") {
                                ele.innerText = takeAwayInOrderCount || 0
                            }
                            if (props?.id === "dineInOrderCount") {
                                ele.innerText = dineInOrderCount || 0
                            }
                            if (props?.id === "deliveryInOrderCount") {
                                ele.innerText = deliveryInOrderCount || 0
                            }
                        }
                    }
                })
            }
        }
    }
    (() => {
        getOrderCount()
        setInterval(() => {
            getOrderCount()
        }, ORDER_COUNT_DELAY)
    })()

    return (
        <span>{props?.title + " "} <span className=' bg-primary order-count' id={props?.id}>{" " + count}</span></span>
    )
}

export default OrderCount