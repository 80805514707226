import { callPostApi } from 'api/ApiCaller';
import { Send_Notification_Client_Post } from 'api/ApiConst';
import { API_SUCCESS } from 'api/ResponseCodes';
import ImageSelector from 'components/common/ImageSelector';
import PageHeading from 'components/common/PageHeading'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { isEmpty } from 'utils/TextUtils';
import { SECTION_LOADER } from 'utils/constants';

const notificationTargetKeyValuePairs = [
    { label: "Home Delivery", value: "HomeDelivery", key: "HomeDelivery" },
    { label: "DineIn", value: "DineIn", key: "DineIn" },
    { label: "Take Away", value: "TakeAway", key: "TakeAway" },
    { label: "Steal Deal ", value: "StealDeal ", key: "StealDeal " },
]

const NotificationMaster = () => {
    const defaultRecordObj = {
        notificationTitle: "",
        notificationTarget: "",
        notificationImageRefId: "",
        message: ""
    }

    const [record, setRecord] = useState(defaultRecordObj);

    // IMAGE CHANGE
    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, notificationImageRefId: imageRefId });
    };

    //Text Change Event
    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    //Select Text Change Event
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    const checkInputValidation = (recordObj) => {
        if (isEmpty(recordObj.notificationImageRefId)) {
            toast.error("Notification Image must not be empty.", { id: "toast" });
            return false
        } else if (isEmpty(recordObj.notificationTitle)) {
            toast.error("Notification title must not be empty", { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.notificationTarget)) {
            toast.error("Target Selection must not be empty.", { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.message)) {
            toast.error("Message must not be empty.", { id: "toast" });
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        try {
            const recordObj = {
                ...record,
                notificationTarget: record.notificationTarget?.key || "",
            }
            if (!checkInputValidation(recordObj)) {
                return;
            }
            callPostApi(SECTION_LOADER, Send_Notification_Client_Post, recordObj, {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    toast.success(response.message, {id: "toast"});
                    setRecord(defaultRecordObj);
                } else {
                    toast.error(response.message)
                }
            })
        } catch (error) {
            return;
        }
    }

    return (
        <>
            {PageHeading("Send Notification", "Dashboard", "/")}
            <div className="card">
                <div className="card-body tableView">
                    <div className="form-group row mb-3">
                        <div className="col-lg-12 my-3 cstm-obj-fit">
                            <label>Notification Image *</label>
                            <ImageSelector
                                style={{
                                    height: "120px",
                                    width: "100%",
                                    // borderRadius: "50%",
                                    overflow: "hidden",
                                }}
                                handleImageChange={handleImageChange}
                                selectedImage={record.notificationImageRefId}
                            />
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>Notification Title *</label>
                            <input type="text" className="form-control input-default" id="notificationTitle" onChange={handleTextChange} value={record.notificationTitle} />
                        </div>
                        <div className="col-lg-6 my-2">
                            <label>Select Target Selection *</label>
                            <Select options={notificationTargetKeyValuePairs} value={record.notificationTarget} onChange={(e) => handleSelectChange(e, "notificationTarget")} />
                        </div>
                        <div className="col-lg-12 my-2">
                            <label>Message *</label>
                            <textarea className="form-control input-default" id="message" cols="30" rows="5" onChange={handleTextChange} value={record.message} />
                        </div>
                    </div>
                    <Button variant="primary" className='float-end mt-0' onClick={handleSubmit}>Submit</Button>
                </div>
            </div>
        </>
    )
}

export default NotificationMaster