import { useEffect, useState } from 'react'

import { toast } from 'react-hot-toast';
import Select from 'react-select';

import { All_Client_Restaurant_Key_value_List_Post, Copy_Menu_Item_Post, Force_Copy_Menu_Item_Post, Force_Sync_Menu_Item_Post, Get_Menu_Item_List_For_Copy_Post, Get_Menu_Item_List_For_Sync_Post, Sync_Menu_Item__Post } from 'api/ApiConst';
import { callPostApi } from 'api/ApiCaller';
import { isEmpty } from 'utils/TextUtils';
import { MODAL_LOADER } from 'utils/constants';
import { API_SUCCESS } from 'api/ResponseCodes';

import ModalWrapper from 'components/common/ModalWrapper';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';


const CopySyncMenuItem = (props) => {
    const { show, hideModal, isMenuItemCopy, handleRefresh } = props;
    const { brandKeyValuePairs } = useSelector((state) => state.utility);
    const defaultRecordObj = {
        brandUuid: brandKeyValuePairs.length === 1 ? brandKeyValuePairs[0] : "",
        restaurantUuid: "",
    };

    const [record, setRecord] = useState(defaultRecordObj);
    const [restaurantKeyValuePairs, setRestaurantKeyValuePairs] = useState([])
    const [forceSync, setForceSync] = useState(false)
    const [brandItemList, setBrandItemList] = useState([])
    const [restaurantItemList, setRestaurantItemList] = useState([])
    const [viewItemsList, setViewItemsList] = useState(false)

    useEffect(() => {
        if (brandKeyValuePairs.length === 1) {
            getRestaurantKeyValuePairs(brandKeyValuePairs[0].key)
        }
    }, [])


    //Text Check Availabilities
    const handleSelectChange = (e, id) => {
        if (id === "brandUuid") {
            setViewItemsList(false)
            getRestaurantKeyValuePairs(e.key);
            setRecord((prev) => ({ ...prev, [id]: e, restaurantUuid: "" }));
        } else {
            let recordObj = { ...record, [id]: e };
            setRecord((prev) => ({ ...prev, [id]: e }));
            getMenuItemsListForCopySync(recordObj)
        }
    };

    // Get Restaurant Key-Value Pair List
    const getRestaurantKeyValuePairs = (brandUuid) => {
        const rUrl = All_Client_Restaurant_Key_value_List_Post;
        const rObj = {
            brandUuid: brandUuid,
        };
        callPostApi(null, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setRestaurantKeyValuePairs(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    }

    // get items list for copy & sync
    const getMenuItemsListForCopySync = (recordObj) => {
        const rUrl = (isMenuItemCopy ? Get_Menu_Item_List_For_Copy_Post : Get_Menu_Item_List_For_Sync_Post)
            .replaceAll("{recordID}", recordObj.brandUuid.key + "/" + recordObj.restaurantUuid.key);
        callPostApi(null, rUrl, {}, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                let res = response.responsePacket;

                // brand Item List
                let _brandCategory = res.filter(item => item.assigned === false);
                setBrandItemList(_brandCategory)

                // restaurant item list
                let _restaurantCategory = res.filter(item => item.assigned === true);

                setRestaurantItemList(_restaurantCategory)
                setViewItemsList(true)
            } else {
                toast.error(response.message);
                setViewItemsList(false)
            }
        });
    }

    // Handle Select Item
    const handleSelectItem = (rowData, selectType) => {
        if (selectType = "restaurantItemList") {
            if (forceSync) {
                return;
            }
            let _restaurantCategory = restaurantItemList.map(item => {
                if (item.key === rowData.key) {
                    return { ...item, isSelected: !(rowData.isSelected) };
                }
                return item;
            })
            setRestaurantItemList(_restaurantCategory)
        }

        if (selectType = "brandItemList") {
            let _brandCategory = brandItemList.map(item => {
                if (item.key === rowData.key) {
                    return { ...item, isSelected: !(rowData.isSelected) };
                }
                return item;
            });
            setBrandItemList(_brandCategory);
        }
    }

    //Text Check Box Change
    const handleCheckBoxChange = (e, key) => {
        const { id, checked } = e.target;
        setForceSync(pre => !pre);
    };

    // Move items Brand-to-Restaurant
    const moveBrandToRestaurant = () => {
        let _brandCategory = [...brandItemList];
        let selectedValues = _brandCategory.filter(item => item.isSelected === true).map((item) => {
            if (item.isSelected) {
                return { ...item, isSelected: false }
            }
            return item;
        });
        const unSelectedValues = _brandCategory.filter(item => !(item.isSelected));
        setBrandItemList(unSelectedValues)
        setRestaurantItemList([...restaurantItemList, ...selectedValues])

    }

    // Move items Restaurant-to-Brand
    const moveRestaurantToBrand = () => {
        let _restaurantCategory = [...restaurantItemList];
        const selectedValues = _restaurantCategory.filter(item => item.isSelected === true).map((item) => {
            if (item.isSelected) {
                return { ...item, isSelected: false }
            }
            return item;
        })
        const unSelectedValues = _restaurantCategory.filter(item => !(item.isSelected));
        setRestaurantItemList(unSelectedValues)
        setBrandItemList([...brandItemList, ...selectedValues])
    }

    // Check validation
    const checkInputValidation = (recordObj) => {
        if (isEmpty(recordObj.brandUuid)) {
            toast.error(`Brand must not be empty`,{id: "toast"});
            return false;
        }

        if (isEmpty(recordObj.restaurantUuid)) {
            toast.error(`Restaurant must not be empty`,{id: "toast"});
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        let recordObj = record;

        if (!checkInputValidation(recordObj)) {
            return;
        }

        let rUrl = "";
        let requestObj = {};

        if (forceSync) {
            rUrl = isMenuItemCopy ? Force_Copy_Menu_Item_Post : Force_Sync_Menu_Item_Post;
            requestObj["addItemIds"] = restaurantItemList.filter(res => res.assigned === false).map(res => res.key);
        } else {
            rUrl = isMenuItemCopy ? Copy_Menu_Item_Post : Sync_Menu_Item__Post;
            requestObj["addItemIds"] = restaurantItemList.filter(res => res.assigned === false).map(res => res.key);
            requestObj["removeItemIds"] = brandItemList.filter(res => res.assigned === true).map(res => res.key);
        }

        callPostApi(MODAL_LOADER, rUrl.replaceAll("{recordID}", record.restaurantUuid.key), requestObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                toast.success(response.message, {id: "toast"});
                hideModal();
                handleRefresh();
            } else {
                toast.error(response.message);
            }
        });
    }

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`${isMenuItemCopy ? "Copy" : "Sync"} Menu Item`}>
            {isMenuItemCopy ?
                <p>All the items which you are going to copy to restaurant, that can be completely controlled by restaurant, and the can do any modification for their outlet.</p> :
                <p>All the items which you are going to sync to restaurant, that can not be completely modified by restaurant, but they can change there price for there outlet.</p>
            }
            <div className="form-group row mb-3">
                <div className="col-lg-6">
                    <label>Brand *</label>
                    <Select options={brandKeyValuePairs} value={record.brandUuid} onChange={(e) => handleSelectChange(e, "brandUuid")} />
                </div>
                <div className="col-lg-6">
                    <label>Restaurant *</label>
                    <Select options={restaurantKeyValuePairs} value={record.restaurantUuid} onChange={(e) => handleSelectChange(e, "restaurantUuid")} />
                </div>
            </div>
            {viewItemsList &&
                <>
                    <div className="form-group row mb-3">
                        <div className="col-lg-5 "> {/* brand items list */}
                            <ul className='list-group list-group-flush border p-2 sync-copy-item'>
                                {brandItemList.length !== 0 && brandItemList.map((category, i) =>
                                    <li className={`${category.isSelected ? "copy-sync-select-item" : ""} list-group-item py-1 px-1 border-bottom`} onClick={() => handleSelectItem(category, "brandItemList")} key={`add-${i}`}>{category.label}</li>
                                )}
                            </ul>
                        </div>
                        <div className="col-lg-2 d-flex justify-content-center align-items-center">
                            <ul className='list-unstyled mb-0 w-100'>
                                <span className='d-block btn border mb-2 sync-copy-arrow-btn' onClick={moveBrandToRestaurant} ><li>{">"}</li></span>
                                <span className='d-block btn border sync-copy-arrow-btn' onClick={moveRestaurantToBrand} ><li>{"<"}</li></span>
                            </ul>
                        </div>
                        <div className="col-lg-5"> {/* restaurant items list */}
                            <ul className='list-group list-group-flush border p-2 sync-copy-item'>
                                {restaurantItemList.length !== 0 && restaurantItemList.map((category, i) =>
                                    <li className={`${category.isSelected ? "copy-sync-select-item" : ""} list-group-item py-1 px-1 border-bottom`} onClick={() => handleSelectItem(category, "restaurantItemList")} key={`remove-${i}`}>{category.label}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                </>
            }
            <div className="form-group row mb-0">
                <div className="col-lg-12">
                    <div className="d-flex justify-content-between">
                        <div>
                            <input type="checkbox" className="input-default me-2" id="forceSync" onChange={handleCheckBoxChange} checked={forceSync} />
                            <label htmlFor='forceSync'>Force {isMenuItemCopy ? "Copy" : "Sync"}</label>
                            {isMenuItemCopy ?
                                <div className="h6 text-secondary info-msg">*if enabled, this will first delete every thing, then create new data. otherwise it will skip that data, which is already copied.</div> :
                                <div className="h6 text-secondary info-msg">*if enabled, this will first delete every thing, then create new data. otherwise it will skip that data, which is already synched</div>
                            }
                        </div>
                        <div>
                            <Button variant="primary" onClick={handleSubmit}>Submit</Button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default CopySyncMenuItem