import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { Add_UserAdmin_POST, Get_UserAdmin_Detail_GET, Update_UserAdmin_PUT } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { getDOB, getTimeFromDate, isEmail, isEmpty, isMobile, isValidIsdCode } from "utils/TextUtils";

import ImageSelector from "components/common/ImageSelector";
import ModalWrapper from "components/common/ModalWrapper";
import { MODAL_LOADER } from "utils/constants";

const UserAdminAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const defaultRecordObj = {
        firstName: "",
        lastName: "",
        gender: "Male",
        dateOfBirthTimeStamp: "",
        mobile: "",
        isdCode: "+91",
        email: "",
        username: "",
        password: "",
        photoImageRefId: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Get_UserAdmin_Detail_GET.replaceAll("{recordID}", recordID), {}, (response) => {
                const res = response.responsePacket;
                res.dateOfBirthTimeStamp = getDOB(res.dateOfBirthTimeStamp);
                setRecord(res);
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        if (e.target.id === "mobile") {
            if (e.target.value.length > 10) {
                return;
            }
        }
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    // Image change
    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, photoImageRefId: imageRefId });
    };

    const checkInputValidation = () => {
        if (isEmpty(record.photoImageRefId)) {
            toast.error(`Image must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(record.firstName)) {
            toast.error(`First name must not be empty`,{id: "toast"});
            return false;
        } else if (record.firstName.length > 200) {
            toast.error(`First name must not be more then 200 character.`,{id: "toast"});
            return false;
        } else if (isEmpty(record.lastName)) {
            toast.error(`Last name must not be empty`,{id: "toast"});
            return false;
        } else if (record.lastName.length > 200) {
            toast.error(`Last name must not be more then 200 character.`,{id: "toast"});
            return false;
        } else if (isEmpty(record.isdCode)) {
            toast.error(`ISD Code must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(record.mobile)) {
            toast.error(`Mobile must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(record.email)) {
            toast.error(`Email must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(record.dateOfBirthTimeStamp)) {
            toast.error(`Date Of Birth must not be empty`,{id: "toast"});
            return false;
        }

        if (!recordID) {
            if (isEmpty(record.username)) {
                toast.error(`Username must not be empty`,{id: "toast"});
                return false;
            }
            if (isEmpty(record.password)) {
                toast.error(`Password must not be empty`,{id: "toast"});
                return false;
            }
        }

        if (record.isdCode) {
            if (!isValidIsdCode(record.isdCode)) {
                toast.error(`Please Provide Valid ISD Code`,{id: "toast"});
                return false;
            }
        }

        if (record.mobile) {
            if (!isMobile(record.mobile)) {
                toast.error(`Please Provide Valid Mobile Number`,{id: "toast"});
                return false;
            }
            // let mobileRegEx = /^(0|91)?[6-9][0-9]{9}$/;
            // if (!mobileRegEx.test(record.mobile)) {
            //     toast.error(`Please Provide Valid Mobile Number`,{id: "toast"});
            //     return false;
            // }
        }
        if (record.email) {
            if (!isEmail(record.email)) {
                toast.error(`Please Provide Valid Email`,{id: "toast"});
                return false;
            }
            // let emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // if (!emailRegEx.test(record.email)) {
            //     toast.error(`Please Provide Valid Email`,{id: "toast"});
            //     return false;
            // }
        }
        if (record.dateOfBirthTimeStamp) {
            var currentDate = new Date();
            var dobDate = new Date(record.dateOfBirthTimeStamp);
            if (dobDate.getTime() > currentDate.getTime()) {
                toast.error(`Please Provide Valid date Of Birth`,{id: "toast"});
                return false;
            }
        }

        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            recordObj.dateOfBirthTimeStamp = getTimeFromDate(recordObj.dateOfBirthTimeStamp);
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Add_UserAdmin_POST, recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(MODAL_LOADER, Update_UserAdmin_PUT.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal}  backdrop="static" header={`${recordID ? "Update" : "Add"} User Admin`} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <ImageSelector
                        style={{
                            height: "120px",
                            width: "120px",
                            borderRadius: "50%",
                            overflow: "hidden",
                        }}
                        user={true}
                        handleImageChange={handleImageChange}
                        selectedImage={record.photoImageRefId}
                    />
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-lg-6">
                    <label>First Name *</label>
                    <input type="text" className="form-control input-default" id="firstName" onChange={handleTextChange} value={record.firstName} />
                </div>
                <div className="col-lg-6">
                    <label>Last Name *</label>
                    <input type="text" className="form-control input-default" id="lastName" onChange={handleTextChange} value={record.lastName} />
                </div>
            </div>
            {!recordID && (
                <div className="form-group row mb-3">
                    <div className="col-lg-6">
                        <label>Username *</label>
                        <input type="text" className="form-control input-default" id="username" onChange={handleTextChange} value={record.username} />
                    </div>
                    <div className="col-lg-6">
                        <label>Password *</label>
                        <input type="password" className="form-control input-default" id="password" onChange={handleTextChange} value={record.password} />
                    </div>
                </div>
            )}
            <div className="form-group row mb-3">
                <div className="col-lg-6">
                    <div className="row g-1">
                        <div className="col-4">
                            <label>ISD Code *</label>
                            <input type="text" className="form-control input-default" id="isdCode" onChange={handleTextChange} value={record.isdCode} />
                        </div>
                        <div className="col-8">
                            <label>Mobile *</label>
                            <input type="number" className="form-control input-default" id="mobile" onChange={handleTextChange} value={record.mobile} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <label>Email *</label>
                    <input type="email" className="form-control input-default" id="email" onChange={handleTextChange} value={record.email} />
                </div>
            </div>
            <div className="form-group mb-3 row">
                <div className="col-lg-6">
                    <label className="d-block">Gender</label>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="male"
                            defaultValue="male"
                            checked={record.gender?.toLowerCase() === "male"}
                            onChange={(e) => setRecord({ ...record, gender: "Male" })}
                        />
                        <label className="form-check-label" htmlFor="male">
                            Male
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="female"
                            defaultValue="female"
                            checked={record.gender?.toLowerCase() === "female"}
                            onChange={(e) => setRecord({ ...record, gender: "Female" })}
                        />
                        <label className="form-check-label" htmlFor="female">
                            Female
                        </label>
                    </div>
                </div>
                <div className="col-lg-6">
                    <label>Date Of Birth *</label>
                    <input className="form-control" type="date" id="dateOfBirthTimeStamp" value={record.dateOfBirthTimeStamp} onChange={handleTextChange} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default UserAdminAddEdit;
