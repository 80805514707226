import React, { useState, useCallback, useMemo, useContext } from "react";

import {
    Activate_UserCustomer_PUT,
    All_UserCustomer_List_POST,
    Change_UserCustomer_VIP_Status_PUT,
    DeActivate_UserCustomer_PUT,
    Delete_UserCustomer_DELETE,
    Deleted_UserCustomer_List_POST,
    Revive_UserCustomer_PUT,
} from "api/ApiConst";
import { handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";

import CheckboxButton from "components/common/CheckboxButton";
import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import { Link } from "react-router-dom";
import dateUtilities from "utils/DateUtilities";
import { callPutApi } from "api/ApiCaller";
import { API_SUCCESS } from "api/ResponseCodes";
import { toast } from "react-hot-toast";
import { SECTION_LOADER } from "utils/constants";

const UserCustomerMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => (
                    <div className={row.active ? "led-green" : "led-red"} title={row.active ? "Active" : "Inactive"} />
                ),
                width: "60px",
                sortable: false,
            },
            {
                id: "name",
                name: "Name",
                selector: (row) => (
                    <div className="d-flex align-items-center">
                        <Link to={"details?rId=" + row.uuid}>{row.firstName + " " + row?.lastName}</Link>
                        {row.vipUser && <i className="bx bxs-star ms-1" title="VIP User" style={{ color: "orange", fontSize: "15px" }} />}
                    </div>
                ),
                sortable: false,
            },
            {
                id: "userName",
                name: "User Name",
                selector: (row) => row.username,
                sortable: false,
            },
            {
                id: "mobile",
                name: "Mobile No.",
                selector: (row) => row.isdCode + " " + row.mobile,
                sortable: false,
            },
            {
                id: "email",
                name: "Email",
                selector: (row) => row.email,
                sortable: false,
            },
            {
                id: "registeredAt",
                name: "Registered Time",
                selector: (row) => <div className="ws-initial">{dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa")}</div>,
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => <div className="custom-column-action">{getActionButtons(row)}</div>,
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                    <li onClick={() => handleChangeVipStatus(row)}>
                                        {row.vipUser ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Remove VIP
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Make VIP
                                            </>
                                        )}
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_UserCustomer_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_UserCustomer_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_UserCustomer_DELETE.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_UserCustomer_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleChangeVipStatus = (row) => {
        callPutApi(SECTION_LOADER, Change_UserCustomer_VIP_Status_PUT.replaceAll("{recordID}", row.uuid), {}, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                toast.success(response.message, {id: "toast"});
                handleRefresh();
            } else {
                toast.error(response.message, {id: "toast"});
            }
        });
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("User Customer List", "Dashboard", "/")}
            <div className="card">
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_UserCustomer_List_POST : All_UserCustomer_List_POST}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
        </>
    );
};

export default UserCustomerMaster;
