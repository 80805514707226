import { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import Select from "react-select";

import {
  Add_Restaurant_Delivery_Offer_POST,
  Get_Restaurant_Delivery_Offer_Detail_GET,
  Update_Restaurant_Delivery_Offer_PUT,
} from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";
import Cookies from "universal-cookie";

const DeliveryOfferAddEdit = ({
  show,
  hideModal,
  recordID,
  handleRefresh,
  itemKeyValuePairList,
}) => {
  const defaultRecordObj = {
    alertMinAmountRange: "",
    alertMaxAmountRange: "",
    minOrderValue: "",
    freeItemUuid: "",
    availabilities: [],
    freeItemQty: "",
  };
  const [record, setRecord] = useState(defaultRecordObj);
  const [availabilities, setAvailabilities] = useState({
    HomeDelivery: false,
    TakeAway: false,
  });

  const currencyDetail = new Cookies().get("currencyDetail");

  useEffect(() => {
    if (recordID) {
      callGetApi(
        MODAL_LOADER,
        Get_Restaurant_Delivery_Offer_Detail_GET.replaceAll(
          "{recordID}",
          recordID
        ),
        {},
        (response) => {
          const res = response.responsePacket;
          // Set Offer Availabilities
          for (let i = 0; i < res.availabilities.length; i++) {
            const availabilitiesKey = res.availabilities[i];
            if (availabilitiesKey) {
              setAvailabilities((prev) => ({
                ...prev,
                [availabilitiesKey]: true,
              }));
            }
          }

          // set free Item key Value Pair
          const _freeItemUuid = itemKeyValuePairList.find(
            (obj) => obj.key === res.freeItemUuid
          );
          const recordObj = {
            ...res,
            freeItemUuid: _freeItemUuid || "",
          };
          setRecord(recordObj);
        }
      );
    }
  }, [recordID]);

  //Text Change Event
  const handleTextChange = (e) => {
    const { id, type, value } = e.target;
    if (id === "alertMinAmountRange") {
      if (value.length > 7) {
        return;
      }
    }
    if (id === "alertMaxAmountRange") {
      if (value.length > 7) {
        return;
      }
    }
    if (id === "minOrderValue") {
      if (value.length > 7) {
        return;
      }
    }
    if (type === "number") {
      if (id === "freeItemQty") {
        setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
      } else {
        setRecord((prev) => ({ ...prev, [id]: parseFloat(value) }));
      }
      return;
    }
    setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  // Checkbox check/uncheck
  const handleCheckBoxChange = (e) => {
    const { id, checked } = e.target;
    if (id === "All") {
      const _availabilities = { ...availabilities };
      for (const key in _availabilities) {
        _availabilities[key] = true;
      }
      setAvailabilities({ ..._availabilities });
    } else {
      setAvailabilities((prev) => ({ ...prev, [id]: checked }));
    }
  };

  // Select change
  const handleSelectChange = (e) => {
    setRecord({ ...record, freeItemUuid: e });
  };

  const checkInputValidation = (recordObj) => {
    if (isEmpty(recordObj.alertMinAmountRange)) {
      toast.error(`Min Amount must not be empty`);
      return false;
    } else if (recordObj.alertMinAmountRange < 0) {
      toast.error(`Min Amount must not be negative.`);
      return false;
    } else if (isEmpty(recordObj.alertMaxAmountRange)) {
      toast.error(`Max Amount must not be empty`);
      return false;
    } else if (recordObj.alertMaxAmountRange < 0) {
      toast.error(`Max Amount must not be negative.`);
      return false;
    } else if (recordObj.alertMinAmountRange > recordObj.alertMaxAmountRange) {
      toast.error(`Min Amount must not be more then Max Amount.`);
      return false;
    } else if (isEmpty(recordObj.minOrderValue)) {
      toast.error(`Min Order Value must not be empty`);
      return false;
    } else if (recordObj.minOrderValue < 0) {
      toast.error(`Min Order Value must not be negative.`);
      return false;
    } else if (recordObj.alertMaxAmountRange > recordObj.minOrderValue) {
      toast.error(`Min Order Value must be more then Max Amount.`);
      return false;
    } else if (recordObj.availabilities.length === 0) {
      toast.error(`Availabilities must not be empty`);
      return false;
    } else if (isEmpty(recordObj.freeItemUuid)) {
      toast.error(`Please Select Item.`);
      return false;
    } else if (isEmpty(recordObj.freeItemQty)) {
      toast.error(`Item Quantity must not be empty.`);
      return false;
    }
    return true;
  };

  //Submit Employee Form
  const handleSubmit = async () => {
    try {
      let _availabilities = [];
      for (let key in availabilities) {
        if (availabilities[key]) {
          _availabilities.push(key);
        }
      }
      let recordObj = {
        ...record,
        freeItemUuid: record.freeItemUuid.key || "",
        availabilities: _availabilities,
      };

      if (!checkInputValidation(recordObj)) {
        return;
      }

      if (!recordID || recordID === "") {
        callPostApi(
          MODAL_LOADER,
          Add_Restaurant_Delivery_Offer_POST,
          recordObj,
          {},
          (response) => {
            toast.success(response.message, {id: "toast"});
            hideModal();
            handleRefresh();
          }
        );
      } else {
        callPutApi(
          MODAL_LOADER,
          Update_Restaurant_Delivery_Offer_PUT.replaceAll(
            "{recordID}",
            recordID
          ),
          recordObj,
          {},
          (response) => {
            toast.success(response.message, {id: "toast"});
            hideModal();
            handleRefresh();
          }
        );
      }
    } catch (error) {
      return;
    }
  };

  return (
    <ModalWrapper
      show={show}
      hide={hideModal}
      backdrop="static"
      header={`${recordID ? "Update" : "Add"} Delivery Offer`}
      handleSubmit={handleSubmit}
    >
      <div className="form-group row mb-3">
        <div className="col-lg-4">
          <label>Alert Min Amount *</label>
          <div className="input-group">
            <input
              type="number"
              className="form-control input-default"
              id="alertMinAmountRange"
              onChange={handleTextChange}
              value={record.alertMinAmountRange}
            />
            <div className="input-group-append">
              <span className="input-group-text bg-red">
                {currencyDetail?.currencySymbol}
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <label>Alert Max Amount *</label>
          <div className="input-group">
            <input
              type="number"
              className="form-control input-default"
              id="alertMaxAmountRange"
              onChange={handleTextChange}
              value={record.alertMaxAmountRange}
            />
            <div className="input-group-append">
              <span className="input-group-text bg-red">
                {currencyDetail?.currencySymbol}
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <label>Min Order Value *</label>
          <div className="input-group">
            <input
              type="number"
              className="form-control input-default"
              id="minOrderValue"
              onChange={handleTextChange}
              value={record.minOrderValue}
            />
            <div className="input-group-append">
              <span className="input-group-text bg-red">
                {currencyDetail?.currencySymbol}
              </span>
            </div>
          </div>
        </div>
      </div>
      <fieldset className="my-4">
        <legend>Availabilities *</legend>
        <div className="row ">
          <div className="col-lg-3">
            <input
              type="checkbox"
              className="input-default me-2"
              id="All"
              onChange={handleCheckBoxChange}
              checked={availabilities.HomeDelivery && availabilities.TakeAway}
            />
            <label htmlFor="All">All </label>
          </div>
          <div className="col-lg-3">
            <input
              type="checkbox"
              className="input-default me-2"
              id="HomeDelivery"
              onChange={handleCheckBoxChange}
              checked={availabilities?.HomeDelivery}
            />
            <label htmlFor="HomeDelivery">Delivery </label>
          </div>
          <div className="col-lg-3">
            <input
              type="checkbox"
              className="input-default me-2"
              id="TakeAway"
              onChange={handleCheckBoxChange}
              checked={availabilities?.TakeAway}
            />
            <label htmlFor="TakeAway">Takeaway</label>
          </div>
        </div>
      </fieldset>
      <div className="form-group row mb-3">
        <div className="col-lg-8">
          <label>Select Item *</label>
          <Select
            options={itemKeyValuePairList}
            value={record.freeItemUuid}
            onChange={handleSelectChange}
          />
        </div>
        <div className="col-lg-4">
          <label>Quantity *</label>
          <input
            type="number"
            className="form-control input-default"
            id="freeItemQty"
            onChange={handleTextChange}
            value={record.freeItemQty}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DeliveryOfferAddEdit;
