import React from "react";
import { Button } from "react-bootstrap";
import { updateRestaurantDetails } from "./restaurantUtils";

export const RestaurantDetails = ({ handleTextChange, record, recordID }) => {
    const handleSaveChange = () => {
        let rObj = {
            orderPrefix: record.orderPrefix ? record.orderPrefix : "",
            restaurantTitle: record.restaurantTitle,
            restaurantClosedMessage: record.restaurantClosedMessage,
            restaurantDescription: record.restaurantDescription,
        };
        updateRestaurantDetails(recordID, rObj);
    };

    return (
        <>
            <fieldset className="my-3">
                <legend>Restaurant Details</legend>
                <div className="form-group row mb-3">
                    <div className="col-lg-6 my-2">
                        <label>OrderId Prefix </label>
                        <input type="text" maxLength={20} className="form-control input-default" id="orderPrefix" onChange={handleTextChange} value={record.orderPrefix} />
                    </div>
                    <div className="col-lg-6 my-2">
                        <label>Title </label>
                        <input type="text" className="form-control input-default" id="restaurantTitle" onChange={handleTextChange} value={record.restaurantTitle} />
                    </div>
                    <div className="col-lg-12 my-2">
                        <label>Restaurant Closed Message *</label>
                        <textarea className="form-control input-default" id="restaurantClosedMessage" onChange={handleTextChange} value={record.restaurantClosedMessage} />
                    </div>
                    <div className="col-lg-12 my-2">
                        <label>Description </label>
                        <textarea className="form-control input-default" id="restaurantDescription" onChange={handleTextChange} value={record.restaurantDescription} />
                    </div>
                </div>
                <Button className="mb-3 float-end" onClick={handleSaveChange} variant="primary">
                    Save Changes
                </Button>
            </fieldset>
        </>
    );
};

export default RestaurantDetails;
