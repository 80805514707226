import { useCallback, useEffect, useMemo, useState } from 'react'
import Select from "react-select";
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { All_History_Order_List_Post, Restaurant_Availability_List_Post } from 'api/ApiConst'

import { getOrderedCustomerDetails, insertSpaceInKeyDirect } from 'utils/TextUtils'
import dateUtilities from 'utils/DateUtilities'
import PageHeading from 'components/common/PageHeading'
import PaginationDataTable from 'components/common/PaginationDataTable'
import Cookies from 'universal-cookie'
import CustomButton from 'components/common/CustomButton'
import HistoryShortOrderDetails from './common/HistoryShortOrderDetails';
import { callPostApi } from 'api/ApiCaller';
import { SECTION_LOADER } from 'utils/constants';
import { API_SUCCESS } from 'api/ResponseCodes';

const RestaurantOrderHistory = () => {

    const currentDate = new Date();
    // Calculate the starting date of the last 8 days
    const startDateTimeStamp = new Date(currentDate);
    startDateTimeStamp.setDate(currentDate.getDate() - 5);
    startDateTimeStamp.setHours(0, 0, 0, 0);

    const endDateTimeStamp = new Date(currentDate);
    endDateTimeStamp.setHours(0, 0, 0, 0);
    endDateTimeStamp.setDate(endDateTimeStamp.getDate());
    endDateTimeStamp.setHours(23, 59, 59, 999);

    const defaultObj = {
        startDateTimeStamp: startDateTimeStamp.getTime(),
        endDateTimeStamp: endDateTimeStamp.getTime(),
        orderType: { label: "Dine In", value: "DineIn" }
    }

    const [record, setRecord] = useState({ ...defaultObj });
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [recordID, setRecordID] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showApplyCouponModal, setShowApplyCouponModal] = useState(false)
    const [showComplementaryModal, setShowComplementaryModal] = useState(false);
    const [showSettleBillModal, setShowSettleBillModal] = useState(false);
    const [refreshOrderDtls, setRefreshOrderDtls] = useState(0);
    const [selectedOrderDtls, setSelectedOrderDtls] = useState(null)
    const [recordObj, setRecordObj] = useState(defaultObj);
    const [orderTypeKeyValuePairList, setOrderTypeKeyValuePairList] = useState();

    const navigate = useNavigate();
    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: 160, // Set the desired width
        }),
    };
    const orderType = record.orderType.value;

    const currencyDetail = new Cookies().get("currencyDetail");

    useEffect(() => {
        if (!showDetailsModal && !showApplyCouponModal && !showComplementaryModal && !showSettleBillModal) {
            setRecordID(null);
            setSelectedOrderDtls(null)
            setRefreshOrderDtls(0);
            handleRefresh();
        }
    }, [showDetailsModal, showApplyCouponModal, showComplementaryModal, showSettleBillModal]);

    useEffect(() => {
        getOrderTypeKeyValuePairList();
    }, [])

    const getOrderTypeKeyValuePairList = () => {
        let rUrl = Restaurant_Availability_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            const res = response.responsePacket
            if (response.errorCode === API_SUCCESS) {
                const options = res.map((data) => ({
                    key: data?.key,
                    value: data?.value,
                    label: data?.label,
                }));
                setOrderTypeKeyValuePairList(options);
            } else {
                toast.error(response.message);
            }
        });
    }
    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const handleRefreshOrderDtls = useCallback(() => {
        setRefreshOrderDtls(refreshOrderDtls + 1)
    }, [refreshOrderDtls])

    const handleHistoryViewDetails = (row) => {
        // Open HistoryOrderDetails in a new tab
        const url = `/history-order-details/${row.uuid}`;
        window.open(url, "_blank");
    };

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => <div className={row.active ? "led-green" : "led-red"} title={row.active ? "Active" : "Inactive"} />,
                width: "60px",
                sortable: false,
            },
            {
                id: "orderId",
                name: "Invoice No.",
                selector: (row) =>
                    <a href={`/history-order-details/${row.uuid}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                            e.preventDefault();
                            handleHistoryViewDetails(row);
                        }}
                    >
                        {row?.restaurantOrderNumber && row?.restaurantOrderNumber ? 
                    row?.restaurantOrderNumber:row.orderId}
                    </a>,
                sortable: false,
            },
            {
                id: "customer",
                name: "Customer Details",
                selector: (row) => getOrderedCustomerDetails(row),
                sortable: false,
                wrap: true,
                // grow: 2,

            },
            {
                id: "createdAt",
                name: "Order Time",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
                width: "180px",
            },
            {
                id: "orderSettledDateTimeStamp",
                name: "Settlement Time",
                selector: (row) => dateUtilities.formattedDate(row.orderedAtTimeStamp, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
                width: "150px"
            },

            {
                id: "orderTotal",
                name: "Total Amount",
                selector: (row) => row.orderTotal.toFixed(2) + " " + currencyDetail?.currencySymbol,
                sortable: false,
                right: true,
            },
            {
                id: "orderStatus",
                name: "Status",
                selector: (row) => insertSpaceInKeyDirect(row.orderStatus),
                sortable: false,
                center: true,
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                // width: "80px",
                sortable: false,
                right: true
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <>
                        <button className="listBtn me-2" onClick={() => handleViewDetails(row)}>
                            <i className="mdi mdi-eye-outline" />
                        </button>
                        {/* {getOrderStatusUpdateBtn(row)} */}
                    </>
                )}

            </div>
        );
    };

    // Select function
    const handleSelectOrderTypeChange = (e, id) => {
        setRecord((prev) => ({ ...prev, orderType: e }));
        // setOrderAvailability(e)
    };

    // view order details
    const handleViewDetails = (row) => {
        setRecordID(row.uuid);
        setShowDetailsModal(true);
        // getOrderCouponCodeList(row.uuid);
    }

    // Toggle Apply New Coupon Modal Box
    const toggleApplyCouponModal = () => {
        // setShowDetailsModal(!showDetailsModal);
        setShowApplyCouponModal(!showApplyCouponModal)
    }

    // Toggle Complementary Head Modal Box
    const toggleShowComplementaryHeadModal = () => {
        setShowDetailsModal(!showDetailsModal);
        setShowComplementaryModal(!showComplementaryModal)
    }

    // Toggle Settle Bill Modal Box
    const toggleSettleBillModal = () => {
        setShowDetailsModal(!showDetailsModal);
        setShowSettleBillModal(!showSettleBillModal)
    }

    // handle Value Changed
    const handleTextChange = (e) => {
        const { id, value } = e.target;
        setRecord((prevRecord) => ({ ...prevRecord, [id]: value, }));
    }

    const handleGetReport = () => {
        const startDateTimeStamp = document.getElementById('startDateTimeStamp').value;
        const endDateTimeStamp = document.getElementById('endDateTimeStamp').value;

        if (!startDateTimeStamp || !endDateTimeStamp || !record.orderType) {
            toast.error('Please fill in both start and end date fields and select an order type.', { id: "toast" });
            return;
        }

        // Calculate the difference in days
        const startDate = new Date(startDateTimeStamp);
        const endDate = new Date(endDateTimeStamp);
        const dayDifference = Math.floor((endDate - startDate) / (24 * 60 * 60 * 1000));

        if (dayDifference <= 31) {
            setRecordObj({
                startDateTimeStamp: new Date(startDateTimeStamp).getTime(),
                endDateTimeStamp: new Date(endDateTimeStamp).getTime(),
                orderType: record.orderType, // Preserve the orderType
            });
        } else {
            toast.error("Selected days cannot be more than 31 days");
        }
    };

    return (
        <>
            {PageHeading("History Orders List", "Dashboard", "/")}
            <div className="card searchOnlyBlock">
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={All_History_Order_List_Post
                            .replaceAll("{orderType}", orderType)
                            .replaceAll("{startDateTimeStamp}", recordObj.startDateTimeStamp)
                            .replaceAll("{endDateTimeStamp}", recordObj.endDateTimeStamp)
                        }
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                </div>
                <div className=" createButton">
                    <div className="d-flex">
                        <Select
                            className="me-2"
                            maxMenuHeight={150}
                            styles={customStyles}
                            options={orderTypeKeyValuePairList}
                            value={record.orderType}
                            onChange={(e) => handleSelectOrderTypeChange(e)}
                        />
                        <div className="d-flex align-items-center me-3   ">
                            <div className="me-2">
                                <label>Start Date:</label>
                            </div>
                            <div className="">
                                <input
                                    type="date"
                                    className="form-control ms-1"
                                    id="startDateTimeStamp"
                                    onChange={handleTextChange}
                                    value={record.startDateTimeStamp}
                                />
                            </div>
                        </div>
                        <div className="d-flex align-items-center me-3  ">
                            <div className="me-2">
                                <label>End Date:</label>
                            </div>
                            <div className="">
                                <input
                                    type="date"
                                    className="form-control ms-1"
                                    id="endDateTimeStamp"
                                    onChange={handleTextChange}
                                    value={record.endDateTimeStamp}
                                />
                            </div>
                        </div>
                        <div className="">
                            <CustomButton
                                title="Get Report"
                                buttonClass="bg-success"
                                handleClick={handleGetReport} />
                        </div>
                    </div>
                </div>
            </div>
            {showDetailsModal && <HistoryShortOrderDetails
                show={showDetailsModal}
                hideModal={() => setShowDetailsModal(false)}
                recordID={recordID}
                toggleApplyCouponModal={toggleApplyCouponModal}
                toggleShowComplementaryHeadModal={toggleShowComplementaryHeadModal}
                toggleSettleBillModal={toggleSettleBillModal}
                // handleRemoveApplyCoupon={handleRemoveApplyCoupon}
                handleRefreshOrderDtls={handleRefreshOrderDtls}
                refreshOrderDtls={refreshOrderDtls}
                handleRefresh={handleRefresh}
                setSelectedOrderDtls={setSelectedOrderDtls}
            />}
            {/* {showApplyCouponModal && <ApplyNewCoupon show={showApplyCouponModal} hideModal={toggleApplyCouponModal} recordID={recordID} handleRemoveApplyCoupon={handleRemoveApplyCoupon} handleRefreshOrderDtls={handleRefreshOrderDtls} handleRefresh={handleRefresh} />}
            {showComplementaryModal && <MarkComplementaryHead show={showComplementaryModal} hideModal={toggleShowComplementaryHeadModal} recordID={recordID} complementaryHeadKeyPairList={complementaryHeadKeyPairList} handleRefreshOrderDtls={handleRefreshOrderDtls} handleRefresh={handleRefresh} />}
            {showSettleBillModal && <SettleBill show={showSettleBillModal} hideModal={toggleSettleBillModal} recordID={recordID} handleRefresh={handleRefresh} selectedOrderDtls={selectedOrderDtls} handleRefreshOrderDtls={handleRefreshOrderDtls} />} */}
        </>
    )
}

export default RestaurantOrderHistory