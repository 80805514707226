import React, { useState } from "react";

import { toast } from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Admin_Login_POST,
  Client_Login_POST,
  Restaurant_Login_POST,
} from "api/ApiConst";
import { callPostApi } from "api/ApiCaller";
import {
  ADMIN_LOGIN,
  CLIENT_LOGIN,
  PAGE_LOADER,
  RESTAURANT_LOGIN,
} from "utils/constants";
import { LOGIN } from "reducer/actions";

import Loader from "components/common/Loader";
import { isAlphaNumercSpecialChar } from "utils/TextUtils";

const loginAsOptions = [ADMIN_LOGIN, CLIENT_LOGIN, RESTAURANT_LOGIN];

const Login = () => {
  const { showPageLoader } = useSelector((state) => state.utility);
  let location = useLocation();
  const dispatch = useDispatch();

  const [userDetails, setUserDetails] = useState({
    username: "",
    password: "",
    deviceId: "",
    deviceToken: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loginAs, setLoginAs] = useState(ADMIN_LOGIN);

  const handleLogin = (e) => {
    e.preventDefault();

    if (userDetails.username === "" && userDetails.password === "") {
      toast.error("Please enter username and password", { id: "toast" });
      return;
    }
    if (userDetails.username === "") {
      toast.error("Please Enter Username", { id: "toast" });
      return;
    }
    if (userDetails.username.length < 6) {
      toast.error("Username must be more than 5 characters", { id: "toast" });
      return;
    }
    if (!isAlphaNumercSpecialChar(userDetails.username)) {
      toast.error(
        "User name must contain letters, numbers, or special characters.",
        { id: "toast" }
      );
      return;
    }
    if (userDetails.password === "") {
      toast.error("Please Enter Password", { id: "toast" });
      return;
    }
    if (!isAlphaNumercSpecialChar(userDetails.password)) {
      toast.error(
        "password  must contain letters, numbers, or special characters.",
        { id: "toast" }
      );
      return;
    }
    // const url = loginAs === ADMIN_LOGIN ? Admin_Login_POST : loginAs === CLIENT_LOGIN ? Client_Login_POST : Restaurant_Login_POST;
    const url =
      location.pathname === "/al"
        ? Admin_Login_POST
        : location.pathname === "/clientLogin"
        ? Client_Login_POST
        : Restaurant_Login_POST;
    callPostApi(PAGE_LOADER, url, userDetails, {}, (response) => {
      dispatch({ type: LOGIN, payload: response.responsePacket });
    });
  };

  return (
    <>
      {showPageLoader ? (
        <Loader />
      ) : (
        <div className="account-pages">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <div className="row">
                      <div className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to Skote.</p>
                        </div>
                      </div>
                      <div className="col-5 align-self-end">
                        <img
                          src="/assets/images/profile-img.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src="/assets/images/logo-light.svg"
                              alt=""
                              className="rounded-circle"
                              height={34}
                            />
                          </span>
                        </div>
                      </Link>
                      {/* <Link to="/" className="auth-logo-dark">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src="/assets/images/logo.svg" alt="" className="rounded-circle" height={34} />
                                                    </span>
                                                </div>
                                            </Link> */}
                    </div>
                    <div className="p-2">
                      <form className="form-horizontal">
                        <div className="mb-3">
                          <label htmlFor="username" className="form-label">
                            Username
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength={30}
                            id="username"
                            placeholder="Enter username"
                            value={userDetails.username}
                            onChange={(e) =>
                              setUserDetails({
                                ...userDetails,
                                username: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Password</label>
                          <div className="input-group auth-pass-inputgroup">
                            <input
                              type={showPassword ? "text" : "password"}
                              maxLength={16}
                              className="form-control"
                              placeholder="Enter password"
                              value={userDetails.password}
                              onChange={(e) =>
                                setUserDetails({
                                  ...userDetails,
                                  password: e.target.value,
                                })
                              }
                            />
                            <button
                              className="btn btn-light "
                              type="button"
                              id="password-addon"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <i className="mdi mdi-eye-off-outline" />
                              ) : (
                                <i className="mdi mdi-eye-outline" />
                              )}
                            </button>
                          </div>
                        </div>
                        {/* <div className="mb-3">
                                                    <label className="form-label">Login As</label>
                                                    <div className="d-flex">
                                                        {loginAsOptions.map((userType) => (
                                                            <div className="form-check me-3">
                                                                <input
                                                                    key={userType}
                                                                    type="radio"
                                                                    name="loginAs"
                                                                    id={userType}
                                                                    className="form-check-input"
                                                                    checked={loginAs === userType}
                                                                    onChange={() => setLoginAs(userType)}
                                                                />
                                                                <label htmlFor={userType}>{userType}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div> */}
                        <hr />
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="remember-check"
                          >
                            Remember me
                          </label>
                        </div>
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            onClick={handleLogin}
                          >
                            Log In
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                          <h5 className="font-size-14 mb-3">Sign in with</h5>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="social-list-item bg-primary text-white border-primary"
                              >
                                <i className="mdi mdi-facebook" />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="social-list-item bg-info text-white border-info"
                              >
                                <i className="mdi mdi-twitter" />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="social-list-item bg-danger text-white border-danger"
                              >
                                <i className="mdi mdi-google" />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="mt-4 text-center">
                          <a href="auth-recoverpw.html" className="text-muted">
                            <i className="mdi mdi-lock me-1" /> Forgot your
                            password?
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="mt-5 text-center">
                  <div>
                    {/* <p>
                                            Don't have an account ?
                                            <a href="auth-register.html" className="fw-medium text-primary">
                                                Signup now
                                            </a>
                                        </p> */}
                    <p>
                      © Matka. Crafted with{" "}
                      <i className="mdi mdi-heart text-danger" />
                      &nbsp; by Themesbrand
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
