import { callGetApi, callPostApi, callPutApi } from 'api/ApiCaller';
import { Details_Client_Condiment_List_Get, Save_Client_Condiment_Post, Update_Client_Condiment_Put } from 'api/ApiConst';
import { API_SUCCESS } from 'api/ResponseCodes';
import ModalWrapper from 'components/common/ModalWrapper'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { isEmpty } from 'utils/TextUtils';
import { MODAL_LOADER } from 'utils/constants';

const CondimentsAddEdit = (props) => {
    const { show, hideModal, recordID, handleRefresh } = props;
    const { brandKeyValuePairs } = useSelector((state) => state.utility);
    const defaultRecordObj = {
        brandUuid: brandKeyValuePairs.length === 1 ? brandKeyValuePairs[0] : "",
        title: "",
    };

    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Client_Condiment_List_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const res = response.responsePacket;

                    // Set brand Key
                    const _selectedBrandUuid = brandKeyValuePairs.find((obj) => obj.key === res.brandUuid);
                    let recordObj = {
                        title: res.title,
                        brandUuid: _selectedBrandUuid || "",
                    };
                    setRecord(recordObj);
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    //Text Check Availabilities
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    // Check validation
    const checkInputValidation = (recordObj) => {
        if (isEmpty(recordObj.brandUuid)) {
            toast.error(`Brand must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(recordObj.title)) {
            toast.error(`Title must not be empty`,{id: "toast"});
            return false;
        }
        return true;
    };

    //Submit Item Customization Form
    const handleSubmit = async () => {
        try {
            let recordObj = {
                ...record,
                brandUuid: record.brandUuid.key,
            };

            if (!checkInputValidation(recordObj)) {
                return;
            }

            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Client_Condiment_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            } else {
                delete recordObj.brandUuid; // delete brandUuid at update
                callPutApi(MODAL_LOADER, Update_Client_Condiment_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal}  backdrop="static" header={`${recordID ? "Update" : "Add"} Condiments`} size={"md"} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                {!recordID &&
                    <div className="col-lg-12 my-2">
                        <label>Brand *</label>
                        <Select isDisabled={!recordID ? false : true} options={brandKeyValuePairs} value={record.brandUuid} onChange={(e) => handleSelectChange(e, "brandUuid")} />
                    </div>
                }
                <div className="col-lg-12 my-2">
                    <label>Title *</label>
                    <input type="text" className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} />
                </div>
            </div>
        </ModalWrapper>
    )
}

export default CondimentsAddEdit