import React, { useEffect } from "react";
import { toast } from "react-hot-toast";

// libraries
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// utilities
import { LOGOUT, UPDATE_BRAND_KEY_VALUE_PAIRS } from "reducer/actions";

import { isEmpty } from "utils/TextUtils";
import { callPostApi } from "api/ApiCaller";
import { API_SUCCESS } from "api/ResponseCodes";
import { Post_Brand_Key_Value_Pairs_List } from "api/ApiConst";

// components
import Dashboard from "components/pages/Dashboard";
import Login from "components/pages/Login";
import Home from "components/pages/Home";
import UserAdminMaster from "components/master/admin/user-admin/UserAdminMaster";
import AdvertisementMaster from "components/master/client/promotion/advertisement/AdvertisementMaster";
import CuisineMaster from "components/master/client/restaurant-setup/cuisine/CuisineMaster";
import RestaurantMaster from "components/master/client/restaurant-setup/restaurant/RestaurantMaster";
import TaxMaster from "components/master/client/restaurant-setup/tax/TaxMaster";
import UserClientMaster from "components/master/admin/user-client/UserClientMaster";
import UserCustomerMaster from "components/master/admin/user-customer/UserCustomerMaster";
import LocalCountryMaster from "components/master/admin/locality/local-country/LocalCountryMaster";
import LocalStateMaster from "components/master/admin/locality/local-state/LocalStateMaster";
import LocalCityMaster from "components/master/admin/locality/local-city/LocalCityMaster";
import BrandMaster from "components/master/client/brand/BrandMaster";
import ComplementaryHeadMaster from "components/master/client/complementary-head/ComplementaryHeadMaster";
import ProjectSettingMaster from "components/master/admin/settings/project-setting/ProjectSettingMaster";
import ClientSettingMaster from "components/master/client/settings/ClientSettingMaster";
import CurrencyMaster from "components/master/super-admin/currency/CurrencyMaster";
import ClientMaster from "components/master/super-admin/client/ClientMaster";
import TimingGroupMaster from "components/master/client/restaurant-setup/timing-group/TimingGroupMaster";
import RoleMaster from "components/master/super-admin/role/RoleMaster";
import ModuleMaster from "components/master/super-admin/module/ModuleMaster";
import CategoryMaster from "components/master/client/menu/category/CategoryMaster";
import ItemMaster from "components/master/client/menu/item/ItemMaster";
import ItemCustomizationMaster from "components/master/client/menu/item-customization/ItemCustomizationMaster";
import CondimentsMaster from "components/master/client/menu/condiments/CondimentsMaster";
import EventMaster from "components/master/client/restaurant-setup/event/EventMaster";
import VoucherMaster from "components/master/client/voucher/VoucherMaster";
import WorkInProgress from "components/common/WorkInProgress";
import StickerMaster from "components/master/client/restaurant-setup/sticker/StickerMaster";
import RestaurantVoucherMaster from "components/master/restaurant/voucher/RestaurantVoucherMaster";
import RestaurantCondimentsMaster from "components/master/restaurant/menu/condiments/RestaurantCondimentsMaster";
import RestaurantTableMaster from "components/master/restaurant/table/RestaurantTableMaster";
import RestaurantTimingGroupMaster from "components/master/restaurant/timing-group/RestaurantTimingGroupMaster";
import RestaurantTaxMaster from "components/master/restaurant/tax/RestaurantTaxMaster";
import RestaurantPrinterMaster from "components/master/restaurant/printer/RestaurantPrinterMaster";
import RestaurantItemMaster from "components/master/restaurant/menu/item/RestaurantItemMaster";
import RestaurantCategoryMaster from "components/master/restaurant/menu/category/RestaurantCategoryMaster";
import RestaurantItemCustomizationMaster from "components/master/restaurant/menu/item-customization/RestaurantItemCustomizationMaster";
import QRCodeCashBackMaster from "components/master/client/promotion/qr-code-cashback/QRCodeCashBackMaster";
import DineInCashBackMaster from "components/master/client/promotion/dine-in-cashback/DineInCashBackMaster";
import CouponCodeMaster from "components/master/client/promotion/coupon-code/CouponCodeMaster";
import StaffCouponCodeMaster from "components/master/client/promotion/coupon-code/StaffCouponCodeMaster";
import CustomerCouponCodeMaster from "components/master/client/promotion/coupon-code/CustomerCouponCodeMaster";
import CMSMaster from "components/master/client/cms/CMSMaster";
import NotificationMaster from "components/master/client/notification/NotificationMaster";
import StealDealCategoryMaster from "components/master/client/menu/category/StealDealCategoryMaster";
import StealDealItemMaster from "components/master/client/menu/item/StealDealItemMaster";
import RestaurantStealDealCategoryMaster from "components/master/restaurant/menu/category/RestaurantStealDealCategoryMaster";
import RestaurantStealDealItemMaster from "components/master/restaurant/menu/item/RestaurantStealDealItemMaster";
import RestaurantProfile from "components/master/restaurant/profile/RestaurantProfile";
import AdminCmsMaster from "components/master/admin/cms/AdminCmsMaster";
import FeedbackMaster from "components/master/client/feedback/FeedbackMaster";
import OTPMaster from "components/master/client/otp/OTPMaster";
import RestaurantComplementaryHeadMaster from "components/master/restaurant/complementary-head/RestaurantComplementaryHeadMaster";
import RestaurantEmployeeMaster from "components/master/restaurant/staff/employee/RestaurantEmployeeMaster";
import RestaurantDeliveryBoyMaster from "components/master/restaurant/staff/delivery-boy/RestaurantDeliveryBoyMaster";
import TrackDeliveryBoyMaster from "components/master/restaurant/staff/track-delivery-boy/TrackDeliveryBoyMaster";
import DeliveryOfferMaster from "components/master/restaurant/offers/delivery-offer/DeliveryOfferMaster";
import HappyHoursOfferMaster from "components/master/restaurant/offers/happy-hours/HappyHoursOfferMaster";
import EmployeeMovementMaster from "components/master/client/employee-movement/EmployeeMovementMaster";
import DineInOrderMaster from "components/master/restaurant/order/dineIn-order/DineInOrderMaster";
import DeliveryOrderMaster from "components/master/restaurant/order/delivery-order/DeliveryOrderMaster";
import TakeAwayOrderMaster from "components/master/restaurant/order/takeaway-order/TakeAwayOrderMaster";
import OrderDetails from "components/master/restaurant/order/common/OrderDetails";
import Pos from "components/pages/Pos";
import RestaurantAggregatorMaster from "components/master/restaurant/aggregator/RestaurantAggregatorMaster";
import RestaurantSaleReportMaster from "components/master/restaurant/report/RestaurantSaleReportMaster";
// import HistoryDineInOrderMaster from "components/master/restaurant/orderHistory/dineIn-order-history/HistoryDineInOrderMaster";
// import HistoryDeliveryOrderMaster from "components/master/restaurant/orderHistory/delivery-order-history/HistoryDeliveryOrderMaster";
// import HistoryTakeAwayOrderMaster from "components/master/restaurant/orderHistory/takeaway-order-history/HistoryTakeAwayOrderMaster";
// import HistoryOrderDetails from "components/master/restaurant/history/common/HistoryOrderDetails";
import RestaurantOrderHistory from "components/master/restaurant/history/RestaurantOrderHistory";
import HistoryOrderDetails from "components/master/restaurant/history/common/HistoryOrderDetails";
import RestaurantPaymentModeMaster from "components/master/restaurant/payment-mode/RestaurantPaymentModeMaster";
import RestaurantOrderReportMaster from "components/master/restaurant/report/RestaurantOrderReportMaster";

const AppRoutes = () => {
    const { isAuthenticated, user } = useSelector((state) => state.user);
    let location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isAuthenticated) {
            dispatch({ type: LOGOUT });
            if (location.pathname !== "/al" && location.pathname !== "/clientLogin" && location.pathname !== "/restLogin") {
                if (localStorage.getItem("location")) {
                    navigate(localStorage.getItem("location"));
                } else {
                    navigate("/restLogin");
                }
            }
            if (location.pathname === "/al" || location.pathname === "/clientLogin" || location.pathname === "/restLogin") {
                localStorage.setItem("location", location.pathname);
            }
            // document.title = "Pyramid - Admin";
        } else {
            if (location.pathname === "/al" || location.pathname === "/clientLogin" || location.pathname === "/restLogin") {
                navigate("/");
            }
            if (!isEmpty(user?.productTitle)) {
                if (location.pathname !== "/al") {
                    document.title = `${user?.productTitle}`;
                }
            }
            if (user?.userType === "ROLE_CLIENT_ADMIN") {
                // call brand key value Pair List
                const rUrl = Post_Brand_Key_Value_Pairs_List;
                const rObj = {};
                callPostApi(null, rUrl, rObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        dispatch({ type: UPDATE_BRAND_KEY_VALUE_PAIRS, payload: response.responsePacket });
                    } else {
                        toast.error(response.message);
                    }
                });
            }
        }
    }, [isAuthenticated]);

    return (
        <Routes>
            {!isAuthenticated ? (
                <>
                    <Route path="/al" element={isAuthenticated ? <Navigate to={-1} /> : <Login />} />
                    <Route path="/clientLogin" element={isAuthenticated ? <Navigate to={-1} /> : <Login />} />
                    <Route path="/restLogin" element={isAuthenticated ? <Navigate to={-1} /> : <Login />} />
                </>
            ) : (
                <>
                    <Route path="/" element={<Dashboard />}>
                        <Route index element={<Home />} />
                        <Route path="admin-user-admin" element={<UserAdminMaster />} />
                        <Route path="client-ad-banner" element={<AdvertisementMaster />} />
                        <Route path="client-restaurant-tax" element={<TaxMaster />} />
                        <Route path="client-restaurant-cuisine" element={<CuisineMaster />} />
                        <Route path="client-restaurant" element={<RestaurantMaster />} />
                        <Route path="admin-user-client" element={<UserClientMaster />} />
                        <Route path="admin-user-customer" element={<UserCustomerMaster />} />
                        <Route path="locality-local-country" element={<LocalCountryMaster />} />
                        <Route path="locality-local-state" element={<LocalStateMaster />} />
                        <Route path="locality-local-city" element={<LocalCityMaster />} />
                        <Route path="client-brand" element={<BrandMaster />} />
                        <Route path="client-complementary-head" element={<ComplementaryHeadMaster />} />
                        <Route path="admin-setting-project" element={<ProjectSettingMaster />} />
                        <Route path="admin-cms" element={<AdminCmsMaster />} />
                        <Route path="client-setting" element={<ClientSettingMaster />} />
                        <Route path="super-admin-currency" element={<CurrencyMaster />} />
                        <Route path="super-admin-client" element={<ClientMaster />} />
                        <Route path="client-timing-group" element={<TimingGroupMaster />} />
                        <Route path="super-admin-role" element={<RoleMaster />} />
                        <Route path="super-admin-module" element={<ModuleMaster />} />
                        <Route path="client-menu-non-steal-deal-category" element={<CategoryMaster />} />
                        <Route path="client-menu-steal-deal-category" element={<StealDealCategoryMaster />} />
                        <Route path="client-menu-non-steal-deal-item" element={<ItemMaster />} />
                        <Route path="client-menu-steal-deal-item" element={<StealDealItemMaster />} />
                        <Route path="client-menu-customization" element={<ItemCustomizationMaster />} />
                        <Route path="client-menu-condiments" element={<CondimentsMaster />} />
                        <Route path="client-event" element={<EventMaster />} />
                        <Route path="client-voucher" element={<VoucherMaster />} />
                        <Route path="client-restaurant-sticker" element={<StickerMaster />} />
                        <Route path="client-qr-code-cash-back" element={<QRCodeCashBackMaster />} />
                        <Route path="client-dine-in-cash-back" element={<DineInCashBackMaster />} />
                        <Route path="client-coupon-code" element={<CouponCodeMaster />} />
                        <Route path="client-staff-coupon" element={<StaffCouponCodeMaster />} />
                        <Route path="client-customer-coupon" element={<CustomerCouponCodeMaster />} />
                        <Route path="client-cms" element={<CMSMaster />} />
                        <Route path="client-notification" element={<NotificationMaster />} />
                        <Route path="client-feedback" element={<FeedbackMaster />} />
                        <Route path="client-otp-list" element={<OTPMaster />} />
                        <Route path="client-employee-movement" element={<EmployeeMovementMaster />} />
                        <Route path="restaurant-voucher" element={<RestaurantVoucherMaster />} />
                        <Route path="restaurant-menu-condiments" element={<RestaurantCondimentsMaster />} />
                        <Route path="restaurant-table" element={<RestaurantTableMaster />} />
                        <Route path="restaurant-setup-timing-group" element={<RestaurantTimingGroupMaster />} />
                        <Route path="restaurant-setup-tax" element={<RestaurantTaxMaster />} />
                        <Route path="restaurant-printer" element={<RestaurantPrinterMaster />} />
                        <Route path="restaurant-menu-non-steal-deal-item" element={<RestaurantItemMaster />} />
                        <Route path="restaurant-menu-steal-deal-item" element={<RestaurantStealDealItemMaster />} />
                        <Route path="restaurant-menu-non-steal-deal-category" element={<RestaurantCategoryMaster />} />
                        <Route path="restaurant-menu-steal-deal-category" element={<RestaurantStealDealCategoryMaster />} />
                        <Route path="restaurant-menu-customization" element={<RestaurantItemCustomizationMaster />} />
                        <Route path="restaurant-profile" element={<RestaurantProfile />} />
                        <Route path="restaurant-complementary-head" element={<RestaurantComplementaryHeadMaster />} />
                        <Route path="restaurant-employee" element={<RestaurantEmployeeMaster />} />
                        <Route path="restaurant-delivery-boy" element={<RestaurantDeliveryBoyMaster />} />
                        <Route path="restaurant-track-delivery-boy" element={<TrackDeliveryBoyMaster />} />
                        <Route path="restaurant-delivery-offer" element={<DeliveryOfferMaster />} />
                        <Route path="restaurant-happy-hours-offer" element={<HappyHoursOfferMaster />} />
                        <Route path="restaurant-dineIn-orders" element={<DineInOrderMaster />} />
                        <Route path="restaurant-order-details/:orderedId" element={<OrderDetails />} />
                        <Route path="restaurant-delivery-orders" element={<DeliveryOrderMaster />} />
                        <Route path="restaurant-takeaway-orders" element={<TakeAwayOrderMaster />} />
                        <Route path="restaurant-pos" element={<Pos />} />
                        <Route path="restaurant-aggregator" element={<RestaurantAggregatorMaster />} />
                        <Route path="restaurant-sale-report" element={<RestaurantSaleReportMaster />} />
                        <Route path="restaurant-order-report" element={<RestaurantOrderReportMaster />} />
                        {/* <Route path="restaurant-dineIn-orders-history" element={<HistoryDineInOrderMaster />} />
                        <Route path="restaurant-delivery-orders-history" element={<HistoryDeliveryOrderMaster />} />
                        <Route path="restaurant-takeaway-orders-history" element={<HistoryTakeAwayOrderMaster />} /> */}
                        <Route path="history-order-details/:orderedId" element={<HistoryOrderDetails />} />
                        <Route path="restaurant-order-history/" element={<RestaurantOrderHistory />} />
                        <Route path="restaurant-payment-mode/" element={<RestaurantPaymentModeMaster />} />

                        <Route path="*" element={<WorkInProgress />} />
                    </Route>
                </>
            )}
        </Routes>
    );
};

export default AppRoutes;
