import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import Select from "react-select";

import { Add_LocalCity_POST, Get_LocalCity_Detail_GET, StateList_LocalState_POST, Update_LocalCity_PUT } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isChar, isEmpty } from "utils/TextUtils";

import ModalWrapper from "components/common/ModalWrapper";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

const LocalCityAddEdit = ({ show, hideModal, recordID, handleRefresh, countryOptionsList }) => {
    const defaultRecordObj = {
        title: "",
        stateUuid: "",
        countryUuid: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);
    const [stateOptionList, setStateOptionList] = useState([]);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Get_LocalCity_Detail_GET.replaceAll("{recordID}", recordID), {}, (response) => {
                const res = response.responsePacket;
                getStateKeyValuePairList(res.countryUuid);
                res.countryUuid = countryOptionsList.find((country) => country.value === res.countryUuid);
                res.stateUuid = { label: res.stateTitle, value: res.stateUuid, key: "stateUuid" };
                setRecord(res);
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, type, value } = e.target;

        if( id === "title") {
            if(!isChar(value)  && value.length !== 0){
                return;
            }
        } 
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    // Option Select Event
    const handleSelect = (e) => {
        if (e.key === "countryUuid") {
            getStateKeyValuePairList(e.value);
            setRecord((prev) => ({ ...prev, [e.key]: e, stateUuid: "" }));
        } else {
            setRecord((prev) => ({ ...prev, [e.key]: e }));
        }
    };

    const getStateKeyValuePairList = (countryId) => {
        callPostApi(null, StateList_LocalState_POST, { countryUuid: countryId }, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                const options = response.responsePacket.map((state) => ({
                    value: state.key,
                    label: state.value,
                    key: "stateUuid",
                }));
                setStateOptionList(options);
            } else {
                toast.error(response.message);
            }
        });
    };

    const checkInputValidation = () => {
        if (isEmpty(record.countryUuid)) {
            toast.error(`Country must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(record.stateUuid)) {
            toast.error(`State must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(record.title)) {
            toast.error(`City Name must not be empty`,{id: "toast"});
            return false;
        } else if (record.title.length <= 3) {
            toast.error('City Name must be more than 3 characters',{id: "toast"});
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            recordObj.stateUuid = recordObj.stateUuid.value;
            delete recordObj.countryUuid;
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Add_LocalCity_POST, recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(MODAL_LOADER, Update_LocalCity_PUT.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    toast.success(response.message , {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            }
        } catch (error) {
            return;
        }
    };
    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`${recordID ? "Update" : "Add"} City`} size="ms" handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Select Country *</label>
                    <Select options={countryOptionsList} onChange={handleSelect} value={record.countryUuid} />
                </div>
                <div className="col-lg-12">
                    <label>Select State *</label>
                    <Select options={stateOptionList} onChange={handleSelect} value={record.stateUuid} />
                    {/* <input type="text" className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} /> */}
                </div>
                <div className="col-lg-12">
                    <label>City Name *</label>
                    <input 
                        type="text" 
                        className="form-control input-default"
                        maxLength={20}
                        id="title"
                        onChange={handleTextChange}
                        value={record.title} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default LocalCityAddEdit;
