export const initialRestaurantAddValue = {
    restaurantOwner: {
        firstName: "",
        lastName: "",
        isdCode: "+91",
        mobile: "",
        email: "",
        username: "",
        password: ""
    },
    brandUuid: "",
    restaurantTitle: "",
    iconImageRefId: "",
    gstNumber: "",
    tinNumber: "",
    vatNumber: ""
}

export const restaurantDefaultRecordObj = {
    firstName: "",
    lastName: "",
    isdCode: "+91",
    mobile: "",
    email: "",
    username: "",
    password: "",
    address: {
        latitude: 0,
        longitude: 0,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        pinCode: "",
        addressType: "Office",
        countryUuid: "",
        stateUuid: "",
        cityUuid: "",
        countryTitle: "",
        stateTitle: "",
        cityTitle: "",
    },
    brandUuid: "",
    restaurantTitle: "",
    restaurantDescription: "",
    iconImageRefId: "",
    restaurantClosedMessage: "",
    gstNumber: "",
    tinNumber: "",
    vatNumber: "",
    restaurantLicenseNumber:"",
    bannerImageRefId: "",
    webBannerImageRefIdCsv: "",
};