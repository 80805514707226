import { useCallback, useMemo, useState } from "react";
import {
    All_Client_Feedback_List_Post,
    Hide_Client_Feedback_Put,
    Show_Client_Feedback_Put
} from "api/ApiConst";
import { handleActive, handleInactive } from "utils/UserUtils";
import dateUtilities from "utils/DateUtilities";

import PaginationDataTable from "components/common/PaginationDataTable";
import PageHeading from "components/common/PageHeading";

const FeedbackMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => <div className={row.show ? "led-green" : "led-red"} title={row.show ? "Visible" : "Not Visible"} />,
                width: "60px",
                sortable: false,
            },
            {
                id: "customerDetails",
                name: "Customer",
                selector: (row) => (
                    <div className="my-3">
                        <div className="my-2">
                            <span><i className='bx bx-user' title="Name" /></span> <span className="ms-1">{row.customerName}</span>
                        </div>
                        <div className="my-2">
                            <span><i className='bx bx-phone-call' title="Mobile" /></span> <span className="ms-1">{row.customerMobile}</span>
                        </div>
                        <div className="my-2">
                            <span><i className='bx bx-calendar' title="Date" /></span> <span className="ms-1">{dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa")}</span>
                        </div>
                    </div>
                ),
                sortable: false,
                width: "200px"
            },
            {
                id: "restaurantTitle",
                name: "Restaurant Detail",
                selector: (row) => row.restaurantTitle,
                sortable: false,
                wrap: true,
            },
            {
                id: "suggestion",
                name: "Suggestion",
                selector: (row) => row.suggestion,
                sortable: false,
                wrap: true,
            },
            {
                id: "bestPartOfRestaurant",
                name: "Best Part Of Restaurant",
                selector: (row) => row.bestPartOfRestaurant,
                sortable: false,
            },
            {
                id: "qualityOfFood",
                name: <span title="Quality Of Food">Q</span>,
                selector: (row) => row.qualityOfFood,
                sortable: false,
                width: "50px"
            },
            {
                id: "serviceQuality",
                name: <span title="Service Quality">S</span>,
                selector: (row) => row.serviceQuality,
                sortable: false,
                width: "50px"
            },
            {
                id: "cleaning",
                name: <span title="Cleaning">C</span>,
                selector: (row) => row.cleaning,
                sortable: false,
                width: "50px",
            },
            {
                id: "staffBehavior",
                name: <span title="Staff Behavior">B</span>,
                selector: (row) => row.staffBehavior,
                sortable: false,
                width: "50px"
            },
            {
                id: "music",
                name: <span title="Music">M</span>,
                selector: (row) => row.music,
                sortable: false,
                width: "50px"
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                <button className={`listBtn me-2 ${row.show ? "bg-danger" : "bg-success"}`} title={(row.show ? "Hide" : "Show") + " Feedback"} onClick={() => handleShowHideFeedback(row)}>
                    <i className={`bx ${row.show ? "bxs-hide" : "bxs-show"}`}></i>
                </button>
            </div>
        );
    };

    const handleShowHideFeedback = (row) => {
        if (row.show) {
            handleInactive(Hide_Client_Feedback_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Show_Client_Feedback_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    return (
        <>
            {PageHeading("Customer Feedback List", "Dashboard", "/")}
            <div className="card searchOnlyBlock">
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={All_Client_Feedback_List_Post}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                </div>
            </div>
        </>
    )
}

export default FeedbackMaster