import React, { useCallback, useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import Button from "react-bootstrap/Button";

import { Add_TimingGroup_POST, Get_TimingGroup_Detail_GET, Update_TimingGroup_PUT } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isAlphaNumercSpecialCharSpc, isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";
import Select from "react-select";
import { useSelector } from "react-redux";

const TimingGroupAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const { brandKeyValuePairs } = useSelector((state) => state.utility);
    const defaultRecordObj = {
        brandUuid: brandKeyValuePairs.length === 1 ? brandKeyValuePairs[0] : "",
        timingGroupTitle: "",
        monday: [{ startTimeStamp: "", endTimeStamp: "" }],
        tuesday: [{ startTimeStamp: "", endTimeStamp: "" }],
        wednesday: [{ startTimeStamp: "", endTimeStamp: "" }],
        thursday: [{ startTimeStamp: "", endTimeStamp: "" }],
        friday: [{ startTimeStamp: "", endTimeStamp: "" }],
        saturday: [{ startTimeStamp: "", endTimeStamp: "" }],
        sunday: [{ startTimeStamp: "", endTimeStamp: "" }],
    };
    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    const [record, setRecord] = useState({ ...defaultRecordObj });
    const [availableDays, setAvailableDays] = useState([]);
    const [activeTab, setActiveTab] = useState("monday");

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Get_TimingGroup_Detail_GET.replaceAll("{recordID}", recordID), {}, (response) => {
                const result = response.responsePacket;
                let daysTemp = [];

                // loop for set active tab
                for (let dayName of days) {
                    if (response.responsePacket.hasOwnProperty(dayName)) {
                        setActiveTab(dayName);
                        break;
                    }
                }

               { days && days.map((day) => {
                    let dayName = day.toLowerCase();
                    const foundDay = response.responsePacket.hasOwnProperty(dayName);
                    if (!foundDay) {
                        result[`${dayName}`] = [{ startTimeStamp: "", endTimeStamp: "" }];
                    } else {
                        daysTemp.push(day);
                    }
                 });
               }  
                setAvailableDays(daysTemp);
                setRecord(result);
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const {id, value} = e.target;

        if(id === "timingGroupTitle") {
            if(!isAlphaNumercSpecialCharSpc(value) && value.length !== 0){
                return;
            }
        }
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    //Select Text Change Event
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    // Time Change Event
    const handleTimeChange = (e, i) => {
        const date = new Date();
        date.setHours(e.target.value.substr(0, 2));
        date.setMinutes(e.target.value.substr(3, 2));
        date.setSeconds(0);
        date.setMilliseconds(0);
        const _recordArrClone = { ...record };
        const selectedKeyObject = [..._recordArrClone[`${e.target.name}`]];
        let selectedObjectIndex = { ...selectedKeyObject[i] };
        selectedKeyObject[i] = { ...selectedObjectIndex, [e.target.id]: date.getTime() };
        _recordArrClone[`${e.target.name}`] = [...selectedKeyObject];
        setRecord(_recordArrClone);
    };

    // day selection
    const handleDaySelection = (day) => {
        const foundDay = availableDays.findIndex((data) => data === day);
        const daysArrClone = JSON.parse(JSON.stringify(availableDays));
        if (foundDay > -1) {
            daysArrClone.splice(foundDay, 1);
            setAvailableDays(daysArrClone);
        } else {
            const arrClone = [];
         { days &&   days.map((data) => {
                const foundDay = availableDays.find((avDay) => avDay === data);
                if (foundDay || data === day) {
                    arrClone.push(data);
                    setActiveTab(day);
                }
            });
        }
            setAvailableDays(arrClone);
        }
    };

    // add more time fields
    const handleAddMoreTimeFields = () => {
        const _recordArrClone = { ...record };
        const selectedKeyObject = [..._recordArrClone[`${activeTab}`]];
        // if (isEmpty(selectedKeyObject[selectedKeyObject.length - 1].startTimeStamp) || isEmpty(selectedKeyObject[selectedKeyObject.length - 1].endTimeStamp)) {
        //     toast.error("Remaining fields must not be empty, fill remaining and add more fields",{id: "error"})
        // } else {
        _recordArrClone[`${activeTab}`] = [...selectedKeyObject, { startTimeStamp: "", endTimeStamp: "" }];
        setRecord(_recordArrClone);
        // }
    };

    // remove time files
    const handleRemoveTimeFields = (i) => {
        const _recordArrClone = { ...record };
        const selectedKeyObject = [..._recordArrClone[`${activeTab}`]];
        selectedKeyObject.splice(i, 1);
        _recordArrClone[`${activeTab}`] = [...selectedKeyObject];
        setRecord(_recordArrClone);
    };

    // capitalize day name
    const capitalizeDayName = (keyName) => {
        return keyName.charAt(0).toUpperCase() + keyName.slice(1).toLowerCase(); // capitalize day name
    };

    // Apply same Time for all days
    const handleApplyToAllDays = (e) => {
        let daysTemp = [];
        let _recordArrClone = { ...record };
        let filtered = record[`${e.target.id}`];
        { days && days.map((day) => {
            let dayName = day.toLowerCase();
            _recordArrClone[`${dayName}`] = filtered;
            daysTemp.push(day);
        });
    }
        setAvailableDays(daysTemp);
        setRecord(_recordArrClone);
        // if (e.target.checked) {
        //     setApplyToAllDays(e.target.id);
        //     let daysTemp = [];
        //     let _recordArrClone = { ...record }
        //     let filtered = record[`${e.target.id}`];
        //     days.map((day) => {
        //         let dayName = day.toLowerCase()
        //         _recordArrClone[`${dayName}`] = filtered;
        //         daysTemp.push(day)
        //     });
        //     setAvailableDays(daysTemp)
        //     setRecord(_recordArrClone)
        // } else {
        //     let daysTemp = [];
        //     days.map((day) => {
        //         if (day.toLowerCase() === e.target.id) {
        //             daysTemp.push(day)
        //         }
        //     });
        //     setAvailableDays(daysTemp)
        //     setApplyToAllDays("")
        // }
    };

    // Convert timesTamp to time
    const convertTimestampToTime = (timestamp) => {
        if (isEmpty(timestamp)) {
            // let emptyString = "";
            return "";
        }
        var date = new Date(timestamp);
        var hour = date.getHours();
        var min = date.getMinutes();
        if (hour < 10) {
            hour = "0" + hour;
        }
        if (min < 10) {
            min = "0" + min;
        }
        var time = hour + ":" + min;
        return time;
    };

    // show Selected tab form Data
    const getTabpanelData = () => {
        return (
            <>
                <div className="row" key={`${activeTab}`}>
                    <div className="col-lg-12">
                        <fieldset className="mt-4">
                            <legend>{availableDays.includes(activeTab) ? "Open" : "Close"}</legend>
                            <div className="row mb-1">
                                <div className="col-lg-6">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name={activeTab}
                                        id={activeTab}
                                        checked={availableDays.includes(activeTab)}
                                        onChange={(e) => handleDaySelection(activeTab)}
                                    />
                                    <label className="form-check-label ms-2" htmlFor={activeTab}>
                                        Open
                                    </label>
                                </div>
                                <div className="col-lg-6">
                                    <Button variant="primary" className="float-end" onClick={() => handleAddMoreTimeFields(activeTab)}>
                                        Add More +
                                    </Button>
                                </div>
                            </div>
                            <div className="time-list mb-2">
                                {record[`${activeTab}`] && record[`${activeTab}`].map((data, i) => (
                                    <div className="row mb-3" key={i} style={{ position: "relative" }}>
                                        <div className="col-lg-6">
                                            <label>Start Time </label>
                                            <input
                                                type="time"
                                                className="form-control input-default"
                                                id="startTimeStamp"
                                                name={activeTab}
                                                value={convertTimestampToTime(data.startTimeStamp)}
                                                onChange={(e) => handleTimeChange(e, i)}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Close Time</label>
                                            <input
                                                type="time"
                                                className="form-control input-default"
                                                id="endTimeStamp"
                                                name={activeTab}
                                                value={convertTimestampToTime(data.endTimeStamp)}
                                                onChange={(e) => handleTimeChange(e, i)}
                                            />
                                        </div>
                                        {i > 0 && (
                                            <span className="remove-time-row" onClick={() => handleRemoveTimeFields(i)}>
                                                X
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="row mb-1">
                                <div className="col-lg-6 align-items-center">
                                    <Button variant="primary" id={activeTab} onClick={handleApplyToAllDays}>
                                        Apply for All Days
                                    </Button>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </>
        );
    };

    // compare two times validation
    const compareTwoTimes = (startTimeStamp, endTimeStamp, tabName, conditionType, row) => {
        // conditionType === 1 :- check row startTime1 < endTime1
        // conditionType === 2 :- check row filed startTime2 > endTime1 and so on
        let startTime = new Date(startTimeStamp);
        let endTime = new Date(endTimeStamp);
        if (isEmpty(startTimeStamp)) {
            toast.error(`${row === 0 ? "Opened for " + tabName : "in " + tabName + " row " + (row + 1)}, start time must not be empty`,{id: "error"});
            return false;
        } else if (isEmpty(endTimeStamp)) {
            toast.error(`${row === 0 ? "Opened for " + tabName : "in " + tabName + " row " + (row + 1)}, end time must not be empty`,{id: "error"});
            return false;
        } else if (startTimeStamp && endTimeStamp) {
            if (startTime > endTime) {
                if (conditionType === 1) {
                    toast.error(`${row === 0 ? "Opened for " + tabName : "in " + tabName + " row " + (row + 1)}, start time must not be greater to it's end Time`,{id: "error"});
                }

                if (conditionType === 2) {
                    toast.error(`in ${tabName}, row ${row + 1} start time must not be greater to row ${row} end Time`,{id: "error"});
                }

                // if (conditionType === 3) {
                //     toast.error(`${tabName} row ${row + 1}, start time must not be greater to it's end Time`,{id: "error"})
                // }
                return false;
            }
        }
        return true;
    };

    // check time validations
    const checkTimeValidations = (keyName) => {
        let tabName = capitalizeDayName(keyName); // capitalize day name
        let keyData = record[`${keyName}`];
        for (var i = 0; i < keyData.length; i++) {
            if (i === 0) {
                if (!compareTwoTimes(keyData[0].startTimeStamp, keyData[0].endTimeStamp, tabName, 1, i)) {
                    return false;
                }
            } else if (i > 0) {
                if (!compareTwoTimes(keyData[i].startTimeStamp, keyData[i].endTimeStamp, tabName, 1, i)) {
                    return false;
                } else if (!compareTwoTimes(keyData[i - 1].endTimeStamp, keyData[i].startTimeStamp, tabName, 2, i)) {
                    return false;
                }
            }
        }
        return true;
    };

    const checkInputValidation = () => {
        if (!recordID && isEmpty(record.brandUuid)) {
            toast.error(`Brand must not be empty`,{id: "error"});
            return false;
        } else if (isEmpty(record.timingGroupTitle)) {
            toast.error(`Group title must not be empty`,{id: "error"});
            return false;
        } else if (record.timingGroupTitle.length < 3) {
            toast.error(`Group title must be at least 3 characters`,{id: "error"});
            return false;
        }

        for (let i = 0; i < days.length; i++) {
            if (availableDays.includes(days[i])) {
                // startTimes1 > endTime1 && startTime2 > endTime1 and so on..
                if (!checkTimeValidations(days[i].toLocaleLowerCase())) {
                    return;
                }
            }
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            recordObj.brandUuid = record.brandUuid?.key || "";

            // to remove all the time rows for uncheck days
            days.filter((dayName) => {
                if (!availableDays.includes(dayName)) {
                    recordObj[`${dayName}`] = null;
                }
            });

            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Add_TimingGroup_POST, recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(MODAL_LOADER, Update_TimingGroup_PUT.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} backdrop="static" hide={hideModal} header={`${recordID ? "Update" : "Add"} Timing Group`} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                {!recordID &&
                    <div className="col-lg-12 my-2">
                        <label>Brand *</label>
                        <Select options={brandKeyValuePairs} value={record.brandUuid} onChange={(e) => handleSelectChange(e, "brandUuid")} />
                    </div>
                }
                <div className="col-lg-12">
                    <label>Timing Group Title *</label>
                    <input type="text" maxLength={20} className="form-control input-default" id="timingGroupTitle" onChange={handleTextChange} value={record.timingGroupTitle} />
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <ul className="nav nav-tabs checkbox-tabs">
                        {days &&
                            days.map((day, i) => (
                                <li key={i} className={`d-flex align-items-center mx-2 ${activeTab === day ? "active" : ""}`}>
                                    <a className="px-2 py-1 d-flex align-items-center" data-toggle="tab" onClick={() => setActiveTab(day)}>
                                        {capitalizeDayName(day)} <i className={`bx ${availableDays.includes(day) ? "bx-check text-success" : "bx-x text-danger"} h4 mb-0`} />
                                    </a>
                                </li>
                            ))}
                    </ul>
                    <div className="tab-content px-1">
                        <div className="tab-pane active" id="activeTabContent">
                            {getTabpanelData()}
                        </div>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default TimingGroupAddEdit;
