import ImageSelector from "components/common/ImageSelector";

const RestaurantMultiImageSelector = (props) => {
    const { selectedImage, index, handleImageSelect, ownedItem } = props;

    const handleImageChange = (imageRefId) => {
        handleImageSelect(imageRefId, index);
    };

    // Image loader
    return (
        <ImageSelector
            style={{
                height: "40px",
                width: "100%",
                // borderRadius: "50%",
                overflow: "hidden",
            }}
            handleImageChange={handleImageChange}
            selectedImage={selectedImage}
            owned={ownedItem}
        />
    );
};

export default RestaurantMultiImageSelector;
