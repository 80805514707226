import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import { Button } from "react-bootstrap";

import {
    Get_Client_Detail_GET,
    Update_Client_BackupDb_PUT,
    Update_Client_ContactDetails_PUT,
    Update_Client_PUT,
    Update_Client_PrimaryDb_PUT,
    Update_Client_ProductDetails_PUT,
    Update_Client_S3Details_PUT,
    Update_Client_SecondaryDb_PUT,
} from "api/ApiConst";
import { callGetApi, callPutApi } from "api/ApiCaller";
import { isEmail, isEmpty, isMobile } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";

const ClientDetailsModal = ({ show, hideModal, recordID }) => {
    const defaultRecordObj = {
        clientIdentity: "",
        thirdPartyId: "",
        ipAddress: "",
        contactName: "",
        contactNumber: "",
        contactEmail: "",
        productTitle: "",
        productDescription: "",
        productIconRefId: "",
        primaryDbDetail: {
            host: "",
            port: "",
            dbName: "",
            authDbName: "",
            authUsername: "",
            authPassword: "",
        },
        s3Bucket: false,
        secondaryDb: false,
        backUpDb: false,
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Get_Client_Detail_GET.replaceAll("{recordID}", recordID), {}, (response) => {
                const res = response.responsePacket;
                setRecord(res);
            });
        }
    }, [recordID]);

    // handle input fields text change
    const handleTextChange = (e) => {
        if (e.target.type === "checkbox") {
            setRecord((prev) => ({ ...prev, [e.target.id]: e.target.checked }));
        } else {
            if (e.target.name === "primaryDb") {
                setRecord((prev) => ({ ...prev, primaryDbDetail: { ...prev.primaryDbDetail, [e.target.id]: e.target.value } }));
            } else if (e.target.name === "secondaryDb") {
                setRecord((prev) => ({ ...prev, secondaryDbDetail: { ...prev.secondaryDbDetail, [e.target.id]: e.target.value } }));
            } else if (e.target.name === "backUpDb") {
                setRecord((prev) => ({ ...prev, backUpDbDetail: { ...prev.backUpDbDetail, [e.target.id]: e.target.value } }));
            } else if (e.target.name === "s3Bucket") {
                setRecord((prev) => ({ ...prev, s3BucketDetail: { ...prev.dbDetail, [e.target.id]: e.target.value } }));
            } else {
                if (e.target.id === "contactNumber") {
                    if (e.target.value.length > 10) {
                        return;
                    }
                }
                setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
            }
        }
    }

    // Client Details
    const viewClientDetails = () => {
        return (
            <fieldset>
                <legend>Client Details</legend>
                <div className="form-group row mb-3">
                    <div className="col-lg-6">
                        <label>Client Identity *</label>
                        <input type="text" className="form-control input-default" id="clientIdentity" name="client" onChange={handleTextChange} value={record?.clientIdentity} />
                    </div>
                    <div className="col-lg-6">
                        <label>IP Address *</label>
                        <input type="text" className="form-control input-default" id="ipAddress" name="client" onChange={handleTextChange} value={record?.ipAddress} />
                    </div>
                </div>
                <Button variant="primary" className='float-end mt-0' onClick={() => handleSubmit("client")}>
                    Submit
                </Button>
            </fieldset>
        )
    }

    // Contact Details
    const viewContactDetails = () => {
        return (
            <fieldset className='my-4'>
                <legend>Contact Details</legend>
                <div className="form-group row mb-3">
                    <div className="col-lg-6">
                        <label>Name *</label>
                        <input type="text" className="form-control input-default" id="contactName" name='contact' onChange={handleTextChange} value={record?.contactName} />
                    </div>
                    <div className="col-lg-6">
                        <label>Contact No *</label>
                        <input type="text" className="form-control input-default" id="contactNumber" name='contact' onChange={handleTextChange} value={record?.contactNumber} min={10} max={10} />
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <div className="col-lg-12">
                        <label>Email *</label>
                        <input type="text" className="form-control input-default" id="contactEmail" name='contact' onChange={handleTextChange} value={record?.contactEmail} />
                    </div>
                </div>
                <Button variant="primary" className='float-end mt-0' onClick={() => handleSubmit("contact")}>
                    Submit
                </Button>
            </fieldset>
        )
    }

    // Product Details
    const viewProductDetails = () => {
        return (
            <fieldset className='my-4'>
                <legend>Product Details</legend>
                <div className="form-group row mb-3">
                    <div className="col-lg-12">
                        <label>Product Name *</label>
                        <input type="text" className="form-control input-default" id="productTitle" name='product' onChange={handleTextChange} value={record?.productTitle} />
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <div className="col-lg-12">
                        <label>Product Description *</label>
                        <input type="text" className="form-control input-default" id="productDescription" name='product' onChange={handleTextChange} value={record?.productDescription} />
                    </div>
                </div>
                <Button variant="primary" className='float-end mt-0' onClick={() => handleSubmit("product")}>
                    Submit
                </Button>
            </fieldset>
        )
    }

    // Primary Db Details
    const viewPrimaryDbDetails = () => {
        return (
            <fieldset className='my-4'>
                <legend>Primary DB Details</legend>
                <div className="form-group row mb-3">
                    <div className="col-lg-6">
                        <label>Host Name *</label>
                        <input type="text" className="form-control input-default" id="host" name='primaryDb' onChange={handleTextChange} value={record?.primaryDbDetail?.host} />
                    </div>
                    <div className="col-lg-6">
                        <label>Port *</label>
                        <input type="text" className="form-control input-default" id="port" name='primaryDb' onChange={handleTextChange} value={record?.primaryDbDetail?.port} />
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <div className="col-lg-6">
                        <label>Database Name *</label>
                        <input type="text" className="form-control input-default" id="dbName" name='primaryDb' onChange={handleTextChange} value={record?.primaryDbDetail?.dbName} />
                    </div>
                    <div className="col-lg-6">
                        <label>Auth DB Name *</label>
                        <input type="text" className="form-control input-default" id="authDbName" name='primaryDb' onChange={handleTextChange} value={record?.primaryDbDetail?.authDbName} />
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <div className="col-lg-6">
                        <label>Auth User Name *</label>
                        <input type="text" className="form-control input-default" id="authUsername" name='primaryDb' onChange={handleTextChange} value={record?.primaryDbDetail?.authUsername} />
                    </div>
                    <div className="col-lg-6">
                        <label>Auth Password *</label>
                        <input type="text" className="form-control input-default" id="authPassword" name='primaryDb' onChange={handleTextChange} value={record?.primaryDbDetail?.authPassword} />
                    </div>
                </div>
                <Button variant="primary" className='float-end mt-0' onClick={() => handleSubmit("primaryDb")}>
                    Submit
                </Button>
            </fieldset>
        )
    }

    // Secondary Db Details
    const viewSecondaryDbDetails = () => {
        return (
            <fieldset className='my-4'>
                <legend>Secondary Db Details</legend>
                <div className="form-group row mb-3">
                    <div className="col-lg-6">
                        <input type="checkbox" className="input-default" id="secondaryDb" name='secondaryDb' onChange={handleTextChange} checked={record.secondaryDb} />
                        <label className='mx-2' htmlFor="secondaryDb">Secondary Db </label>
                    </div>
                </div>
                {record.secondaryDb && <>
                    <div className="form-group row mb-3">
                        <div className="col-lg-6">
                            <label>Host Name *</label>
                            <input type="text" className="form-control input-default" id="host" name='secondaryDb' onChange={handleTextChange} value={record?.secondaryDbDetail?.host} />
                        </div>
                        <div className="col-lg-6">
                            <label>Port *</label>
                            <input type="text" className="form-control input-default" id="port" name='secondaryDb' onChange={handleTextChange} value={record?.secondaryDbDetail?.port} />
                        </div>
                    </div>
                    <div className="form-group row mb-3">
                        <div className="col-lg-6">
                            <label>Database Name *</label>
                            <input type="text" className="form-control input-default" id="dbName" name='secondaryDb' onChange={handleTextChange} value={record?.secondaryDbDetail?.dbName} />
                        </div>
                        <div className="col-lg-6">
                            <label>Auth DB Name *</label>
                            <input type="text" className="form-control input-default" id="authDbName" name='secondaryDb' onChange={handleTextChange} value={record?.secondaryDbDetail?.authDbName} />
                        </div>
                    </div>
                    <div className="form-group row mb-3">
                        <div className="col-lg-6">
                            <label>Auth User Name *</label>
                            <input type="text" className="form-control input-default" id="authUsername" name='secondaryDb' onChange={handleTextChange} value={record?.secondaryDbDetail?.authUsername} />
                        </div>
                        <div className="col-lg-6">
                            <label>Auth Password *</label>
                            <input type="text" className="form-control input-default" id="authPassword" name='secondaryDb' onChange={handleTextChange} value={record?.secondaryDbDetail?.authPassword} />
                        </div>
                    </div>
                </>}
                <Button variant="primary" className='float-end mt-0' onClick={() => handleSubmit("secondaryDb")}>
                    Submit
                </Button>
            </fieldset>
        )
    }

    // BackUp Db Details
    const viewBackUpDbDetails = () => {
        return (
            <fieldset className='my-4'>
                <legend>Backup Db Details</legend>
                <div className="form-group row mb-3">
                    <div className="col-lg-6">
                        <input type="checkbox" className="input-default" id="backUpDb" name='backUpDb' onChange={handleTextChange} checked={record.backUpDb} />
                        <label className='mx-2' htmlFor="backUpDb">Backup Db </label>
                    </div>
                </div>
                {record.backUpDb && <>
                    <div className="form-group row mb-3">
                        <div className="col-lg-6">
                            <label>Host Name *</label>
                            <input type="text" className="form-control input-default" id="host" name='backUpDb' onChange={handleTextChange} value={record?.backUpDbDetail?.host} />
                        </div>
                        <div className="col-lg-6">
                            <label>Port *</label>
                            <input type="text" className="form-control input-default" id="port" name='backUpDb' onChange={handleTextChange} value={record?.backUpDbDetail?.port} />
                        </div>
                    </div>
                    <div className="form-group row mb-3">
                        <div className="col-lg-6">
                            <label>Database Name *</label>
                            <input type="text" className="form-control input-default" id="dbName" name='backUpDb' onChange={handleTextChange} value={record?.backUpDbDetail?.dbName} />
                        </div>
                        <div className="col-lg-6">
                            <label>Auth DB Name *</label>
                            <input type="text" className="form-control input-default" id="authDbName" name='backUpDb' onChange={handleTextChange} value={record?.backUpDbDetail?.authDbName} />
                        </div>
                    </div>
                    <div className="form-group row mb-3">
                        <div className="col-lg-6">
                            <label>Auth User Name *</label>
                            <input type="text" className="form-control input-default" id="authUsername" name='backUpDb' onChange={handleTextChange} value={record?.backUpDbDetail?.authUsername} />
                        </div>
                        <div className="col-lg-6">
                            <label>Auth Password *</label>
                            <input type="text" className="form-control input-default" id="authPassword" name='backUpDb' onChange={handleTextChange} value={record?.backUpDbDetail?.authPassword} />
                        </div>
                    </div>
                </>}
                <Button variant="primary" className='float-end mt-0' onClick={() => handleSubmit("backUpDb")}>
                    Submit
                </Button>
            </fieldset>
        )
    }

    // S3 Bucket Details
    const viewS3BucketDetails = () => {
        return (
            <fieldset className="mt-4">
                <legend>S3 Bucket Details</legend>
                <div className="form-group row mb-3">
                    <div className="col-lg-6">
                        <input type="checkbox" className="input-default" id="s3Bucket" name='s3Bucket' onChange={handleTextChange} checked={record.s3Bucket} />
                        <label className='mx-2' htmlFor='s3Bucket'>S3 Bucket </label>
                    </div>
                </div>
                {record.s3Bucket && <>
                    <div className="form-group row mb-3">
                        <div className="col-lg-6">
                            <label>Access Key *</label>
                            <input type="text" className="form-control input-default" id="accessKey" name='s3Bucket' onChange={handleTextChange} value={record?.s3BucketDetail?.accessKey} />
                        </div>
                        <div className="col-lg-6">
                            <label>Bucket *</label>
                            <input type="text" className="form-control input-default" id="bucket" name='s3Bucket' onChange={handleTextChange} value={record?.s3BucketDetail?.bucket} />
                        </div>
                    </div>
                    <div className="form-group row mb-3">
                        <div className="col-lg-6">
                            <label>Bucket URL *</label>
                            <input type="text" className="form-control input-default" id="bucketURL" name='s3Bucket' onChange={handleTextChange} value={record?.s3BucketDetail?.bucketURL} />
                        </div>
                        <div className="col-lg-6">
                            <label>Region *</label>
                            <input type="text" className="form-control input-default" id="region" name='s3Bucket' onChange={handleTextChange} value={record?.s3BucketDetail?.region} />
                        </div>
                    </div>
                    <div className="form-group row mb-3">
                        <div className="col-lg-6">
                            <label>Secret Key *</label>
                            <input type="text" className="form-control input-default" id="secretKey" name='s3Bucket' onChange={handleTextChange} value={record?.s3BucketDetail?.secretKey} />
                        </div>
                    </div>
                </>}
                <Button variant="primary" className='float-end mt-0' onClick={() => handleSubmit("s3Bucket")}>
                    Submit
                </Button>
            </fieldset>
        )
    }

    // Check Validation
    const checkInputValidation = (currentSection) => {
        if (currentSection === "client") {
            if (isEmpty(record?.clientIdentity)) {
                toast.error(`Client name must not be empty`, { id: "toast" });
                return false;
            } else if (record?.clientIdentity.length > 50) {
                toast.error(`Client name must not be more then 50 character.`, { id: "toast" });
                return false;
            } else if (isEmpty(record?.thirdPartyId)) {
                toast.error(`Third party Id must not be empty`, { id: "toast" });
                return false;
            } else if (record?.thirdPartyId.length > 100) {
                toast.error(`Third party Id must not be more then 100 character.`, { id: "toast" });
                return false;
            } else if (isEmpty(record?.ipAddress)) {
                toast.error(`IP address must not be empty`, { id: "toast" });
                return false;
            }

            if (record?.ipAddress) {
                let IpAddressRegEx = /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
                if (!IpAddressRegEx.test(record?.ipAddress)) {
                    toast.error(`Please Provide Valid IP Address`, { id: "toast" });
                    return false;
                }
            }
        }

        if (currentSection === "contact") {
            if (isEmpty(record?.contactName)) {
                toast.error(`Contact name key must not be empty`, { id: "toast" });
                return false;
            } else if (isEmpty(record?.contactNumber)) {
                toast.error(`Contact No. must not be empty`, { id: "toast" });
                return false;
            } else if (isEmpty(record?.contactEmail)) {
                toast.error(`Email must not be empty`, { id: "toast" });
                return false;
            }

            if (record?.contactNumber) {
                if (!isMobile(record?.contactNumber)) {
                    toast.error(`Please Provide Valid Contact No.`, { id: "toast" });
                    return false;
                }
                // let mobileRegEx = /^(0|91)?[6-9][0-9]{9}$/;
                // if (!mobileRegEx.test(record?.contactNumber)) {
                //     toast.error(`Please Provide Valid Contact No.`, { id: "toast" });
                //     return false;
                // }
            }

            if (record?.contactEmail) {
                if (!isEmail(record.contactEmail)) {
                    toast.error(`Please Provide Valid Email`, { id: "toast" });
                    return false;
                }
                // let emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                // if (!emailRegEx.test(record?.contactEmail)) {
                //     toast.error(`Please Provide Valid Email`, { id: "toast" });
                //     return false;
                // }
            }
        }

        // if (currentSection === "product") {
        //     if (isEmpty(record?.productTitle)) {
        //         toast.error(`Product name must not be empty`, { id: "toast" });
        //         return false;
        //     } else if (isEmpty(record?.productDescription)) {
        //         toast.error(`Product Description not be empty`, { id: "toast" });
        //         return false;
        //     }
        // }

        if ((currentSection === "primaryDb") || ((currentSection === "secondaryDb") && record?.secondaryDb) || ((currentSection === "backUpDb") && record?.backUpDb)) {
            let requestObj = record[`${currentSection}Detail`]
            if (isEmpty(requestObj?.host)) {
                toast.error(`Host name must not be empty`, { id: "toast" });
                return false;
            } else if (isEmpty(requestObj?.port)) {
                toast.error(`Port must not be empty`, { id: "toast" });
                return false;
            } else if (isEmpty(requestObj?.dbName)) {
                toast.error(`Database name must not be empty`, { id: "toast" });
                return false;
            } else if (isEmpty(requestObj?.authDbName)) {
                toast.error(`Auth db name must not be empty`, { id: "toast" });
                return false;
            } else if (isEmpty(requestObj?.authUsername)) {
                toast.error(`Auth user name must not be empty`, { id: "toast" });
                return false;
            } else if (isEmpty(requestObj?.authPassword)) {
                toast.error(`Auth password must not be empty`, { id: "toast" });
                return false;
            }
        }
        if (currentSection === "s3Bucket") {
            if (record?.s3Bucket) {
                if (isEmpty(record?.s3BucketDetail?.accessKey)) {
                    toast.error(`Access key must not be empty`, { id: "toast" });
                    return false;
                } else if (isEmpty(record?.s3BucketDetail?.bucket)) {
                    toast.error(`Bucket must not be empty`, { id: "toast" });
                    return false;
                } else if (isEmpty(record?.s3BucketDetail?.bucketURL)) {
                    toast.error(`Bucket url must not be empty`, { id: "toast" });
                    return false;
                } else if (isEmpty(record?.s3BucketDetail?.region)) {
                    toast.error(`Region must not be empty`, { id: "toast" });
                    return false;
                } else if (isEmpty(record?.s3BucketDetail?.secretKey)) {
                    toast.error(`Secret key must not be empty`, { id: "toast" });
                    return false;
                }

                if (record?.s3BucketDetail?.bucketURL) {
                    let UrlRegEx = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
                    if (!UrlRegEx.test(record?.s3BucketDetail?.bucketURL)) {
                        toast.error(`Please Provide Valid Bucket URL`, { id: "toast" });
                        return false;
                    }
                }
            }
        }
        return true;
    }

    const handleSubmit = (currentSection) => {
        if (!checkInputValidation(currentSection)) {
            return
        }
        let recordObj = {};
        let url = "";

        if (currentSection === "client") {
            url = Update_Client_PUT;
            recordObj = {
                clientIdentity: record.clientIdentity,
                ipAddress: record.ipAddress,
            };
        }

        if (currentSection === "contact") {
            url = Update_Client_ContactDetails_PUT
            recordObj = {
                contactName: record?.contactName,
                contactNumber: record?.contactNumber,
                contactEmail: record?.contactEmail,
            }
        }

        if (currentSection === "product") {
            url = Update_Client_ProductDetails_PUT
            recordObj = {
                productTitle: record?.productTitle,
                productDescription: record?.productDescription,
                productIconRefId: record?.productIconRefId,
            }
        }

        if (currentSection === "primaryDb") {
            url = Update_Client_PrimaryDb_PUT;
            recordObj["dbDetail"] = record?.primaryDbDetail
        }

        if (currentSection === "secondaryDb") {
            url = Update_Client_SecondaryDb_PUT;
            recordObj.secondaryDb = record.secondaryDb
            if (recordObj?.secondaryDb) {
                recordObj["dbDetail"] = record?.secondaryDbDetail
            }
        }

        if (currentSection === "backupDb") {
            url = Update_Client_BackupDb_PUT;
            recordObj.backUpDb = record.backUpDb
            if (recordObj?.backUpDb) {
                recordObj["dbDetail"] = record?.backUpDbDetail
            }
        }

        if (currentSection === "s3Bucket") {
            url = Update_Client_S3Details_PUT;
            recordObj.s3Bucket = record?.s3Bucket
            if (recordObj?.s3Bucket) {
                recordObj["s3BucketDetail"] = record?.s3BucketDetail
            }
        }

        callPutApi(MODAL_LOADER, url.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
            toast.success(response.message);
        });
    };

    return (
        <div className="multi-modals">
            <ModalWrapper
                show={show}
                backdrop="static" 
                hide={hideModal}
                header={`${record?.clientIdentity} Details`}
                // handleSubmit={handleSubmit}
                centered={true}
                size="lg"
                dialogClassName="right-modal-dialog"
                contentClassName="right-modal-content"
            >
                {viewClientDetails()}
                {viewContactDetails()}
                {viewProductDetails()}
                {viewPrimaryDbDetails()}
                {viewSecondaryDbDetails()}
                {viewBackUpDbDetails()}
                {viewS3BucketDetails()}
            </ModalWrapper>
        </div>
    );
};

export default ClientDetailsModal;
