import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
    Activate_Client_QRCode_CashBack_Put,
    All_Client_QRCode_CashBack_List_Post,
    DeActivate_Client_QRCode_CashBack_Put,
    Delete_Client_QRCode_CashBack_Delete,
    Deleted_Client_QRCode_CashBack_List_Post,
    Get_QR_Code_Cashback_Qr_Code_Post,
    Revive_Client_QRCode_CashBack_Put
} from "api/ApiConst";
import { handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import { DINE_IN, HOME_DELIVERY, TAKEAWAY, TYPE_AVAILABILITIES } from "utils/constants";
import dateUtilities from "utils/DateUtilities";

import CustomButton from "components/common/CustomButton";
import CheckboxButton from "components/common/CheckboxButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import QRCodeCashBackAddEdit from "./QRCodeCashBackAddEdit";
import PageHeading from "components/common/PageHeading";
import QrCodeImage from "components/common/QrCodeImage";

const QRCodeCashBackMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active" />;
                    } else {
                        return <div className="led-red" title="Inactive" />;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                id: "brandTitle",
                name: "Brand",
                selector: (row) => row.brandTitle,
                sortable: false,
                wrap: true,
            },
            {
                id: "title",
                name: "Title",
                selector: (row) => row.title,
                sortable: false,
                wrap: true,
            },
            {
                id: "discountType",
                name: "Discount",
                selector: (row) => row.discountType,
                sortable: false,
                wrap: true,
            },
            {
                id: "minOrderValue",
                name: "Min Order",
                selector: (row) => row.minOrderValue,
                sortable: false,
                wrap: true,
            }, {
                id: "availabilityList",
                name: "Availability",
                selector: (row) =>
                    TYPE_AVAILABILITIES.map((type, i) =>
                        row.availabilityList.find((data) => data.toLowerCase() === type.toLowerCase()) ? (
                            <span className="badge-available" title={type} key={type + "-" + i}>
                                {type === DINE_IN ? "Di" : type === HOME_DELIVERY ? "De" : type === TAKEAWAY ? "Ta" : "Sd"}
                            </span>
                        ) : (
                            <span className="badge-unavailable" title={type} key={type + "-" + i}>
                                {type === DINE_IN ? "Di" : type === HOME_DELIVERY ? "De" : type === TAKEAWAY ? "Ta" : "Sd"}
                            </span>
                        )
                    ),
                sortable: false,
            },
            {
                id: "dateRange",
                name: "Date Range",
                selector: (row) => dateUtilities.formattedDate(row.startDateTimeStamp, "dd/MM/yyyy") + "-" + dateUtilities.formattedDate(row.endDateTimeStamp, "dd/MM/yyyy"),
                sortable: false,
                wrap: true,
            },
            {
                id: "qrCode",
                name: "QR Code",
                selector: (row) => <QrCodeImage rUrl={Get_QR_Code_Cashback_Qr_Code_Post.replaceAll("{recordID}", row.uuid)} />,
                sortable: false,
                center: true,
                wrap: true,
            },
            // {
            //     id: "createdAt",
            //     name: "Created Time",
            //     selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
            //     sortable: false,
            // },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )}
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_Client_QRCode_CashBack_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_Client_QRCode_CashBack_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_Client_QRCode_CashBack_Delete.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_Client_QRCode_CashBack_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("QR Code Cash Back", "Dashboard", "/")}
            <div className="card">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_Client_QRCode_CashBack_List_Post : All_Client_QRCode_CashBack_List_Post}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showAddUpdateModal && <QRCodeCashBackAddEdit
                show={showAddUpdateModal}
                hideModal={() => setShowAddUpdateModal(false)}
                recordID={recordID}
                handleRefresh={handleRefresh}
            />}
        </>
    );
};

export default QRCodeCashBackMaster