import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

import {
  Activate_Client_Restaurant_Put,
  All_Client_Restaurant_List_Post,
  CountryList_LocalCountry_POST,
  CurrencyList_LocalCountry_GET,
  DeActivate_Client_Restaurant_Put,
  Delete_Client_Restaurant_Delete,
  Deleted_Client_Restaurant_List_POST,
  Get_Timing_Group_Key_Value_Pair_List_Post,
  Revive_Client_Restaurant_Put,
} from "api/ApiConst";
import {
  handleActive,
  handleDelete,
  handleInactive,
  handleRevive,
} from "utils/UserUtils";
import { callGetApi, callPostApi } from "api/ApiCaller";
import { SECTION_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";
import dateUtilities from "utils/DateUtilities";

import CheckboxButton from "components/common/CheckboxButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import RestaurantEdit from "./RestaurantEdit";
import RestaurantAdd from "./RestaurantAdd";

export const RestaurantMaster = () => {
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [viewDeletedList, setViewDeletedList] = useState(false);
  const [recordID, setRecordID] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [timingGroupKeyPairList, setTimingGroupKeyPairList] = useState([]);
  const [currencyOptionsList, setCurrencyOptionsList] = useState([]);

  useEffect(() => {
    if (!showAddModal) {
      setRecordID(null);
    }
  }, [showAddModal]);

  useEffect(() => {
    getCurrencyKeyValuePairList();
    getCountryList();
  }, []);

  const getCurrencyKeyValuePairList = () => {
    callGetApi(
      SECTION_LOADER,
      CurrencyList_LocalCountry_GET,
      {},
      (response) => {
        if (response.errorCode === API_SUCCESS) {
          const options = response.responsePacket.map((currency) => ({
            value: currency.currencyId,
            label: currency.currencyTitle,
            ...currency,
          }));
          setCurrencyOptionsList(options);
        } else {
          toast.error(response.message);
        }
      }
    );
  };
  const handleRefresh = useCallback(() => {
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag, showUpdateModal]);

  const getCountryList = () => {
    let rUrl = CountryList_LocalCountry_POST;
    let rObj = {};
    callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setCountryList(response.responsePacket);
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Timing Group Key Pair List
  const getTimingGroupKeyPairList = (brandId) => {
    let rUrl = Get_Timing_Group_Key_Value_Pair_List_Post + "/" + brandId;
    const rObj = {};
    callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setTimingGroupKeyPairList(response.responsePacket);
      } else {
        toast.error(response.message);
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        id: "status",
        name: "",
        selector: (row) => {
          if (row.active) {
            return <div className="led-green" title="Active" />;
          } else {
            return <div className="led-red" title="Inactive" />;
          }
        },
        width: "60px",
        sortable: false,
      },
      {
        id: "restaurantTitle",
        name: "Restaurant Name",
        selector: (row) => (
          <Link className="d-grid" onClick={() => handleEdit(row)}>
            <span className="my-2">{row.restaurantTitle}</span>
            {/* <span className="my-2">{row.restaurantDescription}</span> */}
          </Link>
        ),
        sortable: false,
        wrap: true,
        width: "175px",
      },
      {
        id: "brandTitle",
        name: "Brand",
        selector: (row) => row.brandTitle,
        sortable: false,
        wrap: true,
        width: "120px",
      },
      {
        id: "restaurantOwnerDetails",
        name: "Owner Details",
        selector: (row) => (
          <div className="my-3">
            <div className="my-2">
              <span>
                <i className="bx bx-user" title="Name" />
              </span>{" "}
              <span className="ms-1">{row.ownerFullName}</span>
            </div>
            <div className="my-2">
              <span>
                <i className="bx bx-phone-call" title="Mobile" />
              </span>{" "}
              <span className="ms-1">{row.ownerMobile}</span>
            </div>
            <div className="my-2">
              <span>
                <i className="bx bx-envelope" title="Email" />
              </span>{" "}
              <span className="ms-1">{row.ownerEmail}</span>
            </div>
            <div className="my-2">
              <span>
                <i className="bx bx-wallet-alt" title="Wallet Balance" />
              </span>{" "}
              <span className="ms-1">{row.ownerWalletAmount}</span>
            </div>
          </div>
        ),
        sortable: false,
        // wrap: true,
      },
      {
        id: "availableOrderType",
        name: "Availability",
        selector: (row, i) => (
          <div className="my-2" key={`availableOrderType-${i}`}>
            <span
              className={`badge-${row.dineIn ? "available" : "unavailable"}`}
              title="DineIn"
              key={`dineIn-${i}`}
            >
              Di
            </span>
            <span
              className={`badge-${row.delivery ? "available" : "unavailable"}`}
              title="HomeDelivery"
              key={`delivery-${i}`}
            >
              De
            </span>
            <span
              className={`badge-${row.takeaway ? "available" : "unavailable"}`}
              title="TakeAway"
              key={`takeaway-${i}`}
            >
              Ta
            </span>
            <span
              className={`badge-${row.stealDeal ? "available" : "unavailable"}`}
              title="StealDeal"
              key={`stealDeal-${i}`}
            >
              Sd
            </span>
          </div>
        ),
        sortable: false,
        width: "130px",
      },
      {
        id: "createdAt",
        name: "Created Time",
        selector: (row) =>
          dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
        sortable: false,
        width: "140px",
      },
      {
        id: "action",
        name: "",
        selector: (row) => getActionButtons(row),
        width: "80px",
        sortable: false,
      },
    ],
    [handleRefresh]
  );

  const getActionButtons = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="dropdown">
          <button
            className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle"
            title="More"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ padding: "5px 10px 8px 10px" }}
          >
            <i className="bx bx-chevron-down"></i>
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            <>
              {row.deleted ? (
                <li onClick={() => handleReviveData(row)}>
                  <i className="bx bx-log-out-circle"></i> Revive
                </li>
              ) : (
                <>
                  {row.deleted ? (
                    <li onClick={() => handleReviveData(row)}>
                      <i className="bx bx-log-out-circle"></i> Revive
                    </li>
                  ) : (
                    <>
                      <li onClick={() => handleDeleteData(row)}>
                        <i className="bx bx-trash"></i> Delete
                      </li>
                      <li onClick={() => handleActiveInactive(row)}>
                        {row.active ? (
                          <>
                            <i className="bx bx-x-circle"></i> Inactive
                          </>
                        ) : (
                          <>
                            <i className="bx bx-check-circle"></i> Active
                          </>
                        )}
                      </li>
                    </>
                  )}
                </>
              )}
            </>
          </ul>
        </div>
      </div>
    );
  };

  const handleEdit = (row) => {
    setRecordID(row.uuid);
    getTimingGroupKeyPairList(row.brandUuid);
    setShowUpdateModal(true);
  };

  const handleActiveInactive = (row) => {
    if (row.active) {
      handleInactive(
        DeActivate_Client_Restaurant_Put.replaceAll("{recordID}", row.uuid),
        handleRefresh
      );
    } else {
      handleActive(
        Activate_Client_Restaurant_Put.replaceAll("{recordID}", row.uuid),
        handleRefresh
      );
    }
  };

  //Delete Record
  const handleDeleteData = (row) => {
    handleDelete(
      Delete_Client_Restaurant_Delete.replaceAll("{recordID}", row.uuid),
      handleRefresh
    );
  };

  //Revive Record
  const handleReviveData = (row) => {
    handleRevive(
      Revive_Client_Restaurant_Put.replaceAll("{recordID}", row.uuid),
      handleRefresh
    );
  };

  const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

  return (
    <>
      {PageHeading("Restaurant List", "Dashboard", "/")}
      <div className="card">
        <CustomButton
          handleClick={() => setShowAddModal(true)}
          title="Create New"
          iconClass="bx bx-plus"
          buttonClass="createButton"
        />
        <div className="card-body tableView">
          <PaginationDataTable
            paginationUrl={
              viewDeletedList
                ? Deleted_Client_Restaurant_List_POST
                : All_Client_Restaurant_List_Post
            }
            serverSidePagination={true}
            search={true}
            pagination={false}
            columns={columns}
          />
          <CheckboxButton
            title="Deleted List"
            handleClick={handleToggleDeletedView}
            additionalClass="deletedBtn ml-auto"
          />
        </div>
      </div>
      {showAddModal && (
        <RestaurantAdd
          show={showAddModal}
          hideModal={() => setShowAddModal(false)}
          recordID={recordID}
          handleRefresh={handleRefresh}
          currencyOptionsList={currencyOptionsList}
        />
      )}
      {showUpdateModal && (
        <RestaurantEdit
          show={showUpdateModal}
          hideModal={() => setShowUpdateModal(false)}
          recordID={recordID}
          handleRefresh={handleRefresh}
          countryList={countryList}
          timingGroupKeyPairList={timingGroupKeyPairList}
          currencyOptionsList={currencyOptionsList}
        />
      )}
    </>
  );
};

export default RestaurantMaster;
