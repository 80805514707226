import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { isCharSpc, isEmail, isMobile, isValidIsdCode } from "utils/TextUtils";
import { updateOwnerDetails } from "./restaurantUtils";

export const OwnerDetails = ({ recordOwnerDetails, currencyOptionsList }) => {
  const [ownerDetails, setOwnerDetails] = useState(recordOwnerDetails);
  const currencyId = ownerDetails.currencyDetail.currencyId;
  const currencyDetails = currencyOptionsList;
  const [currrenySelected, setCurrrenySelected] = useState("");
  useEffect(() => {
    setCurrrenySelected(
      currencyDetails.find((obj) => obj.currencyId === currencyId)
    );
  }, []);

  const handleSaveChange = () => {
    const rObj = {
      firstName: ownerDetails.firstName,
      lastName: ownerDetails.lastName,
      isdCode: ownerDetails.isdCode,
      mobile: ownerDetails.mobile,
      email: ownerDetails.email,
      username: ownerDetails.username,
      password: ownerDetails.password,
      currencyDetail: {
        currencyId: currrenySelected.currencyId,
        currencyTitle: currrenySelected.currencyTitle,
        currencySubUnitTitle: currrenySelected.currencySubUnitTitle,
        unitSubdivision: currrenySelected.unitSubdivision,
        currencyCode: currrenySelected.currencyCode,
        currencySymbol: currrenySelected.currencySymbol,
      },
    };
    setOwnerDetails(prev => ({ ...prev, ...rObj }));
    updateOwnerDetails(ownerDetails.uuid, rObj);
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName" && value.length !== 0) {
      if (!isCharSpc(value)) {
        return;
      }
    }
    if (name === "lastName" && value.length !== 0) {
      if (!isCharSpc(value)) {
        return;
      }
    }
    if (name === "isdCode" && value.length !== 0) {
      if (!isValidIsdCode(value)) {
        return;
      }
    }
    if (name === "mobile" && value.length !== 0) {
      if (!isMobile(value)) {
        return;
      }
    }
    if (name === "email" && value.length !== 0) {
      if (!isEmail(value)) {
        return;
      }
    }
    setOwnerDetails({ ...ownerDetails, [name]: value });
  };

  const handleSelectCurrency = (opt) => {
    setCurrrenySelected({
      label: opt.label,
      value: opt.value,
      ...opt,
    });
  };

  return (
    <>
      <fieldset className="my-3">
        <legend>Owner Details</legend>
        <div className="form-group row mb-3">
          <div className="col-lg-6 my-2">
            <label>First Name </label>
            <input
              type="text"
              maxLength={20}
              className="form-control input-default"
              name="firstName"
              onChange={handleTextChange}
              value={ownerDetails?.firstName}
            />
          </div>
          <div className="col-lg-6 my-2">
            <label>Last Name </label>
            <input
              type="text"
              maxLength={20}
              className="form-control input-default"
              name="lastName"
              onChange={handleTextChange}
              value={ownerDetails?.lastName}
            />
          </div>
          <div className="col-lg-6 my-2">
            <label>ISD CODE *</label>
            <input
              type="text"
              maxLength={20}
              className="form-control input-default"
              name="isdCode"
              onChange={handleTextChange}
              value={ownerDetails?.isdCode}
            />
          </div>
          <div className="col-lg-6 my-2">
            <label>Mobile *</label>
            <input
              type="number"
              className="form-control input-default"
              name="mobile"
              onChange={handleTextChange}
              value={ownerDetails?.mobile}
            />
          </div>
          <div className="col-lg-6 my-2">
            <label>Email *</label>
            <input
              type="email"
              className="form-control input-default"
              name="email"
              onChange={handleTextChange}
              value={ownerDetails?.email}
            />
          </div>
          <div className="col-lg-6 my-2">
            <label>Username *</label>
            <input
              type="email"
              className="form-control input-default"
              name="username"
              onChange={handleTextChange}
              value={ownerDetails?.username}
            />
          </div>
          <div className="col-lg-6 my-2">
            <label>Currency Selector *</label>
            <Select
              value={currrenySelected}
              onChange={handleSelectCurrency}
              options={currencyDetails}
            />
          </div>
          <div className="col-lg-6 my-2">
            <label> New Password *</label>
            <input
              type="password"
              className="form-control input-default"
              placeholder="Enter New Password"
              name="password"
              onChange={handleTextChange}
              value={ownerDetails?.password ? ownerDetails?.password : ""}
            />
          </div>
        </div>
        <Button
          className="mb-3 float-end"
          onClick={(opt) => handleSaveChange(opt)}
          variant="primary"
        >
          Save Changes
        </Button>
      </fieldset>
    </>
  );
};

export default OwnerDetails;
