import { useCallback, useMemo, useState } from "react";
import {
    All_Client_OTP_List_Post
} from "api/ApiConst";
import dateUtilities from "utils/DateUtilities";

import PaginationDataTable from "components/common/PaginationDataTable";
import PageHeading from "components/common/PageHeading";

const OTPMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "createdAt",
                name: "Created Time",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
                wrap: true,
            },
            {
                id: "expiredAt",
                name: "Expired At",
                selector: (row) => dateUtilities.formattedDate(row.expiredAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
                wrap: true,
            },
            {
                id: "otpCode",
                name: "Code",
                selector: (row) => row.otpCode,
                sortable: false,
                wrap: true,
            },
            {
                id: "sentOn",
                name: "Sent On",
                selector: (row) => row.sentOn,
                sortable: false,
            },
            {
                id: "sentOnEmailMobile",
                name: "Sent On Email/Mobile",
                selector: (row) => row.sentOnEmailMobile,
                sortable: false,
            },
            {
                id: "verificationType",
                name: "Verification Type",
                selector: (row) => row.verificationType,
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    return (
        <>
            {PageHeading("OTP List", "Dashboard", "/")}
            <div className="card searchOnlyBlock">
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={All_Client_OTP_List_Post}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                </div>
            </div>
        </>
    )
}

export default OTPMaster