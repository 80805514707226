import { useCallback, useEffect, useMemo, useState } from "react";

import {
    Activate_Restaurant_Staff_DeliveryBoy_PUT,
    All_Restaurant_Staff_DeliveryBoy_List_POST,
    DeActivate_Restaurant_Staff_DeliveryBoy_PUT,
    Delete_Restaurant_Staff_DeliveryBoy_DELETE,
    Deleted_Restaurant_Staff_DeliveryBoy_List_POST,
    Reset_PWD_Restaurant_Staff_DeliveryBoy_Put,
    Revive_Restaurant_Staff_DeliveryBoy_PUT,
} from "api/ApiConst";
import { handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import dateUtilities from "utils/DateUtilities";

import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import RestaurantDeliveryBoyAddEdit from "./RestaurantDeliveryBoyAddEdit";
import CheckboxButton from "components/common/CheckboxButton";
import ChangeUserPassword from "components/common/ChangeUserPassword";

export const RestaurantDeliveryBoyMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [showChangePwdModal, setShowChangePwdModal] = useState(false);

    useEffect(() => {
        if (!showAddUpdateModal && !showChangePwdModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal, showChangePwdModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "Status",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active" />;
                    } else {
                        return <div className="led-red" title="Inactive" />;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                id: "name",
                name: "Name",
                selector: (row) => row.firstName + " " + row.lastName,
                sortable: false,
            },
            {
                id: "mobile",
                name: "Mobile",
                selector: (row) => row.isdCode + " " + row.mobile,
                sortable: false,
            },
            {
                id: "email",
                name: "Email",
                selector: (row) => row.email,
                sortable: false,
            },
            {
                id: "createdAt",
                name: "Registered At",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
            },
            // {
            //     id: "walletBalance",
            //     name: "Wallet",
            //     selector: (row) => <>{row?.walletBalance.toFixed(2)} $</>,
            //     sortable: false,
            // },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "120px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )}
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                    <li onClick={() => handleChangePassword(row)}>
                                        <i className="bx bxs-key" /> Change Password
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_Restaurant_Staff_DeliveryBoy_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_Restaurant_Staff_DeliveryBoy_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_Restaurant_Staff_DeliveryBoy_DELETE.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_Restaurant_Staff_DeliveryBoy_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Change Password
    const handleChangePassword = (row) => {
        setRecordID(row.uuid);
        setShowChangePwdModal(true);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("Delivery Boy List", "Dashboard", "/")}
            <div className="card">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView mt-4">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_Restaurant_Staff_DeliveryBoy_List_POST : All_Restaurant_Staff_DeliveryBoy_List_POST}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showAddUpdateModal && <RestaurantDeliveryBoyAddEdit show={showAddUpdateModal} hideModal={() => setShowAddUpdateModal(false)} recordID={recordID} handleRefresh={handleRefresh} />}
            {showChangePwdModal && <ChangeUserPassword
                show={showChangePwdModal}
                hideModal={() => setShowChangePwdModal(false)}
                recordID={recordID}
                handleRefresh={handleRefresh}
                rUrl={Reset_PWD_Restaurant_Staff_DeliveryBoy_Put.replaceAll("{recordID}", recordID)}
            />}
        </>
    );
};

export default RestaurantDeliveryBoyMaster;