import React, { useState } from "react";
import { toast } from "react-hot-toast";
import Select from "react-select";

import { callPostApi } from "api/ApiCaller";
import { Save_Client_Restaurant_Post } from "api/ApiConst";

import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";
import { isAlphaNumercSpecialChar, isAlphaNumercSpecialCharSpc, isAlphaNumeric, isEmpty, isMobile, isValidIsdCode } from "utils/TextUtils";

import { useSelector } from "react-redux";
import ImageSelector from "components/common/ImageSelector";
import ModalWrapper from "components/common/ModalWrapper";
import { initialRestaurantAddValue } from "./restaurantInitialValues";

export const RestaurantAdd = ({ show, hideModal, recordID, handleRefresh, currencyOptionsList }) => {
    const { brandKeyValuePairs } = useSelector((state) => state.utility);
    const [record, setRecord] = useState({ ...initialRestaurantAddValue, brandUuid: brandKeyValuePairs.length === 1 ? brandKeyValuePairs[0] : "" });
    const [showPassword, setShowPassword] = useState(false);
    const [selectedCurrencyOptionsList, setSelectedCurrencyOptionsList] = useState("")

    const { restaurantOwner } = record;

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value } = e.target;
        if (id === "restaurantTitle" && value.length !== 0) {
            if (!isAlphaNumercSpecialCharSpc(value)) {
                return;
            }
        }
        if (id === "gstNumber" && value.length !== 0) {
            if (!isAlphaNumeric(value)) {
                return;
            }
        }
        if (id === "tinNumber" && value.length !== 0) {
            if (!isAlphaNumeric(value)) {
                return;
            }
        }
        if (id === "vatNumber" && value.length !== 0) {
            if (!isAlphaNumeric(value)) {
                return;
            }
        }
        // if(id === "gstNumber" && value.length !== 0) {
        //     if(!isValidGST(value)) {
        //         return console.log("2nd",value);  
        //     }
        // }
        setRecord((prev) => ({ ...prev, [id]: value }));
    };

    //Select currency Option  event
    const handleSelect = (e) => {
        const recObj = {
            currencyId: e.currencyId,
            currencyTitle: e.currencyTitle,
            currencySubUnitTitle: e.currencySubUnitTitle,
            unitSubdivision: e.unitSubdivision,
            currencyCode: e.currencyCode,
            currencySymbol: e.currencySymbol,
        }
        setRecord({ ...record, currencyDetail: recObj });
        setSelectedCurrencyOptionsList({
            value: e.value,
            label: e.label,
            ...e
        });
    };

    //Select Brand
    const handleSelectBrand = (e) => {
        setRecord((prev) => ({ ...prev, brandUuid: e }));
    };

    //Text Change Event
    const handleOwnerDetailChange = (e) => {
        const { id, value, type } = e.target;
        if (id === "email" && value.length !== 0) {
            if (!isAlphaNumercSpecialChar(value)) {
                return;
            }
        }
        if (id === "username" && value.length !== 0) {
            if (!isAlphaNumercSpecialChar(value)) {
                return;
            }
        }
        if (type === "number") {
            if (id === "mobile") {
                if (value.length > 10) {
                    return;
                }
            }
            setRecord((prev) => ({ ...prev, restaurantOwner: { ...prev.restaurantOwner, [id]: parseInt(value) } }));
        } else {
            setRecord((prev) => ({ ...prev, restaurantOwner: { ...prev.restaurantOwner, [id]: value } }));
        }
    };
    // Restaurant Icon Image Change
    const handleIconChange = (imageRefId) => {
        setRecord((prev) => ({ ...prev, iconImageRefId: imageRefId }));
    };

    // Validation Check
    const checkInputValidation = () => {

        if (isEmpty(restaurantOwner.firstName)) {
            toast.error(`First name must not be empty`, { id: "toast" });
            return false;
        } else if (restaurantOwner.firstName.length < 3) {
            toast.error(`First name must be more then 2 character.`, { id: "toast" });
            return false;
        } else if (isEmpty(restaurantOwner.lastName)) {
            toast.error(`Last must not be empty`, { id: "toast" });
            return false;
        } else if (restaurantOwner.lastName.length < 3) {
            toast.error(`Last name must be more then 2 character.`, { id: "toast" });
            return false;
        } else if (isEmpty(restaurantOwner.isdCode)) {
            toast.error(`isdCode must not be empty`, { id: "toast" });
            return false;
        } else if (!isValidIsdCode(restaurantOwner.isdCode)) {
            toast.error(`Please Provide Valid ISD Code`, { id: "toast" });
            return false;
        } else if (isEmpty(restaurantOwner.mobile)) {
            toast.error(`Mobile Number must not be empty`, { id: "toast" });
            return false;
        } else if (!isMobile(restaurantOwner.mobile)) {
            toast.error(`Please Provide Valid Mobile Number`, { id: "toast" });
            return false;
        } else if (isEmpty(restaurantOwner.email)) {
            toast.error(`Email must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(restaurantOwner.username)) {
            toast.error(`User name must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(restaurantOwner.password)) {
            toast.error(`Password must not be empty`, { id: "toast" });
            return false;
        } else if (restaurantOwner.password.length < 6) {
            toast.error('Password must be more than 5 characters', { id: "toast" });
            return false;
        }
        else if (isEmpty(record.currencyDetail.currencyId)) {
            toast.error(`Currency must not be empty`, { id: "toast" });
            return false;
        }
        else if (isEmpty(record.brandUuid)) {
            toast.error(`Brand must not be empty`, { id: "toast" });
            return false;
        }
        else if (isEmpty(record.restaurantTitle)) {
            toast.error(`Restaurant title must not be empty`, { id: "toast" });
            return false;
        }
        //  else if (isEmpty(record.gstNumber)) {
        //     toast.error(`GST number must not be empty`, { id: "toast" });
        //     return false;
        // }
        //  else if (record.gstNumber.length > 50) {
        //     toast.error(`GST number must not be more then 50 character.`, { id: "toast" });
        //     return false;
        // }
        //  else if (isEmpty(record.tinNumber)) {
        //     toast.error(`TIN number must not be empty`, { id: "toast" });
        //     return false;
        // } 
        // else if (record.tinNumber.length > 50) {
        //     toast.error(`TIN number must not be more then 50 character.`, { id: "toast" });
        //     return false;
        // }
        //  else if (isEmpty(record.vatNumber)) {
        //     toast.error(`VAT number must not be empty`, { id: "toast" });
        //     return false;
        // }
        //  else if (record.vatNumber.length > 50) {
        //     toast.error(`VAT number must not be more then 50 character.`, { id: "toast" });
        //     return false;
        // } 
        else if (isEmpty(record.iconImageRefId)) {
            toast.error(`Restaurant icon must not be empty`, { id: "toast" });
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            recordObj.brandUuid = record.brandUuid.key;
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Client_Restaurant_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, { id: "toast" });
                        hideModal();
                        handleRefresh();
                    } else {
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} backdrop="static" hide={hideModal} header={`${recordID ? "Update" : "Add"} Restaurant`} handleSubmit={handleSubmit}>
            <div className="row">
                <div className="col-lg-6">
                    <fieldset>
                        <legend>Owner Details</legend>
                        <div className="form-group row">
                            <div className="col-lg-12 my-2">
                                <label>First Name *</label>
                                <input type="text" maxLength={20} className="form-control input-default" id="firstName" onChange={handleOwnerDetailChange} value={restaurantOwner.firstName} />
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>Last Name *</label>
                                <input type="text" maxLength={20} className="form-control input-default" id="lastName" onChange={handleOwnerDetailChange} value={restaurantOwner.lastName} />
                            </div>
                            <div className="col-lg-5 my-2">
                                <label>ISD CODE*</label>
                                <input type="text" className="form-control input-default" id="isdCode" onChange={handleOwnerDetailChange} value={restaurantOwner.isdCode} />
                            </div>
                            <div className="col-lg-7 my-2">
                                <label>Mobile*</label>
                                <input type="number" className="form-control input-default" id="mobile" onChange={handleOwnerDetailChange} value={restaurantOwner.mobile} />
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>Email *</label>
                                <input type="email" maxLength={65} className="form-control input-default" id="email" onChange={handleOwnerDetailChange} value={restaurantOwner.email} />
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>User Name*</label>
                                <input type="text" maxLength={25} className="form-control input-default" id="username" onChange={handleOwnerDetailChange} value={restaurantOwner.username} />
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>Password*</label>
                                <div className="input-group auth-pass-inputgroup">
                                    <input type={showPassword ? "text" : "password"} maxLength={16} className="form-control input-default" id="password" onChange={handleOwnerDetailChange} value={restaurantOwner.password} />
                                    <button className="btn btn-light " type="button" id="password-addon" onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <i className="mdi mdi-eye-off-outline" /> : <i className="mdi mdi-eye-outline" />}
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>Currency selector</label>
                                <Select options={currencyOptionsList} onChange={(e) => handleSelect(e)} value={selectedCurrencyOptionsList} />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="col-lg-6">
                    <fieldset>
                        <legend>Restaurant Details</legend>
                        <div className="form-group row">
                            <div className="col-lg-12 my-2">
                                <label>Brand *</label>
                                <Select options={brandKeyValuePairs} value={record.brandUuid} onChange={handleSelectBrand} />
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>Restaurant Title*</label>
                                <input type="text" maxLength={30} className="form-control input-default" id="restaurantTitle" onChange={handleTextChange} value={record.restaurantTitle} />
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>Gst No.*</label>
                                <input type="text" maxLength={15} className="form-control input-default" id="gstNumber" onChange={handleTextChange} value={record.gstNumber} />
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>Tin No.*</label>
                                <input type="text" maxLength={15} className="form-control input-default" id="tinNumber" onChange={handleTextChange} value={record.tinNumber} />
                            </div>
                            <div className="col-lg-12 my-2">
                                <label>Vat No.*</label>
                                <input type="text" maxLength={15} className="form-control input-default" id="vatNumber" onChange={handleTextChange} value={record.vatNumber} />
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <fieldset className="my-4">
                <legend>Restaurant Icon*</legend>
                <div className="col-lg-12 my-1">
                    <ImageSelector
                        style={{
                            height: "120px",
                            width: "100%",
                            overflow: "hidden",
                        }}
                        handleImageChange={handleIconChange}
                        selectedImage={record.iconImageRefId}
                    />
                </div>
            </fieldset>
        </ModalWrapper>
    );
};

export default RestaurantAdd;
