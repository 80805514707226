import { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import { Button } from "react-bootstrap";

import {
  Details_Client_Event_Ticket_Get,
  Save_Client_Event_Ticket_Post,
  Update_Client_Event_Ticket_Put,
} from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isEmpty } from "utils/TextUtils";
import { API_SUCCESS } from "api/ResponseCodes";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";
import Cookies from "universal-cookie";

const defaultRecordObj = {
  eventUuid: "",
  ticketName: "",
  ticketDescription: "",
  ticketPrice: "",
  maxNumberOfTickets: "",
  termsAndCondition: "",
  giftCouponAmount: "",
  soldTicketCount: "",
};

const EventTicketAddEdit = (props) => {
  const { show, hideModal, eventID, recordID, handleRefresh } = props;

  const [record, setRecord] = useState({
    ...defaultRecordObj,
    eventUuid: eventID,
  });

  const currencyDetail = new Cookies().get("currencyDetail");

  useEffect(() => {
    if (recordID) {
      callGetApi(
        MODAL_LOADER,
        Details_Client_Event_Ticket_Get.replaceAll(
          "{recordID}",
          recordID
        ).replaceAll("{eventID}", eventID),
        {},
        (response) => {
          const res = response.responsePacket;
          setRecord(res);
        }
      );
    }
  }, [recordID]);

  //Text Change Event
  const handleTextChange = (e) => {
    const { id, value, type } = e.target;
    if (type === "number") {
      if (id === "maxNumberOfTickets") {
        setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
      } else {
        setRecord((prev) => ({ ...prev, [id]: parseFloat(value) }));
      }
    } else {
      setRecord((prev) => ({ ...prev, [id]: value }));
    }
  };

  const checkInputValidation = () => {
    if (isEmpty(record.ticketName)) {
      toast.error(`Ticket name must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(record.ticketPrice)) {
      toast.error(`Price must not be empty`, { id: "toast" });
      return false;
    } else if (record.ticketPrice < 0) {
      toast.error(`Price must not be negative`, { id: "toast" });
      return false;
    } else if (isEmpty(record.maxNumberOfTickets)) {
      toast.error(`Maximum Tickets must not be empty`, { id: "toast" });
      return false;
    } else if (record.maxNumberOfTickets < 0) {
      toast.error(`Maximum Tickets must not be negative`, { id: "toast" });
      return false;
    } else if (isEmpty(record.giftCouponAmount)) {
      toast.error(`Coupon Amount must not be empty`, { id: "toast" });
      return false;
    } else if (record.giftCouponAmount < 0) {
      toast.error(`Coupon Amount must not be negative`, { id: "toast" });
      return false;
    } else if (isEmpty(record.termsAndCondition)) {
      toast.error(`Terms & Condition must not be empty`, { id: "toast" });
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    try {
      if (!checkInputValidation()) {
        return;
      }
      let recordObj = record;
      if (!recordID || recordID === "") {
        callPostApi(
          MODAL_LOADER,
          Save_Client_Event_Ticket_Post.replaceAll("{eventID}", eventID),
          recordObj,
          {},
          (response) => {
            if (response.errorCode === API_SUCCESS) {
              toast.success(response.message, {id: "toast"});
              hideModal();
              handleRefresh();
            } else {
              toast.error(response.message, {id: "toast"});
            }
          }
        );
      } else {
        delete recordObj.restaurantUuid;
        callPutApi(
          MODAL_LOADER,
          Update_Client_Event_Ticket_Put.replaceAll(
            "{recordID}",
            recordID
          ).replaceAll("{eventID}", eventID),
          recordObj,
          {},
          (response) => {
            if (response.errorCode === API_SUCCESS) {
              toast.success(response.message, {id: "toast"});
              hideModal();
              handleRefresh();
            } else {
              toast.error(response.message, {id: "toast"});
            }
          }
        );
      }
    } catch (error) {
      return;
    }
  };

  return (
    <ModalWrapper
      show={show}
      hide={hideModal}
      header={`${recordID ? "Update" : "Add"} Ticket`}
      centered={true}
      size="md"
      backdrop="static"
      dialogClassName="right-modal-dialog"
      contentClassName="right-modal-content"
    >
      <div className="form-group row mb-3">
        <div className="col-lg-12">
          <label>Ticket Name *</label>
          <input
            type="text"
            className="form-control input-default"
            id="ticketName"
            onChange={handleTextChange}
            value={record.ticketName}
          />
        </div>
        <div className="col-lg-12 my-2">
          <label>Price *</label>
          <div className="input-group">
            <input
              type="number"
              className="form-control input-default"
              id="ticketPrice"
              onChange={handleTextChange}
              value={record.ticketPrice}
            />
            <div className="input-group-append">
              <span className="input-group-text bg-red">
                {currencyDetail?.currencySymbol}
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-12 my-2">
          <label>Maximum Tickets *</label>
          <input
            type="number"
            className="form-control input-default"
            id="maxNumberOfTickets"
            onChange={handleTextChange}
            value={record.maxNumberOfTickets}
          />
        </div>
        <div className="col-lg-12 my-2">
          <label>Gift Coupon Amount *</label>
          <div className="input-group">
            <input
              type="number"
              className="form-control input-default"
              id="giftCouponAmount"
              onChange={handleTextChange}
              value={record.giftCouponAmount}
            />
            <div className="input-group-append">
              <span className="input-group-text bg-red">
                {currencyDetail?.currencySymbol}
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-12 my-2">
          <label>Terms & Conditions *</label>
          <textarea
            className="form-control input-default"
            id="termsAndCondition"
            cols="30"
            rows="2"
            onChange={handleTextChange}
            value={record.termsAndCondition}
          />
        </div>
        <div className="col-lg-12 my-2">
          <label>Description</label>
          <textarea
            className="form-control input-default"
            id="ticketDescription"
            cols="30"
            rows="2"
            onChange={handleTextChange}
            value={record.ticketDescription}
          />
        </div>
      </div>
      <Button
        variant="primary"
        className="float-end mt-0"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </ModalWrapper>
  );
};

export default EventTicketAddEdit;
