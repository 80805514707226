import React from 'react'

import ImageSelector from 'components/common/ImageSelector';

const MultiImageSelector = (props) => {
    const { selectedImage, index, handleImageSelect } = props;

    const handleImageChange = (imageRefId) => {
        handleImageSelect(imageRefId, index)
    };
    //ToDo >> Image loader
    return (
        <ImageSelector
            style={{
                height: "40px",
                width: "100%",
                // borderRadius: "50%",
                overflow: "hidden",
            }}
            handleImageChange={handleImageChange}
            selectedImage={selectedImage}
        />
    )
}

export default MultiImageSelector