import React, { useState } from "react";

import { toast } from "react-hot-toast";

import { Add_Currency_POST } from "api/ApiConst";
import { callPostApi } from "api/ApiCaller";
import { isEmpty, isNumeric } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";

const CurrencyAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const defaultRecordObj = {
        currencyTitle: "",
        currencySubUnitTitle: "",
        unitSubdivision: "",
        currencyCode: "",
        currencySymbol: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    const checkInputValidation = () => {
        if (isEmpty(record.currencyTitle)) {
            toast.error(`Currency title must not be empty`, { id: "toast" });
            return false;
        } else if (record.currencyTitle.length > 20) {
            toast.error(`Currency title must not be more then 20 character.`, { id: "toast" });
            return false;
        } else if (isEmpty(record.currencySubUnitTitle)) {
            toast.error(`Currency sub title must not be empty`, { id: "toast" });
            return false;
        } else if (record.currencySubUnitTitle.length > 20) {
            toast.error(`Currency sub title must not be more then 20 character.`, { id: "toast" });
            return false;
        } else if (isEmpty(record.currencyCode)) {
            toast.error(`Currency code must not be empty`, { id: "toast" });
            return false;
        } else if (record.currencyCode.length > 5) {
            toast.error(`Currency code must not be more then 5 character.`, { id: "toast" });
            return false;
        } else if (isEmpty(record.currencySymbol)) {
            toast.error(`Currency symbol must not be empty`, { id: "toast" });
            return false;
        } else if (record.currencySymbol.length > 20) {
            toast.error(`Currency symbol must not be more then 20 character.`, { id: "toast" });
            return false;
        } else if (isEmpty(record.unitSubdivision)) {
            toast.error(`unit sub division must not be empty or Zero`, { id: "toast" });
            return false;
        } else if (record.unitSubdivision < 0) {
            toast.error(`unit sub division must not be negative`, { id: "toast" });
            return false;
        } else if(record.unitSubdivision > 10000000){
            toast.error(`unit sub division must not be more then 10 Million.`, { id: "toast" });
            return false;
        }

        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            callPostApi(MODAL_LOADER, Add_Currency_POST, recordObj, {}, (response) => {
                toast.success(response.message, {id: "toast"});
                hideModal();
                handleRefresh();
            });
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show}  backdrop="static" hide={hideModal} header={`${recordID ? "Update" : "Add"} Currency`} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-6">
                    <label>Currency Title *</label>
                    <input type="text" className="form-control input-default" id="currencyTitle" onChange={handleTextChange} value={record.currencyTitle} />
                </div>
                <div className="col-lg-6">
                    <label>Currency Sub Title *</label>
                    <input type="text" className="form-control input-default" id="currencySubUnitTitle" onChange={handleTextChange} value={record.currencySubUnitTitle} />
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-lg-6">
                    <label>Currency Code *</label>
                    <input type="text" className="form-control input-default" id="currencyCode" onChange={handleTextChange} value={record.currencyCode} />
                </div>
                <div className="col-lg-6">
                    <label>Currency Symbol *</label>
                    <input type="text" className="form-control input-default" id="currencySymbol" onChange={handleTextChange} value={record.currencySymbol} />
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-lg-6">
                    <label>Unit Sub Division *</label>
                    <input type="number" className="form-control input-default" id="unitSubdivision" onChange={handleTextChange} value={record.unitSubdivision} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default CurrencyAddEdit;
