export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const SHOW_SECTION_LOADING = "SHOW_SECTION_LOADING";
export const HIDE_SECTION_LOADING = "HIDE_SECTION_LOADING";

export const SHOW_PAGE_LOADING = "SHOW_PAGE_LOADING";
export const HIDE_PAGE_LOADING = "HIDE_PAGE_LOADING";

export const SHOW_MODAL_LOADING = "SHOW_MODAL_LOADING";
export const HIDE_MODAL_LOADING = "HIDE_MODAL_LOADING";

export const UPDATE_BRAND_KEY_VALUE_PAIRS = "UPDATE_BRAND_KEY_VALUE_PAIRS";

export const SHOW_BULK_INVOICE_DOWNLOAD_MODAL = "SHOW_BULK_INVOICE_DOWNLOAD_MODAL";
export const HIDE_BULK_INVOICE_DOWNLOAD_MODAL = "HIDE_BULK_INVOICE_DOWNLOAD_MODAL";
