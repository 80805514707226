import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Activate_Client_Coupon_Code_Put,
  All_Client_Coupon_Code_List_Post,
  DeActivate_Client_Coupon_Code_Put,
  Delete_Client_Coupon_Code_Delete,
  Deleted_Client_Coupon_Code_List_Post,
  Revive_Client_Coupon_Code_Put
} from "api/ApiConst";
import { handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import { DINE_IN, HOME_DELIVERY, TAKEAWAY, TYPE_AVAILABILITIES } from "utils/constants";
import dateUtilities from "utils/DateUtilities";

import CheckboxButton from "components/common/CheckboxButton";
import CustomButton from "components/common/CustomButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import CouponCodeAddEdit from "./CouponCodeAddEdit";
import PageHeading from "components/common/PageHeading";

const CustomerCouponCodeMaster = () => {
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [viewDeletedList, setViewDeletedList] = useState(false);
  const [recordID, setRecordID] = useState(null);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);

  useEffect(() => {
    if (!showAddUpdateModal) {
      setRecordID(null);
    }
  }, [showAddUpdateModal]);

  const handleRefresh = useCallback(() => {
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag]);

  const columns = useMemo(
    () => [
      {
        id: "status",
        name: "",
        selector: (row) => {
          if (row.active) {
            return <div className="led-green" title="Active" />;
          } else {
            return <div className="led-red" title="Inactive" />;
          }
        },
        width: "60px",
        sortable: false,
      },
      {
        id: "title",
        name: "Title",
        selector: (row) => row.title,
        sortable: false,
      }, {
        id: "brandTitle",
        name: "Brand",
        selector: (row) => row.brandTitle,
        sortable: false,
      },
      {
        id: "title",
        name: "Title",
        selector: (row) => row.title,
        sortable: false,
        wrap: true,
      },
      {
        id: "customerName",
        name: "Customer",
        selector: (row) => row?.customerName,
        sortable: false,
        wrap: true,
      },
      {
        id: "discountType",
        name: "Discount",
        selector: (row) => row.discountType,
        sortable: false,
        wrap: true,
      },
      {
        id: "minOrderValue",
        name: "Min Order",
        selector: (row) => row.minOrderValue,
        sortable: false,
        wrap: true,
      }, {
        id: "availabilityList",
        name: "Availability",
        selector: (row) =>
          TYPE_AVAILABILITIES.map((type, i) =>
            row.availabilityList.find((data) => data.toLowerCase() === type.toLowerCase()) ? (
              <span className="badge-available" title={type} key={type + "-" + i}>
                {type === DINE_IN ? "Di" : type === HOME_DELIVERY ? "De" : type === TAKEAWAY ? "Ta" : "Sd"}
              </span>
            ) : (
              <span className="badge-unavailable" title={type} key={type + "-" + i}>
                {type === DINE_IN ? "Di" : type === HOME_DELIVERY ? "De" : type === TAKEAWAY ? "Ta" : "Sd"}
              </span>
            )
          ),
        sortable: false,
      },
      {
        id: "dateRange",
        name: "Date Range",
        selector: (row) => dateUtilities.formattedDate(row.startDateTimeStamp, "dd/MM/yyyy") + "-" + dateUtilities.formattedDate(row.endDateTimeStamp, "dd/MM/yyyy"),
        sortable: false,
        wrap: true,
      },
      // {
      //   id: "createdAt",
      //   name: "Created Time",
      //   selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
      //   sortable: false,
      // },
      {
        id: "action",
        name: "",
        selector: (row) => getActionButtons(row),
        width: "80px",
        sortable: false,
      },
    ],
    [handleRefresh]
  );

  const getActionButtons = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        {!row.deleted && (
          <button className="listBtn me-2" onClick={() => handleEdit(row)}>
            <i className="bx bx-pencil"></i>
          </button>
        )}
        <div className="dropdown">
          <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
            <i className="bx bx-chevron-down"></i>
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            <>
              {row.deleted ? (
                <li onClick={() => handleReviveData(row)}>
                  <i className="bx bx-log-out-circle"></i> Revive
                </li>
              ) : (
                <>
                  <li onClick={() => handleDeleteData(row)}>
                    <i className="bx bx-trash"></i> Delete
                  </li>
                  <li onClick={() => handleActiveInactive(row)}>
                    {row.active ? (
                      <>
                        <i className="bx bx-x-circle"></i> Inactive
                      </>
                    ) : (
                      <>
                        <i className="bx bx-check-circle"></i> Active
                      </>
                    )}
                  </li>
                </>
              )}
            </>
          </ul>
        </div>
      </div>
    );
  };

  const handleEdit = (row) => {
    setRecordID(row.uuid);
    setShowAddUpdateModal(true);
  };

  const handleActiveInactive = (row) => {
    if (row.active) {
      handleInactive(DeActivate_Client_Coupon_Code_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
    } else {
      handleActive(Activate_Client_Coupon_Code_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
    }
  };

  //Delete Record
  const handleDeleteData = (row) => {
    handleDelete(Delete_Client_Coupon_Code_Delete.replaceAll("{recordID}", row.uuid), handleRefresh);
  };

  //Revive Record
  const handleReviveData = (row) => {
    handleRevive(Revive_Client_Coupon_Code_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
  };

  const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

  return (
    <>
      {PageHeading("Customer Coupon Code", "Dashboard", "/")}
      <div className="card">
        <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
        <div className="card-body tableView">
          <PaginationDataTable
            paginationUrl={viewDeletedList ? Deleted_Client_Coupon_Code_List_Post + "/customer" : All_Client_Coupon_Code_List_Post + "/customer"}
            serverSidePagination={true}
            search={true}
            pagination={false}
            columns={columns}
          />
          <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
        </div>
      </div>
      {showAddUpdateModal && <CouponCodeAddEdit
        show={showAddUpdateModal}
        hideModal={() => setShowAddUpdateModal(false)}
        recordID={recordID}
        handleRefresh={handleRefresh}
        couponType={"customer"}
      />}
    </>
  );
};

export default CustomerCouponCodeMaster