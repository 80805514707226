import React, { useEffect, useState } from "react";

import Select from "react-select";
import { toast } from "react-hot-toast";

import { Get_UnMerge_Restaurant_Table_List_Get, Merge_Restaurant_Table_List_PUT } from "api/ApiConst";
import { callGetApi, callPutApi } from "api/ApiCaller";
import { API_SUCCESS } from "api/ResponseCodes";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";

const RestaurantTableMerge = (props) => {
    const { show, hideModal, handleRefresh } = props;

    const [selectedTableToMergeInto, setSelectedTableToMergeInto] = useState({
        label: "Select",
        value: "",
    });
    const [selectedTablesToMerge, setSelectedTablesToMerge] = useState([]);
    const [tablesKeyValuePair, setTablesKeyValuePair] = useState([
        {
            label: "",
            value: "",
        },
    ]);

    useEffect(() => {
        callGetApi(MODAL_LOADER, Get_UnMerge_Restaurant_Table_List_Get, {}, (response) => {
            setTablesKeyValuePair(response.responsePacket);
        });
    }, []);

    const handleMergeInto = (e) => {
        setSelectedTableToMergeInto(e);
        const tablesKeyValuePairClone = [...tablesKeyValuePair];
        tablesKeyValuePairClone.map((data) => {
            if (data.key === e.key) {
                data.isDisabled = true;
            } else {
                data.isDisabled = false;
            }
        });
        setSelectedTablesToMerge([]);
        setTablesKeyValuePair(tablesKeyValuePairClone);
    };

    // Check validation
    const checkInputValidation = () => {
        if (selectedTableToMergeInto.value === "") {
            toast.error(`Table to merge into must not be empty`, { id: "toast" });
            return false;
        }
        if (selectedTablesToMerge.length === 0) {
            toast.error(`Tables to merge must not be empty`, { id: "toast" });
            return false;
        }
        return true;
    };

    //Submit Item Customization Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            const mergeTableUuids = [];
            selectedTablesToMerge.map((data) => mergeTableUuids.push(data.key));
            let recordObj = {
                mergeTableUuids: mergeTableUuids,
            };

            callPutApi(MODAL_LOADER, Merge_Restaurant_Table_List_PUT.replaceAll("{recordID}", selectedTableToMergeInto.key), recordObj, {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                } else {
                    toast.error(response.message, {id: "toast"});
                }
            });
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`Merge Tables`} size={"md"} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12 mb-3">
                    <label>Select a Table to Merge Into</label>
                    <Select options={tablesKeyValuePair} value={selectedTableToMergeInto} onChange={handleMergeInto} />
                </div>
                {selectedTableToMergeInto.value !== "" && (
                    <div className="col-lg-12">
                        <label>Select Tables to Merge</label>
                        <Select options={tablesKeyValuePair} isMulti closeMenuOnSelect={false} value={selectedTablesToMerge} onChange={(e) => setSelectedTablesToMerge(e)} />
                    </div>
                )}
            </div>
        </ModalWrapper>
    );
};

export default RestaurantTableMerge;
