import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";


import ImageSelector from "components/common/ImageSelector";
import RestaurantMultiImageSelector from "components/master/client/restaurant-setup/restaurant/RestaurantMultiImageSelector";

const imgStyle = { height: "auto", width: "100%", overflow: "hidden" };

export const RestaurantProfileImages = ({ record, setRecord, recordID }) => {
    const [webBanners, setWebBanners] = useState([])
    const handleImageChange = (imageRefId, id) => {
        setRecord((prev) => ({ ...prev, [id]: imageRefId }));
    };

    useEffect(() => {
        if (record.webBannerImageRefIdCsv) {
            const webBannerImages = record.webBannerImageRefIdCsv.split(",");
            setWebBanners(webBannerImages)
        }
    }, [])

    const handleWebBannerImageChange = (imageRefId, i) => {
        const _webBanners = [...webBanners];
        _webBanners[i] = imageRefId;
        setWebBanners(_webBanners);
    }

    const handleRemoveBannerImage = (i) => {
        const _webBanners = [...webBanners];
        _webBanners.splice(i, 1);
        setWebBanners(_webBanners);
    }

    const handleMoreWebBanners = () => {
        const _webBanners = [...webBanners];
        _webBanners.push("");
        setWebBanners(_webBanners)
    }

    return (
        <>
            <fieldset className="mt-3">
                <legend>Restaurant Image</legend>
                <div className="row gx-2">
                    <div className="col-lg-6">
                        <div className="restaurant-images p-2 card border mb-0">
                            <label>Icon*</label>
                            <ImageSelector style={imgStyle} owned={false} handleImageChange={(imageRefId) => handleImageChange(imageRefId, "iconImageRefId")} selectedImage={record.iconImageRefId} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="restaurant-images p-2 card border mb-0">
                            <label>Banner*</label>
                            <ImageSelector style={imgStyle} owned={false} handleImageChange={(imageRefId) => handleImageChange(imageRefId, "bannerImageRefId")} selectedImage={record.bannerImageRefId} />
                        </div>
                    </div>
                </div>
                <fieldset className="my-2">
                    <legend>Web Banners</legend>
                    <div className="row gx-2">
                        <div className="col-lg-12">
                            <div className="d-flex overflow-auto">
                                {webBanners.length !== 0 && webBanners.map((image, i) =>
                                    <RestaurantMultiImageSelector selectedImage={image} key={`web-banner-${i}`} handleImageSelect={handleWebBannerImageChange} handleRemoveBannerImage={handleRemoveBannerImage} remove={false} owned={false} />
                                )}
                                {webBanners.length === 0 && <span>Web Banner Images are ot available now.</span>}
                            </div>
                        </div>
                        {/* <div className="col-lg-2"><Button onClick={handleMoreWebBanners}> + Add More</Button></div> */}
                    </div>
                </fieldset>
            </fieldset>
        </>
    );
};

export default RestaurantProfileImages;
