import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { Details_Restaurant_Tax_List_Get, Save_Restaurant_Tax_Post, Update_Restaurant_Tax_Put } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isAlphaNumericSpc, isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

import ModalWrapper from "components/common/ModalWrapper";

const RestaurantTaxAddEdit = ({ show, hideModal, recordID, handleRefresh, isMenuItemCopy }) => {
    const defaultRecordObj = {
        taxTitle: "",
        taxDescription: "",
        taxPercentage: 0,
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Restaurant_Tax_List_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const res = response.responsePacket;
                    setRecord(res);
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (id === "taxPercentage" && value.length !== 0) {
            if (value.length > 5) {
                return;
            }
        }
        if (id === "taxTitle" && value.length !== 0) {
            if (!isAlphaNumericSpc(value)) {
                return;
            }
        }
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseFloat(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };
    const checkInputValidation = () => {
        if (isEmpty(record.taxTitle)) {
            toast.error(`Tax title must not be empty`, { id: "toast" });
            return false;
        }
        else if (record.taxPercentage !== 0) {
            if (isEmpty(record.taxPercentage)) {
                toast.error(`Tax percentage must not be empty`, { id: "toast" });
                return false;
            }
        }
        else if (record.taxPercentage < 0) {
            toast.error(`Tax percentage must not be negative`, { id: "toast" });
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Restaurant_Tax_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, { id: "toast" });
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, { id: "toast" });
                    }
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Restaurant_Tax_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, { id: "toast" });
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, { id: "toast" });
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static" header={`${recordID ? "Update" : "Add"} Tax`} size="ms" handleSubmit={!isMenuItemCopy && handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12 my-2">
                    <label>Tax Title *</label>
                    <input disabled={isMenuItemCopy} type="text" maxLength={30} className="form-control input-default" id="taxTitle" onChange={handleTextChange} value={record.taxTitle} />
                </div>
                <div className="col-lg-12 my-2">
                    <label>Tax Percentage *</label>
                    <div className="input-group">
                        <div className="input-group-append">
                            <span className="input-group-text bg-red">%</span>
                        </div>
                        <input disabled={isMenuItemCopy} type="number" className="form-control input-default" id="taxPercentage" onChange={handleTextChange} value={record.taxPercentage} />
                    </div>
                </div>
                <div className="col-lg-12 my-2">
                    <label>Tax Description </label>
                    <textarea disabled={isMenuItemCopy} className="form-control input-default" id="taxDescription" cols="30" rows="2" onChange={handleTextChange} value={record.taxDescription} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default RestaurantTaxAddEdit;
