import React from "react";

import { Link } from "react-router-dom";

const PageHeading = (heading, firstLinkTitle, firstLink) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
                    <h4 className="mb-sm-0 font-size-18">{heading}</h4>
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item">
                                <Link to={firstLink}>{firstLinkTitle}</Link>
                            </li>
                            <li className="breadcrumb-item active">{heading}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageHeading;
