import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { Details_Client_Condiment_List_Get, Save_Client_Condiment_Post, Update_Client_Condiment_Put } from "api/ApiConst";
import { API_SUCCESS } from "api/ResponseCodes";
import ModalWrapper from "components/common/ModalWrapper";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

const defaultRecordObj = {
    title: "",
};
const RestaurantCondimentsAddEdit = (props) => {
    const { show, hideModal, recordID, handleRefresh, ownedItem } = props;

    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Client_Condiment_List_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const res = response.responsePacket;

                    let recordObj = {
                        title: res.title,
                    };
                    setRecord(recordObj);
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    // Check validation
    const checkInputValidation = (recordObj) => {
        if (isEmpty(recordObj.title)) {
            toast.error(`Condiment Title must not be empty`, { id: "toast" });
            return false;
        }
        return true;
    };

    //Submit Item Customization Form
    const handleSubmit = async () => {
        try {
            let recordObj = {
                ...record,
            };

            if (!checkInputValidation(recordObj)) {
                return;
            }

            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Client_Condiment_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Client_Condiment_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`${recordID ? "Update" : "Add"} Condiments`} size={"md"} handleSubmit={ownedItem && handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Title *</label>
                    <input disabled={!ownedItem} type="text" className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default RestaurantCondimentsAddEdit;
