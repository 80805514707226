import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { updateRestaurantTaxNumbers } from "./restaurantUtils";
import toast from "react-hot-toast";
import { restaurantLengthErrorMsg } from "./restaurantInitialValues";

export const TaxNumberUpdate = ({ record, handleTextChange, recordID }) => {

    const checkInputValidation = (rObj) => {
        // const {gstNumber, vatNumber, tinNumber} = restaurantLengthErrorMsg;

        // console.log("tax number update ",rObj);
        // console.log("record gst numbre", record.gstNumber)
        // if (record.gstNumber.length > 15) {
        //     toast.error("GST number must be 15 characters",);
        //     return false;
        // }
        return true;

    }
    const handleSaveChange = () => {
        let rObj = {
            gstNumber: record.gstNumber,
            tinNumber: record.tinNumber,
            vatNumber: record.vatNumber,
            restaurantLicenseNumber: record.restaurantLicenseNumber ? record.restaurantLicenseNumber : null,
        };

        // if(!checkInputValidation(rObj)) {
        //     return;
        // }


        updateRestaurantTaxNumbers(recordID, rObj);
    };
    
    return (
        <>
            <div className="row">
                <div className="col-lg-4 my-2">
                    <label>Gst Number </label>
                    <input type="text" maxLength={15} className="form-control input-default" id="gstNumber" onChange={handleTextChange} value={record.gstNumber} />
                </div>
                <div className="col-lg-4 my-2">
                    <label>Tin Number </label>
                    <input type="text" maxLength={11} className="form-control input-default" id="tinNumber" onChange={handleTextChange} value={record.tinNumber} />
                </div>
                <div className="col-lg-4 my-2">
                    <label>Vat Number </label>
                    <input type="text" maxLength={15} className="form-control input-default" id="vatNumber" onChange={handleTextChange} value={record.vatNumber} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 my-2">
                    <label>License Number </label>
                    <input type="text" maxLength={50} className="form-control input-default" id="restaurantLicenseNumber" onChange={handleTextChange} value={record.restaurantLicenseNumber } />
                </div>
            </div>
            <Button className="mb-3 float-end" onClick={handleSaveChange} variant="primary">
                Save Changes
            </Button>
        </>
    );
};

export default TaxNumberUpdate;
