import { useEffect, useState } from "react";

import toast from "react-hot-toast";
import Select from "react-select";
import { Button } from "react-bootstrap";

import {
    Details_Rest_Menu_Restaurant_Menu_Item_List_Get,
    Details_Restaurant_Menu_Item_List_Get,
    Get_Restaurant_SubCategory_Key_Value_List_Post,
    Save_Restaurant_Menu_Item_Post,
    Update_Rest_Menu_Restaurant_Menu_Item_Put,
    Update_Restaurant_Menu_Item_Put,
} from "api/ApiConst";
import { API_SUCCESS } from "api/ResponseCodes";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isAlphaNumercSpecialCharSpc, isAlphaNumericSpc, isEmpty } from "utils/TextUtils";
import { DINE_IN, HOME_DELIVERY, MODAL_LOADER, STEAL_DEAL, SWIGGY, TAKEAWAY, ZOMATO } from "utils/constants";

import Cookies from "universal-cookie";

import ModalWrapper from "components/common/ModalWrapper";
import ImageSelector from "components/common/ImageSelector";

const RestaurantMenuItemAddEdit = (props) => {
    const {
        show,
        hideModal,
        recordID,
        handleRefresh,
        ownedItem,
        isStealDealItem,
        categoryKeyValuePairList,
        taxKeyPairList,
        condimentsKeyValuePairs,
        customizationsKeyValuePairs,
        timingGroupKeyPairList,
        stickerKeyValuePairList,
        printerKeyValuePairs,
        comboItemsKeyValuePairList,
        setComboItemsKeyValuePairList = { setComboItemsKeyValuePairList }

    } = props;

    const defaultRecordObj = {
        categoryUuid: "",
        subCategoryUuid: "",
        title: "",
        description: "",
        nutrients: [
            {
                nutrientName: "",
                nutrientValue: "",
            },
        ],
        ingredients: "",
        imageType: "NONE",
        itemImageRefId: "null",
        itemCustomizationUuids: [],
        itemCondimentUuids: [],
        vegNonVeg: "",
        sticker: "",
        sortOrder: 1,
        timingGroupUuid: "",
        offerApplicable: false,
        maximumRetailPrice: 0,
        availabilityPrices: [],
        aggregatorAvailabilityPrices: [],
        itemTaxUuids: [],
        comboItemUuids: [],
        stealDealItemType: "",
        buyingUnit: "",
        quantity: 0,
        consumableUnit: "",
        consumableUnitPostfix: "",
        shortDescription: "",
        printerUuids: [],
        comboItems: [
            {
                uuid: "",
                quantity: 0
            }
        ]
    };
    const stealDealItemKeyValuePairs = [
        { label: "Glass", value: "Glass", key: "Glass" },
        { label: "Peg", value: "Peg", key: "Peg" },
        { label: "Shot", value: "Shot", key: "Shot" },
        { label: "Portion", value: "Portion", key: "Portion" },
        { label: "Piece", value: "Piece", key: "Piece" },
        { label: "Bottle", value: "Bottle", key: "Bottle" },
    ];
    const defaultAvailabilityPrices = [
        {
            menuAvailabilityType: DINE_IN,
            checked: false,
            price: 0,
        },
        {
            menuAvailabilityType: HOME_DELIVERY,
            checked: false,
            price: 0,
        },
        {
            menuAvailabilityType: TAKEAWAY,
            checked: false,
            price: 0,
        },

        {
            menuAvailabilityType: STEAL_DEAL,
            checked: isStealDealItem,
            price: 0,
        },
    ];
    const defaultAggregatorAvailabilityPrices = [
        {
            menuAvailabilityType: ZOMATO,
            checked: false,
            price: 0,
        },
        {
            menuAvailabilityType: SWIGGY,
            checked: false,
            price: 0,
        },
    ];
    const nutrientsOptions = [
        {
            nutrientName: "",
            nutrientValue: "",
        },
    ];
    const comboItemOptions = [
        {
            uuid: "",
            quantity: "",
        },
    ];
    const vegNonVegOptions = ["Veg", "NonVeg", "Vegan","None"];
    const imageTypeOptions = [{ label: "Small", value: "SMALL" }, { label: "Large", value: "BIG" }];

    const [record, setRecord] = useState({ ...defaultRecordObj });
    const [availabilityPrices, setAvailabilityPrices] = useState(
        Array.from(defaultAvailabilityPrices)
    );

    const [aggregatorAvailabilityPrices, setAggregatorAvailabilityPrices] =
        useState(Array.from(defaultAggregatorAvailabilityPrices));

    const [nutrients, setNutrients] = useState(Array.from(nutrientsOptions));

    const [comboItem, setComboItem] = useState([]);

    const [unselectedComboItem, setUnselectedComboItem] = useState([...comboItemsKeyValuePairList]);

    const [subCategoryKeyValuePairList, setSubCategoryKeyValuePairList] =
        useState([]);
    const [showComboItemSection, setShowComboItemSection] = useState(false);
    const currencyDetail = new Cookies().get("currencyDetail");

    useEffect(() => {
        if (recordID) {
            const rUrl = ownedItem
                ? Details_Restaurant_Menu_Item_List_Get
                : Details_Rest_Menu_Restaurant_Menu_Item_List_Get;
            callGetApi(
                MODAL_LOADER,
                rUrl.replaceAll("{recordID}", recordID),
                {},
                (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        const res = response.responsePacket;
                        // ------- SETTING INITIAL VALUES -------

                        // Set Category
                        let _categoryUuid = categoryKeyValuePairList.find(
                            (obj) => obj.key === res.categoryUuid
                        );

                        // Set Tax
                        let _taxUuids = [];
                        if (res.itemTaxUuids && res.itemTaxUuids.length > 0) {
                            _taxUuids = Array.from(taxKeyPairList).filter(
                                (data) =>
                                    res.itemTaxUuids.findIndex((obj) => obj === data.key) !==
                                    -1 && data
                            );
                        }

                        // Set Condiments
                        let _itemCondimentUuids = [];
                        if (res._itemCondimentUuids && res.itemCondimentUuids.length > 0) {
                            _itemCondimentUuids = Array.from(condimentsKeyValuePairs).map(
                                (data) =>
                                    res.itemCondimentUuids.findIndex(
                                        (obj) => obj === data.key
                                    ) !== -1 && data
                            );
                        }

                        // Set Customization
                        let _itemCustomizationUuids = [];
                        if (
                            res.itemCustomizationUuids &&
                            res.itemCustomizationUuids.length > 0
                        ) {
                            _itemCustomizationUuids = Array.from(
                                customizationsKeyValuePairs
                            ).map(
                                (data) =>
                                    res.itemCustomizationUuids.findIndex(
                                        (obj) => obj === data.key
                                    ) !== -1 && data
                            );
                        }

                        // Set Printer
                        let _printerUuids = [];
                        if (
                            res.printerUuids &&
                            res.printerUuids.length > 0
                        ) {
                            _printerUuids = Array.from(
                                printerKeyValuePairs
                            ).map(
                                (data) =>
                                    res.printerUuids.findIndex(
                                        (obj) => obj === data.key
                                    ) !== -1 && data
                            );
                        }

                        // Set Timing Group
                        const _selectedTimingGroup = timingGroupKeyPairList.find(
                            (obj) => obj.key === res.timingGroupUuid
                        );

                        // Set Sticker
                        const _sticker = stickerKeyValuePairList.find(
                            (obj) => obj.key === res.sticker
                        );

                        let _stealDealItemType = "";
                        let _buyingUnit = "";
                        let _consumableUnit = "";
                        let _consumableUnitPostfix = "";

                        handleCategoryChange(res.categoryUuid, res.subCategoryUuid);

                        // Set Availability Prices
                        let _availabilityPrices = [...availabilityPrices];
                        if (res.availabilityPrices && res.availabilityPrices.length > 0) {
                            _availabilityPrices = _availabilityPrices.map((data) => {
                                const foundIndex = res.availabilityPrices.findIndex(
                                    (obj) =>
                                        obj.menuAvailabilityType === data.menuAvailabilityType
                                );
                                if (foundIndex !== -1) {
                                    data.checked = true;
                                    data.price = res.availabilityPrices[foundIndex].price;
                                } else {
                                    data.checked = false;
                                }
                                return data;
                            });
                            if (
                                res.availabilityPrices.find(
                                    (obj) => obj.menuAvailabilityType === "StealDeal"
                                )
                            ) {
                                _stealDealItemType = stealDealItemKeyValuePairs.find(
                                    (obj) => obj.key === res.stealDealItemType
                                );
                                _buyingUnit = stealDealItemKeyValuePairs.find(
                                    (obj) => obj.key === res.buyingUnit
                                );
                                _consumableUnit = stealDealItemKeyValuePairs.find(
                                    (obj) => obj.key === res.consumableUnit
                                );
                                _consumableUnitPostfix = stealDealItemKeyValuePairs.find(
                                    (obj) => obj.key === res.consumableUnitPostfix
                                );
                            }
                        }
                        setAvailabilityPrices(_availabilityPrices);

                        // Set Aggregator Availability Prices
                        let _aggregatorAvailability = [...aggregatorAvailabilityPrices];
                        if (
                            !isEmpty(res.aggregatorAvailabilityPrices) &&
                            res.aggregatorAvailabilityPrices.length > 0
                        ) {
                            _aggregatorAvailability = _aggregatorAvailability.map((data) => {
                                const foundIndex = res.aggregatorAvailabilityPrices.findIndex(
                                    (obj) =>
                                        obj.menuAvailabilityType === data.menuAvailabilityType
                                );
                                if (foundIndex !== -1) {
                                    data.checked = true;
                                    data.price =
                                        res.aggregatorAvailabilityPrices[foundIndex].price;
                                } else {
                                    data.checked = false;
                                }
                                return data;
                            });
                        }
                        setAggregatorAvailabilityPrices(_aggregatorAvailability);
                        if (!isEmpty(res.aggregatorAvailabilityPrices)) {
                            setNutrients(res.nutrients);
                        }


                        // Set Combo Item
                        let _comboItems = [];
                        if (res.comboItems && res.comboItems.length > 0) {
                            const _res = res.comboItems.map((data) => {
                                const _matchedKey = comboItemsKeyValuePairList.find((item) =>
                                    item.key === data.uuid
                                )
                                return {
                                    uuid: _matchedKey,
                                    quantity: data.quantity,
                                }
                            })
                            _comboItems = _res
                            setComboItem(_res)
                        }

                        let recordObj = {
                            ...res,
                            // imageType: record.imageType.charAt(0).toUpperCase() + record.imageType.slice(1).toLowerCase(),
                            timingGroupUuid: _selectedTimingGroup || "",
                            categoryUuid: _categoryUuid || "",
                            itemTaxUuids: _taxUuids.length ? _taxUuids : [],
                            itemCondimentUuids: _itemCondimentUuids.length
                                ? _itemCondimentUuids
                                : [],
                            itemCustomizationUuids: _itemCustomizationUuids.length
                                ? _itemCustomizationUuids
                                : [],
                            printerUuids: _printerUuids.length
                                ? _printerUuids
                                : [],
                            stealDealItemType: _stealDealItemType,
                            buyingUnit: _buyingUnit,
                            consumableUnit: _consumableUnit,
                            consumableUnitPostfix: _consumableUnitPostfix,
                            sticker: _sticker === undefined ? "" : _sticker,
                            comboItems: _comboItems,
                        };
                        setShowComboItemSection(true);
                        setRecord(recordObj);
                    } else {
                        toast.error(response.message);
                    }
                }
            );
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (id === "title" && value.length !== 0) {
            if (!isAlphaNumercSpecialCharSpc(value)) {
                return;
            }
        }
        if (id === "maximumRetailPrice" && value.length !== 0) {
            if (value.length > 7) {
                return;
            }
        }
        if (id === "quantity" && value.length !== 0) {
            if (value.length > 4) {
                return;
            }
        }
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseFloat(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    //Select Text Change
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };


    const handleAvailabilityPrices = (e, availType, checkOrPrice) => {
        if (e.target.id === "title" && e.target.value.length !== 0) {
            if (e.target.value.length > 7) {
                return;
            }
        }
        let _availabilityPrices = [...availabilityPrices];
        if (checkOrPrice === "check") {
            if (
                !ownedItem &&
                !record?.parentAvailabilityPrices.some(
                    (obj) => obj.menuAvailabilityType === availType
                )
            ) {
                return;
            }
            if (isStealDealItem) {
                return;
            }
            if (availType === "StealDeal") {
                if (!ownedItem && !isStealDealItem) {
                    return;
                }
                _availabilityPrices.map((data) => {
                    if (e.target.checked && data.menuAvailabilityType === "StealDeal") {
                        data.checked = e.target.checked;
                    } else {
                        data.checked = false;
                        data.price = 0;
                    }
                    return data;
                });
            } else {
                _availabilityPrices.map((data) => {
                    if (e.target.checked && data.menuAvailabilityType === "StealDeal") {
                        data.checked = false;
                        data.price = 0;
                    } else {
                        if (data.menuAvailabilityType === availType) {
                            if (e.target.checked) {
                                data.checked = true;
                                data.price = record.maximumRetailPrice;

                            } else {
                                data.checked = false;
                                data.price = 0;
                            }
                        }
                    }
                    return data;
                });
            }
            setAvailabilityPrices(_availabilityPrices);
        } else {
            _availabilityPrices.map((data) => {
                if (data.menuAvailabilityType === availType) {
                    data.price = parseFloat(e.target.value);
                }
                return data;
            });
            setAvailabilityPrices(_availabilityPrices);
        }
    };

    // Aggregator Availability Price changes
    const handleAggregatorAvailabilityPrices = (e, availType, checkOrPrice) => {
        if (e.target.id === "title" && e.target.value.length !== 0) {
            if (e.target.value.length > 7) {
                return;
            }
        }
        let _aggregatorAvailabilityPrices = [...aggregatorAvailabilityPrices];
        if (checkOrPrice === "check") {
            if (
                !ownedItem &&
                !record?.parentAggregatorAvailabilityPrices.some(
                    (obj) => obj.menuAvailabilityType === availType
                )
            ) {
                return;
            }
            _aggregatorAvailabilityPrices.map((data) => {
                if (data.menuAvailabilityType === availType) {
                    if (e.target.checked) {
                        data.checked = true;
                    } else {
                        data.checked = false;
                        data.price = 0;
                    }
                }
                return data;
            });
            setAggregatorAvailabilityPrices(_aggregatorAvailabilityPrices);
        } else {
            _aggregatorAvailabilityPrices.map((data) => {
                if (data.menuAvailabilityType === availType) {
                    data.price = parseFloat(e.target.value);
                }
                return data;
            });
            setAggregatorAvailabilityPrices(_aggregatorAvailabilityPrices);
        }
    };

    // IMAGE CHANGE
    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, itemImageRefId: imageRefId });
    };

    // Nutrients Text change
    const handleNutrientsChange = (e, i, nameOrValue) => {
        const clone = Array.from(nutrients);
        if (e.target.id === "title" && e.target.value.length !== 0) {
            if (!isAlphaNumericSpc(e.target.value)) {
                return;
            }
        }

        if (nameOrValue === "name") {
            clone[i].nutrientName = e.target.value;
        } else {
            clone[i].nutrientValue = e.target.value;
        }
        setNutrients(clone);
    };

    // Add new row for nutrients
    const handleAddMoreNutrientRow = () => {
        setNutrients((prev) => [
            ...prev,
            JSON.parse(JSON.stringify(nutrientsOptions[0])),
        ]);
    };
    // Remove Nutrients row
    const handleRemoveNutrient = (i) => {
        const clone = Array.from(nutrients);
        clone.splice(i, 1);
        setNutrients(clone);
    };

    useEffect(() => {
        getComboListBySelectedItem();
    }, [comboItem])

    //Select COmbo Item Change
    const handleComboItemChange = (selectedOption, i, uuidOrQuantity) => {
        const clone = Array.from(comboItem);
        if (uuidOrQuantity === "uuid") {
            clone[i].uuid = selectedOption;
        } else {
            clone[i].quantity = selectedOption.target.value;
        }
        setComboItem(clone);

        getComboListBySelectedItem();
    };

    const getComboListBySelectedItem = () => {
        const _selectedItem = comboItem.map((item) => comboItemsKeyValuePairList.find((value) => value.key === item.uuid.key))
        const _unselectedItem = comboItemsKeyValuePairList.filter((item) => {
            if (!_selectedItem.includes(item)) { return (item) }
        })
        setUnselectedComboItem(_unselectedItem)
    }


    // Add new row for nutrients
    const handleAddCOmboItemRow = () => {
        setShowComboItemSection(true)
        setComboItem((prev) => [
            ...prev,
            JSON.parse(JSON.stringify(comboItemOptions[0])),
        ]);
    };
    // Remove Nutrients row
    const handleRemoveComboItem = (i) => {
        const clone = Array.from(comboItem);
        clone.splice(i, 1);
        setComboItem(clone);
    };

    // get SubCategory key-list on Category change
    const handleCategoryChange = (categoryId, subCategoryId = null) => {
        if (!isStealDealItem) {
            const rUrl = Get_Restaurant_SubCategory_Key_Value_List_Post.replaceAll(
                "{categoryID}",
                categoryId
            );
            callGetApi(null, rUrl, {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    setSubCategoryKeyValuePairList(response.responsePacket);

                    // Set Sub Category
                    const _subCategoryId = response.responsePacket.find(
                        (obj) => obj.key === subCategoryId
                    );
                    setRecord((prev) => ({
                        ...prev,
                        subCategoryUuid: _subCategoryId || "",
                    }));
                } else {
                    toast.error(response.message);
                }
            });
        }
    };

    // Check validation
    const checkInputValidation = (recordObj) => {
        if (isEmpty(recordObj.title)) {
            toast.error(`Title must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.maximumRetailPrice)) {
            toast.error(`Max Retail Price must not be empty`, { id: "toast" });
            return false;
        } else if (recordObj.maximumRetailPrice < 0) {
            toast.error(`Max Retail Price must not be negative`, { id: "toast" });
            return false;
        } else if (recordObj.availabilityPrices.length === 0) {
            toast.error(`Availabilities must not be empty`, { id: "toast" });
            return false;
        } else if (ownedItem && isEmpty(recordObj.categoryUuid)) {
            toast.error(`Category must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.sortOrder)) {
            toast.error(`Sort Order must not be empty`, { id: "toast" });
            return false;
        }
        // else if (recordObj.itemTaxUuids.length === 0) {
        //     toast.error(`Category tax must not be empty`, { id: "toast" });
        //     return false;
        // }
        else if (isEmpty(recordObj.vegNonVeg)) {
            toast.error(`Item Type must not be empty`, { id: "toast" });
            return false;
        } else if (recordObj?.imageType === "BIG" || recordObj?.imageType === "SMALL") {
            if(isEmpty(recordObj.itemImageRefId) || recordObj.itemImageRefId === "null"){
                toast.error(`Image Field must not be empty`, { id: "toast" });
                return false;
            }
        }
        // else if (isEmpty(recordObj.itemImageRefId)) {
        //     toast.error(`Image Ref ID must not be empty`, { id: "toast" });
        //     return false;
        // }
        return true;
    };

    // check validation for steal deal
    const checkStealDealInputValidation = (recordObj) => {
        if (isEmpty(recordObj.stealDealItemType)) {
            toast.error(`Item Type must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.buyingUnit)) {
            toast.error(`Buying unit must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.quantity)) {
            toast.error(`Quantity must not be empty`, { id: "toast" });
            return false;
        } else if (recordObj.quantity < 0) {
            toast.error(`Quantity must not be negative`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.consumableUnit)) {
            toast.error(`Consumable unit must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.consumableUnitPostfix)) {
            toast.error(`Consumable unit Postfix must not be empty`, { id: "toast" });
            return false;
        }
        return true;
    };
    const handleSubmit = () => {
        try {
            let _availabilityPrices = availabilityPrices.reduce((acc, curr) => {
                if (curr.checked) {
                    acc.push({
                        menuAvailabilityType: curr.menuAvailabilityType,
                        price: curr.price,
                    });
                }
                return acc;
            }, []);

            let _aggregatorAvailability = aggregatorAvailabilityPrices.reduce(
                (acc, curr) => {
                    if (curr.checked) {
                        acc.push({
                            menuAvailabilityType: curr.menuAvailabilityType,
                            price: curr.price,
                        });
                    }
                    return acc;
                },
                []
            );

            let _itemCustomizationUuids = record.itemCustomizationUuids.reduce(
                (acc, curr) => {
                    acc.push(curr.key);
                    return acc;
                },
                []
            );

            let _printerUuids = record.printerUuids.reduce(
                (acc, curr) => {
                    acc.push(curr.key);
                    return acc;
                },
                []
            );

            let _taxUuids = record.itemTaxUuids.reduce((acc, curr) => {
                acc.push(curr.key);
                return acc;
            }, []);

            let _itemCondimentUuids = record.itemCondimentUuids.reduce(
                (acc, curr) => {
                    acc.push(curr.key);
                    return acc;
                },
                []
            );

            let _subCategory =
                subCategoryKeyValuePairList.length !== 0 ? record.subCategoryUuid : "";

            let _comboItems = [];

            for (let i = 0; i < comboItem.length; i++) {
                const { quantity, uuid } = comboItem[i];

                if (quantity === "") {
                    toast.error("Please Provide quantity for all the Items", { id: "toast", });
                    return;
                }
                if (uuid === "") {
                    toast.error("Please Provide Items for all ", { id: "toast", });
                    return;
                }
                _comboItems.push({ uuid: uuid.key, quantity });
            }


            let recordObj = {
                ...record,
                sortOrder: parseInt(record.sortOrder),
                categoryUuid: record.categoryUuid?.key || "",
                timingGroupUuid: record.timingGroupUuid?.key || "",
                availabilityPrices: _availabilityPrices,
                aggregatorAvailabilityPrices: availabilityPrices.find(
                    (obj) => obj.menuAvailabilityType === HOME_DELIVERY && obj.checked
                )
                    ? _aggregatorAvailability
                    : [],
                itemCustomizationUuids: _itemCustomizationUuids.filter(
                    (item) => item !== null && item !== undefined
                ),
                printerUuids: _printerUuids.filter(
                    (item) => item !== null && item !== undefined
                ),
                itemTaxUuids: _taxUuids,
                subCategoryUuid: _subCategory?.key || null,
                itemCondimentUuids: _itemCondimentUuids.filter(
                    (item) => item !== null && item !== undefined
                ),
                sticker: record.sticker.key || "",

                comboItems: _comboItems,
            };
            if (!checkInputValidation(recordObj)) {
                return;
            }
            if (
                recordObj.availabilityPrices.find(
                    (obj) => obj.menuAvailabilityType === "StealDeal"
                )
            ) {
                if (!checkStealDealInputValidation(recordObj)) {
                    return;
                }

                recordObj.stealDealItemType = recordObj.stealDealItemType.key;
                recordObj.buyingUnit = recordObj.buyingUnit.key;
                recordObj.consumableUnit = recordObj.consumableUnit.key;
                recordObj.consumableUnitPostfix = recordObj.consumableUnitPostfix.key;
            } else {
                delete recordObj.stealDealItemType;
                delete recordObj.buyingUnit;
                delete recordObj.consumableUnit;
                delete recordObj.consumableUnitPostfix;
            }

            let _nutrients = [];
            if (recordID) {
                for (let i = 0; i < nutrients.length; i++) {
                    if (nutrients[i].nutrientName === "") {
                        toast.error("Please Provide Name for all the nutrients", {
                            id: "toast",
                        });
                        return;
                    }
                    _nutrients.push(nutrients[i]);
                }

                recordObj.nutrients = _nutrients;
            } else {
                recordObj.nutrients = _nutrients;
            }

            if (!recordID || recordID === "") {
                let rUrl = Save_Restaurant_Menu_Item_Post;
                callPostApi(MODAL_LOADER, rUrl, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, { id: "toast" });
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, { id: "toast" });
                    }
                });
            } else {
                let rUrl = ownedItem
                    ? Update_Restaurant_Menu_Item_Put
                    : Update_Rest_Menu_Restaurant_Menu_Item_Put;
                callPutApi(
                    MODAL_LOADER,
                    rUrl.replaceAll("{recordID}", recordID),
                    recordObj,
                    {},
                    (response) => {
                        if (response.errorCode === API_SUCCESS) {
                            toast.success(response.message, { id: "toast" });
                            hideModal();
                            handleRefresh();
                        } else {
                            toast.error(response.message, { id: "toast" });
                        }
                    }
                );
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper
            show={show}
            hide={hideModal}
            backdrop="static"
            header={`${recordID ? "Update" : "Add"} ${isStealDealItem ? "Steal Deal" : "Food"
                } Item`}
            handleSubmit={handleSubmit}
            size="xl"
        >
            <div className="row">
                <div className="col-lg-7">
                    <fieldset>
                        <legend>Basic Details</legend>
                        <div className="form-group row align-items-center">
                            <div className="col-lg-12 my-2">
                                <label> Title *</label>
                                <input
                                    disabled={!ownedItem}
                                    type="text"
                                    maxLength={100}
                                    className="form-control input-default"
                                    id="title"
                                    onChange={handleTextChange}
                                    value={record.title}
                                />
                            </div>
                            <div className={`col-lg-${isStealDealItem ? 6 : 12} my-2`}>
                                <label> Max Retail Price *</label>
                                <div className="input-group">
                                    <input
                                        type="number"
                                        className="form-control input-default"
                                        id="maximumRetailPrice"
                                        onChange={handleTextChange}
                                        value={record.maximumRetailPrice}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text bg-red">
                                            {currencyDetail?.currencySymbol}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {isStealDealItem &&
                                availabilityPrices.map(
                                    (data, i) =>
                                        data.menuAvailabilityType === STEAL_DEAL && (
                                            <div
                                                className="col-lg-6 my-2"
                                                key={data.menuAvailabilityType + "-" + i}
                                            >
                                                <label>Availability Price </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        className="form-control input-default"
                                                        id="title"
                                                        onChange={(e) =>
                                                            handleAvailabilityPrices(
                                                                e,
                                                                data.menuAvailabilityType,
                                                                "price"
                                                            )
                                                        }
                                                        value={data.price}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text bg-red">
                                                            {currencyDetail?.currencySymbol}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                )}
                        </div>
                    </fieldset>
                    {!isStealDealItem && (
                        <fieldset className="my-4">
                            <legend>Availability Prices *</legend>
                            <div className="row">
                                {availabilityPrices.map(
                                    (data, i) =>
                                        data.menuAvailabilityType !== STEAL_DEAL && (
                                            <div
                                                className="my-2 col-6"
                                                key={data.menuAvailabilityType}
                                            >
                                                <div className="row align-items-center">
                                                    <div className="col-6">
                                                        <input
                                                            type="checkbox"
                                                            className="input-default me-2"
                                                            id={data.menuAvailabilityType}
                                                            onChange={(e) =>
                                                                handleAvailabilityPrices(
                                                                    e,
                                                                    data.menuAvailabilityType,
                                                                    "check"
                                                                )
                                                            }
                                                            checked={data.checked}
                                                        />
                                                        <label htmlFor={data.menuAvailabilityType}>
                                                            {data.menuAvailabilityType}
                                                        </label>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="input-group">
                                                            <input
                                                                type="number"
                                                                className="form-control input-default"
                                                                id="title"
                                                                placeholder="Price"
                                                                disabled={!data.checked}
                                                                onChange={(e) =>
                                                                    handleAvailabilityPrices(
                                                                        e,
                                                                        data.menuAvailabilityType,
                                                                        "price"
                                                                    )
                                                                }
                                                                value={data.price}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text bg-red">
                                                                    {currencyDetail?.currencySymbol}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                )}
                            </div>
                        </fieldset>
                    )}
                    {isStealDealItem && (
                        <fieldset className="my-4">
                            <legend>Steal deal Details *</legend>
                            <div className="form-group row align-items-center">
                                <div className="col-lg-4 my-2">
                                    <label>Item Type *</label>
                                    <Select
                                        isDisabled={!ownedItem}
                                        options={stealDealItemKeyValuePairs}
                                        value={record.stealDealItemType}
                                        onChange={(e) => handleSelectChange(e, "stealDealItemType")}
                                    />
                                </div>
                                <div className="col-lg-4 my-2">
                                    <label> Buying Unit *</label>
                                    <Select
                                        isDisabled={!ownedItem}
                                        options={stealDealItemKeyValuePairs}
                                        value={record.buyingUnit}
                                        onChange={(e) => handleSelectChange(e, "buyingUnit")}
                                    />
                                </div>
                                <div className="col-lg-4 my-2">
                                    <label> Quantity *</label>
                                    <input
                                        disabled={!ownedItem}
                                        type="number"
                                        className="form-control input-default"
                                        id="quantity"
                                        onChange={handleTextChange}
                                        value={record.quantity}
                                    />
                                </div>
                                <div className="col-lg-6 my-2">
                                    <label> Consumable Unit *</label>
                                    <Select
                                        isDisabled={!ownedItem}
                                        options={stealDealItemKeyValuePairs}
                                        value={record.consumableUnit}
                                        onChange={(e) => handleSelectChange(e, "consumableUnit")}
                                    />
                                </div>
                                <div className="col-lg-6 my-2">
                                    <label> Consumable Unit Postfix *</label>
                                    <Select
                                        isDisabled={!ownedItem}
                                        options={stealDealItemKeyValuePairs}
                                        value={record.consumableUnitPostfix}
                                        onChange={(e) =>
                                            handleSelectChange(e, "consumableUnitPostfix")
                                        }
                                    />
                                </div>
                                <div className="col-lg-12 my-2">
                                    <label> Short Description </label>
                                    <textarea
                                        disabled={!ownedItem}
                                        className="form-control input-default"
                                        id="shortDescription"
                                        cols="30"
                                        rows="2"
                                        onChange={handleTextChange}
                                        value={record.shortDescription}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    )}

                    {availabilityPrices[1].checked && (
                        <fieldset className="my-4">
                            <legend>Aggregator Availability Prices</legend>
                            <div className="row">
                                {aggregatorAvailabilityPrices.map((data, i) => (
                                    <div className="my-2 col-6" key={data.menuAvailabilityType}>
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <input
                                                    type="checkbox"
                                                    className="input-default me-2"
                                                    id={data.menuAvailabilityType}
                                                    onChange={(e) =>
                                                        handleAggregatorAvailabilityPrices(
                                                            e,
                                                            data.menuAvailabilityType,
                                                            "check"
                                                        )
                                                    }
                                                    checked={data.checked}
                                                />
                                                <label htmlFor={data.menuAvailabilityType}>
                                                    {data.menuAvailabilityType}
                                                </label>
                                            </div>
                                            <div className="col-6">
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        className="form-control input-default"
                                                        id="title"
                                                        placeholder="Price"
                                                        disabled={!data.checked}
                                                        onChange={(e) =>
                                                            handleAggregatorAvailabilityPrices(
                                                                e,
                                                                data.menuAvailabilityType,
                                                                "price"
                                                            )
                                                        }
                                                        value={data.price}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text bg-red">
                                                            {currencyDetail?.currencySymbol}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </fieldset>
                    )}
                    {
                        <fieldset className="my-4">
                            <legend>Combo Item</legend>
                            {showComboItemSection && comboItem.map((data, i) => (
                                <div className="my-2" key={i}>
                                    <div className="row">
                                        <div className={`col-lg-${11}`}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        options={unselectedComboItem}
                                                        value={data?.uuid}
                                                        onChange={(selectedOption) => handleComboItemChange(selectedOption, i, "uuid")}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        type="number"
                                                        className="form-control input-default"
                                                        id="quantity"
                                                        maxLength={16}
                                                        placeholder="Quantity"
                                                        onChange={(e) =>
                                                            handleComboItemChange(e, i, "quantity")
                                                        }
                                                        value={data.quantity}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1">
                                            <Button onClick={(e) => handleRemoveComboItem(i)}>
                                                <i className="bx bx-trash" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {ownedItem && (
                                <Button onClick={handleAddCOmboItemRow}>
                                    {" "}
                                    + Add New Row
                                </Button>
                            )}
                        </fieldset>
                    }
                    {recordID && (
                        <fieldset className="my-4">
                            <legend>Nutrients</legend>
                            {nutrients.map((data, i) => (
                                <div className="my-2" key={i}>
                                    <div className="row">
                                        <div className={`col-lg-${ownedItem ? 11 : 12}`}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        type="text"
                                                        className="form-control input-default"
                                                        id="title"
                                                        maxLength={20}
                                                        placeholder="Name"
                                                        disabled={!ownedItem}
                                                        onChange={(e) =>
                                                            handleNutrientsChange(e, i, "name")
                                                        }
                                                        value={data.nutrientName}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        type="text"
                                                        className="form-control input-default"
                                                        id="title"
                                                        maxLength={16}
                                                        placeholder="Value"
                                                        disabled={!ownedItem}
                                                        onChange={(e) =>
                                                            handleNutrientsChange(e, i, "value")
                                                        }
                                                        value={data.nutrientValue}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {ownedItem && (
                                            <div className="col-lg-1">
                                                <Button onClick={(e) => handleRemoveNutrient(i)}>
                                                    <i className="bx bx-trash" />
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            {ownedItem && (
                                <Button onClick={handleAddMoreNutrientRow}>
                                    {" "}
                                    + Add New Row
                                </Button>
                            )}
                        </fieldset>
                    )}
                </div>
                <div className="col-lg-5 cstm-obj-fit">
                    <fieldset>
                        <legend>Other Details</legend>
                        <div className="form-group row align-items-center">
                            <div className="col-lg-12 my-2">
                                <label>Category *</label>
                                <Select
                                    options={categoryKeyValuePairList}
                                    value={record.categoryUuid}
                                    onChange={(e) => {
                                        handleCategoryChange(e.key);
                                        handleSelectChange(e, "categoryUuid");
                                    }}
                                    isDisabled={!ownedItem}
                                />
                            </div>
                            {!isEmpty(record.categoryUuid) &&
                                !isStealDealItem &&
                                subCategoryKeyValuePairList.length !== 0 && (
                                    <div className="col-lg-12 my-2">
                                        <label>Sub Category</label>
                                        <Select
                                            isDisabled={!ownedItem}
                                            options={subCategoryKeyValuePairList}
                                            value={record.subCategoryUuid}
                                            onChange={(e) => handleSelectChange(e, "subCategoryUuid")}
                                        />
                                    </div>
                                )}
                            {/* <div className="col-lg-4 my-2">
                                <label> Sort Order *</label>
                                <input type="number" className="form-control input-default" id="sortOrder" onChange={handleTextChange} value={record.sortOrder} />
                            </div> */}
                            <div className="col-lg-12 my-2">
                                <label>Item Taxes (Multi) *</label>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    options={taxKeyPairList}
                                    value={record?.itemTaxUuids}
                                    onChange={(e) => handleSelectChange(e, "itemTaxUuids")}
                                />
                            </div>
                            {!isStealDealItem && (
                                <>
                                    <div className="col-lg-12 my-2">
                                        <label>Condiments (Multi)</label>
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={condimentsKeyValuePairs}
                                            value={record.itemCondimentUuids}
                                            onChange={(e) =>
                                                handleSelectChange(e, "itemCondimentUuids")
                                            }
                                            isDisabled={!ownedItem}
                                        />
                                    </div>
                                    <div className="col-lg-12 my-2">
                                        <label>Customizations (Multi)</label>
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={customizationsKeyValuePairs}
                                            value={record.itemCustomizationUuids}
                                            onChange={(e) =>
                                                handleSelectChange(e, "itemCustomizationUuids")
                                            }
                                            isDisabled={!ownedItem}
                                        />
                                    </div>
                                    <div className="col-lg-12 my-2">
                                        <label>Printer (Multi)</label>
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={printerKeyValuePairs}
                                            value={record.printerUuids}
                                            onChange={(e) => handleSelectChange(e, "printerUuids")}
                                        />
                                    </div>
                                    <div className="col-lg-12 my-2">
                                        <label>Timing Group List</label>
                                        <Select
                                            isClearable
                                            options={timingGroupKeyPairList}
                                            value={record.timingGroupUuid}
                                            onChange={(e) => handleSelectChange(e, "timingGroupUuid")}
                                        />
                                    </div>
                                    <div className="col-lg-12 my-2">
                                        <label>Sticker</label>
                                        <Select
                                            isDisabled={!ownedItem}
                                            isClearable
                                            options={stickerKeyValuePairList}
                                            value={record.sticker}
                                            onChange={(e) => handleSelectChange(e, "sticker")}
                                        />
                                    </div>
                                </>
                            )}
                            {recordID && (
                                <>
                                    <div className="col-xl-12 my-2">
                                        <label> Ingredients</label>
                                        <textarea
                                            disabled={!ownedItem}
                                            className="form-control input-default"
                                            id="ingredients"
                                            onChange={handleTextChange}
                                            value={record.ingredients}
                                        />
                                    </div>
                                </>
                            )}
                            <div className="col-lg-12 my-2">
                                <fieldset className="my-2">
                                    <legend>Item Type</legend>
                                    <div className="d-flex">
                                        {vegNonVegOptions.map((data) => (
                                            <div key={data} className="me-3 form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="vegNonVeg"
                                                    id={data}
                                                    disabled={!ownedItem}
                                                    checked={record.vegNonVeg === data}
                                                    onChange={(e) =>
                                                        setRecord({ ...record, vegNonVeg: data })
                                                    }
                                                />
                                                <label htmlFor={data}>{data}</label>
                                            </div>
                                        ))}
                                    </div>
                                </fieldset>
                            </div>
                            {!isStealDealItem && (
                                <div className="col-lg-12 my-2">
                                    <div className="form-check">
                                        <label htmlFor="offerApplicable">Offer Applicable</label>
                                        <input
                                            type="checkbox"
                                            name="offerApplicable"
                                            id="offerApplicable"
                                            className="form-check-input"
                                            disabled={!ownedItem}
                                            checked={record.offerApplicable}
                                            onChange={(e) =>
                                                setRecord({
                                                    ...record,
                                                    offerApplicable: e.target.checked,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-xl-12 my-2">
                                <label> Description </label>
                                <textarea
                                    disabled={!ownedItem}
                                    className="form-control input-default"
                                    id="description"
                                    onChange={handleTextChange}
                                    value={record.description}
                                    rows='4'
                                />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="my-4">
                        <div className="col-lg-12 my-2">
                            <div className="d-flex">
                                <label className="me-3">Image Type:</label>
                                {imageTypeOptions.map((data) => (
                                    <div key={data} className="me-2 form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="imageType"
                                            id={data?.label}
                                            checked={record?.imageType === data?.value}
                                            disabled={!ownedItem}
                                            onChange={(e) => {

                                                record?.imageType === data.value ? setRecord({ ...record, imageType: "NONE" }) :
                                                    setRecord({ ...record, imageType: data.value })
                                                // const newValue = e.target.checked ? data : "";
                                                // console.log("eeeeeeeeee", e.target.checked)
                                                // setRecord({ ...record, imageType: newValue });
                                            }
                                            }
                                        />

                                        <label htmlFor={data?.label}>{data?.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <legend>Image</legend>
                        <ImageSelector
                            style={{
                                height: "120px",
                                width: "100%",
                                // borderRadius: "50%",
                                overflow: "hidden",
                            }}
                            owned={ownedItem}
                            handleImageChange={handleImageChange}
                            selectedImage={record.itemImageRefId}
                        />
                    </fieldset>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default RestaurantMenuItemAddEdit;
