import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { Add_Brand_POST, Get_Brand_Detail_GET, Update_Brand_PUT } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { MODAL_LOADER } from "utils/constants";
import { isAlphaNumercSpecialChar, isAlphaNumercSpecialCharSpc, isEmpty } from "utils/TextUtils";

import ModalWrapper from "components/common/ModalWrapper";
import ImageSelector from "components/common/ImageSelector";

const BrandAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const defaultRecordObj = {
        brandTitle: "",
        brandDescription: "",
        iconImageRefId: "",
        iconLogoRefId: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Get_Brand_Detail_GET.replaceAll("{recordID}", recordID), {}, (response) => {
                const res = response.responsePacket;
                setRecord(res);
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const {id,value} = e.target;

        if (id === "brandTitle"  && value.length !== 0) {
            if (!isAlphaNumercSpecialCharSpc(value)) {
                return;
            }
        }
        if (id === "brandDescription"  && value.length !== 0) {
            if (!isAlphaNumercSpecialCharSpc(value)) {
                return;
            }
        }
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    // Image change
    const handleImageChange = (imageRefId) => {
        setRecord({ ...record, iconImageRefId: imageRefId });
    };
    // Image change
    const handleLogoImageChange = (imageRefId) => {
        setRecord({ ...record, iconLogoRefId: imageRefId });
    };

    const checkInputValidation = () => {
        if (isEmpty(record.iconImageRefId)) {
            toast.error(`Image must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(record.iconLogoRefId)) {
            toast.error(`Image Logo  must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(record.brandTitle)) {
            toast.error(`Brand name must not be empty`,{id: "toast"});
            return false;
        } else if (record.brandTitle.length < 3) {
            toast.error(`Brand name must be more than 2 characters`,{id: "toast"});
            return false;
        }
        // else if (record.brandTitle.length > 50) {
        //     toast.error(`Brand name must  be more then 50 character.`,{id: "toast"});
        //     return false;
        // }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Add_Brand_POST, recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(MODAL_LOADER, Update_Brand_PUT.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            }
        } catch (error) {
            return;
        }
    };
    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`${recordID ? "Update" : "Add"} Brand`} size="ms" handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-6">
                <label>Brand Icon</label>
                    <ImageSelector
                        style={{
                            height: "120px",
                            width: "120px",
                            // borderRadius: "50%",
                            overflow: "hidden",
                        }}
                        handleImageChange={handleImageChange}
                        selectedImage={record.iconImageRefId}
                    />
                </div>
                <div className="col-lg-6">
                <label class>Brand Logo</label>
                    <ImageSelector
                        style={{
                            height: "120px",
                            width: "120px",
                            // borderRadius: "50%",
                            overflow: "hidden",
                        }}
                        handleImageChange={handleLogoImageChange}
                        selectedImage={record.iconLogoRefId}
                    />
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-lg-12 mb-3">
                    <label>Brand Name *</label>
                    <input type="text" maxLength={50} className="form-control input-default" id="brandTitle" onChange={handleTextChange} value={record.brandTitle} />
                </div>
                <div className="col-lg-12 ">
                    <label>Brand Description </label>
                    <textarea className="form-control input-default" id="brandDescription" cols="10" rows="2" onChange={handleTextChange} value={record.brandDescription} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default BrandAddEdit;
