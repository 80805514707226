import { useCallback, useEffect, useMemo, useState } from "react";

import Select from "react-select";
import { Button } from "react-bootstrap";

import {
  Activate_Client_Menu_Item_Put,
  All_Client_Menu_Item_List_Post,
  DeActivate_Client_Menu_Item_Put,
  Delete_Client_Menu_Item_Delete,
  Deleted_Client_Menu_Item_List_POST,
  Revive_Client_Menu_Item_Put,
} from "api/ApiConst";
import {
  DELIVERY_PARTNERS_AVAILABILITIES,
  DINE_IN,
  HOME_DELIVERY,
  STEAL_DEAL,
  TAKEAWAY,
  ZOMATO,
  SWIGGY,
  TYPE_AVAILABILITIES,
} from "utils/constants";
import {
  getImageSrcViaRefId,
  handleActive,
  handleDelete,
  handleInactive,
  handleRevive,
} from "utils/UserUtils";
import { isEmpty } from "utils/TextUtils";

import MenuItemAddEdit from "./MenuItemAddEdit";
import CheckboxButton from "components/common/CheckboxButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import CopySyncMenuItem from "./CopySyncMenuItem";
import Cookies from "universal-cookie";

const availabilitiesOptions = [
  { label: "All Item", value: "AllItem" },
  { label: "Dine In Item", value: "DineIn" },
  { label: "Home Delivery Item", value: "HomeDelivery" },
  { label: "Take away Item", value: "TakeAway" },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: 160, // Set the desired width
  }),
};

export const ItemMaster = () => {
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [viewDeletedList, setViewDeletedList] = useState(false);
  const [recordID, setRecordID] = useState(null);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [showCopySyncModal, setShowCopySyncModal] = useState(false);
  const [isMenuItemCopy, setIsMenuItemCopy] = useState(false);
  const [itemAvailability, setItemAvailability] = useState({
    label: "All Item",
    value: "AllItem",
  });

  const currencyDetail = new Cookies().get("currencyDetail");

  useEffect(() => {
    if (!showAddUpdateModal) {
      setRecordID(null);
    }
  }, [showAddUpdateModal]);

  const handleRefresh = useCallback(() => {
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag]);

  const columns = useMemo(
    () => [
      {
        id: "status",
        name: "",
        selector: (row) => {
          if (row.active) {
            return <div className="led-green" title="Active" />;
          } else {
            return <div className="led-red" title="Inactive" />;
          }
        },
        width: "60px",
        sortable: false,
      },
      {
        id: "itemImageRefId",
        name: "",
        selector: (row, i) => (
          <div className="list-img" key={`category-img-${i}`}>
            <img
              src={getImageSrcViaRefId(row.itemImageRefId)}
              alt="category icon"
            />
          </div>
        ),
        width: "70px",
        sortable: false,
      },
      {
        id: "title",
        name: "Title",
        selector: (row, i) => (
          <>
            {
              <img
                key={`vegNonVeg-img-${i}`}
                src={`/assets/images/${
                  row.vegNonVeg.toLowerCase() === "veg"
                    ? "veg"
                    : row.vegNonVeg.toLowerCase() === "nonveg"
                    ? "nonVeg"
                    : row.vegNonVeg.toLowerCase() === "vegan"
                    ? "vegan"
                    : "none"
                }.png`}
                className="me-1"
                alt=""
                style={{ height: "15px", width: "auto" }}
              />
            }{" "}
            {row.title}
          </>
        ),
        wrap: true,
        sortable: false,
      },
      {
        id: "brandTitle",
        name: "Brand",
        selector: (row) => row.brandTitle,
        sortable: false,
        wrap: 2,
      },
      {
        id: "categoryTitle",
        name: "Category",
        selector: (row) => <>{row.categoryTitle}</>,
        wrap: true,
      },
      {
        id: "subCategoryTitle",
        name: "Sub Cat",
        selector: (row) => <>{row.subCategoryTitle}</>,
        sortable: false,
        wrap: true,
      },
      {
        id: "availabilityPrices",
        name: "Availability",
        selector: (row, i) =>
          TYPE_AVAILABILITIES.map(
            (type, j) =>
              type !== STEAL_DEAL &&
              (row.availabilityPrices.find(
                (data) =>
                  data.menuAvailabilityType.toLowerCase() === type.toLowerCase()
              ) ? (
                <span
                  className="badge-available"
                  title={type}
                  key={`type-availability-available-${i}-${j}`}
                >
                  {type === DINE_IN
                    ? "Di"
                    : type === HOME_DELIVERY
                    ? "De"
                    : type === TAKEAWAY
                    ? "Ta"
                    : "Sd"}
                </span>
              ) : (
                <span
                  className="badge-unavailable"
                  title={type}
                  key={`type-availability-unavailable-${i}-${j}`}
                >
                  {type === DINE_IN
                    ? "Di"
                    : type === HOME_DELIVERY
                    ? "De"
                    : type === TAKEAWAY
                    ? "Ta"
                    : "Sd"}
                </span>
              ))
          ),
        sortable: false,
        width: "120px",
      },
      {
        id: "aggregatorAvailabilityPrices",
        name: "D. Partner",
        selector: (row, i) =>
          DELIVERY_PARTNERS_AVAILABILITIES.map((type, j) =>
            !isEmpty(row.aggregatorAvailabilityPrices) &&
            row.aggregatorAvailabilityPrices.find(
              (data) =>
                data.menuAvailabilityType.toLowerCase() === type.toLowerCase()
            ) ? (
              <span
                className="badge-available"
                title={type}
                key={`delivery-availability-available-${i}-${j}`}
              >
                {type === ZOMATO ? "Zo" : type === SWIGGY ? "Sw" : ""}
              </span>
            ) : (
              <span
                className="badge-unavailable"
                title={type}
                key={`delivery-availability-available-${i}-${j}`}
              >
                {type === ZOMATO ? "Zo" : type === SWIGGY ? "Sw" : ""}
              </span>
            )
          ),
        sortable: false,
        width: "100px",
      },
      {
        id: "maximumRetailPrice",
        name: "MRP",
        selector: (row) => (
          <>
            {row.maximumRetailPrice.toFixed(2) +
              " " +
              currencyDetail?.currencySymbol}{" "}
          </>
        ),
        sortable: false,
        right: true,
        width: "60px",
      },
      {
        id: "action",
        name: "",
        selector: (row) => getActionButtons(row),
        width: "100px",
        sortable: false,
      },
    ],
    [handleRefresh]
  );

  const getActionButtons = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        {!row.deleted && (
          <button className="listBtn me-2" onClick={() => handleEdit(row)}>
            <i className="bx bx-pencil"></i>
          </button>
        )}
        <div className="dropdown">
          <button
            className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle"
            title="More"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ padding: "5px 10px 8px 10px" }}
          >
            <i className="bx bx-chevron-down"></i>
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            <>
              {row.deleted ? (
                <li onClick={() => handleReviveData(row)}>
                  <i className="bx bx-log-out-circle"></i> Revive
                </li>
              ) : (
                <>
                  <li onClick={() => handleDeleteData(row)}>
                    <i className="bx bx-trash"></i> Delete
                  </li>
                  <li onClick={() => handleActiveInactive(row)}>
                    {row.active ? (
                      <>
                        <i className="bx bx-x-circle"></i> Inactive
                      </>
                    ) : (
                      <>
                        <i className="bx bx-check-circle"></i> Active
                      </>
                    )}
                  </li>
                </>
              )}
            </>
          </ul>
        </div>
      </div>
    );
  };

  const handleEdit = (row) => {
    setRecordID(row.uuid);
    setShowAddUpdateModal(true);
  };

  const handleActiveInactive = (row) => {
    if (row.active) {
      handleInactive(
        DeActivate_Client_Menu_Item_Put.replaceAll("{recordID}", row.uuid),
        handleRefresh
      );
    } else {
      handleActive(
        Activate_Client_Menu_Item_Put.replaceAll("{recordID}", row.uuid),
        handleRefresh
      );
    }
  };

  //Delete Record
  const handleDeleteData = (row) => {
    handleDelete(
      Delete_Client_Menu_Item_Delete.replaceAll("{recordID}", row.uuid),
      handleRefresh
    );
  };

  //Revive Record
  const handleReviveData = (row) => {
    handleRevive(
      Revive_Client_Menu_Item_Put.replaceAll("{recordID}", row.uuid),
      handleRefresh
    );
  };

  // handle Copy-Sync Modal
  const handleCopySyncModal = (isCopyItem) => {
    setIsMenuItemCopy(isCopyItem);
    setShowCopySyncModal(true);
  };

  const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

  return (
    <>
      {PageHeading("Food Item List", "Menu", "/")}
      <div className="card">
        <div className="d-flex createButton">
          <CustomButton
            handleClick={() => setShowAddUpdateModal(true)}
            title="Create New"
            iconClass="bx bx-plus"
            buttonClass=""
          />
          <Button
            className="btn btn-primary mx-2"
            onClick={() => handleCopySyncModal(false)}
          >
            <i className="bx bx-sync"></i> Sync Menu Item
          </Button>
          <Button
            className="btn btn-primary mx-2"
            onClick={() => handleCopySyncModal(true)}
          >
            <i className="bx bx-copy"></i> Copy Menu Item
          </Button>
          <Select
            className=""
            maxMenuHeight={150}
            styles={customStyles}
            options={availabilitiesOptions}
            value={itemAvailability}
            onChange={(e) => setItemAvailability(e)}
          />
        </div>
        <div className="card-body tableView">
          <PaginationDataTable
            paginationUrl={
              viewDeletedList
                ? Deleted_Client_Menu_Item_List_POST +
                  "/" +
                  itemAvailability.value
                : All_Client_Menu_Item_List_Post + "/" + itemAvailability.value
            }
            serverSidePagination={true}
            search={true}
            pagination={false}
            columns={columns}
          />
          <CheckboxButton
            title="Deleted List"
            handleClick={handleToggleDeletedView}
            additionalClass="deletedBtn ml-auto"
          />
        </div>
      </div>
      {showAddUpdateModal && (
        <MenuItemAddEdit
          show={showAddUpdateModal}
          hideModal={() => setShowAddUpdateModal(false)}
          recordID={recordID}
          handleRefresh={handleRefresh}
          isStealDealItem={false}
        />
      )}
      {showCopySyncModal && (
        <CopySyncMenuItem
          show={showCopySyncModal}
          hideModal={() => setShowCopySyncModal(false)}
          handleRefresh={handleRefresh}
          isMenuItemCopy={isMenuItemCopy}
        />
      )}
    </>
  );
};

export default ItemMaster;
