import { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import Select from "react-select";
import { Button } from "react-bootstrap";

import {
  Add_Restaurant_Happy_Hours_Offer_POST,
  Get_Restaurant_Happy_Hours_Offer_Detail_GET,
  Update_Restaurant_Happy_Hours_Offer_PUT,
} from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import {
  getDOB,
  getTimeFromDate,
  isAlphaNumercSpecialCharSpc,
  isEmpty,
} from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";
import Cookies from "universal-cookie";

const offerTypeKeyValuePairs = [
  { label: "BuyAnyGetAny", value: "BuyAnyGetAny", key: "BuyAnyGetAny" },
  { label: "FlatDiscount", value: "FlatDiscount", key: "FlatDiscount" },
  { label: "FlatPrice", value: "FlatPrice", key: "FlatPrice" },
];

const HappyHourOfferAddEdit = ({
  show,
  hideModal,
  recordID,
  handleRefresh,
  itemKeyValuePairList,
}) => {
  const defaultRecordObj = {
    offerType: "",
    offerTitle: "",
    discountPercentage: "",
    discountPrice: "",
    startDateTimeStamp: "",
    endDateTimeStamp: "",
    startTimeTimeStamp: "",
    endTimeTimeStamp: "",
    availabilities: [],
  };
  const days = [
    "applicableOnMonday",
    "applicableOnTuesday",
    "applicableOnWednesday",
    "applicableOnThursday",
    "applicableOnFriday",
    "applicableOnSaturday",
    "applicableOnSunday",
  ];

  const [record, setRecord] = useState(defaultRecordObj);
  const [availabilities, setAvailabilities] = useState({
    HomeDelivery: false,
    TakeAway: false,
    DineIn: false,
  });
  const [applicableDays, setApplicableDays] = useState([]);
  const [happyHoursOfferItemList, setHappyHoursOfferItemList] = useState([]);

  const currencyDetail = new Cookies().get("currencyDetail");

  useEffect(() => {
    if (recordID) {
      callGetApi(
        MODAL_LOADER,
        Get_Restaurant_Happy_Hours_Offer_Detail_GET.replaceAll(
          "{recordID}",
          recordID
        ),
        {},
        (response) => {
          const res = response.responsePacket;

          // Set Offer Type Key-Value Pair
          const _offerType = offerTypeKeyValuePairs.find(
            (obj) => obj.key === res.offerType
          );

          const recordObj = {
            ...res,
            offerType: _offerType,
            startDateTimeStamp: getDOB(res.startDateTimeStamp),
            endDateTimeStamp: getDOB(res.endDateTimeStamp),
            startTimeTimeStamp: convertTimestampToTime(res.startTimeTimeStamp),
            endTimeTimeStamp: convertTimestampToTime(res.endTimeTimeStamp),
            discountPercentage:
              res.offerType === "FlatDiscount" ? res.offerDiscountValue : "",
            discountPrice:
              res.offerType === "FlatPrice" ? res.offerDiscountValue : "",
          };
          setRecord(recordObj);

          // Set Offer Availabilities
          for (let i = 0; i < res.availabilities.length; i++) {
            const availabilitiesKey = res.availabilities[i];
            if (availabilitiesKey) {
              setAvailabilities((prev) => ({
                ...prev,
                [availabilitiesKey]: true,
              }));
            }
          }

          // set Applicable Days
          const _applicableDays = [];
          days &&
            days.map((day) => {
              if (res[day]) {
                _applicableDays.push(day);
              }
            });
          setApplicableDays(_applicableDays);

          // set Happy Hours Offer Item List
          const _happyHoursOfferItemList =
            res.detailHappyHoursOfferItemList.map((data) => ({
              paidItemUuid:
                itemKeyValuePairList.find(
                  (obj) => obj.key === data.paidItemUuid
                ) || "",
              paidItemQty: data.paidItemQty,
              freeItemUuid:
                itemKeyValuePairList.find(
                  (obj) => obj.key === data.freeItemUuid
                ) || "",
              freeItemQty: data.freeItemQty,
            }));
          setHappyHoursOfferItemList(_happyHoursOfferItemList);
        }
      );
    }
  }, [recordID]);

  //Text Change Event
  const handleTextChange = (e) => {
    const { id, type, value } = e.target;
    if (id === "offerTitle" && value.length !== 0) {
      if (!isAlphaNumercSpecialCharSpc(value)) {
        return;
      }
    }
    if (id === "discountPercentage") {
      if (value.length > 2) {
        return;
      }
    }
    if (id === "discountPrice") {
      if (value.length > 7) {
        return;
      }
    }
    if (type === "number") {
      if (id === "itemQuantity") {
        setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
      }
      setRecord((prev) => ({ ...prev, [id]: parseFloat(value) }));
      return;
    }
    setRecord((prev) => ({ ...prev, [id]: value }));
  };

  // Checkbox check/uncheck
  const handleCheckBoxChange = (e, key) => {
    const { id, checked } = e.target;
    if (key === "availabilities") {
      if (id === "All") {
        const _availability = { ...availabilities };
        for (const key in _availability) {
          _availability[key] = true;
        }
        setAvailabilities({ ..._availability });
      } else {
        setAvailabilities((prev) => ({ ...prev, [id]: checked }));
      }
    } else if (key === "applicableDays") {
      if (id === "AllDays") {
        setApplicableDays(days);
      } else {
        const foundDay = applicableDays.findIndex((data) => data === id);
        const daysArrClone = JSON.parse(JSON.stringify(applicableDays));
        if (foundDay > -1) {
          daysArrClone.splice(foundDay, 1);
          setApplicableDays(daysArrClone);
        } else {
          const arrClone = [];
          days &&
            days.map((day) => {
              const foundDay = applicableDays.find((avDay) => avDay === day);
              if (foundDay || day === id) {
                arrClone.push(day);
              }
            });
          setApplicableDays(arrClone);
        }
      }
    }
  };

  // Select change
  const handleSelectChange = (e) => {
    setRecord({ ...record, offerType: e });
  };

  // Offer Items List text change
  const handleOfferItemTextChange = (e, i) => {
    const { id, value, type } = e.target;
    const _offerItems = [...happyHoursOfferItemList];
    _offerItems[i] = {
      ..._offerItems[i],
      [id]: type === "number" ? parseInt(value) : value,
    };
    setHappyHoursOfferItemList(_offerItems);
  };

  // Offer Items List Select change
  const handleOfferItemSelectChange = (e, key, i) => {
    const _offerItems = [...happyHoursOfferItemList];
    _offerItems[i] = { ..._offerItems[i], [key]: e };
    setHappyHoursOfferItemList(_offerItems);
  };

  // Filter Item Options // *remove selected options for others
  const filterOptions = (options) => {
    // Filter out the selected options from the options array
    return options.filter(
      (option) =>
        !happyHoursOfferItemList.find(
          (selected) => selected["paidItemUuid"].key === option.value
        )
    );
  };

  // Add More row Items
  const handleAddMoreOfferItems = () => {
    const _happyHoursOfferItemList = [...happyHoursOfferItemList];
    if (filterOptions(itemKeyValuePairList).length === 0) {
      toast.error("Items List is Empty, You can't add More.");
      return;
    } else if (
      _happyHoursOfferItemList.length ===
      filterOptions(itemKeyValuePairList).length
    ) {
      toast.error("You have Enough Items, can't add More.");
      return;
    }
    _happyHoursOfferItemList.push({
      paidItemUuid: "",
      paidItemQty: "",
      freeItemUuid: "",
      freeItemQty: "",
    });
    setHappyHoursOfferItemList(_happyHoursOfferItemList);
  };

  // remove Items row
  const handleRemoveItemsRow = (i) => {
    const _happyHoursOfferItemList = [...happyHoursOfferItemList];
    _happyHoursOfferItemList.splice(i, 1);
    setHappyHoursOfferItemList(_happyHoursOfferItemList);
  };

  // Convert timesTamp to time
  const convertTimestampToTime = (timestamp) => {
    if (isEmpty(timestamp)) {
      return "";
    }
    var date = new Date(timestamp);
    var hour = date.getHours();
    var min = date.getMinutes();
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (min < 10) {
      min = "0" + min;
    }
    var time = hour + ":" + min;
    return time;
  };

  // convert Timestamp time to format time
  const handleChangeTimeToTimesTamp = (time) => {
    if (isEmpty(time)) {
      return "";
    }
    const date = new Date();
    date.setHours(time.substr(0, 2));
    date.setMinutes(time.substr(3, 2));
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  };
  // Check offer Input Validation
  const checkInputValidation = (recordObj) => {
    if (isEmpty(recordObj.offerType)) {
      toast.error(`Offer Type must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.offerTitle)) {
      toast.error(`Title must not be empty`);
      return false;
    } else if (recordObj.offerTitle.length > 50) {
      toast.error(`Title must not be more then 50 characters.`, {
        id: "toast",
      });
      return false;
    } else if (
      recordObj.offerType === "FlatDiscount" &&
      isEmpty(recordObj.discountPercentage)
    ) {
      toast.error(`Discount Percentage must not be empty`, { id: "toast" });
      return false;
    } else if (recordObj.discountPercentage < 0) {
      toast.error(`Discount Percentage must not be negative.`, { id: "toast" });
      return false;
    } else if (
      recordObj.offerType === "FlatPrice" &&
      isEmpty(recordObj.discountPrice)
    ) {
      toast.error(`Discount Price must not be empty`, { id: "toast" });
      return false;
    } else if (recordObj.discountPrice < 0) {
      toast.error(`Discount Price must not be negative.`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.startDateTimeStamp)) {
      toast.error(`Start Date must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.endDateTimeStamp)) {
      toast.error(`End Date must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.startTimeTimeStamp)) {
      toast.error(`Start Time must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.endTimeTimeStamp)) {
      toast.error(`End Time must not be empty`, { id: "toast" });
      return false;
    } else if (recordObj.availabilities.length === 0) {
      toast.error(`Availabilities must not be empty`, { id: "toast" });
      return false;
    }
    return true;
  };

  // Check offer Items Input Validation
  const checkItemInputValidation = () => {
    for (let i = 0; i < happyHoursOfferItemList.length; i++) {
      if (isEmpty(happyHoursOfferItemList[i].paidItemUuid)) {
        toast.error("Paid Item must not be empty.", { id: "toast" });
        return false;
      } else {
        if (record.offerType.key === "BuyAnyGetAny") {
          if (isEmpty(happyHoursOfferItemList[i].paidItemQty)) {
            toast.error("Paid Item Quantity must not be empty.", {
              id: "toast",
            });
            return false;
          } else if (happyHoursOfferItemList[i].paidItemQty < 0) {
            toast.error("Paid Item Quantity must not be negative.", {
              id: "toast",
            });
            return false;
          } else if (isEmpty(happyHoursOfferItemList[i].freeItemUuid)) {
            toast.error("Free Item must not be empty.", { id: "toast" });
            return false;
          } else if (isEmpty(happyHoursOfferItemList[i].freeItemQty)) {
            toast.error("Free Item Quantity must not be empty.", {
              id: "toast",
            });
            return false;
          } else if (happyHoursOfferItemList[i].freeItemQty < 0) {
            toast.error("Free Item Quantity must not be negative.", {
              id: "toast",
            });
            return false;
          }
        }
      }
    }
    return true;
  };

  //Submit Happy hours offer Form
  const handleSubmit = async () => {
    try {
      // get Availability Options [DineIn, HomeDelivery, TakeAway]
      let _availabilities = [];
      for (let key in availabilities) {
        if (availabilities[key]) {
          _availabilities.push(key);
        }
      }

      // get Happy Hours Item List Array [paidItem, paidItemQty, freeItem, freeItemQty]
      let _happyHoursOfferItemList = [];
      for (let i = 0; i < happyHoursOfferItemList.length; i++) {
        _happyHoursOfferItemList.push({
          paidItemUuid: happyHoursOfferItemList[i].paidItemUuid.key || "",
          paidItemQty: happyHoursOfferItemList[i].paidItemQty,
          freeItemUuid: happyHoursOfferItemList[i].freeItemUuid.key || "",
          freeItemQty: happyHoursOfferItemList[i].freeItemQty,
        });
      }

      // get offer Applicable days Object
      const _applicableDays = {};
      for (const day of days) {
        _applicableDays[day] = applicableDays.includes(day);
      }

      let recordObj = {
        ...record,
        ..._applicableDays,
        offerType: record.offerType.key || "",
        availabilities: _availabilities,
        startDateTimeStamp: getTimeFromDate(record.startDateTimeStamp),
        endDateTimeStamp: getTimeFromDate(record.endDateTimeStamp),
        startTimeTimeStamp: handleChangeTimeToTimesTamp(
          record.startTimeTimeStamp
        ),
        endTimeTimeStamp: handleChangeTimeToTimesTamp(record.endTimeTimeStamp),
        happyHoursOfferItemList: _happyHoursOfferItemList,
        offerDiscountValue:
          record.offerType?.key === "FlatDiscount"
            ? record.discountPercentage
            : record.offerType?.key === "FlatPrice"
              ? record.discountPrice
              : null,
      };

      if (!checkInputValidation(recordObj)) {
        return;
      }

      if (!checkItemInputValidation()) {
        return;
      }

      if (!recordID || recordID === "") {
        callPostApi(
          MODAL_LOADER,
          Add_Restaurant_Happy_Hours_Offer_POST,
          recordObj,
          {},
          (response) => {
            toast.success(response.message, { id: "toast" });
            hideModal();
            handleRefresh();
          }
        );
      } else {
        callPutApi(
          MODAL_LOADER,
          Update_Restaurant_Happy_Hours_Offer_PUT.replaceAll(
            "{recordID}",
            recordID
          ),
          recordObj,
          {},
          (response) => {
            toast.success(response.message, { id: "toast" });
            hideModal();
            handleRefresh();
          }
        );
      }
    } catch (error) {
      return;
    }
  };

  return (
    <ModalWrapper
      show={show}
      size="xl"
      backdrop="static"
      hide={hideModal}
      header={`${recordID ? "Update" : "Add"} Happy Hours Offer`}
      handleSubmit={handleSubmit}
    >
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group row">
            <div className="col-lg-4 mb-2">
              <label>Offer Type *</label>
              <Select
                options={offerTypeKeyValuePairs}
                value={record.offerType}
                onChange={handleSelectChange}
              />
            </div>
            <div
              className={`col-lg-${record.offerType?.key === "FlatDiscount" ||
                  record.offerType?.key === "FlatPrice"
                  ? 4
                  : 8
                }  mb-2`}
            >
              <label>Title *</label>
              <input
                type="text"
                className="form-control input-default"
                id="offerTitle"
                onChange={handleTextChange}
                value={record.offerTitle}
              />
            </div>
            {record.offerType?.key === "FlatDiscount" && (
              <div className="col-lg-4 mb-2">
                <label>Discount Percentage *</label>
                <div className="input-group">
                  <div className="input-group-append">
                    <span className="input-group-text bg-red">%</span>
                  </div>
                  <input
                    type="number"
                    className="form-control input-default"
                    id="discountPercentage"
                    onChange={handleTextChange}
                    value={record.discountPercentage}
                  />
                </div>
              </div>
            )}
            {record.offerType?.key === "FlatPrice" && (
              <div className="col-lg-4 mb-2">
                <label>Discount Price *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control input-default"
                    id="discountPrice"
                    onChange={handleTextChange}
                    value={record.discountPrice}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text bg-red">
                      {currencyDetail?.currencySymbol}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="form-group row mb-3">
            <div className="col-lg-6">
              <label>Start Date *</label>
              <input
                type="date"
                className="form-control input-default"
                id="startDateTimeStamp"
                onChange={handleTextChange}
                value={record.startDateTimeStamp}
              />
            </div>
            <div className="col-lg-6">
              <label>End Date *</label>
              <input
                type="date"
                className="form-control input-default"
                id="endDateTimeStamp"
                onChange={handleTextChange}
                value={record.endDateTimeStamp}
              />
            </div>
          </div>
          <div className="form-group row mb-3">
            <div className="col-lg-6">
              <label>Start Time *</label>
              <input
                type="time"
                className="form-control input-default"
                id="startTimeTimeStamp"
                onChange={handleTextChange}
                value={record.startTimeTimeStamp}
              />
            </div>
            <div className="col-lg-6">
              <label>Close Time *</label>
              <input
                type="time"
                className="form-control input-default"
                id="endTimeTimeStamp"
                onChange={handleTextChange}
                value={record.endTimeTimeStamp}
              />
            </div>
          </div>
          <fieldset className="my-4">
            <legend>Availabilities *</legend>
            <div className="row ">
              <div className="col-lg-3">
                <input
                  type="checkbox"
                  className="input-default me-2"
                  id="All"
                  onChange={(e) => handleCheckBoxChange(e, "availabilities")}
                  checked={
                    availabilities.DineIn &&
                    availabilities.HomeDelivery &&
                    availabilities.TakeAway
                  }
                />
                <label htmlFor="All">All </label>
              </div>
              <div className="col-lg-3">
                <input
                  type="checkbox"
                  className="input-default me-2"
                  id="DineIn"
                  onChange={(e) => handleCheckBoxChange(e, "availabilities")}
                  checked={availabilities?.DineIn}
                />
                <label htmlFor="DineIn">DineIn </label>
              </div>
              <div className="col-lg-3">
                <input
                  type="checkbox"
                  className="input-default me-2"
                  id="HomeDelivery"
                  onChange={(e) => handleCheckBoxChange(e, "availabilities")}
                  checked={availabilities?.HomeDelivery}
                />
                <label htmlFor="HomeDelivery">Delivery </label>
              </div>
              <div className="col-lg-3">
                <input
                  type="checkbox"
                  className="input-default me-2"
                  id="TakeAway"
                  onChange={(e) => handleCheckBoxChange(e, "availabilities")}
                  checked={availabilities?.TakeAway}
                />
                <label htmlFor="TakeAway">Takeaway</label>
              </div>
            </div>
          </fieldset>
          <fieldset className="mt-4">
            <legend>Applicable Days</legend>
            <div className="row">
              <div className="col-lg-3 my-2">
                <input
                  type="checkbox"
                  className="input-default me-2"
                  id="AllDays"
                  onChange={(e) => handleCheckBoxChange(e, "applicableDays")}
                  checked={days.every((value) =>
                    applicableDays.includes(value)
                  )}
                />
                <label htmlFor="AllDays">All</label>
              </div>
              {days &&
                days.map((day, i) => (
                  <div className="col-lg-3 my-2" key={day + "-" + i}>
                    <input
                      type="checkbox"
                      className="input-default me-2"
                      id={day}
                      onChange={(e) =>
                        handleCheckBoxChange(e, "applicableDays")
                      }
                      checked={applicableDays.includes(day)}
                    />
                    {/* <label htmlFor={day}>{capitalizeDayName(day)} </label> */}
                    <label htmlFor={day}>
                      {day.replaceAll("applicableOn", "")}{" "}
                    </label>
                  </div>
                ))}
            </div>
          </fieldset>
        </div>
        <div className="col-lg-6">
          <fieldset>
            <legend>Items</legend>
            {happyHoursOfferItemList.length !== 0 && (
              <div className="mb-1">
                <div className="row">
                  <div className="col-lg-11">
                    <div className="row">
                      <div
                        className={`col-lg-${record.offerType?.key === "BuyAnyGetAny" ? 4 : 12
                          }`}
                      >
                        {" "}
                        <label>Paid Item *</label>
                      </div>
                      {record.offerType?.key === "BuyAnyGetAny" && (
                        <>
                          <div className="col-lg-2">
                            <label>Qty *</label>
                          </div>
                          <div className="col-lg-4">
                            <label>Free Item *</label>
                          </div>
                          <div className="col-lg-2">
                            <label>Qty *</label>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-1 px-0" />
                </div>
              </div>
            )}
            {happyHoursOfferItemList &&
              happyHoursOfferItemList.map((items, i) => (
                <div className="mb-3" key={i}>
                  <div className="row align-items-center">
                    <div className="col-lg-11">
                      <div className="row">
                        <div
                          className={`col-lg-${record.offerType?.key === "BuyAnyGetAny" ? 4 : 12
                            }`}
                        >
                          <Select
                            options={filterOptions(itemKeyValuePairList)}
                            value={items.paidItemUuid}
                            onChange={(e) =>
                              handleOfferItemSelectChange(e, "paidItemUuid", i)
                            }
                          />
                        </div>
                        {record.offerType?.key === "BuyAnyGetAny" && (
                          <>
                            <div className="col-lg-2">
                              <input
                                type="number"
                                className="form-control input-default"
                                id="paidItemQty"
                                onChange={(e) =>
                                  handleOfferItemTextChange(e, i)
                                }
                                value={items.paidItemQty}
                              />
                            </div>
                            <div className="col-lg-4">
                              <Select
                                options={itemKeyValuePairList}
                                value={items.freeItemUuid}
                                onChange={(e) =>
                                  handleOfferItemSelectChange(
                                    e,
                                    "freeItemUuid",
                                    i
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-2">
                              <input
                                type="number"
                                className="form-control input-default"
                                id="freeItemQty"
                                onChange={(e) =>
                                  handleOfferItemTextChange(e, i)
                                }
                                value={items.freeItemQty}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-1 px-0">
                      <Button onClick={() => handleRemoveItemsRow(i)}>
                        <i className="bx bx-trash" />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            <Button onClick={handleAddMoreOfferItems}> + Add New Row</Button>
          </fieldset>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default HappyHourOfferAddEdit;
