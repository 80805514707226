// // // // // // Saurabh Server
export const HTTPS = process.env.REACT_APP_HTTPS;
export const HOST = process.env.REACT_APP_HOST;
export const PORT = process.env.REACT_APP_PORT;
export const SERVICE = process.env.REACT_APP_SERVICE;
export const CLIENT_ID = process.env.REACT_APP_PosImageClientId;
export const POS_FRONTEND_BASE_URL = process.env.REACT_APP_POS_FRONTEND_BASE_URL;
export const DOCUMENT_BASE_URL = process.env.REACT_APP_DocumentBaseUrl;

//Pos-Required-Details
export const POS_BACKEND_BASE_URL = HTTPS + "://" + HOST + ":" + PORT;
export const POS_IMAGE_CLIENT_ID = CLIENT_ID;

export const BASE_URL = `${HTTPS}://${HOST}:${PORT}` + (SERVICE !== "" ? "/" + SERVICE : "");
export const BASE_URL_V1 = BASE_URL + "/v1/";
export const BASE_URL_OPS_V1 = BASE_URL + "/ops/v1/";
export const BASE_URL_OPS_V1_SUPER_ADMIN = BASE_URL + "/ops/super/v1/";
export const BASE_URL_OPS_V1_ADMIN = BASE_URL + "/ops/admin/v1/";
export const BASE_URL_OPS_V1_CLIENT = BASE_URL + "/ops/client/v1/";
export const BASE_URL_OPS_V1_RESTAURANT = BASE_URL + "/ops/user-restaurant/v1/";
export const BASE_URL_OPS_CLIENT_V1 = BASE_URL + "/ops/client/v1/";
// Auth-controller
export const Admin_Login_POST = BASE_URL + "/auth/admin-login";
export const Client_Login_POST = BASE_URL + "/auth/client-login";
export const Restaurant_Login_POST = BASE_URL + "/auth/restaurant-login";
export const Admin_Change_Password_POST = BASE_URL + "/profile/changePassword";

// Common APIs
export const Upload_File_POST = `${DOCUMENT_BASE_URL}/api/attachment/${CLIENT_ID}/upload`;
export const Download_File_GET = `${DOCUMENT_BASE_URL}/api/attachment/${CLIENT_ID}/download/{attachemntId}`;
export const UploadFile_POST = BASE_URL + "/uploadFile/inBase64";

const recordID = "{recordID}";
const activateURL = "activate/" + recordID;
const inActivateURL = "inactivate/" + recordID;
const deleteURL = "delete/" + recordID;
const reviveURL = "revive/" + recordID;
const deletedListURL = "deleted-list";
const listURL = "list";
const updateUrl = "update/";

// Profile-controller
const profile_Url = BASE_URL_OPS_V1 + "profile/";
export const Change_Login_User_Password_Put = profile_Url + "change-password";
export const Get_Restaurant_Profile_Details_Get =
    profile_Url + "get-user-restaurant-profile-detail";

// super-admin-Currency
const currency_URL = BASE_URL_OPS_V1_SUPER_ADMIN + "currency/";
export const All_Currency_List_POST = currency_URL + "list-currency";
export const Add_Currency_POST = currency_URL + "create-currency";
export const Get_Currency_Details_GET =
    currency_URL + "get-currency/" + recordID;

// Super-admin-client
const client_URL = BASE_URL_OPS_V1_SUPER_ADMIN + "client/";
export const Update_Client_SecondaryDb_PUT =
    client_URL + "update-secondary-dB/" + recordID;
export const Update_Client_S3Details_PUT =
    client_URL + "update-s3-details/" + recordID;
export const Update_Client_ProductDetails_PUT =
    client_URL + "update-product-details/" + recordID;
export const Update_Client_PrimaryDb_PUT =
    client_URL + "update-primary-dB/" + recordID;
export const Update_Client_ContactDetails_PUT =
    client_URL + "update-contact-details/" + recordID;
export const Update_Client_BackupDb_PUT =
    client_URL + "update-backup-dB/" + recordID;
export const All_Client_List_POST = client_URL + listURL;
export const Get_Client_Detail_GET = client_URL + recordID;
export const Update_Client_PUT = client_URL + "update/" + recordID;
export const Activate_Client_PUT = client_URL + activateURL;
export const DeActivate_Client_PUT = client_URL + inActivateURL;
export const Delete_Client_DELETE = client_URL + deleteURL;
export const Revive_Client_PUT = client_URL + reviveURL;
export const Deleted_Client_List_POST = client_URL + deletedListURL;

// Super-admin-role
const role_URL = BASE_URL_OPS_V1_SUPER_ADMIN + "role/";
export const All_Role_List_POST = role_URL + listURL;
export const Get_Role_Detail_GET = role_URL + "get/" + recordID;
export const Update_Role_PUT = role_URL + "update/" + recordID;
export const Activate_Role_PUT = role_URL + activateURL;
export const DeActivate_Role_PUT = role_URL + inActivateURL;
export const Delete_Role_DELETE = role_URL + deleteURL;
export const Revive_Role_PUT = role_URL + reviveURL;
export const Deleted_Role_List_POST = role_URL + deletedListURL;
export const Add_Role_POST = role_URL + "save";
export const Assign_Role_URLs_PUT = role_URL + "assign-urls/" + recordID;
export const Assign_Modules_URLs_PUT = role_URL + "assign-modules/" + recordID;
export const Get_Role_ModuleList_POST =
    role_URL + "get-role-url-list/" + recordID;
export const Get_Role_KeyValue_Pair_POST = role_URL + "key-value-list";

// Module
const module_URL = BASE_URL_OPS_V1_SUPER_ADMIN + "module/";
export const All_Module_List_POST = module_URL + listURL;
export const Get_Module_Detail_GET = module_URL + "get/" + recordID;
export const Update_Module_PUT = module_URL + "update/" + recordID;
export const Activate_Module_PUT = module_URL + activateURL;
export const DeActivate_Module_PUT = module_URL + inActivateURL;
export const Delete_Module_DELETE = module_URL + deleteURL;
export const Revive_Module_PUT = module_URL + reviveURL;
export const Deleted_Module_List_POST = module_URL + deletedListURL;
export const Add_Module_POST = module_URL + "save";
export const All_Module_URL_List_POST = module_URL + "get-module-url-list";
export const Assign_Urls_Module_PUT = module_URL + "assign-urls/" + recordID;

// Super-admin-user
export const superAdminUser_URL = BASE_URL_OPS_V1_SUPER_ADMIN + "user/";
export const Assign_Urls_SuperAdmin_User_PUT =
    superAdminUser_URL + "assign-urls/" + recordID;
export const Assign_Roles_SuperAdmin_User_PUT =
    superAdminUser_URL + "assign-roles" + recordID;
export const Assign_Modules_SuperAdmin_User_PUT =
    superAdminUser_URL + "assign-modules/" + recordID;
export const Add_SuperAdmin_User_POST = superAdminUser_URL + "register";
export const All_SuperAdmin_User_POST = superAdminUser_URL + listURL;
export const Get_Assigned_Role_List_POST =
    superAdminUser_URL + "get-assigned-role-list";

// User Admin
const userAdmin_URL = BASE_URL_OPS_V1_ADMIN + "user-admin/";
export const All_UserAdmin_List_POST = userAdmin_URL + listURL;
export const Get_UserAdmin_Detail_GET = userAdmin_URL + "get/" + recordID;
export const Update_UserAdmin_PUT = userAdmin_URL + "update/" + recordID;
export const Activate_UserAdmin_PUT = userAdmin_URL + activateURL;
export const DeActivate_UserAdmin_PUT = userAdmin_URL + inActivateURL;
export const Delete_UserAdmin_DELETE = userAdmin_URL + deleteURL;
export const Revive_UserAdmin_PUT = userAdmin_URL + reviveURL;
export const Deleted_UserAdmin_List_POST = userAdmin_URL + deletedListURL;
export const Add_UserAdmin_POST = userAdmin_URL + "save";
export const AssignRoles_UserAdmin_PUT =
    userAdmin_URL + "assign-roles/" + recordID;
export const Assigned_RolesList_UserAdmin_POST =
    userAdmin_URL + "get-assigned-role-list/" + recordID;

// User Client
const userClient_URL = BASE_URL_OPS_V1_ADMIN + "user-client/";
export const All_UserClient_List_POST = userClient_URL + listURL;
export const Get_UserClient_Detail_GET = userClient_URL + "get/" + recordID;
export const Update_UserClient_PUT = userClient_URL + "update/" + recordID;
export const Activate_UserClient_PUT = userClient_URL + activateURL;
export const DeActivate_UserClient_PUT = userClient_URL + inActivateURL;
export const Delete_UserClient_DELETE = userClient_URL + deleteURL;
export const Revive_UserClient_PUT = userClient_URL + reviveURL;
export const Deleted_UserClient_List_POST = userClient_URL + deletedListURL;
export const Add_UserClient_POST = userClient_URL + "save";
export const AssignRoles_UserClient_PUT =
    userClient_URL + "assign-roles/" + recordID;
export const Assigned_RolesList_UserClient_POST =
    userClient_URL + "get-assigned-role-list/" + recordID;

// User Customer
const userCustomer_URL = BASE_URL_OPS_V1_ADMIN + "user-customer/";
export const Get_UserCustomer_Detail_GET = userCustomer_URL + "get/" + recordID;
export const All_UserCustomer_List_POST = userCustomer_URL + listURL;
export const Activate_UserCustomer_PUT = userCustomer_URL + activateURL;
export const DeActivate_UserCustomer_PUT = userCustomer_URL + inActivateURL;
export const Delete_UserCustomer_DELETE = userCustomer_URL + deleteURL;
export const Revive_UserCustomer_PUT = userCustomer_URL + reviveURL;
export const Deleted_UserCustomer_List_POST = userCustomer_URL + deletedListURL;
export const Change_UserCustomer_VIP_Status_PUT =
    userCustomer_URL + "change-vip-status/" + recordID;

//  admin-advertisement-controller
const advertisement_URL = BASE_URL_OPS_V1_CLIENT + "advertisement/";
export const Update_Advertisement_Put =
    advertisement_URL + updateUrl + recordID;
export const Revive_Advertisement_Put = advertisement_URL + reviveURL;
export const DeActivate_Advertisement_Put = advertisement_URL + inActivateURL;
export const Activate_Advertisement_Put = advertisement_URL + activateURL;
export const Save_Advertisement_Post = advertisement_URL + "save";
export const All_Advertisement_List_Post = advertisement_URL + listURL;
export const Deleted_Advertisement_List_POST =
    advertisement_URL + deletedListURL;
export const Details_Advertisement_List_Get =
    advertisement_URL + "get/" + recordID;
export const Delete_Advertisement_Delete = advertisement_URL + deleteURL;

// Local Country
const localCountry_URL = BASE_URL_OPS_V1_ADMIN + "local-country/";
export const All_LocalCountry_List_POST = localCountry_URL + listURL;
export const Get_LocalCountry_Detail_GET = localCountry_URL + "get/" + recordID;
export const Update_LocalCountry_PUT = localCountry_URL + "update/" + recordID;
export const Activate_LocalCountry_PUT = localCountry_URL + activateURL;
export const DeActivate_LocalCountry_PUT = localCountry_URL + inActivateURL;
export const Delete_LocalCountry_DELETE = localCountry_URL + deleteURL;
export const Revive_LocalCountry_PUT = localCountry_URL + reviveURL;
export const Deleted_LocalCountry_List_POST = localCountry_URL + deletedListURL;
export const CountryList_LocalCountry_POST = localCountry_URL + "country-list";
export const Add_LocalCountry_POST = localCountry_URL + "save";

// Local State
const localState_URL = BASE_URL_OPS_V1_ADMIN + "local-state/";
export const All_LocalState_List_POST = localState_URL + listURL;
export const Get_LocalState_Detail_GET = localState_URL + "get/" + recordID;
export const Update_LocalState_PUT = localState_URL + "update/" + recordID;
export const Activate_LocalState_PUT = localState_URL + activateURL;
export const DeActivate_LocalState_PUT = localState_URL + inActivateURL;
export const Delete_LocalState_DELETE = localState_URL + deleteURL;
export const Revive_LocalState_PUT = localState_URL + reviveURL;
export const Deleted_LocalState_List_POST = localState_URL + deletedListURL;
export const Add_LocalState_POST = localState_URL + "save";
export const StateList_LocalState_POST = localState_URL + "state-list";

// Local City
const localCity_URL = BASE_URL_OPS_V1_ADMIN + "local-city/";
export const All_LocalCity_List_POST = localCity_URL + listURL;
export const Get_LocalCity_Detail_GET = localCity_URL + "get/" + recordID;
export const Update_LocalCity_PUT = localCity_URL + "update/" + recordID;
export const Activate_LocalCity_PUT = localCity_URL + activateURL;
export const DeActivate_LocalCity_PUT = localCity_URL + inActivateURL;
export const Delete_LocalCity_DELETE = localCity_URL + deleteURL;
export const Revive_LocalCity_PUT = localCity_URL + reviveURL;
export const Deleted_LocalCity_List_POST = localCity_URL + deletedListURL;
export const Add_LocalCity_POST = localCity_URL + "save";
export const CityList_LocalCity_POST = localCity_URL + "city-list";

// Project Setting
const admin_Project_Setting__URL = BASE_URL_OPS_V1_ADMIN + "project-setting/";
export const Details_Project_Setting_Get =
    admin_Project_Setting__URL + recordID;
export const Update_Project_Setting_Put = admin_Project_Setting__URL + recordID;
export const All_Project_Setting_List_Post =
    admin_Project_Setting__URL + listURL;
export const All_List_Keys_Project_Setting_Post =
    admin_Project_Setting__URL + "list-keys";

// admin-website-cms-controller
const admin_WebSite_CMS_Url = BASE_URL_OPS_V1_ADMIN + "website-cms/";
export const Details_Admin_Website_CMS_Get = admin_WebSite_CMS_Url + recordID;
export const Update_Admin_Website_CMS_Put = admin_WebSite_CMS_Url + recordID;
export const All_List_Keys_Admin_Website_CMS_Post =
    admin_WebSite_CMS_Url + "list-keys";

// client-tax-controller
const client_Tax_Url = BASE_URL_OPS_V1_CLIENT + "tax/";
export const Update_Client_Tax_Put = client_Tax_Url + updateUrl + recordID;
export const Revive_Client_Tax_Put = client_Tax_Url + reviveURL;
export const DeActivate_Client_Tax_Put = client_Tax_Url + inActivateURL;
export const Activate_Client_Tax_Put = client_Tax_Url + activateURL;
export const Save_Client_Tax_Post = client_Tax_Url + "save";
export const All_Client_Tax_List_Post = client_Tax_Url + listURL;
export const Deleted_Client_Tax_List_POST = client_Tax_Url + deletedListURL;
export const Details_Client_Tax_List_Get = client_Tax_Url + "get/" + recordID;
export const Delete_Client_Tax_Delete = client_Tax_Url + deleteURL;
export const Get_Client_Tax_Key_Value_List_Post =
    client_Tax_Url + "key-value-list";

// client-cuisine-controller
const client_cuisine_Url = BASE_URL_OPS_V1_CLIENT + "cuisine/";
export const Update_Client_Cuisine_Put =
    client_cuisine_Url + updateUrl + recordID;
export const Revive_Client_Cuisine_Put = client_cuisine_Url + reviveURL;
export const DeActivate_Client_Cuisine_Put = client_cuisine_Url + inActivateURL;
export const Activate_Client_Cuisine_Put = client_cuisine_Url + activateURL;
export const Save_Client_Cuisine_Post = client_cuisine_Url + "save";
export const All_Client_Cuisine_List_Post = client_cuisine_Url + listURL;
export const Deleted_Client_Cuisine_List_POST =
    client_cuisine_Url + deletedListURL;
export const Details_Client_Cuisine_List_Get =
    client_cuisine_Url + "get/" + recordID;
export const Delete_Client_Cuisine_Delete = client_cuisine_Url + deleteURL;

// client-restaurant-controller
const client_Restaurant_URL = BASE_URL_OPS_V1_CLIENT + "restaurant/";
const client_restaurant_update = (type) => `update-restaurant-${type}/${recordID}`;
export const Update_Client_Restaurant_Put = client_Restaurant_URL + updateUrl + recordID;
export const Revive_Client_Restaurant_Put = client_Restaurant_URL + reviveURL;
export const DeActivate_Client_Restaurant_Put = client_Restaurant_URL + inActivateURL;
export const Activate_Client_Restaurant_Put = client_Restaurant_URL + activateURL;
export const Save_Client_Restaurant_Post = client_Restaurant_URL + "save";
export const All_Client_Restaurant_List_Post = client_Restaurant_URL + listURL;
export const Deleted_Client_Restaurant_List_POST = client_Restaurant_URL + deletedListURL;
export const Details_Client_Restaurant_List_Get = client_Restaurant_URL + "get/" + recordID;
export const Delete_Client_Restaurant_Delete = client_Restaurant_URL + deleteURL;
export const All_Client_Restaurant_Key_value_List_Post = client_Restaurant_URL + "restaurant-key-value-list";

export const Activate_Client_Restaurant_Delivery_Put = client_Restaurant_URL + "enable-delivery/" + recordID;
export const DeActivate_Client_Restaurant_Delivery_Put = client_Restaurant_URL + "disable-delivery/" + recordID;

export const Activate_Client_Restaurant_Takeaway_Put = client_Restaurant_URL + "enable-take-away/" + recordID;
export const DeActivate_Client_Restaurant_Takeaway_Put = client_Restaurant_URL + "disable-take-away/" + recordID;

export const Activate_Client_Restaurant_DineIn_Put = client_Restaurant_URL + "enable-dine-in/" + recordID;
export const DeActivate_Client_Restaurant_DineIn_Put = client_Restaurant_URL + "disable-dine-in/" + recordID;

export const Activate_Client_Restaurant_Steal_Deal_Put = client_Restaurant_URL + "enable-steal-deal/" + recordID;
export const DeActivate_Client_Restaurant_Steal_Deal_Put = client_Restaurant_URL + "disable-steal-deal/" + recordID;

export const Activate_Client_Restaurant_Schedule_Put = client_Restaurant_URL + "enable-schedule/" + recordID;
export const DeActivate_Client_Restaurant_Schedule_Put = client_Restaurant_URL + "disable-schedule/" + recordID;

export const Update_Client_Restaurant_Address = client_Restaurant_URL + client_restaurant_update("address");
export const Update_Client_Restaurant_Tax_Number = client_Restaurant_URL + client_restaurant_update("tax-number");
export const Update_Client_Restaurant_Configuration = client_Restaurant_URL + client_restaurant_update("configuration");
export const Update_Client_Restaurant_Images = client_Restaurant_URL + client_restaurant_update("images");
export const Update_Client_Restaurant_Timing = client_Restaurant_URL + client_restaurant_update("timing");

export const OnOff_Client_Restaurant = client_Restaurant_URL + "restaurant-on-off/" + recordID;
export const OnOff_Client_Restaurant_Club_Mode = client_Restaurant_URL + "club-mode-on-off/" + recordID;


// Client Brand
const brand_URL = BASE_URL_OPS_V1_CLIENT + "brand/";
export const All_Brand_List_POST = brand_URL + listURL;
export const Get_Brand_Detail_GET = brand_URL + "get/" + recordID;
export const Update_Brand_PUT = brand_URL + "update/" + recordID;
export const Activate_Brand_PUT = brand_URL + activateURL;
export const DeActivate_Brand_PUT = brand_URL + inActivateURL;
export const Delete_Brand_DELETE = brand_URL + deleteURL;
export const Revive_Brand_PUT = brand_URL + reviveURL;
export const Deleted_Brand_List_POST = brand_URL + deletedListURL;
export const Add_Brand_POST = brand_URL + "save";
export const Post_Brand_Key_Value_Pairs_List = brand_URL + "key-value-list";

// Complementary Head
const complementaryHead_URL = BASE_URL_OPS_V1_CLIENT + "complementary-head/";
export const All_ComplementaryHead_List_POST = complementaryHead_URL + listURL;
export const Get_ComplementaryHead_Detail_GET =
    complementaryHead_URL + "get/" + recordID;
export const Update_ComplementaryHead_PUT =
    complementaryHead_URL + "update/" + recordID;
export const Activate_ComplementaryHead_PUT =
    complementaryHead_URL + activateURL;
export const DeActivate_ComplementaryHead_PUT =
    complementaryHead_URL + inActivateURL;
export const Delete_ComplementaryHead_DELETE =
    complementaryHead_URL + deleteURL;
export const Revive_ComplementaryHead_PUT = complementaryHead_URL + reviveURL;
export const Deleted_ComplementaryHead_List_POST =
    complementaryHead_URL + deletedListURL;
export const Add_ComplementaryHead_POST = complementaryHead_URL + "save";

// Client Setting
const client_Setting_URL = BASE_URL_OPS_V1_CLIENT + "client-setting/";
export const Details_Client_Setting_Get = client_Setting_URL + recordID;
export const Update_CLient_Setting_Put = client_Setting_URL + recordID;
export const All_Client_Setting_List_Post = client_Setting_URL + listURL;
export const All_List_Keys_Client_Setting_Post =
    client_Setting_URL + "list-keys";

// Timing-group
const timingGroup_URL = BASE_URL_OPS_V1_CLIENT + "timing-group/";
export const All_TimingGroup_List_POST = timingGroup_URL + listURL;
export const Get_TimingGroup_Detail_GET = timingGroup_URL + "get/" + recordID;
export const Update_TimingGroup_PUT = timingGroup_URL + "update/" + recordID;
export const Activate_TimingGroup_PUT = timingGroup_URL + activateURL;
export const DeActivate_TimingGroup_PUT = timingGroup_URL + inActivateURL;
export const Delete_TimingGroup_DELETE = timingGroup_URL + deleteURL;
export const Revive_TimingGroup_PUT = timingGroup_URL + reviveURL;
export const Deleted_TimingGroup_List_POST = timingGroup_URL + deletedListURL;
export const Add_TimingGroup_POST = timingGroup_URL + "save";
export const Get_Timing_Group_Key_Value_Pair_List_Post =
    timingGroup_URL + "key-pair-list";

// client-menu-category-controller
const client_category_Url = BASE_URL_OPS_V1_CLIENT + "menu-category/";
export const Update_Client_Category_Put =
    client_category_Url + updateUrl + recordID;
export const Revive_Client_Category_Put = client_category_Url + reviveURL;
export const Remove_Image_Client_Category_Put =
    client_category_Url + "remove-image/" + recordID;
export const Add_Image_Client_Category_Put =
    client_category_Url + "add-image/" + recordID;
export const DeActivate_Client_Category_Put =
    client_category_Url + inActivateURL;
export const Activate_Client_Category_Put = client_category_Url + activateURL;
export const Save_Client_Category_Post = client_category_Url + "save";
export const All_Client_Category_List_Post = client_category_Url + listURL;
export const Deleted_Client_Category_List_POST =
    client_category_Url + deletedListURL;
export const Details_Client_Category_List_Get =
    client_category_Url + "get/" + recordID;
export const Delete_Client_Category_Delete = client_category_Url + deleteURL;
export const GET_Client_Steal_Deal_Category_Key_Value_List_Post =
    client_category_Url + "steal-deal-category-key-value-list";
export const Get_Client_Category_Key_Value_List_Post =
    client_category_Url + "non-steal-deal-category-key-value-list";
export const Get_Client_SubCategory_Key_Value_List_Post =
    client_category_Url + "sub-category-key-value-list/{categoryID}";

// client-menu-item-controller
const client_Menu_Item_Url = BASE_URL_OPS_V1_CLIENT + "menu-item/";
export const Update_Client_Menu_Item_Put =
    client_Menu_Item_Url + updateUrl + recordID;
export const Revive_Client_Menu_Item_Put = client_Menu_Item_Url + reviveURL;
export const DeActivate_Client_Menu_Item_Put =
    client_Menu_Item_Url + inActivateURL;
export const Activate_Client_Menu_Item_Put = client_Menu_Item_Url + activateURL;
export const Save_Client_Menu_Item_Post = client_Menu_Item_Url + "save";
export const All_Client_Menu_Item_List_Post = client_Menu_Item_Url + listURL;
export const Deleted_Client_Menu_Item_List_POST =
    client_Menu_Item_Url + deletedListURL;
export const Details_Client_Menu_Item_List_Get =
    client_Menu_Item_Url + "get/" + recordID;
export const Delete_Client_Menu_Item_Delete = client_Menu_Item_Url + deleteURL;
export const Get_Client_Item_Key_Value_List_Post =
    client_Menu_Item_Url + "key-value-list";
export const Get_Client_Brand_Item_Key_Value_List_Post =
    client_Menu_Item_Url + "key-value-list";

// client-item-customization-controller
const client_item_customization_Url =
    BASE_URL_OPS_V1_CLIENT + "menu-item-customization/";
export const Update_Client_Item_Customization_Put =
    client_item_customization_Url + updateUrl + recordID;
export const Revive_Client_Item_Customization_Put =
    client_item_customization_Url + reviveURL;
export const DeActivate_Client_Item_Customization_Put =
    client_item_customization_Url + inActivateURL;
export const Activate_Client_Item_Customization_Put =
    client_item_customization_Url + activateURL;
export const Save_Client_Item_Customization_Post =
    client_item_customization_Url + "save";
export const All_Client_Item_Customization_List_Post =
    client_item_customization_Url + listURL;
export const Deleted_Client_Item_Customization_List_POST =
    client_item_customization_Url + deletedListURL;
export const Details_Client_Item_Customization_List_Get =
    client_item_customization_Url + "get/" + recordID;
export const Delete_Client_Item_Customization_Delete =
    client_item_customization_Url + deleteURL;
export const Get_Client_Item_Customization_Key_Value_List_Post =
    client_item_customization_Url + "key-value-list";

// client-condiment-controller
const client_Condiment_Url = BASE_URL_OPS_V1_CLIENT + "condiment/";
export const Update_Client_Condiment_Put =
    client_Condiment_Url + updateUrl + recordID;
export const Revive_Client_Condiment_Put = client_Condiment_Url + reviveURL;
export const DeActivate_Client_Condiment_Put =
    client_Condiment_Url + inActivateURL;
export const Activate_Client_Condiment_Put = client_Condiment_Url + activateURL;
export const Save_Client_Condiment_Post = client_Condiment_Url + "save";
export const All_Client_Condiment_List_Post = client_Condiment_Url + listURL;
export const Deleted_Client_Condiment_List_POST =
    client_Condiment_Url + deletedListURL;
export const Details_Client_Condiment_List_Get =
    client_Condiment_Url + "get/" + recordID;
export const Delete_Client_Condiment_Delete = client_Condiment_Url + deleteURL;
export const Get_Client_Condiment_Key_Value_List_Post =
    client_Condiment_Url + "key-value-list";

// client-menu-sync-copy-controller
const client_menu_item_copySync_Url = BASE_URL_OPS_V1_CLIENT + "menu-item/";
export const Sync_Menu_Item__Post =
    client_menu_item_copySync_Url + "sync-item/" + recordID;
export const Get_Menu_Item_List_For_Sync_Post =
    client_menu_item_copySync_Url + "get-item-list-for-sync/" + recordID;
export const Get_Menu_Item_List_For_Copy_Post =
    client_menu_item_copySync_Url + "get-item-list-for-copy/" + recordID;
export const Force_Sync_Menu_Item_Post =
    client_menu_item_copySync_Url + "force-sync-item/" + recordID;
export const Force_Copy_Menu_Item_Post =
    client_menu_item_copySync_Url + "force-copy-item/" + recordID;
export const Copy_Menu_Item_Post =
    client_menu_item_copySync_Url + "copy-item/" + recordID;

// client-event-controller
const client_Event_Url = BASE_URL_OPS_V1_CLIENT + "event/";
export const Update_Client_Event_Put = client_Event_Url + updateUrl + recordID;
export const Switch_To_Paid_UnPaid_Client_Event_Put =
    client_Event_Url + "switch-to-paid-unpaid/" + recordID;
export const Revive_Client_Event_Put = client_Event_Url + reviveURL;
export const DeActivate_Client_Event_Put = client_Event_Url + inActivateURL;
export const Activate_Client_Event_Put = client_Event_Url + activateURL;
export const Save_Client_Event_Post = client_Event_Url + "save";
export const All_Client_Event_List_Post = client_Event_Url + listURL;
export const Deleted_Client_Event_List_Post = client_Event_Url + deletedListURL;
export const Details_Client_Event_Get = client_Event_Url + "get/" + recordID;
export const Delete_Client_Event_Delete = client_Event_Url + deleteURL;

// client-event-ticket-controller
const client_Event_Ticket_Url =
    BASE_URL_OPS_V1_CLIENT + "event-ticket/{eventID}/";
export const Update_Client_Event_Ticket_Put =
    client_Event_Ticket_Url + updateUrl + recordID;
export const Revive_Client_Event_Ticket_Put =
    client_Event_Ticket_Url + reviveURL;
export const DeActivate_Client_Event_Ticket_Put =
    client_Event_Ticket_Url + inActivateURL;
export const Activate_Client_Event_Ticket_Put =
    client_Event_Ticket_Url + activateURL;
export const Save_Client_Event_Ticket_Post = client_Event_Ticket_Url + "save";
export const All_Client_Event_Ticket_List_Post =
    client_Event_Ticket_Url + listURL;
export const Deleted_Client_Event_Ticket_List_Post =
    client_Event_Ticket_Url + deletedListURL;
export const Details_Client_Event_Ticket_Get =
    client_Event_Ticket_Url + "get/" + recordID;
export const Delete_Client_Event_Ticket_Delete =
    client_Event_Ticket_Url + deleteURL;

// client-voucher-controller
const client_Voucher_Url = BASE_URL_OPS_V1_CLIENT + "voucher/";
export const Send_Message_Client_Voucher_Put =
    client_Voucher_Url + "send-message";
export const Revive_Client_Voucher_Put = client_Voucher_Url + reviveURL;
export const DeActivate_Client_Voucher_Put = client_Voucher_Url + inActivateURL;
export const Activate_Client_Voucher_Put = client_Voucher_Url + activateURL;
export const Save_Client_Voucher_Post = client_Voucher_Url + "save";
export const All_Client_Voucher_List_Post = client_Voucher_Url + listURL;
export const Consumed_Voucher_Client_Voucher_Post =
    client_Voucher_Url + "consumed-voucher/" + recordID;
export const Delete_Client_Voucher_Delete = client_Voucher_Url + deleteURL;

// client-sticker-controller
const client_Sticker_Url = BASE_URL_OPS_V1_CLIENT + "sticker/";
export const Update_Client_Sticker_Put =
    client_Sticker_Url + updateUrl + recordID;
export const Revive_Client_Sticker_Put = client_Sticker_Url + reviveURL;
export const DeActivate_Client_Sticker_Put = client_Sticker_Url + inActivateURL;
export const Activate_Client_Sticker_Put = client_Sticker_Url + activateURL;
export const Save_Client_Sticker_Post = client_Sticker_Url + "save";
export const All_Client_Sticker_List_Post = client_Sticker_Url + listURL;
export const Deleted_Client_Sticker_List_Post =
    client_Sticker_Url + deletedListURL;
export const Details_Client_Sticker_Get =
    client_Sticker_Url + "get/" + recordID;
export const Delete_Client_Sticker_Delete = client_Sticker_Url + deleteURL;
export const GET_Client_Sticker_Key_Value_List_Post =
    client_Sticker_Url + "key-value-list";

// Client-qr-code-cash-back-controller
const client_QrCode_CashBack_Url =
    BASE_URL_OPS_V1_CLIENT + "q-r-code-cash-back/";
export const Update_Client_QRCode_CashBack_Put =
    client_QrCode_CashBack_Url + updateUrl + recordID;
export const Revive_Client_QRCode_CashBack_Put =
    client_QrCode_CashBack_Url + reviveURL;
export const DeActivate_Client_QRCode_CashBack_Put =
    client_QrCode_CashBack_Url + inActivateURL;
export const Activate_Client_QRCode_CashBack_Put =
    client_QrCode_CashBack_Url + activateURL;
export const Save_Client_QRCode_CashBack_Post =
    client_QrCode_CashBack_Url + "save";
export const All_Client_QRCode_CashBack_List_Post =
    client_QrCode_CashBack_Url + listURL;
export const Deleted_Client_QRCode_CashBack_List_Post =
    client_QrCode_CashBack_Url + deletedListURL;
export const Details_Client_QRCode_CashBack_Get =
    client_QrCode_CashBack_Url + "get/" + recordID;
export const Delete_Client_QRCode_CashBack_Delete =
    client_QrCode_CashBack_Url + deleteURL;

// Client-coupon-code-controller
const client_Coupon_Code_Url = BASE_URL_OPS_V1_CLIENT + "coupon-code/";
export const Update_Client_Coupon_Code_Put =
    client_Coupon_Code_Url + updateUrl + recordID;
export const Revive_Client_Coupon_Code_Put = client_Coupon_Code_Url + reviveURL;
export const DeActivate_Client_Coupon_Code_Put =
    client_Coupon_Code_Url + inActivateURL;
export const Activate_Client_Coupon_Code_Put =
    client_Coupon_Code_Url + activateURL;
export const Save_Client_Coupon_Code_Post = client_Coupon_Code_Url + "save";
export const All_Client_Coupon_Code_List_Post =
    client_Coupon_Code_Url + listURL;
export const Deleted_Client_Coupon_Code_List_Post =
    client_Coupon_Code_Url + deletedListURL;
export const Details_Client_Coupon_Code_Get =
    client_Coupon_Code_Url + "get/" + recordID;
export const Delete_Client_Coupon_Code_Delete =
    client_Coupon_Code_Url + deleteURL;
export const Get_Menu_Item_Key_Value_List_Post =
    client_Coupon_Code_Url + "key-value-list-for-coupon";

// Client-dine-in-cash-back-controller
const client_DineIn_Cash_Back_Url =
    BASE_URL_OPS_V1_CLIENT + "dine-in-cash-back/";
export const Update_Client_DineIn_Cash_Back_Put =
    client_DineIn_Cash_Back_Url + updateUrl + recordID;
export const DeActivate_Client_DineIn_Cash_Back_Put =
    client_DineIn_Cash_Back_Url + inActivateURL;
export const Activate_Client_DineIn_Cash_Back_Put =
    client_DineIn_Cash_Back_Url + activateURL;
export const All_Client_DineIn_Cash_Back_List_Post =
    client_DineIn_Cash_Back_Url + listURL;
export const Details_Client_DineIn_Cash_Back_Get =
    client_DineIn_Cash_Back_Url + "get/" + recordID;
export const Details_Client_DineIn_Cash_Back_ByBrandId_Get =
    client_DineIn_Cash_Back_Url + "get-by-brand/" + recordID;

// client-website-cms-controller
const client_WebSite_CMS_Url = BASE_URL_OPS_V1_CLIENT + "website-cms/";
export const Details_Client_Website_CMS_Get = client_WebSite_CMS_Url + recordID;
export const Update_Client_Website_CMS_Put = client_WebSite_CMS_Url + recordID;
export const All_List_Keys_Client_Website_CMS_Post =
    client_WebSite_CMS_Url + "list-keys";

// client-notification
const client_Notification_Url = BASE_URL_OPS_V1_CLIENT + "notification/";
export const Send_Notification_Client_Post =
    client_Notification_Url + "send-notification";

// client-feedback-controller
const client_Feedback_Url = BASE_URL_OPS_V1_CLIENT + "feedback/";
export const Show_Client_Feedback_Put =
    client_Feedback_Url + "show/" + recordID;
export const Hide_Client_Feedback_Put =
    client_Feedback_Url + "hide/" + recordID;
export const All_Client_Feedback_List_Post = client_Feedback_Url + listURL;

// one-time-password-controller (OTP)
const client_OTP_Url = BASE_URL_OPS_V1_CLIENT + "one-time-password/";
export const All_Client_OTP_List_Post = client_OTP_Url + listURL;

// client-employee-movement
const client_Movement_Employee_Url =
    BASE_URL_OPS_V1_CLIENT + "restaurant-employee/";
export const Move_Client_Restaurant_Employee_Movement_Put =
    client_Movement_Employee_Url + "employee-movement/" + recordID;
export const Revive_Client_Employee_Movement_Put =
    client_Movement_Employee_Url + reviveURL;
export const DeActivate_Client_Employee_Movement_Put =
    client_Movement_Employee_Url + inActivateURL;
export const Activate_Client_Employee_Movement_Put =
    client_Movement_Employee_Url + activateURL;
export const All_Movement_Employee_List_Post =
    client_Movement_Employee_Url + listURL;
export const Move_Movement_Employee_Put =
    client_Movement_Employee_Url + "mover-employee/" + recordID;
export const All_Client_Restaurant_Employee_By_Brand_Post =
    client_Movement_Employee_Url + "brand-employee-list";

// restaurant-restaurant-voucher-controller
const Restaurant_Voucher_Url = BASE_URL_OPS_V1_RESTAURANT + "voucher/";
export const Send_Message_Restaurant_Voucher_Put =
    Restaurant_Voucher_Url + "send-message";
export const All_Restaurant_Voucher_List_Post =
    Restaurant_Voucher_Url + listURL;
export const Consumed_Voucher_Restaurant_Voucher_Post =
    Restaurant_Voucher_Url + "consumed-voucher/" + recordID;
export const Save_Voucher_Restaurant_Post = Restaurant_Voucher_Url + "save";

// restaurant-condiment-controller
const Restaurant_Condiment_Url = BASE_URL_OPS_V1_RESTAURANT + "condiment/";
export const Update_Restaurant_Condiment_Put =
    Restaurant_Condiment_Url + updateUrl + recordID;
export const Revive_Restaurant_Condiment_Put =
    Restaurant_Condiment_Url + reviveURL;
export const DeActivate_Restaurant_Condiment_Put =
    Restaurant_Condiment_Url + inActivateURL;
export const Activate_Restaurant_Condiment_Put =
    Restaurant_Condiment_Url + activateURL;
export const Save_Restaurant_Condiment_Post = Restaurant_Condiment_Url + "save";
export const All_Restaurant_Condiment_List_Post =
    Restaurant_Condiment_Url + listURL;
export const Deleted_Restaurant_Condiment_List_POST =
    Restaurant_Condiment_Url + deletedListURL;
export const Details_Restaurant_Condiment_List_Get =
    Restaurant_Condiment_Url + "get/" + recordID;
export const Delete_Restaurant_Condiment_Delete =
    Restaurant_Condiment_Url + deleteURL;
export const Get_Restaurant_Condiment_Key_Value_List_Post =
    Restaurant_Condiment_Url + "key-value-list";

// restaurant-table-number
const Restaurant_Table_Url = BASE_URL_OPS_V1_RESTAURANT + "table-number/";
export const Update_Restaurant_Table_Put =
    Restaurant_Table_Url + updateUrl + recordID;
export const Revive_Restaurant_Table_Put = Restaurant_Table_Url + reviveURL;
export const DeActivate_Restaurant_Table_Put =
    Restaurant_Table_Url + inActivateURL;
export const Activate_Restaurant_Table_Put = Restaurant_Table_Url + activateURL;
export const Save_Restaurant_Table_Post = Restaurant_Table_Url + "save";
export const All_Restaurant_Table_List_Post = Restaurant_Table_Url + listURL;
export const Deleted_Restaurant_Table_List_POST =
    Restaurant_Table_Url + deletedListURL;
export const Details_Restaurant_Table_List_Get =
    Restaurant_Table_Url + "get/" + recordID;
export const Delete_Restaurant_Table_Delete = Restaurant_Table_Url + deleteURL;
export const Get_Restaurant_Table_Key_Value_List_Post =
    Restaurant_Table_Url + "key-value-list";
export const Get_UnMerge_Restaurant_Table_List_Get =
    Restaurant_Table_Url + "merge-table-list";
export const Merge_Restaurant_Table_List_PUT =
    Restaurant_Table_Url + "merge-table/" + recordID;
export const UnMerge_Restaurant_Table_List_PUT =
    Restaurant_Table_Url + "un-merge-table/" + recordID;

// Restaurant-Timing-group
const Restaurant_TimingGroup_URL = BASE_URL_OPS_V1_RESTAURANT + "timing-group/";
export const All_Restaurant_TimingGroup_List_POST =
    Restaurant_TimingGroup_URL + listURL;
export const Get_Restaurant_TimingGroup_Detail_GET =
    Restaurant_TimingGroup_URL + "get/" + recordID;
export const Update_Restaurant_TimingGroup_PUT =
    Restaurant_TimingGroup_URL + "update/" + recordID;
export const Activate_Restaurant_TimingGroup_PUT =
    Restaurant_TimingGroup_URL + activateURL;
export const DeActivate_Restaurant_TimingGroup_PUT =
    Restaurant_TimingGroup_URL + inActivateURL;
export const Delete_Restaurant_TimingGroup_DELETE =
    Restaurant_TimingGroup_URL + deleteURL;
export const Revive_Restaurant_TimingGroup_PUT =
    Restaurant_TimingGroup_URL + reviveURL;
export const Deleted_Restaurant_TimingGroup_List_POST =
    Restaurant_TimingGroup_URL + deletedListURL;
export const Add_Restaurant_TimingGroup_POST =
    Restaurant_TimingGroup_URL + "save";
export const Get_Restaurant_Timing_Group_Key_Value_Pair_List_Post =
    Restaurant_TimingGroup_URL + "key-pair-list";

// restaurant-tax-controller
const Restaurant_Tax_Url = BASE_URL_OPS_V1_RESTAURANT + "tax/";
export const Update_Restaurant_Tax_Put =
    Restaurant_Tax_Url + updateUrl + recordID;
export const Revive_Restaurant_Tax_Put = Restaurant_Tax_Url + reviveURL;
export const DeActivate_Restaurant_Tax_Put = Restaurant_Tax_Url + inActivateURL;
export const Activate_Restaurant_Tax_Put = Restaurant_Tax_Url + activateURL;
export const Save_Restaurant_Tax_Post = Restaurant_Tax_Url + "save";
export const All_Restaurant_Tax_List_Post = Restaurant_Tax_Url + listURL;
export const Deleted_Restaurant_Tax_List_POST =
    Restaurant_Tax_Url + deletedListURL;
export const Details_Restaurant_Tax_List_Get =
    Restaurant_Tax_Url + "get/" + recordID;
export const Delete_Restaurant_Tax_Delete = Restaurant_Tax_Url + deleteURL;
export const Get_Restaurant_Tax_Key_Value_List_Post =
    Restaurant_Tax_Url + "key-value-list";

// restaurant-printer
const Restaurant_Printer_Url = BASE_URL_OPS_V1_RESTAURANT + "printer/";
export const Update_Restaurant_Printer_Put =
    Restaurant_Printer_Url + updateUrl + recordID;
export const Revive_Restaurant_Printer_Put = Restaurant_Printer_Url + reviveURL;
export const DeActivate_Restaurant_Printer_Put =
    Restaurant_Printer_Url + inActivateURL;
export const Activate_Restaurant_Printer_Put =
    Restaurant_Printer_Url + activateURL;
export const Save_Restaurant_Printer_Post = Restaurant_Printer_Url + "save";
export const All_Restaurant_Printer_List_Post =
    Restaurant_Printer_Url + "printer-" + listURL;
export const Deleted_Restaurant_Printer_List_POST =
    Restaurant_Printer_Url + deletedListURL;
export const Details_Restaurant_Printer_List_Get =
    Restaurant_Printer_Url + "get/" + recordID;
export const Delete_Restaurant_Printer_Delete =
    Restaurant_Printer_Url + deleteURL;
export const Get_Restaurant_Printer_Key_Value_List_Post =
    Restaurant_Printer_Url + "key-value-list";
export const Mark_Restaurant_Default_Printer_PUT =
    Restaurant_Printer_Url + "mark-default-printer/" + recordID;

// Restaurant-menu-category-controller
const Restaurant_category_Url = BASE_URL_OPS_V1_RESTAURANT + "menu-category/";
export const Update_Restaurant_Category_Put =
    Restaurant_category_Url + updateUrl + recordID;
export const Update_Rest_Menu_Restaurant_Category_Put =
    Restaurant_category_Url + "update-rest-category/" + recordID;
export const Revive_Restaurant_Category_Put =
    Restaurant_category_Url + reviveURL;
export const Revive_Rest_Menu_Restaurant_Category_Put =
    Restaurant_category_Url + "revive-rest-category/" + recordID;
export const DeActivate_Restaurant_Category_Put =
    Restaurant_category_Url + inActivateURL;
export const DeActivate_Rest_Menu_Restaurant_Category_Put =
    Restaurant_category_Url + "inactivate-rest-category/" + recordID;
export const Activate_Restaurant_Category_Put =
    Restaurant_category_Url + activateURL;
export const Activate_Rest_Menu_Restaurant_Category_Put =
    Restaurant_category_Url + "activate-rest-category/" + recordID;
export const Save_Restaurant_Category_Post = Restaurant_category_Url + "save";
export const All_Restaurant_Category_List_Post =
    Restaurant_category_Url + listURL;
export const Deleted_Restaurant_Category_List_POST =
    Restaurant_category_Url + deletedListURL;
export const Details_Restaurant_Category_List_Get =
    Restaurant_category_Url + "get/" + recordID;
export const Details_Rest_Menu_Restaurant_Category_List_Get =
    Restaurant_category_Url + "get-rest-category/" + recordID;
export const Delete_Restaurant_Category_Delete =
    Restaurant_category_Url + deleteURL;
export const Delete_Rest_Menu_Restaurant_Category_Delete =
    Restaurant_category_Url + "delete-rest-category/" + recordID;
export const Get_Restaurant_Non_Steal_Deal_Category_Key_Value_List_Post =
    Restaurant_category_Url + "non-steal-deal-category-key-value-list";
export const GET_Restaurant_Steal_Deal_Category_Key_Value_List_Post =
    Restaurant_category_Url + "steal-deal-category-key-value-list";
export const Get_Restaurant_SubCategory_Key_Value_List_Post =
    Restaurant_category_Url + "sub-category-key-value-list/{categoryID}";

// Restaurant-item-customization-controller
const Restaurant_item_customization_Url =
    BASE_URL_OPS_V1_RESTAURANT + "menu-item-customization/";
export const Update_Restaurant_Item_Customization_Put =
    Restaurant_item_customization_Url + updateUrl + recordID;
export const Update_Rest_Menu_Restaurant_Item_Customization_Put =
    Restaurant_item_customization_Url +
    "update-rest-item-customization/" +
    recordID;
export const Revive_Restaurant_Item_Customization_Put =
    Restaurant_item_customization_Url + reviveURL;
export const Revive_Rest_Menu_Restaurant_Item_Customization_Put =
    Restaurant_item_customization_Url +
    "revive-rest-item-customization/" +
    recordID;
export const DeActivate_Restaurant_Item_Customization_Put =
    Restaurant_item_customization_Url + inActivateURL;
export const DeActivate_Rest_Menu_Restaurant_Item_Customization_Put =
    Restaurant_item_customization_Url +
    "inactivate-rest-item-customization/" +
    recordID;
export const Activate_Restaurant_Item_Customization_Put =
    Restaurant_item_customization_Url + activateURL;
export const Activate_Rest_Menu_Restaurant_Item_Customization_Put =
    Restaurant_item_customization_Url +
    "activate-rest-item-customization/" +
    recordID;
export const Save_Restaurant_Item_Customization_Post =
    Restaurant_item_customization_Url + "save";
export const All_Restaurant_Item_Customization_List_Post =
    Restaurant_item_customization_Url + "rest-" + listURL;
export const Deleted_Restaurant_Item_Customization_List_POST =
    Restaurant_item_customization_Url + "rest-" + deletedListURL;
export const Details_Restaurant_Item_Customization_List_Get =
    Restaurant_item_customization_Url + "get/" + recordID;
export const Details_Rest_Menu_Restaurant_Item_Customization_List_Get =
    Restaurant_item_customization_Url + "get-rest-item-customization/" + recordID;
export const Delete_Restaurant_Item_Customization_Delete =
    Restaurant_item_customization_Url + deleteURL;
export const Delete_Rest_Menu_Restaurant_Item_Customization_Delete =
    Restaurant_item_customization_Url +
    "delete-rest-item-customization/" +
    recordID;
export const Get_Restaurant_Item_Customization_Key_Value_List_Post =
    Restaurant_item_customization_Url + "key-value-list";

// restaurant-menu-item-controller
const Restaurant_Menu_Item_Url = BASE_URL_OPS_V1_RESTAURANT + "menu-item/";
export const Update_Restaurant_Menu_Item_Put =
    Restaurant_Menu_Item_Url + updateUrl + recordID;
export const Update_Rest_Menu_Restaurant_Menu_Item_Put =
    Restaurant_Menu_Item_Url + "update-rest-item/" + recordID;
export const Revive_Restaurant_Menu_Item_Put =
    Restaurant_Menu_Item_Url + reviveURL;
export const Revive_Rest_Menu_Restaurant_Menu_Item_Put =
    Restaurant_Menu_Item_Url + "revive-rest-item/" + recordID;
export const DeActivate_Restaurant_Menu_Item_Put =
    Restaurant_Menu_Item_Url + inActivateURL;
export const DeActivate_Rest_Menu_Restaurant_Menu_Item_Put =
    Restaurant_Menu_Item_Url + "inactivate-rest-item/" + recordID;
export const Activate_Restaurant_Menu_Item_Put =
    Restaurant_Menu_Item_Url + activateURL;
export const Activate_Rest_Menu_Restaurant_Menu_Item_Put =
    Restaurant_Menu_Item_Url + "activate-rest-item/" + recordID;
export const Save_Restaurant_Menu_Item_Post = Restaurant_Menu_Item_Url + "save";
export const All_Restaurant_Menu_Item_List_Post =
    Restaurant_Menu_Item_Url + listURL;
export const Deleted_Restaurant_Menu_Item_List_POST =
    Restaurant_Menu_Item_Url + deletedListURL;
export const Details_Restaurant_Menu_Item_List_Get =
    Restaurant_Menu_Item_Url + "get/" + recordID;
export const Details_Rest_Menu_Restaurant_Menu_Item_List_Get =
    Restaurant_Menu_Item_Url + "get-rest-item/" + recordID;
export const Delete_Restaurant_Menu_Item_Delete =
    Restaurant_Menu_Item_Url + deleteURL;
export const Delete_Rest_Menu_Restaurant_Menu_Item_Delete =
    Restaurant_Menu_Item_Url + "delete-rest-item/" + recordID;
export const All_Steal_Deal_Restaurant_Menu_Item_List_Post =
    Restaurant_Menu_Item_Url + "steal-deal-item-list";
export const Get_Delivery_Offer_Item_Key_Value_List_Post =
    Restaurant_Menu_Item_Url + "get-item-key-list-for-delivery-offer";
export const Get_Happy_Hour_Offer_Item_Key_Value_List_Post =
    Restaurant_Menu_Item_Url + "get-item-key-list-for-happy-hours-offer";

// Restaurant-Complementary-Head
const Restaurant_ComplementaryHead_URL =
    BASE_URL_OPS_V1_RESTAURANT + "complementary-head/";
export const All_Restaurant_Complementary_Head_List_POST =
    Restaurant_ComplementaryHead_URL + listURL;
export const Get_Restaurant_Complementary_Head_Detail_GET =
    Restaurant_ComplementaryHead_URL + "get/" + recordID;
export const Update_Restaurant_Complementary_Head_PUT =
    Restaurant_ComplementaryHead_URL + "update/" + recordID;
export const Activate_Restaurant_Complementary_Head_PUT =
    Restaurant_ComplementaryHead_URL + activateURL;
export const DeActivate_Restaurant_Complementary_Head_PUT =
    Restaurant_ComplementaryHead_URL + inActivateURL;
export const Delete_Restaurant_Complementary_Head_DELETE =
    Restaurant_ComplementaryHead_URL + deleteURL;
export const Revive_Restaurant_Complementary_Head_PUT =
    Restaurant_ComplementaryHead_URL + reviveURL;
export const Deleted_Restaurant_Complementary_Head_List_POST =
    Restaurant_ComplementaryHead_URL + deletedListURL;
export const Add_Restaurant_Complementary_Head_POST =
    Restaurant_ComplementaryHead_URL + "save";

// restaurant-staff-employee-controller
const Restaurant_Staff_Employee_URL =
    BASE_URL_OPS_V1_RESTAURANT + "restaurant-employee/";
export const All_Restaurant_Staff_Employee_List_POST =
    Restaurant_Staff_Employee_URL + listURL;
export const Get_Restaurant_Staff_Employee_Detail_GET =
    Restaurant_Staff_Employee_URL + "get/" + recordID;
export const Update_Restaurant_Staff_Employee_PUT =
    Restaurant_Staff_Employee_URL + "update/" + recordID;
export const Activate_Restaurant_Staff_Employee_PUT =
    Restaurant_Staff_Employee_URL + activateURL;
export const DeActivate_Restaurant_Staff_Employee_PUT =
    Restaurant_Staff_Employee_URL + inActivateURL;
export const Delete_Restaurant_Staff_Employee_DELETE =
    Restaurant_Staff_Employee_URL + deleteURL;
export const Revive_Restaurant_Staff_Employee_PUT =
    Restaurant_Staff_Employee_URL + reviveURL;
export const Deleted_Restaurant_Staff_Employee_List_POST =
    Restaurant_Staff_Employee_URL + deletedListURL;
export const Add_Restaurant_Staff_Employee_POST =
    Restaurant_Staff_Employee_URL + "save";
export const Reset_PWD_Restaurant_Staff_Employee_Put =
    Restaurant_Staff_Employee_URL + "reset-password/" + recordID;

// restaurant-staff-deliveryBoy-controller
const Restaurant_Staff_DeliveryBoy_URL =
    BASE_URL_OPS_V1_RESTAURANT + "user-delivery-boy/";
export const All_Restaurant_Staff_DeliveryBoy_List_POST =
    Restaurant_Staff_DeliveryBoy_URL + listURL;
export const Get_Restaurant_Staff_DeliveryBoy_Detail_GET =
    Restaurant_Staff_DeliveryBoy_URL + "get/" + recordID;
export const Update_Restaurant_Staff_DeliveryBoy_PUT =
    Restaurant_Staff_DeliveryBoy_URL + "update/" + recordID;
export const Activate_Restaurant_Staff_DeliveryBoy_PUT =
    Restaurant_Staff_DeliveryBoy_URL + activateURL;
export const DeActivate_Restaurant_Staff_DeliveryBoy_PUT =
    Restaurant_Staff_DeliveryBoy_URL + inActivateURL;
export const Delete_Restaurant_Staff_DeliveryBoy_DELETE =
    Restaurant_Staff_DeliveryBoy_URL + deleteURL;
export const Revive_Restaurant_Staff_DeliveryBoy_PUT =
    Restaurant_Staff_DeliveryBoy_URL + reviveURL;
export const Deleted_Restaurant_Staff_DeliveryBoy_List_POST =
    Restaurant_Staff_DeliveryBoy_URL + deletedListURL;
export const Add_Restaurant_Staff_DeliveryBoy_POST =
    Restaurant_Staff_DeliveryBoy_URL + "save";
export const Reset_PWD_Restaurant_Staff_DeliveryBoy_Put =
    Restaurant_Staff_DeliveryBoy_URL + "reset-password/" + recordID;

// restaurant-delivery-offer-controller
const Restaurant_Delivery_Offer_Url =
    BASE_URL_OPS_V1_RESTAURANT + "delivery-offer/";
export const All_Restaurant_Delivery_Offer_List_POST =
    Restaurant_Delivery_Offer_Url + listURL;
export const Get_Restaurant_Delivery_Offer_Detail_GET =
    Restaurant_Delivery_Offer_Url + "get/" + recordID;
export const Update_Restaurant_Delivery_Offer_PUT =
    Restaurant_Delivery_Offer_Url + "update/" + recordID;
export const Activate_Restaurant_Delivery_Offer_PUT =
    Restaurant_Delivery_Offer_Url + activateURL;
export const DeActivate_Restaurant_Delivery_Offer_PUT =
    Restaurant_Delivery_Offer_Url + inActivateURL;
export const Delete_Restaurant_Delivery_Offer_DELETE =
    Restaurant_Delivery_Offer_Url + deleteURL;
export const Revive_Restaurant_Delivery_Offer_PUT =
    Restaurant_Delivery_Offer_Url + reviveURL;
export const Deleted_Restaurant_Delivery_Offer_List_POST =
    Restaurant_Delivery_Offer_Url + deletedListURL;
export const Add_Restaurant_Delivery_Offer_POST =
    Restaurant_Delivery_Offer_Url + "save";

// restaurant-happy-hours-offer-controller
const Restaurant_Happy_Hours_Offer_Url =
    BASE_URL_OPS_V1_RESTAURANT + "happy-hours-offer/";
export const All_Restaurant_Happy_Hours_Offer_List_POST =
    Restaurant_Happy_Hours_Offer_Url + listURL;
export const Get_Restaurant_Happy_Hours_Offer_Detail_GET =
    Restaurant_Happy_Hours_Offer_Url + "get/" + recordID;
export const Update_Restaurant_Happy_Hours_Offer_PUT =
    Restaurant_Happy_Hours_Offer_Url + "update/" + recordID;
export const Activate_Restaurant_Happy_Hours_Offer_PUT =
    Restaurant_Happy_Hours_Offer_Url + activateURL;
export const DeActivate_Restaurant_Happy_Hours_Offer_PUT =
    Restaurant_Happy_Hours_Offer_Url + inActivateURL;
export const Delete_Restaurant_Happy_Hours_Offer_DELETE =
    Restaurant_Happy_Hours_Offer_Url + deleteURL;
export const Revive_Restaurant_Happy_Hours_Offer_PUT =
    Restaurant_Happy_Hours_Offer_Url + reviveURL;
export const Deleted_Restaurant_Happy_Hours_Offer_List_POST =
    Restaurant_Happy_Hours_Offer_Url + deletedListURL;
export const Add_Restaurant_Happy_Hours_Offer_POST =
    Restaurant_Happy_Hours_Offer_Url + "save";

// meta-controller
const meta_Url = BASE_URL + "/meta/";
export const Get_Table_Qr_Code_Post =
    meta_Url + "get-table-qr-code/" + recordID;
export const Get_QR_Code_Cashback_Qr_Code_Post =
    meta_Url + "get-qr-code-cash-back-qr-code/" + recordID;
export const Get_DineIn_Cashback_Qr_Code_Post =
    meta_Url + "get-dine-in-cash-back-qr-code/" + recordID;
export const CurrencyList_LocalCountry_GET = meta_Url + "currency-list";

// user-restaurant-admin-order-processing-controller
const Restaurant_Order_Processing_URL = BASE_URL_OPS_V1_RESTAURANT + "admin-order-processing/";
export const Update_Order_Status_Put = Restaurant_Order_Processing_URL + "update-order-status/" + recordID + "/{orderStatus}";
export const All_Order_List_Post = Restaurant_Order_Processing_URL + "list/" + "{orderType}";
export const All_History_Order_List_Post = Restaurant_Order_Processing_URL + "list-history/" + "{orderType}/" + "{startDateTimeStamp}/" + "{endDateTimeStamp}";
export const Get_Order_Details_Get = Restaurant_Order_Processing_URL + "get/" + recordID;
export const Get_Order_Count_Get = Restaurant_Order_Processing_URL + "get-order-count";
export const Remove_Coupon_Code_Put = Restaurant_Order_Processing_URL + "remove-coupon-code/" + recordID;
export const Print_Bill_Put = Restaurant_Order_Processing_URL + "print-bill/" + recordID;
export const Apply_Coupon_Code_Put = Restaurant_Order_Processing_URL + "apply-coupon-code/" + "{couponCode}/" + recordID;
export const Get_Coupon_Code_Key_Pair_List_Post = Restaurant_Order_Processing_URL + "coupon-code-list/" + recordID;
export const Order_Complementary_Head_Key_Pair_List_Post = Restaurant_Order_Processing_URL + "complementary-head-key-value-list";
export const Make_Order_COmplementary_Head_Put = Restaurant_Order_Processing_URL + "mark-order-complementary-head/" + recordID;
export const Accept_Order_Items_Status_Put = Restaurant_Order_Processing_URL + "accept-order-items/" + recordID;
export const Reject_Order_Items_Status_Put = Restaurant_Order_Processing_URL + "reject-order-items/" + recordID;
export const Delete_Order_Item_Put = Restaurant_Order_Processing_URL + "delete-order-item/" + recordID;
export const Order_Bill_Settlement_Put = Restaurant_Order_Processing_URL + "order-bill-settlement/" + recordID;
export const Get_Order_Bill_Settlement_Get = Restaurant_Order_Processing_URL + "get-order-bill-settlement-detail/" + recordID;
export const Get_Order_History_Get = Restaurant_Order_Processing_URL + "get-history/" + recordID;
export const Restaurant_Availability_List_Post = Restaurant_Order_Processing_URL + "restaurant-availability-list";
export const GET_BULK_INVOICE_DOWNLOAD_GET = Restaurant_Order_Processing_URL + "download-bulk-invoice/{startDate}/{endDate}";

// client-user-restaurant-controller
const Client_User_Restaurant = BASE_URL_OPS_CLIENT_V1 + "user-restaurant/";
export const Update_Client_User_Restaurant_Owner_Details_Put =
    Client_User_Restaurant + "update/{uuid}";
export const Update_Client_User_Restaurant_Owner_Details_POST =
    Client_User_Restaurant + updateUrl + recordID;
export const Get_Client_User_Restaurant_Owner_Details_GET =
    Client_User_Restaurant + "get/{uuid}";

// user-restaurant-aggregator-controller
const User_Restaurant_Aggregator_URL = BASE_URL_OPS_V1_RESTAURANT + "aggregator/";
export const All_Restaurant_Aggregator_List_POST =
    User_Restaurant_Aggregator_URL + listURL;
export const Get_Restaurant_Aggregator_Detail_GET =
    User_Restaurant_Aggregator_URL + "get/" + recordID;
export const Update_Restaurant_Aggregator_PUT =
    User_Restaurant_Aggregator_URL + "update/" + recordID;
export const Activate_Restaurant_Aggregator_PUT =
    User_Restaurant_Aggregator_URL + activateURL;
export const DeActivate_Restaurant_Aggregator_PUT =
    User_Restaurant_Aggregator_URL + inActivateURL;
export const Delete_Restaurant_Aggregator_DELETE =
    User_Restaurant_Aggregator_URL + deleteURL;
export const Revive_Restaurant_Aggregator_PUT =
    User_Restaurant_Aggregator_URL + reviveURL;
export const Deleted_Restaurant_Aggregator_List_POST =
    User_Restaurant_Aggregator_URL + deletedListURL;
export const Add_Restaurant_Aggregator_POST =
    User_Restaurant_Aggregator_URL + "save";
export const Key_Value_List_Restaurant_Aggregator_POST =
    User_Restaurant_Aggregator_URL + "key-value-list";


// user-restaurant-report-controller
const User_Restaurant_Report_URL = BASE_URL_OPS_V1_RESTAURANT + "report/";
export const User_Restaurant_Sale_Report_POST = User_Restaurant_Report_URL + "sale-report";
export const User_Restaurant_Order_Report_POST = User_Restaurant_Report_URL + "order-report";
export const User_Restaurant_Today_Sale_Report_POST = User_Restaurant_Report_URL + "today-sale-report";
export const User_Restaurant_Today_Order_Report_POST = User_Restaurant_Report_URL + "today-order-report";


// user-restaurant-dashboard-controller
const User_Restaurant_Dashboard_URL = BASE_URL_OPS_V1_RESTAURANT + "dashboard/";
export const Restaurant_Dashboard_Pos_Order_POST = User_Restaurant_Dashboard_URL + "pos-order/{orderStatus}";
export const Restaurant_Dashboard_Restaurant_Performance_GET = User_Restaurant_Dashboard_URL + "restaurant-performance";
export const Restaurant_Dashboard_Menu_Detail_GET = User_Restaurant_Dashboard_URL + "menu-detail";

// user-restaurant-restaurant-controller
const User_Restaurant_Restaurant_URL = BASE_URL_OPS_V1_RESTAURANT + "restaurant/";
export const Get_Restaurant_Closing_Time_URL = User_Restaurant_Restaurant_URL + "get-restaurant-closing-time";
export const Update_Restaurant_Closing_Time_URL = User_Restaurant_Restaurant_URL + "update-restaurant-closing-time/" + "{restaurantClosingTimeStamp}";

// user-restaurant-payment-mode-controller
const User_Restaurant_Payment_Mode_URL = BASE_URL_OPS_V1_RESTAURANT + "payment-mode/";
export const Update_Restaurant_Payment_Mode_PUT = User_Restaurant_Payment_Mode_URL + "update/" + recordID;
export const All_Restaurant_Payment_Mode_List_POST = User_Restaurant_Payment_Mode_URL + listURL;
export const Get_Restaurant_Payment_Mode_Detail_GET = User_Restaurant_Payment_Mode_URL + "get/" + recordID;
export const Activate_Restaurant_Payment_Mode_PUT = User_Restaurant_Payment_Mode_URL + activateURL;
export const DeActivate_Restaurant_Payment_Mode_PUT = User_Restaurant_Payment_Mode_URL + inActivateURL;
export const Delete_Restaurant_Payment_Mode_DELETE = User_Restaurant_Payment_Mode_URL + deleteURL;
export const Revive_Restaurant_Payment_Mode_PUT = User_Restaurant_Payment_Mode_URL + reviveURL;
export const Deleted_Restaurant_Payment_Mode_List_POST = User_Restaurant_Payment_Mode_URL + deletedListURL;
export const Add_Restaurant_Payment_Mode_POST = User_Restaurant_Payment_Mode_URL + "save";
export const Key_Value_List_Restaurant_Payment_Mode_POST = User_Restaurant_Payment_Mode_URL + "key-value-list";

