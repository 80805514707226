import React from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import Cookies from "universal-cookie";

const UserRestaurantDetails = (props) => {
  const {
    handleTextChange,
    record,
    handleCheckedChange,
    handleOrderTypeTextChange,
    handleOrderTypeSelectChange,
    handleSelectTextChange,
  } = props;
  const {
    restaurantDineInDetail,
    restaurantStealDealDetail,
    restaurantTakeAwayDetail,
    restaurantDeliveryDetail,
  } = record;

  const currencyDetail = new Cookies().get("currencyDetail");

  // Restaurant Details
  const viewRestaurantDetails = () => {
    return (
      <fieldset className="my-3">
        <legend>Restaurant Details</legend>
        <div className="form-group row mb-3">
          <div className="col-lg-6 my-2">
            <label>OrderId Prefix </label>
            <span className="d-block">{record.orderPrefix}</span>
            {/* <input type="text" className="form-control input-default" id="orderPrefix" onChange={handleTextChange} value={record.orderPrefix} /> */}
          </div>
          <div className="col-lg-6 my-2">
            <label>Title </label>
            <span className="d-block">{record.restaurantTitle}</span>
            {/* <input type="text" className="form-control input-default" id="restaurantTitle" onChange={handleTextChange} value={record.restaurantTitle} /> */}
          </div>
          <div className="col-lg-12 my-2">
            <label>Restaurant Closed Message *</label>
            <span className="d-block">{record.restaurantClosedMessage}</span>
            {/* <textarea className="form-control input-default" id="restaurantClosedMessage" onChange={handleTextChange} value={record.restaurantClosedMessage} /> */}
          </div>
          <div className="col-lg-12 my-2">
            <label>Description </label>
            <span className="d-block">{record.restaurantDescription}</span>
            {/* <textarea className="form-control input-default" id="restaurantDescription" onChange={handleTextChange} value={record.restaurantDescription} /> */}
          </div>
        </div>
        {/* <Button className="mb-3 float-end" onClick={handleSaveChange} variant="primary">
                    Save Changes
                </Button> */}
      </fieldset>
    );
  };

  // Dine In Order Availability
  const DineInOrderDetails = () => {
    return (
      <fieldset className="my-3">
        {/* <legend><label><input type="checkbox" id="dineIn" checked={record.dineIn} onChange={handleCheckedChange} /> Dine In</label></legend> */}
        <legend> Dine In</legend>

        <div className="row d-flex align-items-center">
          <span className="col-lg-12">
            DineIn Order availability is{" "}
            <b>{record.dineIn ? "Enable" : "Disable"}</b>, if you want to{" "}
            <b>{!record.dineIn ? "Enable" : "Disable"}</b> the DineIn order
            availability then contact to admin.
          </span>
          {/* {!record.dineIn ?
                        <span className="col-lg-10">if you want to enable the DineIn order type availability for this restaurant, check upper checkbox & Click on Submit button</span> :
                        <div className="col-lg-6 my-2">
                            <label>Timing Group List *</label>
                            <Select options={[]} value={restaurantDineInDetail?.timingGroupUuid} onChange={(e) => handleOrderTypeSelectChange(e, "DineIn", "timingGroupUuid")} />
                        </div>
                    } */}
          <div className={`col-lg-${record.dineIn ? 6 : 2} my-2`}>
            {/* <Button variant="primary" className="float-end" onClick={record.dineIn ? handleEnableDineIn : handleDisableDineIn}>
                            Submit
                        </Button> */}
          </div>
        </div>
      </fieldset>
    );
  };

  // Home Delivery Order Availability
  const homeDeliveryOrderDetails = () => {
    return (
      <fieldset className="my-3">
        {/* <legend><label><input type="checkbox" id="delivery" checked={record.delivery} onChange={handleCheckedChange} /> Home Delivery</label> </legend> */}
        <legend>Home Delivery</legend>
        {!record.delivery ? (
          // <span>if you want to enable the Home Delivery order type availability for this restaurant, check upper checkbox & Click on Submit button</span> :
          <span className="col-lg-12">
            Home Delivery Order availability is <b>disable</b>, if you want to{" "}
            <b>enable</b> the Home Delivery order availability then contact to
            admin.
          </span>
        ) : (
          <div className="row">
            <div className="col-lg-6 my-2">
              <label>Minimum Order Amount </label>
              <span className="d-block">
                {restaurantDeliveryDetail?.deliveryMinimumOrderAmount}{" "}
                {" " + currencyDetail?.currencySymbol}{" "}
              </span>
              {/* <input
                                type="number"
                                className="form-control input-default"
                                id="deliveryMinimumOrderAmount"
                                value={restaurantDeliveryDetail?.deliveryMinimumOrderAmount}
                                onChange={(e) => handleOrderTypeTextChange(e, "Delivery")}
                            /> */}
            </div>
            <div className="col-lg-6 my-2">
              <label>Base Delivery Charges </label>
              <span className="d-block">
                {restaurantDeliveryDetail?.baseDeliveryCharges} {+" "+ currencyDetail?.currencySymbol}
              </span>
              {/* <input
                                type="number"
                                className="form-control input-default"
                                id="baseDeliveryCharges"
                                value={restaurantDeliveryDetail?.baseDeliveryCharges}
                                onChange={(e) => handleOrderTypeTextChange(e, "Delivery")}
                            /> */}
            </div>
            <div className="col-lg-6 my-2">
              <label>Maximum Distance For Base Charges</label>
              <span className="d-block">
                {restaurantDeliveryDetail?.maximumDistanceForBaseCharges}{" "}
                {" " + currencyDetail?.currencySymbol}
              </span>
              {/* <input
                                type="number"
                                className="form-control input-default"
                                id="maximumDistanceForBaseCharges"
                                value={restaurantDeliveryDetail?.maximumDistanceForBaseCharges}
                                onChange={(e) => handleOrderTypeTextChange(e, "Delivery")}
                            /> */}
            </div>
            <div className="col-lg-6 my-2">
              <label>Delivery Charges Per KM </label>
              <span className="d-block">
                {restaurantDeliveryDetail?.deliveryChargesPerKM} {+" "+ currencyDetail?.currencySymbol}
              </span>
              {/* <input
                                type="number"
                                className="form-control input-default"
                                id="deliveryChargesPerKM"
                                value={restaurantDeliveryDetail?.deliveryChargesPerKM}
                                onChange={(e) => handleOrderTypeTextChange(e, "Delivery")}
                            /> */}
            </div>
            <div className="col-lg-6 my-2">
              <label>Delivery Radius </label>
              <span className="d-block">
                {restaurantDeliveryDetail?.deliveryRadius}
              </span>
              {/* <input
                                type="number"
                                className="form-control input-default"
                                id="deliveryRadius"
                                value={restaurantDeliveryDetail?.deliveryRadius}
                                onChange={(e) => handleOrderTypeTextChange(e, "Delivery")}
                            /> */}
            </div>
            {/* <div className="col-lg-6 my-2">
                            <label>Timing Group List *</label>
                            <Select options={[]} value={restaurantDeliveryDetail?.timingGroupUuid} onChange={(e) => handleOrderTypeSelectChange(e, "Delivery", "timingGroupUuid")} />
                        </div> */}
          </div>
        )}

        {/* <Button variant="primary" className="float-end" onClick={record.delivery ? handleEnableDelivery : handleDisableDelivery}>
                    Submit
                </Button> */}
      </fieldset>
    );
  };

  // Take Away Order Availability
  const takeAwayOrderAvailability = () => {
    return (
      <fieldset className="my-3">
        {/* <legend><label><input type="checkbox" id="takeaway" checked={record.takeaway} onChange={handleCheckedChange} /> Take Away</label></legend> */}
        <legend>Take Away</legend>
        <div className="row align-items-center">
          {!record.takeaway ? (
            // <span className="col-lg-10">if you want to enable the Take away order type availability for this restaurant, check upper checkbox & Click on Submit button</span> :
            <span className="col-lg-12">
              Take Away Order availability is <b>disable</b>, if you want to{" "}
              <b>enable</b> the Take Away order availability then contact to
              admin.
            </span>
          ) : (
            <>
              <div className="col-lg-4 my-2">
                <label>Minimum Order Amount </label>
                <span className="d-block">
                  {restaurantTakeAwayDetail?.takeAwayMinimumOrderAmount}{" "}
                  {" " + currencyDetail?.currencySymbol}
                </span>
                {/* <input
                                    type="number"
                                    className="form-control input-default"
                                    id="takeAwayMinimumOrderAmount"
                                    onChange={(e) => handleOrderTypeTextChange(e, "TakeAway")}
                                    value={restaurantTakeAwayDetail?.takeAwayMinimumOrderAmount}
                                /> */}
              </div>
              <span className="col-lg-12">
                Take Away Order availability is <b>enable</b>, if you want to{" "}
                <b>disable</b> the Take Away order availability then contact to
                admin.
              </span>
              {/* <div className="col-lg-4 my-2">
                                <label>Timing Group List *</label>
                                <Select
                                    options={[]}
                                    value={restaurantTakeAwayDetail?.timingGroupUuid}
                                    onChange={(e) => handleOrderTypeSelectChange(e, "TakeAway", "timingGroupUuid")}
                                />
                            </div> */}
            </>
          )}
          <div className={`col-lg-${record.takeaway ? 4 : 2} my-2 `}>
            {/* <Button variant="primary" className="float-end" onClick={record.takeaway ? handleEnableTakeaway : handleDisableTakeaway}>
                            Submit
                        </Button> */}
          </div>
        </div>
      </fieldset>
    );
  };

  // Steal Deal Order Availability
  const stealDealOrderAvailability = () => {
    return (
      <fieldset className="my-3">
        {/* <legend><label><input type="checkbox" id="stealDeal" checked={record.stealDeal} onChange={handleCheckedChange} /> Steal Deal</label></legend> */}
        <legend>Steal Deal</legend>
        <div className="row align-items-center">
          <span className="col-lg-12">
            Steal Deal Order availability is{" "}
            <b>{record.stealDeal ? "enable" : "disable"}</b>, if you want to{" "}
            <b>{!record.stealDeal ? "enable" : "disable"}</b> the Steal Deal
            order availability then contact to admin.
          </span>{" "}
          :
          {/* {!record.stealDeal ?
                        <span className="col-lg-10">if you want to enable the Steal Deal order type availability for this restaurant, check upper checkbox & Click on Submit button</span> :
                        <div className="col-lg-6 my-2">
                            <label>Timing Group List *</label>
                            <Select options={[]} value={restaurantStealDealDetail.timingGroupUuid} onChange={(e) => handleOrderTypeSelectChange(e, "StealDeal", "timingGroupUuid")} />
                        </div>
                    } */}
          <div className={`col-lg-${record.stealDeal ? 6 : 2} my-2 `}>
            {/* <Button variant="primary" className="float-end" onClick={record.stealDeal ? handleEnableStealDeal : handleDisableStealDeal}>
                            Submit
                        </Button> */}
          </div>
        </div>
      </fieldset>
    );
  };

  // Tax Numbers Details
  const taxNumberDetails = () => {
    return (
      <fieldset className="my-3">
        <legend>Tax Numbers</legend>
        <div className="row">
          <div className="col-lg-4 my-2">
            <label>Gst Number </label>
            <span className="d-block">{record.gstNumber}</span>
            {/* <input type="text" className="form-control input-default" id="gstNumber" onChange={handleTextChange} value={record.gstNumber} /> */}
          </div>
          <div className="col-lg-4 my-2">
            <label>Tin Number </label>
            <span className="d-block">{record.tinNumber}</span>
            {/* <input type="text" className="form-control input-default" id="tinNumber" onChange={handleTextChange} value={record.tinNumber} /> */}
          </div>
          <div className="col-lg-4 my-2">
            <label>Vat Number </label>
            <span className="d-block">{record.vatNumber}</span>
            {/* <input type="text" className="form-control input-default" id="vatNumber" onChange={handleTextChange} value={record.vatNumber} /> */}
          </div>
        </div>
        {/* <Button className="mb-3 float-end" onClick={handleSaveChange} variant="primary">
                    Save Changes
                </Button> */}
      </fieldset>
    );
  };

  // Configuration  Details
  const configurationDetails = () => {
    return (
      <fieldset className="my-3">
        <legend>Configuration</legend>
        <div className="row">
          <div className="col-lg-4 my-2">
            {/* <input type="checkbox" className="input-default" id="autoPrintKOT" onChange={handleCheckedChange} checked={record.autoPrintKOT} /> */}
            <i
              className={`bx  ${
                record.autoPrintKOT
                  ? "bx-check text-success"
                  : "bx-x text-danger"
              }`}
              style={{ fontSize: "18px" }}
            />
            <label className="ms-2" htmlFor="autoPrintKOT">
              Auto Print KOT{" "}
            </label>
          </div>
          <div className="col-lg-4 my-2">
            {/* <input type="checkbox" className="input-default" id="autoPrintBOT" onChange={handleCheckedChange} checked={record.autoPrintBOT} /> */}
            <i
              className={`bx  ${
                record.autoPrintBOT
                  ? "bx-check text-success"
                  : "bx-x text-danger"
              }`}
              style={{ fontSize: "18px" }}
            />
            <label className="ms-2" htmlFor="autoPrintBOT">
              Auto Print BOT{" "}
            </label>
          </div>
          <div className="col-lg-4 my-2">
            {/* <input type="checkbox" className="input-default" id="autoAcceptOrder" onChange={handleCheckedChange} checked={record.autoAcceptOrder} /> */}
            <i
              className={`bx  ${
                record.autoAcceptOrder
                  ? "bx-check text-success"
                  : "bx-x text-danger"
              }`}
              style={{ fontSize: "18px" }}
            />
            <label className="ms-2" htmlFor="autoAcceptOrder">
              Auto Accept Order{" "}
            </label>
          </div>
          <div className="col-lg-4 my-2">
            {/* <input type="checkbox" className="input-default" id="showInReservation" onChange={handleCheckedChange} checked={record.showInReservation} /> */}
            <i
              className={`bx  ${
                record.showInReservation
                  ? "bx-check text-success"
                  : "bx-x text-danger"
              }`}
              style={{ fontSize: "18px" }}
            />
            <label className="ms-2" htmlFor="showInReservation">
              Show In Reservation{" "}
            </label>
          </div>
          <div className="col-lg-4 my-2">
            {/* <input type="checkbox" className="input-default" id="showInLocation" onChange={handleCheckedChange} checked={record.showInLocation} /> */}
            <i
              className={`bx  ${
                record.showInLocation
                  ? "bx-check text-success"
                  : "bx-x text-danger"
              }`}
              style={{ fontSize: "18px" }}
            />
            <label className="ms-2" htmlFor="showInLocation">
              Show In Location{" "}
            </label>
          </div>
        </div>
        {/* <Button className="mb-3 float-end" onClick={handleSaveChange} variant="primary">
                    Save Changes
                </Button> */}
      </fieldset>
    );
  };

  // Club Mode Status
  const clubModeStatusDetails = () => {
    return (
      <fieldset className="my-3">
        <legend>Club Mode Status</legend>
        <div className="row">
          <div className="col-lg-12 my-2">
            <span className="col-lg-12">
              Club Mode is <b>{record.clubMode ? "Enable" : "Disable"}</b>, if
              you want to <b>{!record.clubMode ? "Enable" : "Disable"}</b> the
              Club Mode then contact to admin.
            </span>{" "}
            {/* <input type="checkbox" className="input-default" id="clubMode" checked={record.clubMode} onChange={handleCheckedChange} /> */}
            {/* <label className="ms-2" htmlFor="clubMode">now, Club Mode is <b>{record.clubMode ? "Enable" : "Disable"}</b>, for change status click on button </label> */}
          </div>
          <div className="col-lg-4 my-2">
            {/* <Button className="float-end mb-3" onClick={handleUpdateClubMode} variant="primary">
                            {!record.clubMode ? "Enable" : "Disable"} Club Mode
                        </Button> */}
          </div>
        </div>
      </fieldset>
    );
  };

  // Restaurant Open/Close Status
  const restaurantOpenCloseStatus = () => {
    return (
      <fieldset className="my-3">
        <legend>Restaurant Status</legend>
        <div className="row">
          <div className="col-lg-12 my-2">
            <span className="col-lg-12">
              Restaurant is <b>{record.open ? "Open" : "Close"}</b>, if you want
              to <b>{!record.clubMode ? "Open" : "Close"}</b> the Restaurant
              then contact to admin.
            </span>{" "}
            {/* <input type="checkbox" className="input-default" id="clubMode" checked={record.clubMode} onChange={handleCheckedChange} /> */}
            {/* <label className="ms-2" htmlFor="open">now, Restaurant is <b>{record.open ? "Open" : "Close"}</b>, for change status click on button</label> */}
          </div>
          <div className="col-lg-4 my-2">
            {/* <Button className="float-end mb-3" onClick={handleUpdateRestaurantStatus} variant="primary">
                            {!record.open ? "Open" : "Close"} Restaurant
                        </Button> */}
          </div>
        </div>
      </fieldset>
    );
  };

  // Active Timing Group for Restaurant
  const restaurantActiveTimingGroup = () => {
    return (
      <fieldset className="my-3">
        <legend>Restaurant Timing</legend>
        <div className="row align-items-center">
          <div className="col-lg-6 my-2">
            <label>Timing Group List </label>
            {/* <Select options={[]} value={record?.timingGroupUuid} onChange={(e) => handleSelectTextChange(e, "timingGroupUuid")} /> */}
          </div>
          <div className="col-lg-6 my-2">
            {/* <Button variant="primary" className="float-end my-2" onClick={handleUpdateRestaurantTiming}>
                            Save Changes
                        </Button> */}
          </div>
        </div>
      </fieldset>
    );
  };

  return (
    <>
      {viewRestaurantDetails()}
      {DineInOrderDetails()}
      {homeDeliveryOrderDetails()}
      {takeAwayOrderAvailability()}
      {stealDealOrderAvailability()}
      {taxNumberDetails()}
      {configurationDetails()}
      {clubModeStatusDetails()}
      {restaurantOpenCloseStatus()}
      {restaurantActiveTimingGroup()}
    </>
  );
};

export default UserRestaurantDetails;
