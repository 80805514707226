import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";

import { Activate_Client_PUT, All_Client_List_POST, DeActivate_Client_PUT, Delete_Client_DELETE, Deleted_Client_List_POST, Revive_Client_PUT } from "api/ApiConst";
import { handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import dateUtilities from "utils/DateUtilities";

import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import CheckboxButton from "components/common/CheckboxButton";
import ClientDetailsModal from "./ClientDetailsModal";

const ClientMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    useEffect(() => {
        if (!showDetailsModal) {
            setRecordID(null);
        }
    }, [showDetailsModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => <div className={row.active ? "led-green" : "led-red"} title={row.active ? "Active" : "Inactive"} />,
                width: "60px",
                sortable: false,
            },
            {
                id: "clientIdentity",
                name: "Client Identity",
                // selector: (row) => <Link to={`/client/${row.uuid}`}>{row.clientIdentity}</Link>,
                selector: (row) => <Link onClick={() => handleViewDetails(row)}>{row.clientIdentity}</Link>,
                sortable: false,
            },
            {
                id: "thirdPartyId",
                name: "Third Party",
                selector: (row) => <div className="ws-initial">{row?.thirdPartyId}</div>,
                sortable: false,
            },
            {
                id: "createdAt",
                name: "Created Time",
                selector: (row) => <div className="ws-initial">{dateUtilities.formattedDate(row.createdAtTimeStamp, "dd-MM-yyyy, hh:mm aa")}</div>,
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => <div className="custom-column-action">{getActionButtons(row)}</div>,
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleViewDetails = (row) => {
        setRecordID(row.uuid);
        setShowDetailsModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_Client_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_Client_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_Client_DELETE.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_Client_PUT.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("Client List", "Dashboard", "/")}
            <div className="card">
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_Client_List_POST : All_Client_List_POST}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showDetailsModal && <ClientDetailsModal show={showDetailsModal} hideModal={() => setShowDetailsModal(false)} recordID={recordID} />}
        </>
    );
};

export default ClientMaster;
