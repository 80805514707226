import { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import {
  Add_Restaurant_Staff_DeliveryBoy_POST,
  Get_Restaurant_Staff_DeliveryBoy_Detail_GET,
  Update_Restaurant_Staff_DeliveryBoy_PUT,
} from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import {
  getDOB,
  getTimeFromDate,
  isAlphaNumercSpecialCharSpc,
  isEmail,
  isEmpty,
  isMobile,
  isValidIsdCode,
} from "utils/TextUtils";

import ImageSelector from "components/common/ImageSelector";
import ModalWrapper from "components/common/ModalWrapper";
import { MODAL_LOADER } from "utils/constants";
import Cookies from "universal-cookie";

const RestaurantDeliveryBoyAddEdit = ({
  show,
  hideModal,
  recordID,
  handleRefresh,
}) => {
  const defaultRecordObj = {
    firstName: "",
    lastName: "",
    gender: "Male",
    mobile: "",
    isdCode: "+91",
    email: "",
    password: "",
    photoImageRefId: "",
    perKmCharges: "",
    perDayCharges: "",
    dateOfBirthTimeStamp: "",
  };
  const [record, setRecord] = useState(defaultRecordObj);
  const [showPassword, setShowPassword] = useState(false);

  const currencyDetail = new Cookies().get("currencyDetail");

  useEffect(() => {
    if (recordID) {
      callGetApi(
        MODAL_LOADER,
        Get_Restaurant_Staff_DeliveryBoy_Detail_GET.replaceAll(
          "{recordID}",
          recordID
        ),
        {},
        (response) => {
          const res = response.responsePacket;
          res.dateOfBirthTimeStamp = getDOB(res.dateOfBirthTimeStamp);
          setRecord(res);
        }
      );
    }
  }, [recordID]);

  //Text Change Event
  const handleTextChange = (e) => {
    const { id, value } = e.target;
    if (id === "firstName") {
      if (value.length > 20) {
        return;
      }
    }
    if (id === "firstName" && value.length !== 0) {
      if (!isAlphaNumercSpecialCharSpc(value)) {
        return;
      }
    }
    if (id === "lastName") {
      if (value.length > 20) {
        return;
      }
    }
    if (id === "lastName" && value.length !== 0) {
      if (!isAlphaNumercSpecialCharSpc(value)) {
        return;
      }
    }
    if (id === "mobile") {
      if (value.length > 10) {
        return;
      }
    }
    if (id === "perKmCharges") {
      if (value.length > 7) {
        return;
      }
    }
    if (id === "perDayCharges") {
      if (value.length > 7) {
        return;
      }
    }
    setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  // Image change
  const handleImageChange = (imageRefId) => {
    setRecord({ ...record, photoImageRefId: imageRefId });
  };

  const checkInputValidation = (recordObj) => {
    if (isEmpty(recordObj.photoImageRefId)) {
      toast.error(`Image must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.firstName)) {
      toast.error(`First Name must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.lastName)) {
      toast.error(`Last Name must not be empty`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.isdCode)) {
      toast.error(`ISD Code must not be empty`, { id: "toast" });
      return false;
    } else if (!isValidIsdCode(recordObj.isdCode)) {
      toast.error(`Please Provide Valid ISD Code`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.mobile)) {
      toast.error(`Mobile must not be empty`, { id: "toast" });
      return false;
    } else if (!isMobile(recordObj.mobile)) {
      toast.error(`Please Provide Valid Mobile Number`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.email)) {
      toast.error(`Email must not be empty`, { id: "toast" });
      return false;
    } else if (recordObj.email && !isEmail(recordObj.email)) {
      toast.error(`Please Provide Valid Email`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.perKmCharges)) {
      toast.error(`Per Km Charges must not be empty`, { id: "toast" });
      return false;
    } else if (recordObj.perKmCharges < 0) {
      toast.error(`Per Km Charges must not be negative`, { id: "toast" });
      return false;
    } else if (isEmpty(recordObj.perDayCharges)) {
      toast.error(`Per Day Charges must not be empty`, { id: "toast" });
      return false;
    } else if (recordObj.perDayCharges < 0) {
      toast.error(`Per Day charges must not be negative`, { id: "toast" });
      return false;
    }
    // else if (recordObj.lastName.length > 200) {
    //   toast.error(`Last Name must not be more then 200 character.`, { id: "toast" });
    //   return false;
    // }
    //  else if (recordObj.firstName.length > 200) {
    //   toast.error(`First Name must not be more then 200 character.`, { id: "toast" });
    //   return false;
    // }
    if (!recordID) {
      if (isEmpty(recordObj.password)) {
        toast.error(`Password must not be empty`, { id: "toast" });
        return false;
      }
    }
    if (recordObj.dateOfBirthTimeStamp) {
      var currentDate = new Date();
      var dobDate = new Date(recordObj.dateOfBirthTimeStamp);

      if (dobDate.getTime() > currentDate.getTime()) {
        toast.error(`Please Provide Valid date Of Birth`, { id: "toast" });
        return false;
      }
    }
    return true;
  };

  //Submit Employee Form
  const handleSubmit = async () => {
    try {
      let recordObj = {
        ...record,
        dateOfBirthTimeStamp: getTimeFromDate(record.dateOfBirthTimeStamp),
      };
      if (!checkInputValidation(recordObj)) {
        return;
      }
      if (!recordID || recordID === "") {
        callPostApi(
          MODAL_LOADER,
          Add_Restaurant_Staff_DeliveryBoy_POST,
          recordObj,
          {},
          (response) => {
            toast.success(response.message, {id: "toast"});
            hideModal();
            handleRefresh();
          }
        );
      } else {
        callPutApi(
          MODAL_LOADER,
          Update_Restaurant_Staff_DeliveryBoy_PUT.replaceAll(
            "{recordID}",
            recordID
          ),
          recordObj,
          {},
          (response) => {
            toast.success(response.message, {id: "toast"});
            hideModal();
            handleRefresh();
          }
        );
      }
    } catch (error) {
      return;
    }
  };

  return (
    <ModalWrapper
      show={show}
      backdrop="static"
      hide={hideModal}
      header={`${recordID ? "Update" : "Add"} Delivery Boy`}
      handleSubmit={handleSubmit}
    >
      <div className="form-group row mb-3">
        <div className="col-lg-12">
          <ImageSelector
            style={{
              height: "120px",
              width: "120px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
            user={true}
            handleImageChange={handleImageChange}
            selectedImage={record.photoImageRefId}
          />
        </div>
      </div>
      <div className="form-group row mb-3">
        <div className="col-lg-6">
          <label>First Name *</label>
          <input
            type="text"
            className="form-control input-default"
            id="firstName"
            onChange={handleTextChange}
            value={record.firstName}
          />
        </div>
        <div className="col-lg-6">
          <label>Last Name *</label>
          <input
            type="text"
            className="form-control input-default"
            id="lastName"
            onChange={handleTextChange}
            value={record.lastName}
          />
        </div>
      </div>
      <div className="form-group row mb-3">
        <div className="col-lg-6">
          <div className="row g-1">
            <div className="col-4">
              <label>ISD Code *</label>
              <input
                type="text"
                className="form-control input-default"
                id="isdCode"
                onChange={handleTextChange}
                value={record.isdCode}
              />
            </div>
            <div className="col-8">
              <label>Mobile *</label>
              <input
                type="number"
                className="form-control input-default"
                id="mobile"
                onChange={handleTextChange}
                value={record.mobile}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <label>Email *</label>
          <input
            type="email"
            className="form-control input-default"
            id="email"
            onChange={handleTextChange}
            value={record.email}
          />
        </div>
      </div>
      <div className="form-group row mb-3">
        <div className="col-lg-4">
          <label className="d-block">Gender</label>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              id="male"
              defaultValue="male"
              checked={record.gender?.toLowerCase() === "male"}
              onChange={(e) => setRecord({ ...record, gender: "Male" })}
            />
            <label className="form-check-label" htmlFor="male">
              Male
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              id="female"
              defaultValue="female"
              checked={record.gender?.toLowerCase() === "female"}
              onChange={(e) => setRecord({ ...record, gender: "Female" })}
            />
            <label className="form-check-label" htmlFor="female">
              Female
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              id="other"
              defaultValue="other"
              checked={record.gender?.toLowerCase() === "other"}
              onChange={(e) => setRecord({ ...record, gender: "Other" })}
            />
            <label className="form-check-label" htmlFor="other">
              Other
            </label>
          </div>
        </div>
        <div className="col-lg-4">
          <label>Per KM Charges *</label>
          <div className="input-group mb-3">
            <input
              type="number"
              className="form-control input-default"
              id="perKmCharges"
              onChange={handleTextChange}
              value={record.perKmCharges}
            />
            <div className="input-group-append">
              <span className="input-group-text bg-red">
                {currencyDetail?.currencySymbol}
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <label>Per Day Charges *</label>
          <div className="input-group mb-3">
            <input
              type="number"
              className="form-control input-default"
              id="perDayCharges"
              onChange={handleTextChange}
              value={record.perDayCharges}
            />
            <div className="input-group-append">
              <span className="input-group-text bg-red"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row mb-3">
        {!recordID && (
          <div className="col-lg-6">
            <label>Password *</label>
            <div className="input-group auth-pass-inputgroup">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control input-default"
                id="password"
                onChange={handleTextChange}
                value={record.password}
              />
              <button
                className="btn btn-light "
                type="button"
                id="password-addon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className="mdi mdi-eye-off-outline" />
                ) : (
                  <i className="mdi mdi-eye-outline" />
                )}
              </button>
            </div>
          </div>
        )}
        <div className="col-lg-6">
          <label>Date Of Birth *</label>
          <input
            className="form-control"
            type="date"
            id="dateOfBirthTimeStamp"
            value={record.dateOfBirthTimeStamp}
            onChange={handleTextChange}
            max={new Date().toISOString().split("T")[0]}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default RestaurantDeliveryBoyAddEdit;
