import { useCallback, useEffect, useMemo, useState } from "react";

import { toast } from "react-hot-toast";

import {
  Activate_Restaurant_Menu_Item_Put,
  All_Restaurant_Menu_Item_List_Post,
  DeActivate_Restaurant_Menu_Item_Put,
  Delete_Restaurant_Menu_Item_Delete,
  Deleted_Restaurant_Menu_Item_List_POST,
  GET_Restaurant_Steal_Deal_Category_Key_Value_List_Post,
  GET_Client_Sticker_Key_Value_List_Post,
  Get_Restaurant_Non_Steal_Deal_Category_Key_Value_List_Post,
  Get_Restaurant_Condiment_Key_Value_List_Post,
  Get_Restaurant_Item_Customization_Key_Value_List_Post,
  Get_Restaurant_Tax_Key_Value_List_Post,
  Get_Restaurant_Timing_Group_Key_Value_Pair_List_Post,
  // Post_Brand_Key_Value_Pairs_List,
  Revive_Restaurant_Menu_Item_Put,
  DeActivate_Rest_Menu_Restaurant_Menu_Item_Put,
  Activate_Rest_Menu_Restaurant_Menu_Item_Put,
  Delete_Rest_Menu_Restaurant_Menu_Item_Delete,
  Revive_Rest_Menu_Restaurant_Menu_Item_Put,
  Get_Restaurant_Printer_Key_Value_List_Post,
  Get_Delivery_Offer_Item_Key_Value_List_Post,
  Get_Happy_Hour_Offer_Item_Key_Value_List_Post,
} from "api/ApiConst";
import { callGetApi, callPostApi } from "api/ApiCaller";
import {
  DELIVERY_PARTNERS_AVAILABILITIES,
  DINE_IN,
  HOME_DELIVERY,
  TAKEAWAY,
  ZOMATO,
  SWIGGY,
  SECTION_LOADER,
  TYPE_AVAILABILITIES,
  STEAL_DEAL,
} from "utils/constants";
import {
  getImageSrcViaRefId,
  handleActive,
  handleDelete,
  handleInactive,
  handleRevive,
} from "utils/UserUtils";
import { API_SUCCESS } from "api/ResponseCodes";

import CheckboxButton from "components/common/CheckboxButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";

import { isEmpty } from "utils/TextUtils";
import Select from "react-select";
import RestaurantMenuItemAddEdit from "./RestaurantMenuItemAddEdit";
import Cookies from "universal-cookie";

const availabilitiesOptions = [
  { label: "All Item", value: "AllItem" },
  { label: "Dine In Item", value: "DineIn" },
  { label: "Home Delivery Item", value: "HomeDelivery" },
  { label: "Take away Item", value: "TakeAway" },
  // { label: "Steal Deal Item", value: "StealDeal" },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: 160, // Set the desired width
  }),
};

export const RestaurantItemMaster = () => {
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [viewDeletedList, setViewDeletedList] = useState(false);
  const [recordID, setRecordID] = useState(null);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  // const [brandKeyValuePairs, setBrandKeyValuePairs] = useState([]);
  const [timingGroupKeyPairList, setTimingGroupKeyPairList] = useState([]);
  const [taxKeyPairList, setTaxKeyPairList] = useState([]);
  const [condimentsKeyValuePairs, setCondimentsKeyValuePairs] = useState([]);
  const [customizationsKeyValuePairs, setCustomizationsKeyValuePairs] =
    useState([]);
  const [printerKeyValuePairs, setPrinterKeyValuePairs] =
    useState([]);
  const [categoryKeyValuePairList, setCategoryKeyValuePairList] = useState([]);
  const [stickerKeyValuePairList, setStickerKeyValuePairList] = useState([]);
  const [comboItemsKeyValuePairList, setComboItemsKeyValuePairList] = useState([]);
  const [itemAvailability, setItemAvailability] = useState({
    label: "All Item",
    value: "AllItem",
  });
  const [ownedItem, setOwnedItem] = useState(true);

  const currencyDetail = new Cookies().get("currencyDetail");

  useEffect(() => {
    if (!showAddUpdateModal) {
      setRecordID(null);
      setOwnedItem(true);
    }
  }, [showAddUpdateModal]);

  useEffect(() => {
    getCategoryKeyValuePairs();
    getTaxKeyPairList();
    getCondimentsKeyValuePairs();
    getCustomizationsKeyValuePairs();
    getTimingGroupKeyPairList();
    getStickerKeyValuePairs();
    getPrinterKeyValuePairList();
    getComboItemsKeyValuePairList();
  }, []);

  // Get Category Key Pair List
  const getCategoryKeyValuePairs = () => {
    const rUrl = Get_Restaurant_Non_Steal_Deal_Category_Key_Value_List_Post;
    const rObj = {};
    callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setCategoryKeyValuePairList(response.responsePacket);
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Tax Key Pair List
  const getTaxKeyPairList = () => {
    let rUrl = Get_Restaurant_Tax_Key_Value_List_Post;
    const rObj = {};
    callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setTaxKeyPairList(response.responsePacket);
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Condiments Key Pair List
  const getCondimentsKeyValuePairs = () => {
    const rUrl = Get_Restaurant_Condiment_Key_Value_List_Post;
    const rObj = {};
    callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setCondimentsKeyValuePairs(response.responsePacket);
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Customizations Key Pair List
  const getCustomizationsKeyValuePairs = () => {
    const rUrl = Get_Restaurant_Item_Customization_Key_Value_List_Post;
    const rObj = {};
    callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setCustomizationsKeyValuePairs(response.responsePacket);
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Printer Key value Pair
  const getPrinterKeyValuePairList = () => {
    callGetApi(SECTION_LOADER, Get_Restaurant_Printer_Key_Value_List_Post, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setPrinterKeyValuePairs(response.responsePacket);
      } else {
        toast.error(response.message);
      }
    });
  }
  // Get Timing Group Key Pair List
  const getTimingGroupKeyPairList = () => {
    let rUrl = Get_Restaurant_Timing_Group_Key_Value_Pair_List_Post;
    const rObj = {};
    callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setTimingGroupKeyPairList(response.responsePacket);
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Sticker Key Value Pair
  const getStickerKeyValuePairs = () => {
    const rUrl = GET_Client_Sticker_Key_Value_List_Post;
    const rObj = {};
    callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setStickerKeyValuePairList(response.responsePacket);
      } else {
        toast.error(response.message);
      }
    });
  };

  // Get Sticker Key Value Pair
  const getComboItemsKeyValuePairList = () => {
    const rUrl = Get_Happy_Hour_Offer_Item_Key_Value_List_Post;
    const rObj = {};
    callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
      if (response.errorCode === API_SUCCESS) {
        setComboItemsKeyValuePairList(response.responsePacket);
      } else {
        toast.error(response.message);
      }
    });
  };

  const handleRefresh = useCallback(() => {
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag]);

  const columns = useMemo(
    () => [
      {
        id: "status",
        name: "",
        selector: (row) => {
          if (row.active) {
            return <div className="led-green" title="Active" />;
          } else {
            return <div className="led-red" title="Inactive" />;
          }
        },
        width: "60px",
        sortable: false,
      },
      {
        id: "itemImageRefId",
        name: "",
        selector: (row) => (
          <div className="list-img">
            <img
              src={row?.itemImageRefId ? getImageSrcViaRefId(row.itemImageRefId) :"/assets/images/defaultItemImage.png"}
              alt="category icon"
            />
          </div>
        ),
        width: "70px",
        sortable: false,
      },
      {
        id: "title",
        name: "Title",
        selector: (row) => (
          <span className="parent-owned-flag">
            {
              <img
                src={`/assets/images/${row.vegNonVeg.toLowerCase() === "veg"
                  ? "veg"
                  : row.vegNonVeg.toLowerCase() === "nonveg"
                    ? "nonVeg"
                    : row.vegNonVeg.toLowerCase() === "vegan"
                      ? "vegan"
                      : "none"
                  }.png`}
                className="me-1"
                alt=""
                title={row.vegNonVeg}
                style={{ height: "15px", width: "auto" }}
              />
            }{" "}
            {row.owned && (
              <span className="badge bg-primary owned-flag" title="Owned">
                O
              </span>
            )}{" "}
            {row.title}
          </span>
        ),
        wrap: true,
        sortable: false,
        // width: "250px"
      },
      {
        id: "categoryTitle",
        name: "Category",
        wrap: true,
        selector: (row) => <>{row.categoryTitle}</>,
      },
      {
        id: "subCategoryTitle",
        name: "Sub Cat",
        selector: (row) => <>{row.subCategoryTitle}</>,
        wrap: true,
        sortable: false,
      },
      {
        id: "availabilityPrices",
        name: "Availability",
        selector: (row, i) =>
          TYPE_AVAILABILITIES.map(
            (type, j) =>
              type !== STEAL_DEAL &&
              (row.availabilityPrices.find(
                (data) =>
                  data.menuAvailabilityType.toLowerCase() === type.toLowerCase()
              ) ? (
                <span
                  className="badge-available"
                  title={type}
                  key={`type-availability-available-${i}-${j}`}
                >
                  {type === DINE_IN
                    ? "Di"
                    : type === HOME_DELIVERY
                      ? "De"
                      : type === TAKEAWAY
                        ? "Ta"
                        : "Sd"}
                </span>
              ) : (
                <span
                  className="badge-unavailable"
                  title={type}
                  key={`type-availability-unavailable-${i}-${j}`}
                >
                  {type === DINE_IN
                    ? "Di"
                    : type === HOME_DELIVERY
                      ? "De"
                      : type === TAKEAWAY
                        ? "Ta"
                        : "Sd"}
                </span>
              ))
          ),
        sortable: false,
        width: "100px",
      },
      {
        id: "aggregatorAvailabilityPrices",
        name: "D. Partner",
        selector: (row, i) =>
          DELIVERY_PARTNERS_AVAILABILITIES.map((type, j) =>
            !isEmpty(row.aggregatorAvailabilityPrices) &&
              row.aggregatorAvailabilityPrices.find(
                (data) =>
                  data.menuAvailabilityType.toLowerCase() === type.toLowerCase()
              ) ? (
              <span
                className="badge-available"
                title={type}
                key={`delivery-availability-available-${i}-${j}`}
              >
                {type === ZOMATO ? "Zo" : type === SWIGGY ? "Sw" : ""}
              </span>
            ) : (
              <span
                className="badge-unavailable"
                title={type}
                key={`delivery-availability-available-${i}-${j}`}
              >
                {type === ZOMATO ? "Zo" : type === SWIGGY ? "Sw" : ""}
              </span>
            )
          ),
        sortable: false,
        width: "100px",
      },

      {
        id: "maximumRetailPrice",
        name: "MRP",
        selector: (row) => (
          <>
            {row.maximumRetailPrice.toFixed(2) +
              " " +
              currencyDetail?.currencySymbol}{" "}
          </>
        ),
        sortable: false,
        right: true,
        width: "70px",
      },
      {
        id: "action",
        name: "",
        selector: (row) => getActionButtons(row),
        width: "100px",
        sortable: false,
      },
    ],
    [handleRefresh]
  );

  const getActionButtons = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        {!row.deleted && (
          <button className="listBtn me-2" onClick={() => handleEdit(row)}>
            <i className="bx bx-pencil"></i>
          </button>
        )}
        <div className="dropdown">
          <button
            className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle"
            title="More"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ padding: "5px 10px 8px 10px" }}
          >
            <i className="bx bx-chevron-down"></i>
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            <>
              {row.deleted ? (
                <li onClick={() => handleReviveData(row)}>
                  <i className="bx bx-log-out-circle"></i> Revive
                </li>
              ) : (
                <>
                  <li onClick={() => handleDeleteData(row)}>
                    <i className="bx bx-trash"></i> Delete
                  </li>
                  <li onClick={() => handleActiveInactive(row)}>
                    {row.active ? (
                      <>
                        <i className="bx bx-x-circle"></i> Inactive
                      </>
                    ) : (
                      <>
                        <i className="bx bx-check-circle"></i> Active
                      </>
                    )}
                  </li>
                </>
              )}
            </>
          </ul>
        </div>
      </div>
    );
  };

  const handleEdit = (row) => {
    setRecordID(row.uuid);
    setOwnedItem(row.owned);
    setShowAddUpdateModal(true);
  };

  const handleActiveInactive = (row) => {
    if (row.active) {
      const rUrl = row.owned
        ? DeActivate_Restaurant_Menu_Item_Put
        : DeActivate_Rest_Menu_Restaurant_Menu_Item_Put;
      handleInactive(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
    } else {
      const rUrl = row.owned
        ? Activate_Restaurant_Menu_Item_Put
        : Activate_Rest_Menu_Restaurant_Menu_Item_Put;
      handleActive(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
    }
  };

  //Delete Record
  const handleDeleteData = (row) => {
    const rUrl = row.owned
      ? Delete_Restaurant_Menu_Item_Delete
      : Delete_Rest_Menu_Restaurant_Menu_Item_Delete;
    handleDelete(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
  };

  //Revive Record
  const handleReviveData = (row) => {
    const rUrl = row.owned
      ? Revive_Restaurant_Menu_Item_Put
      : Revive_Rest_Menu_Restaurant_Menu_Item_Put;
    handleRevive(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
  };

  const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

  return (
    <>
      {PageHeading("Food Item List", "Menu", "/")}
      <div className="card">
        <div className="d-flex createButton">
          <CustomButton
            handleClick={() => setShowAddUpdateModal(true)}
            title="Create New"
            iconClass="bx bx-plus"
            buttonClass=""
          />
          <Select
            className="ms-2"
            maxMenuHeight={150}
            styles={customStyles}
            options={availabilitiesOptions}
            value={itemAvailability}
            onChange={(e) => setItemAvailability(e)}
          />
        </div>
        <div className="card-body tableView">
          <PaginationDataTable
            paginationUrl={
              viewDeletedList
                ? Deleted_Restaurant_Menu_Item_List_POST +
                "/" +
                itemAvailability.value
                : All_Restaurant_Menu_Item_List_Post +
                "/" +
                itemAvailability.value
            }
            serverSidePagination={false}
            search={true}
            pagination={false}
            columns={columns}
            filterColumn={"title"}
            filter={true}
          />
          <CheckboxButton
            title="Deleted List"
            handleClick={handleToggleDeletedView}
            additionalClass="deletedBtn ml-auto"
          />
        </div>
      </div>
      {showAddUpdateModal && (
        <RestaurantMenuItemAddEdit
          show={showAddUpdateModal}
          hideModal={() => setShowAddUpdateModal(false)}
          recordID={recordID}
          handleRefresh={handleRefresh}
          categoryKeyValuePairList={categoryKeyValuePairList}
          taxKeyPairList={taxKeyPairList}
          condimentsKeyValuePairs={condimentsKeyValuePairs}
          customizationsKeyValuePairs={customizationsKeyValuePairs}
          timingGroupKeyPairList={timingGroupKeyPairList}
          stickerKeyValuePairList={stickerKeyValuePairList}
          ownedItem={ownedItem}
          isStealDealItem={false}
          printerKeyValuePairs={printerKeyValuePairs}
          comboItemsKeyValuePairList={comboItemsKeyValuePairList}
          setComboItemsKeyValuePairList={setComboItemsKeyValuePairList}
        />
      )}
    </>
  );
};

export default RestaurantItemMaster;
