import { useCallback, useEffect, useMemo, useState } from "react";

import { toast } from "react-hot-toast";

import {
    Activate_Rest_Menu_Restaurant_Category_Put,
    Activate_Restaurant_Category_Put,
    All_Restaurant_Category_List_Post,
    DeActivate_Rest_Menu_Restaurant_Category_Put,
    DeActivate_Restaurant_Category_Put,
    Delete_Rest_Menu_Restaurant_Category_Delete,
    Delete_Restaurant_Category_Delete,
    Deleted_Restaurant_Category_List_POST,
    Get_Restaurant_Printer_Key_Value_List_Post,
    Get_Restaurant_Tax_Key_Value_List_Post,
    Get_Restaurant_Timing_Group_Key_Value_Pair_List_Post,
    Revive_Rest_Menu_Restaurant_Category_Put,
    Revive_Restaurant_Category_Put,
} from "api/ApiConst";
import { callGetApi, callPostApi } from "api/ApiCaller";
import { SECTION_LOADER } from "utils/constants";
import { getImageSrcViaRefId, handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import { API_SUCCESS } from "api/ResponseCodes";

import CheckboxButton from "components/common/CheckboxButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import RestaurantCategoryAddEdit from "./RestaurantCategoryAddEdit";
import dateUtilities from "utils/DateUtilities";

const RestaurantStealDealCategoryMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [timingGroupKeyPairList, setTimingGroupKeyPairList] = useState([]);
    const [categoryTaxKeyPairList, setCategoryTaxKeyPairList] = useState([]);
    const [printerKeyValuePairList, setPrinterKeyValuePairList] = useState([]);
    const [ownedItem, setOwnedItem] = useState(true)

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
            setOwnedItem(true);
        }
    }, [showAddUpdateModal]);

    useEffect(() => {
        getTimingGroupKeyPairList();
        getCategoryTaxKeyPairList();
        getPrinterKeyValuePairList();
    }, []);

    // Get Timing Group Key Pair List
    const getTimingGroupKeyPairList = () => {
        let rUrl = Get_Restaurant_Timing_Group_Key_Value_Pair_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setTimingGroupKeyPairList(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    };

    // Get Category Tax Key Pair List
    const getCategoryTaxKeyPairList = () => {
        let rUrl = Get_Restaurant_Tax_Key_Value_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setCategoryTaxKeyPairList(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    };

    //  Get Printer key Pair List
    const getPrinterKeyValuePairList = () => {
        callGetApi(SECTION_LOADER, Get_Restaurant_Printer_Key_Value_List_Post, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setPrinterKeyValuePairList(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    }

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active" />;
                    } else {
                        return <div className="led-red" title="Inactive" />;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                id: "categoryIconRefId",
                name: "",
                selector: (row, i) => (
                    <div className="list-img" key={row.title + "-" + i}>
                        <img src={getImageSrcViaRefId(row.categoryIconRefId)} alt="category icon" />
                    </div>
                ),
                // width: "70px",
                sortable: false,
            },
            {
                id: "title",
                name: "Title",
                selector: (row) => (<span className="parent-owned-flag">
                    {row.owned && <span className="badge bg-primary owned-flag" title="Owned">O</span>}{""}
                    {row.title} {row.splitBill && <span className={`split-bill ${row.splitBill ? "bg-success" : "bg-danger"}`} title="Split Bill">Splited</span>}
                </span>),
                sortable: false,
                wrap: true,
            },
            {
                id: "createdAt",
                name: "Created Time",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "100px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )}
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setOwnedItem(row.owned)
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            const rUrl = row.owned ? DeActivate_Restaurant_Category_Put : DeActivate_Rest_Menu_Restaurant_Category_Put;
            handleInactive(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            const rUrl = row.owned ? Activate_Restaurant_Category_Put : Activate_Rest_Menu_Restaurant_Category_Put;
            handleActive(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        const rUrl = row.owned ? Delete_Restaurant_Category_Delete : Delete_Rest_Menu_Restaurant_Category_Delete;
        handleDelete(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        const rUrl = row.owned ? Revive_Restaurant_Category_Put : Revive_Rest_Menu_Restaurant_Category_Put;
        handleRevive(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("Steal Deal Category List", "Dashboard", "/")}
            <div className="card">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_Restaurant_Category_List_POST + "/StealDeal" : All_Restaurant_Category_List_Post + "/StealDeal"}
                        serverSidePagination={false}
                        search={true}
                        pagination={false}
                        columns={columns}
                        filterColumn={"title"}
                        filter={true}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showAddUpdateModal && (
                <RestaurantCategoryAddEdit
                    show={showAddUpdateModal}
                    hideModal={() => setShowAddUpdateModal(false)}
                    recordID={recordID}
                    handleRefresh={handleRefresh}
                    categoryTaxKeyPairList={categoryTaxKeyPairList}
                    timingGroupKeyPairList={timingGroupKeyPairList}
                    printerKeyValuePairList={printerKeyValuePairList}
                    ownedItem={ownedItem}
                    isStealDealCategory={true}
                />
            )}
        </>
    );
};

export default RestaurantStealDealCategoryMaster;
