import React, { useState, useEffect } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";

import { Admin_Change_Password_POST, Change_Login_User_Password_Put, Get_Restaurant_Closing_Time_URL, Update_Restaurant_Closing_Time_URL } from "api/ApiConst";
import { callPostApi, callPutApi } from "api/ApiCaller";
import { isAlphaNumercSpecialChar, isEmpty } from "utils/TextUtils";
import { LOGOUT, SHOW_BULK_INVOICE_DOWNLOAD_MODAL } from "reducer/actions";
import ModalWrapper from "./ModalWrapper";
import { MODAL_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";
import Cookies from "universal-cookie";
import ImageSelector from "./ImageSelector";
import { getImageSrcViaRefId } from "utils/UserUtils";
import { store } from "index";

const Header = () => {
    const defaultPasswordObj = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    };

    const { isAuthenticated, user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [changePasswordObj, setChangePasswordObj] = useState(defaultPasswordObj);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [editedClosingTime, setEditedClosingTime] = useState("12-00-AM");
    const [closingTime, setClosingTime] = useState("12-22-AM");
    const userDataCookies = new Cookies().get("user");

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated]);

    useEffect(() => {
        setChangePasswordObj(defaultPasswordObj);
    }, [showChangePasswordModal]);

    useEffect(() => {
        if (user?.userType === "ROLE_RESTAURANT_ADMIN") {
            callPutApi("", Get_Restaurant_Closing_Time_URL, {}, {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const closingTimeInMilliseconds = response.responsePacket;
                    const date = new Date(closingTimeInMilliseconds);
                    const hrs = date.getHours();
                    const minutes = date.getMinutes();

                    const period = hrs >= 12 ? "PM" : "AM";
                    const formattedHrs = hrs % 12 || 12;
                    const formattedClosingTime = `${String(formattedHrs).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;

                    setClosingTime(formattedClosingTime);
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, []);

    const updateClosingTime = () => {

        const value = editedClosingTime;
        const d = new Date();
        const newDate = new Date(`${d.toDateString()} ${value}`);
        const timestamp = newDate.getTime();

        let url = Update_Restaurant_Closing_Time_URL.replaceAll("{restaurantClosingTimeStamp}", timestamp);
        callPutApi(MODAL_LOADER, url, {}, {}, (response) => {
            toast.success(response.message, { id: "toast" });
        });

        // Format time to 12-hour format
        const hrs = newDate.getHours();
        const minutes = newDate.getMinutes();
        const period = hrs >= 12 ? "PM" : "AM";
        const formattedHrs = hrs % 12 || 12;
        const formattedClosingTime = `${String(formattedHrs).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;

        setClosingTime(formattedClosingTime);
    }


    const handleClose = () => {
        setShowChangePasswordModal(false);
    };

    const handleToggleSidebar = () => {
        document.body.classList.toggle("sidebar-enable");
        document.body.classList.toggle("vertical-collpsed");
    };

    const handleTextChange = (e) => {
        let rObj = {
            ...changePasswordObj,
            [e.target.id]: e.target.value,
        };
        setChangePasswordObj(rObj);
    };

    const handleLogoutConfirmation = () => {
        confirmAlert({
            title: "Alert",
            message: "Are sure you want to logout ?",
            overlayClassName: "overlay-custom-class-name",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => dispatch({ type: LOGOUT }),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const changePasswordModal = () => {
        return (
            <ModalWrapper show={showChangePasswordModal} backdrop="static" hide={handleClose} header="Change Password" size="ms" handleSubmit={handleSubmit}>
                <div className="form-group row mb-3">
                    <div className="col-lg-12 my-2">
                        <label>Old Password *</label>
                        <div className="input-group auth-pass-inputgroup">
                            <input
                                type={showOldPassword ? "text" : "password"}
                                maxLength={16}
                                className="form-control input-default"
                                id="oldPassword"
                                autoComplete={false}
                                value={changePasswordObj.oldPassword}
                                onChange={handleTextChange}
                            />
                            <button className="btn btn-light " type="button" id="password-addon" onClick={() => setShowOldPassword(!showOldPassword)}>
                                {showOldPassword ? <i className="mdi mdi-eye-off-outline" /> : <i className="mdi mdi-eye-outline" />}
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6 my-2">
                        <label htmlFor="newPassword">New Password *</label>
                        <div className="input-group auth-pass-inputgroup">
                            <input
                                type={showNewPassword ? "text" : "password"}
                                maxLength={16}
                                className="form-control input-default"
                                id="newPassword"
                                autoComplete={false}
                                value={changePasswordObj.newPassword}
                                onChange={handleTextChange}
                            />
                            <button className="btn btn-light " type="button" id="password-addon" onClick={() => setShowNewPassword(!showNewPassword)}>
                                {showNewPassword ? <i className="mdi mdi-eye-off-outline" /> : <i className="mdi mdi-eye-outline" />}
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 my-2">
                        <label htmlFor="confirmPassword">Confirm Password *</label>
                        <div className="input-group auth-pass-inputgroup">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                maxLength={16}
                                className="form-control"
                                id="confirmPassword"
                                autoComplete={false}
                                value={changePasswordObj.confirmPassword}
                                onChange={handleTextChange}
                            />
                            <button className="btn btn-light " type="button" id="password-addon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                {showConfirmPassword ? <i className="mdi mdi-eye-off-outline" /> : <i className="mdi mdi-eye-outline" />}
                            </button>
                        </div>
                        {changePasswordObj.newPassword !== "" && changePasswordObj.confirmPassword !== "" && changePasswordObj.newPassword !== changePasswordObj.confirmPassword && (
                            <span className="text-danger font-size-12">Passwords do not match</span>
                        )}
                    </div>
                </div>
            </ModalWrapper >
        );
    };

    const handleSubmit = async () => {
        let requestObj = {
            oldPassword: changePasswordObj.oldPassword,
            newPassword: changePasswordObj.newPassword,
            confirmPassword: changePasswordObj.confirmPassword,
        };
        if (isEmpty(requestObj.oldPassword)) {
            toast.error("Old Password must not be empty", { id: "toast" });
            return false;
        } else if (!isAlphaNumercSpecialChar(requestObj.oldPassword)) {
            toast.error("Old Password must contain letters, numbers, or special characters.", { id: "toast" });
            return false;
        } else if (isEmpty(requestObj.newPassword)) {
            toast.error("New Password must not be empty", { id: "toast" });
            return false;
        } else if (changePasswordObj.newPassword.length < 3) {
            toast.error("New password must be more than 2 characters", { id: "toast" });
            return false;
        } else if (!isAlphaNumercSpecialChar(requestObj.newPassword)) {
            toast.error("New Password must contain letters, numbers, or special characters.", { id: "toast" });
            return false;
        } else if (isEmpty(requestObj.confirmPassword)) {
            toast.error("Confirm Password must not be empty", { id: "toast" });
            return false;
        } else if (changePasswordObj.confirmPassword.length < 3) {
            toast.error("Confirm password must be more than 2 characters", { id: "toast" });
            return false;
        } else if (!isAlphaNumercSpecialChar(requestObj.confirmPassword)) {
            toast.error("Confirm Password must contain letters, numbers, or special characters.", { id: "toast" });
            return false;
        } else if (changePasswordObj.newPassword !== changePasswordObj.confirmPassword) {
            toast.error("Passwords do not match", { id: "toast" });
            return false;
        }


        const toastId = toast.loading("Requesting...");
        callPutApi(MODAL_LOADER, Change_Login_User_Password_Put, requestObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                // setShowChangePasswordModal(false);
                handleClose()
                setChangePasswordObj(defaultPasswordObj);
                toast.success(response.message, { id: toastId });
                // dispatch({ type: LOGOUT });
            } else {
                toast.error(response.message, { id: toastId });
            }
        })
    };

    const handleViewProfile = () => {
        if (user?.userType === "ROLE_RESTAURANT_ADMIN") {
            navigate("restaurant-profile");
        }
    }

    // Favicon icon 
    const link = document.querySelector('link[rel="icon"]');
    link.setAttribute('href', getImageSrcViaRefId(userDataCookies?.productIconRefId));
    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    {/* LOGO */}
                    <div className="navbar-brand-box  d-flex align-items-center justify-content-center ">
                        <a href="index.html" className="logo logo-dark">
                            <span className="logo-sm">
                                {userDataCookies.userType === "ROLE_ADMIN" ?
                                    <img src="/assets/images/logo-sm.jpg" alt="" height={22} /> :

                                    <img src={getImageSrcViaRefId(userDataCookies.productIconRefId)
                                    } style={{
                                        marginTop: "7px",
                                        height: "60px",
                                        width: "60px",
                                        // borderRadius: "50%",
                                        overflow: "hidden",
                                        display: "flex",
                                        alignItems: "center",
                                        margin: "0 auto",
                                        objectFit: "scale-down"
                                    }} />

                                }
                            </span>
                            <span className="logo-lg">

                                {userDataCookies.userType === "ROLE_ADMIN" ?
                                    <img src="/assets/images/logo-lg.png" alt="" height={24} /> :
                                    <>

                                        <img src={getImageSrcViaRefId(userDataCookies.productLogoRefId)
                                        } style={{
                                            marginTop: "7px",
                                            height: "60px",
                                            width: "60px",
                                            // borderRadius: "50%",
                                            overflow: "hidden",
                                            display: "flex",
                                            alignItems: "center",
                                            margin: "0 auto",
                                            objectFit: "scale-down"
                                        }} />

                                        {/* // Favicon icon  */}

                                        <img src={getImageSrcViaRefId(userDataCookies.productIconRefId)
                                        } style={{
                                            marginTop: "7px",
                                            height: "60px",
                                            width: "60px",
                                            // borderRadius: "50%",
                                            overflow: "hidden",
                                            display: "none",
                                            alignItems: "center",
                                            margin: "0 auto"
                                        }} />
                                    </>
                                }
                            </span>
                        </a>
                        <a href="index.html" className="logo logo-light">
                            <span className="logo-sm">
                                <img src="/assets/images/logo-sm.jpg" alt="image 3" height={22} />
                            </span>
                            <span className="logo-lg">
                                <img src="/assets/images/logo-lg.png" alt="image 4" height={24} />
                            </span>
                        </a>
                    </div>
                    <div className="d-flex align-items-center ">
                        <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn" onClick={handleToggleSidebar}>
                            <i className="fa fa-fw fa-bars" />
                        </button>
                        {user?.userType === "ROLE_RESTAURANT_ADMIN" &&
                            <>
                                <div className="">
                                    <h5 className="mb-1">Closing Time</h5>
                                    <h6 className="mb-0">{closingTime}</h6>
                                </div>

                                <div className="ropdown d-inline-block">
                                    <button type="button" className="btn bg-primary text-white waves-effect ms-2"
                                        id="page-header-user-dropdown"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    >
                                        <i className="bx bx-pencil"></i>
                                    </button>
                                    <div className="dropdown-menu cls-time-btn p-3">
                                        <div className="mb-2">
                                            <label>Select Closing Time :</label>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <input type="time"
                                                className="me-2 form-control input-default p-2" id="closingTime"
                                                onChange={(e) => { setEditedClosingTime(e.target.value) }} value={editedClosingTime}
                                            />
                                            <button className="dropdown-item bg-primary rounded p-2 text-center text-white  "
                                                onClick={(e) => { updateClosingTime(e) }}>  Update
                                            </button>
                                        </div>

                                        <div className="dropdown-divider m-0" />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
                {user?.userType === "ROLE_RESTAURANT_ADMIN" &&
                    <>
                        {/* <div className="d-flex ">
                            <div className="">
                                <h5>Closing Time</h5>
                                <h6>{closingTime}</h6>
                            </div>
                        </div> */}
                        {/* <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item waves-effect"
                                id="page-header-user-dropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            >
                                <i className="bx bx-pencil">edit</i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <div className="me-5">
                                    <label>Select Time :</label>
                                    <input type="time"
                                        className="form-control input-default p-2" id="closingTime"
                                        onChange={(e) => { setEditedClosingTime(e.target.value) }} value={editedClosingTime}
                                    />
                                </div>
                                <button className="dropdown-item bg-secondary "
                                    onClick={(e) => { updateClosingTime(e) }}>
                                    Update</button>
                                <div className="dropdown-divider m-0" />
                            </div>
                        </div> */}


                        <div className="d-flex gap-2">
                            <div className="">
                                <Link to="/restaurant-pos"
                                    className="btn btn-primary px-3 font-size-16"
                                >Orders</Link>
                            </div>
                            <div className="">
                                <div onClick={() => store.dispatch({type: SHOW_BULK_INVOICE_DOWNLOAD_MODAL})}
                                    className="btn btn-primary px-3 font-size-16"
                                >Bulk Invoice Download</div>
                            </div>
                        </div>
                    </>
                }
                <div className="d-flex">
                    <div className="dropdown d-inline-block d-lg-none ms-2">
                        <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="mdi mdi-magnify" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">
                            <form className="p-3">
                                <div className="form-group m-0">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="submit">
                                                <i className="mdi mdi-magnify" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img className="rounded-circle header-profile-user" src="/assets/images/users/avatar-1.jpg" alt="Header Avatar" />
                            <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                                {user?.fullName}
                            </span>
                            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                            {/* item*/}
                            <button className="dropdown-item py-2" onClick={handleViewProfile} >
                                <i className="bx bx-user font-size-16 align-middle me-1" />
                                <span key="t-profile">Profile</span>
                            </button>
                            <a className="dropdown-item py-2" href="#">
                                <i className="bx bx-wallet font-size-16 align-middle me-1" />
                                <span key="t-my-wallet">My Wallet</span>
                            </a>
                            <button className="dropdown-item py-2" onClick={() => setShowChangePasswordModal(true)}>
                                <i className="bx bx-key font-size-16 align-middle me-1" />
                                <span key="t-my-password">Change Passowrd</span>
                            </button>
                            <div className="dropdown-divider m-0" />
                            <button className="dropdown-item text-danger py-2" onClick={handleLogoutConfirmation}>
                                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                                <span key="t-logout">Logout</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {changePasswordModal()}
        </header>
    );
};

export default Header;
