import { useState } from 'react'

import toast from 'react-hot-toast';
import Select from 'react-select';

import { Make_Order_COmplementary_Head_Put } from 'api/ApiConst';
import { callPutApi } from 'api/ApiCaller';

import { API_SUCCESS } from 'api/ResponseCodes';
import { isEmpty } from 'utils/TextUtils';
import { MODAL_LOADER } from 'utils/constants';
import ModalWrapper from 'components/common/ModalWrapper'

const MarkComplementaryHead = ({ show, hideModal, recordID, complementaryHeadKeyPairList, handleRefreshOrderDtls, handleRefresh }) => {
    const defaultRecordObj = {
        complementaryHeadUuid: "",
        complementaryHeadNote: ""
    }
    const [record, setRecord] = useState(defaultRecordObj);

    //Select change 
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value } = e.target;
        setRecord((prev) => ({ ...prev, [id]: value }));
    };

    const checkInputValidation = (recordObj) => {
        if (isEmpty(recordObj.complementaryHeadUuid)) {
            toast.error("Complementary Title must not be empty", { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.complementaryHeadNote)) {
            toast.error("Complementary Note must not be empty", { id: "toast" });
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        try {
            const recordObj = {
                ...record,
                complementaryHeadUuid: record.complementaryHeadUuid?.key || "",
            }
            if (!checkInputValidation(recordObj)) {
                return;
            }

            callPutApi(MODAL_LOADER, Make_Order_COmplementary_Head_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    toast.success(response.message, {id: "toast"});
                    handleRefreshOrderDtls();
                    handleRefresh();
                    hideModal();
                } else {
                    toast.error(response.message);
                }
            })

        } catch (error) {
            return;
        }
    }

    return (
        <ModalWrapper backdrop="static"  show={show} hide={hideModal} header={"Add Complementary"} size="ms" handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12 my-2">
                    <label>Complementary Head *</label>
                    <Select options={complementaryHeadKeyPairList} value={record.complementaryHeadUuid} onChange={(e) => handleSelectChange(e, "complementaryHeadUuid")} />
                </div>
                <div className="col-lg-12 ">
                    <label>Note *</label>
                    <textarea className="form-control input-default" id="complementaryHeadNote" cols="10" rows="3" onChange={handleTextChange} value={record.complementaryHeadNote} />
                </div>
            </div>
        </ModalWrapper >
    )
}

export default MarkComplementaryHead