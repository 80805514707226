import { updateURL } from 'api/ApiCaller';
import { DOCUMENT_BASE_URL, POS_BACKEND_BASE_URL, POS_FRONTEND_BASE_URL, POS_IMAGE_CLIENT_ID } from 'api/ApiConst';
import Loader from 'components/common/Loader';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';


const Pos = () => {
    const [isFrameLoading, setIsFrameLoading] = useState(true);

    const authToken = new Cookies().get("authToken");
    const navigate = useNavigate();

    useEffect(() => {
        if (!authToken) {
            navigate("/")

        }
        // document.body.style.overflow = "hidden";
        // return () => {
        //     document.body.style.overflow = "auto";
        // }
    }, [authToken])

    const handleFrameLoaded = () => setIsFrameLoading(false)

    const PosIFrameUrl = `${POS_FRONTEND_BASE_URL}/?accessToken=${authToken}&PosBackendBaseUrl=${updateURL(POS_BACKEND_BASE_URL)}&PosImageClientId=${updateURL(POS_IMAGE_CLIENT_ID)}&DocumentBaseUrl=${updateURL(DOCUMENT_BASE_URL)}`;

    return (
        <>
            {isFrameLoading && <Loader />}
            <div style={{ height: "100vh" }}>
                <iframe
                    width="100%"
                    height="100%"
                    // title="POS"
                    id="POS" frameBorder="0"
                    src={PosIFrameUrl}
                    onLoad={handleFrameLoaded}
                >

                </iframe>
            </div>
        </>
    )
}

export default Pos