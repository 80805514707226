import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import { toast } from "react-hot-toast";

import { All_Module_URL_List_POST, Assign_Role_URLs_PUT, Get_Role_ModuleList_POST } from "api/ApiConst";
import { callPostApi, callPutApi } from "api/ApiCaller";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";

const AssignUrls = ({ show, hideModal, recordID, handleRefresh, roleTitle }) => {
    const [moduleUrls, setModuleUrls] = useState([]);

    useEffect(() => {
        // assigned urls list
        callPostApi(MODAL_LOADER, Get_Role_ModuleList_POST.replaceAll("{recordID}", recordID), {}, {}, (response) => {
            let assignUrlsList = response.responsePacket.urlList;

            // default module-urls list
            callPostApi(MODAL_LOADER, All_Module_URL_List_POST, {}, {}, (moduleResponse) => {
                let moduleUrlsList = moduleResponse.responsePacket;
                if (assignUrlsList.length === 0) {
                    let urlResponse = moduleUrlsList.map((data, i) => {
                        data["accessType"] = "";
                        if (data.moduleUrlList.length > 0) {
                            data.moduleUrlList.map((url, j) => {
                                url["isChecked"] = false;
                            });
                        }
                        return data;
                    });
                    setModuleUrls(urlResponse);
                } else {
                    let urlResponse = moduleUrlsList.map((data, i) => {
                        data["accessType"] = "";
                        if (data.moduleUrlList.length > 0) {
                            data.moduleUrlList.map((url, j) => {
                                assignUrlsList.map((assignUrl) => {
                                    if (url.recordId === assignUrl.recordId) {
                                        url["isChecked"] = true;
                                    }
                                });
                                return url;
                            });
                        }
                        return data;
                    });
                    setModuleUrls(urlResponse);
                }
            });
        });
    }, []);


    const viewAccessTypeRadio = (data, i) => {
        return (
            <div className="ps-4">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id={`${i}-READ_ONLY`}
                        checked={data?.accessType === "READ_ONLY" ? true : false}
                        onChange={() => handleUrlAccessType(i, "READ_ONLY")}
                    />
                    <label className="form-check-label" htmlFor={`${i}-READ_ONLY`}>Read</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id={`${i}-READ_WRITE`}
                        checked={data?.accessType === "READ_WRITE" ? true : false}
                        onChange={() => handleUrlAccessType(i, "READ_WRITE")}
                    />
                    <label className="form-check-label" htmlFor={`${i}-READ_WRITE`}>Read-Write</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id={`${i}-READ_WRITE_DELETE`}
                        checked={data.moduleUrlList.length > 0 && data.moduleUrlList.every((url) => url.isChecked === true) ? true : false}
                        onChange={() => handleUrlAccessType(i, "READ_WRITE_DELETE")}
                    />
                    <label className="form-check-label" htmlFor={`${i}-READ_WRITE_DELETE`}>Full Access</label>
                </div>
            </div>
        )
    }

    const handleUrlAccessType = (i, accessType) => {
        let _moduleUrls = [...moduleUrls];
        _moduleUrls[i].accessType = accessType;
        if (accessType === "READ_ONLY") {
            _moduleUrls[i].moduleUrlList.length > 0 && _moduleUrls[i].moduleUrlList.map((url) => (url.accessType == "READ_ONLY" ? (url.isChecked = true) : (url.isChecked = false)));
        } else if (accessType === "READ_WRITE") {
            _moduleUrls[i].moduleUrlList.length > 0 && _moduleUrls[i].moduleUrlList.map((url) => (url.accessType == "READ_WRITE" ? (url.isChecked = true) : (url.isChecked = false)));
        } else {
            _moduleUrls[i].moduleUrlList.length > 0 && _moduleUrls[i].moduleUrlList.map((url) => (url.isChecked = true));
        }
        setModuleUrls(_moduleUrls);
    };

    const handleUrlCheckbox = (e, i, j) => {
        let _moduleUrls = [...moduleUrls];
        _moduleUrls[i].moduleUrlList[j].isChecked = e.target.checked;
        setModuleUrls(_moduleUrls);
    };

    const handleSubmitAssignUrls = () => {
        let recordObj = {
            urlIds: [],
        };
        if (moduleUrls.length > 0) {
            moduleUrls.map((data, i) => {
                if (data.moduleUrlList.length > 0) {
                    data.moduleUrlList.map((url, j) => {
                        if (url.isChecked) {
                            recordObj.urlIds.push(url.recordId);
                        }
                    });
                }
            });
        }
        callPutApi(MODAL_LOADER, Assign_Role_URLs_PUT.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
            toast.success(response.message, {id: "toast"});
            hideModal();
            handleRefresh();
        });
    };

    return (
        <ModalWrapper show={show}  backdrop="static" hide={hideModal} header={`${roleTitle}`} size="lg" dialogClassName="right-modal-dialog" contentClassName="right-modal-content">
            <Button className="assign-urls-btn float-end" onClick={handleSubmitAssignUrls}> Assign Urls</Button>
            {moduleUrls.length > 0 && moduleUrls.map((data, i) => (
                <div className="model-list-url" key={i}>
                    <div className="form-group mb-1 " style={{ fontSize: "20px" }}>{data?.title}</div>
                    <div className="col-lg-12">{viewAccessTypeRadio(data, i)}</div>
                    <div className="col-lg-12 mt-2">
                        {data.moduleUrlList.length > 0 && data.moduleUrlList.map((url, j) => (
                            <div className="custom-control custom-checkbox d-flex align-items-center mb-2 model-url-checkbox" key={url.title + "-" + j}>
                                <input type="checkbox" className="custom-control-input" id={i + "-" + j}
                                    checked={url?.isChecked === undefined ? (url.isChecked = false) : url.isChecked}
                                    onChange={(e) => handleUrlCheckbox(e, i, j)}
                                />
                                <label className="custom-control-label ms-2 mb-0" htmlFor={i + "-" + j}>{url.title}</label>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </ModalWrapper>
    );
};
export default AssignUrls;