import { useCallback, useEffect, useMemo, useState } from "react";

import { toast } from "react-hot-toast";

import {
    Activate_Client_Category_Put,
    All_Client_Category_List_Post,
    DeActivate_Client_Category_Put,
    Delete_Client_Category_Delete,
    Deleted_Client_Category_List_POST,
    Get_Client_Category_Key_Value_List_Post,
    Revive_Client_Category_Put,
} from "api/ApiConst";
import { callPostApi } from "api/ApiCaller";
import { DELIVERY_PARTNERS_AVAILABILITIES, DINE_IN, HOME_DELIVERY, SECTION_LOADER, STEAL_DEAL, SWIGGY, TAKEAWAY, TYPE_AVAILABILITIES, ZOMATO } from "utils/constants";
import { getImageSrcViaRefId, handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import { API_SUCCESS } from "api/ResponseCodes";
import dateUtilities from "utils/DateUtilities";

import CategoryAddEdit from "./CategoryAddEdit";
import CheckboxButton from "components/common/CheckboxButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import Select from "react-select";

const availabilitiesOptions = [
    { label: "All Category", value: "AllCategory" },
    { label: "Dine In Category", value: "DineIn" },
    { label: "Home Delivery Category", value: "HomeDelivery" },
    { label: "Take away Category", value: "TakeAway" },
    // { label: "Steal Deal Category", value: "StealDeal" },
]

const customStyles = {
    control: (provided) => ({
        ...provided,
        width: 190, // Set the desired width
    }),
};

const CategoryMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [itemAvailability, setItemAvailability] = useState({ label: "All Category", value: "AllCategory" });
    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row, i) => {
                    if (row.active) {
                        return <div className="led-green" title="Active" />;
                    } else {
                        return <div className="led-red" title="Inactive" />;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                id: "categoryIconRefId",
                name: "",
                selector: (row, i) => (
                    <div className="list-img" key={"category-icon-" + i}>
                        <img src={getImageSrcViaRefId(row.categoryIconRefId)} alt="category icon" />
                    </div>
                ),
                width: "70px",
                sortable: false,
            },
            {
                id: "title",
                name: "Title",
                selector: (row, i) => <span key={"food-category-" + i}>
                    {row.title}{" "}
                    {row.splitBill && <span className={`split-bill ${row.splitBill ? "bg-success" : "bg-danger"}`} title="Split Bill">Splited</span>}
                </span>,
                sortable: false,
                wrap: true,
            },
            {
                id: "parentCategoryTitle",
                name: "Parent Category",
                selector: (row) => row.parentCategoryTitle,
                wrap: true,
            },
            {
                id: "brandTitle",
                name: "Brand",
                selector: (row) => row.brandTitle,
                sortable: false,
                wrap: true,
            },
            {
                id: "availabilities",
                name: "Availability",
                selector: (row) =>
                    TYPE_AVAILABILITIES.map((type, i) =>
                        (type !== STEAL_DEAL) && (row.availabilities.find((data) => data.toLowerCase() === type.toLowerCase()) ? (
                            <span className="badge-available" title={type} key={"badge-available-" + type + "-" + i}>
                                {type === DINE_IN ? "Di" : type === HOME_DELIVERY ? "De" : type === TAKEAWAY ? "Ta" : "Sd"}
                            </span>
                        ) : (
                            <span className="badge-unavailable" title={type} key={"badge-unavailable-" + type + "-" + i}>
                                {type === DINE_IN ? "Di" : type === HOME_DELIVERY ? "De" : type === TAKEAWAY ? "Ta" : "Sd"}
                            </span>
                        ))
                    ),
                sortable: false,
                width: "120px"
            },
            {
                id: "aggregatorAvailabilities",
                name: "D. Partner",
                selector: (row, i) =>
                    DELIVERY_PARTNERS_AVAILABILITIES.map((type, j) =>
                        row.aggregatorAvailabilities.find((data) => data.toLowerCase() === type.toLowerCase()) ? (
                            <span className="badge-available" title={type} key={type + "-" + i + "-" + j}>
                                {type === ZOMATO ? "Zo" : type === SWIGGY ? "Sw" : ""}
                            </span>
                        ) : (
                            <span className="badge-unavailable" title={type} key={type + "-" + i + "-" + j}>
                                {type === ZOMATO ? "Zo" : type === SWIGGY ? "Sw" : ""}
                            </span>
                        )
                    ),
                sortable: false,
                width: "100px"
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "100px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )}
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_Client_Category_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_Client_Category_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_Client_Category_Delete.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_Client_Category_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("Food Category List", "Dashboard", "/")}
            <div className="card">
                <div className="d-flex createButton" >
                    <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="" />
                    <Select className="ms-2" maxMenuHeight={150} styles={customStyles} options={availabilitiesOptions} value={itemAvailability} onChange={(e) => setItemAvailability(e)} />
                </div>
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_Client_Category_List_POST + "/" + itemAvailability.value : All_Client_Category_List_Post + "/" + itemAvailability.value}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showAddUpdateModal && (
                <CategoryAddEdit
                    show={showAddUpdateModal}
                    hideModal={() => setShowAddUpdateModal(false)}
                    recordID={recordID}
                    handleRefresh={handleRefresh}
                    isStealDealCategory={false}
                />
            )}
        </>
    );
};

export default CategoryMaster;
