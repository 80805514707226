import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
    Activate_Client_DineIn_Cash_Back_Put,
    All_Client_DineIn_Cash_Back_List_Post,
    DeActivate_Client_DineIn_Cash_Back_Put,
    Get_DineIn_Cashback_Qr_Code_Post
} from "api/ApiConst";
import { handleActive, handleInactive } from "utils/UserUtils";
import { DINE_IN, HOME_DELIVERY, TAKEAWAY, TYPE_AVAILABILITIES } from "utils/constants";
import dateUtilities from "utils/DateUtilities";

import CustomButton from "components/common/CustomButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import DineInCashBackAddEdit from "./DineInCashBackAddEdit";
import PageHeading from "components/common/PageHeading";
import QrCodeImage from "components/common/QrCodeImage";

const DineInCashBackMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [brandUuid, setBrandUuid] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setBrandUuid(null);
        }
    }, [showAddUpdateModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active" />;
                    } else {
                        return <div className="led-red" title="Inactive" />;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                id: "brandTitle",
                name: "Brand",
                selector: (row) => row.brandTitle,
                sortable: false,
            },
            {
                id: "title",
                name: "Title",
                selector: (row) => row.title,
                sortable: false,
                wrap: true,
            },
            {
                id: "discountType",
                name: "Discount",
                selector: (row) => row.discountType,
                sortable: false,
                wrap: true,
            },
            {
                id: "minOrderValue",
                name: "Min Order",
                selector: (row) => row.minOrderValue,
                sortable: false,
                wrap: true,
            }, {
                id: "availabilityList",
                name: "Availability",
                selector: (row) =>
                    TYPE_AVAILABILITIES.map((type, i) =>
                        row.availabilityList.find((data) => data.toLowerCase() === type.toLowerCase()) ? (
                            <span className="badge-available" title={type} key={type + "-" + i}>
                                {type === DINE_IN ? "Di" : type === HOME_DELIVERY ? "De" : type === TAKEAWAY ? "Ta" : "Sd"}
                            </span>
                        ) : (
                            <span className="badge-unavailable" title={type} key={type + "-" + i}>
                                {type === DINE_IN ? "Di" : type === HOME_DELIVERY ? "De" : type === TAKEAWAY ? "Ta" : "Sd"}
                            </span>
                        )
                    ),
                sortable: false,
            },
            {
                id: "dateRange",
                name: "Date Range",
                selector: (row) => dateUtilities.formattedDate(row.startDateTimeStamp, "dd/MM/yyyy") + "-" + dateUtilities.formattedDate(row.endDateTimeStamp, "dd/MM/yyyy"),
                sortable: false,
                wrap: true,
            },
            {
                id: "qrCode",
                name: "QR Code",
                selector: (row) => <QrCodeImage rUrl={Get_DineIn_Cashback_Qr_Code_Post.replaceAll("{recordID}", row.uuid)} />,
                sortable: false,
                center: true,
                wrap: true,
            },
            // {
            //     id: "createdAt",
            //     name: "Created Time",
            //     selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
            //     sortable: false,
            // },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                    <i className="bx bx-pencil"></i>
                </button>
                <button className={`listBtn me-2 ${row.active ? "bg-danger" : "bg-success"}`} title={row.active ? "Inactive" : "Active"} onClick={() => handleActiveInactive(row)}>
                    <i className={`bx ${row.active ? "bx-x-circle" : "bx-check-circle"}`}></i>
                </button>
            </div>
        );
    };

    const handleEdit = (row) => {
        setBrandUuid(row.brandUuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_Client_DineIn_Cash_Back_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_Client_DineIn_Cash_Back_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    return (
        <>
            {PageHeading("Dine In Cash Back", "Dashboard", "/")}
            <div className="card searchOnlyBlock">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={All_Client_DineIn_Cash_Back_List_Post}
                        serverSidePagination={true}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                </div>
            </div>
            {showAddUpdateModal && <DineInCashBackAddEdit
                show={showAddUpdateModal}
                hideModal={() => setShowAddUpdateModal(false)}
                brandUuid={brandUuid}
                handleRefresh={handleRefresh}
            />}
        </>
    );
};

export default DineInCashBackMaster