import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { Add_ComplementaryHead_POST, Get_ComplementaryHead_Detail_GET, Update_ComplementaryHead_PUT } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";

const ComplementaryHeadAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const defaultRecordObj = {
        title: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Get_ComplementaryHead_Detail_GET.replaceAll("{recordID}", recordID), {}, (response) => {
                const res = response.responsePacket;
                setRecord(res);
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.title)) {
            toast.error(`Title must not be empty`,{id: "toast"});
            return false;
        } else if (record.title.length > 50) {
            toast.error(`Title must not be more then 50 character.`,{id: "toast"});
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Add_ComplementaryHead_POST, recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(MODAL_LOADER, Update_ComplementaryHead_PUT.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`${recordID ? "Update" : "Add"} Complementary Head`} size="ms" handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Title *</label>
                    <input type="text" className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default ComplementaryHeadAddEdit;
