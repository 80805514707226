import React, { useState } from "react";

import { toast } from "react-hot-toast";

import { isEmpty } from "utils/TextUtils";
import ModalWrapper from "./ModalWrapper";

const ChangePasswordModal = (props) => {
    const [password, setPassword] = useState("");
    const [inputType, setInputType] = useState("text");

    const handleTextChange = (e) => {
        setPassword(e.target.value);
    };

    const toggle = () => {
        props.close();
    };

    const toggleInputType = () => {
        if (inputType === "password") {
            setInputType("text");
        } else {
            setInputType("password");
        }
    };

    const handleSubmitPwd = () => {
        // TODO > Handle Validation
        if (isEmpty(password)) {
            toast.error("Password must not be empty",{id: "toast"});
            return false;
        } else if (password.length > 16) {
            toast.error("Password must not be more then 16 character.",{id: "toast"});
            return false;
        }
        toggle();
        props.handleSubmitPassword(password);
        setPassword("");
    };

    return (
        <>
            <ModalWrapper show={props.show} backdrop="static"   hide={toggle} header="Change Password" handleSubmit={handleSubmitPwd}>
                <div className="form-group row mb-3">
                    <div className="col-lg-12">
                        <label>Password *</label>
                        <div style={{ position: "relative" }}>
                            <input type={inputType} className="form-control input-default" onChange={handleTextChange} value={password} name="rowPWD" />
                            {/* <button className="btn btn-light" type="button" onClick={toggleInputType}
                                    style={{ position: "absolute", right: "0px", top: "0px" }}>
                                    <i className={"mdi " + ((inputType === "text") ? "mdi-eye-off-outline" : "mdi-eye-outline")}></i>
                                </button> */}
                        </div>
                    </div>
                </div>
            </ModalWrapper>
        </>
    );
};

export default ChangePasswordModal;
