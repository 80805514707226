import React, { useEffect, useState } from "react";

import Select from "react-select";
import { toast } from "react-hot-toast";

import { Get_Role_KeyValue_Pair_POST } from "api/ApiConst";
import { callPostApi, callPutApi } from "api/ApiCaller";
import { MODAL_LOADER, SECTION_LOADER } from "utils/constants";
import { API_SUCCESS } from "api/ResponseCodes";

import ModalWrapper from "./ModalWrapper";

const AssignRoles = ({ show, hideModal, recordID, handleRefresh, assignRole, getAssignedRole }) => {
    const [roleOptions, setRoleOptions] = useState([]);
    const [assignRoles, setAssignRoles] = useState({
        roleIds: [],
    });

    useEffect(() => {
        callPostApi(SECTION_LOADER, Get_Role_KeyValue_Pair_POST, {}, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                const options = response.responsePacket.map((country) => ({
                    value: country.key,
                    label: country.value,
                    key: "roleIds",
                }));
                setRoleOptions(options);
            }
        });
    }, []);

    useEffect(() => {
        if (recordID) {
            callPostApi(SECTION_LOADER, getAssignedRole.replaceAll("{recordID}", recordID), {}, {}, (response) => {
                const options = response.responsePacket.map((country) => ({
                    value: country.key,
                    label: country.value,
                    key: "roleIds",
                }));
                setAssignRoles({ roleIds: options });
            });
        }
    }, [recordID]);

    const handleSubmit = () => {
        let recordObj = assignRoles;
        assignRoles.roleIds = assignRoles.roleIds.map((roleId) => roleId.value);
        callPutApi(MODAL_LOADER, assignRole.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
            toast.success(response.message , {id: "toast"});
            hideModal();
            handleRefresh();
        });
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static" header="Assign Role" handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Roles *</label>
                    <Select options={roleOptions} isMulti={true} value={assignRoles.roleIds} onChange={(e) => setAssignRoles({ roleIds: e })} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default AssignRoles;
