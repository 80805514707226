import React, { useState, useEffect, memo } from "react";

import DataTable from "react-data-table-component";

import { callPostApi } from "api/ApiCaller";
import { SECTION_LOADER, MODAL_LOADER } from "utils/constants";
import toast from "react-hot-toast";

const PaginationDataTable = (props) => {
  // npm i react-data-table-component
  // npm i styled-components

  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  

  useEffect(() => {
    var jsonRequest = {
      pageNumber: currentPage - 1,
      pageSize: perPage,
      search: filterText,
      // ...props.filter,
    };
    if (props.filter && props.filter !== undefined && props.filter !== "") {
      jsonRequest = { ...jsonRequest, ...props.filter };
  }
    callPostApi(
      props.modalLoader ? MODAL_LOADER : SECTION_LOADER,
      props.paginationUrl,
      jsonRequest,
      {},
      (response) => {
        if (props.serverSidePagination) {
          setData(response.responsePacket.data);
          setTotalRows(response.responsePacket.totalItems);
        } else {
          const res = response.responsePacket;
          if (props.filter) {
            const filteredItems = res.filter(
              (item) =>
                item.title &&
                item.title.toLowerCase().includes(filterText.toLowerCase())
            );
            setData(filteredItems);
          } else {
            setData(res);
          }
          setTotalRows(response.responsePacket.length);
        }
      },
      (error) => {
      }
    );
  }, [
    props.paginationUrl,
    props.serverSidePagination,
    props.columns,
    perPage,
    currentPage,
    filterText,
  ]);

  const handleChange = (e) => {
    setCurrentPage(1);
    setFilterText(e.target.value);
  };
  
  const handleSearchEmptyValue = () => {
    if(filterText.length === 0) {
      toast.error('Please enter a search item',{id: "toast"})
  }
}

  const subHeaderComponentMemo = () => {
    return (
      <div className="custom-table-header">
        <div className="input-group mb-3 paginationSearchInput">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="bx bx-search" onClick={handleSearchEmptyValue}></i>
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={filterText}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };

  const customStyles = {
    headCells: {
      style: {
        color: "#000000de", // override the cell padding for head cells
        // fontWeight: "600",
        fontSize: "14px",
      },
    },
  };

  return props.serverSidePagination ? (
    <DataTable
      className="custom-data-table"
      title=""
      subHeader={props.search}
      subHeaderComponent={props.search && subHeaderComponentMemo()}
      columns={props.columns}
      data={data}
      // progressPending={loading}
      // progressComponent={<Loader />}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      onChangeRowsPerPage={(e) => setPerPage(e)}
      onChangePage={(e) => setCurrentPage(e)}
      customStyles={customStyles}
      /* selectableRows onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)} */
    />
  ) : (
    <DataTable
      className="custom-data-table"
      title=""
      columns={props.columns}
      data={data}
      pagination={props.pagination}
      subHeader={props.search}
      subHeaderComponent={props.search && subHeaderComponentMemo()}
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      paginationResetDefaultPage={resetPaginationToggle}
      // progressPending={loading}
      // progressComponent={<Loader />}
      /* selectableRows onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)} */
    />
  );
};

export default memo(PaginationDataTable);
