import { useState } from "react";

import { toast } from "react-hot-toast";
import Select from "react-select";

import { Move_Client_Restaurant_Employee_Movement_Put } from "api/ApiConst";
import { callPutApi } from "api/ApiCaller";
import { isEmpty } from "utils/TextUtils";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";

const MoveEmployee = ({ show, hideModal, recordID, handleRefresh, restaurantDetails, restaurantKeyValuePairs }) => {

    const defaultRecordObj = {
        restaurantUuid: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);

    //Text Change Event
    const handleTextChange = (e) => {
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    //Select Change Event
    const handleSelectChange = (e, key) => {
        setRecord((prev) => ({ ...prev, [key]: e }));
    };

    // Filter Item Options // *remove selected options for others
    const filterOptions = (options) => {
        // Filter out the selected options from the options array
        return options.filter(option => option.key !== restaurantDetails.restaurantUuid)
    };

    const checkInputValidation = () => {
        if (isEmpty(record.restaurantUuid)) {
            toast.error(`Restaurant must not be empty`,{id: "toast"});
            return false;
        }
        return true;
    };

    //Submit Avatar Form
    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = {
                ...record,
                restaurantUuid: record.restaurantUuid?.key || "",
            };
            callPutApi(MODAL_LOADER, Move_Client_Restaurant_Employee_Movement_Put.replaceAll("{recordID}", recordID + "/" + recordObj.restaurantUuid), recordObj, {}, (response) => {
                toast.success(response.message, {id: "toast"});
                hideModal();
                handleRefresh();
            });
        } catch (error) {
            return;
        }
    };


    return (
        <ModalWrapper show={show} hide={hideModal}  backdrop="static" header={`Move Employee`} size="ms" handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-6">
                    <label>Form Restaurant *</label>
                    <input type="text" className="form-control input-default" id="title" onChange={handleTextChange} value={restaurantDetails?.restaurantTitle} disabled />
                </div>
                <div className="col-lg-6">
                    <label>To Restaurant *</label>
                    <Select options={filterOptions(restaurantKeyValuePairs)} value={record.restaurantUuid} onChange={(e) => handleSelectChange(e, "restaurantUuid")} />
                </div>
            </div>
        </ModalWrapper>
    )
}

export default MoveEmployee