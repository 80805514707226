import React, { useState } from "react";
import LeftMenu from "../common/LeftMenu";
import Header from "../common/Header";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../common/Loader";
import ModalWrapper from "components/common/ModalWrapper";
import { store } from "index";
import { HIDE_BULK_INVOICE_DOWNLOAD_MODAL } from "reducer/actions";
import { callGetApi, callGetApiForPDFDownload } from "api/ApiCaller";
import { GET_BULK_INVOICE_DOWNLOAD_GET } from "api/ApiConst";

const Dashboard = () => {
    const { showPageLoader, showSectionLoader, bulkInvoiceDownloadModal } = useSelector((state) => state.utility);
    const [selectedDate, setSelectedDate] = useState({
        startDate: "",
        endDate: ""
    })
    const handleSubmit = () => {
        const selectedStartDate = new Date(selectedDate.startDate).getTime();
        const selectedEndDate = new Date(selectedDate.endDate).getTime();
        callGetApiForPDFDownload("", GET_BULK_INVOICE_DOWNLOAD_GET.replaceAll("{startDate}", selectedStartDate).replaceAll("{endDate}", selectedEndDate), {}, (response) => {
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            const downloadLink = document.createElement('a');
            downloadLink.href = pdfUrl;
            downloadLink.download = 'downloaded_pdf.pdf'; // Specify the filename for download
            document.body.appendChild(downloadLink);
            
            // Trigger click event on the link to start download
            downloadLink.click();
            
            // Clean up: Remove the temporary URL and link element
            URL.revokeObjectURL(pdfUrl);
            document.body.removeChild(downloadLink);
            store.dispatch({type: HIDE_BULK_INVOICE_DOWNLOAD_MODAL});
        })
        // console.log(new Date(selectedDate.startDate).getTime());
    }

    return (
        <>
            {showPageLoader && <Loader />}
            <div id="layout-wrapper">
                <Header />
                <LeftMenu />
                <div className="main-content">
                    {showSectionLoader && <Loader />}
                    <div className="page-content">
                        <div className="container-fluid">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
            <ModalWrapper show={bulkInvoiceDownloadModal} hide={() => store.dispatch({type: HIDE_BULK_INVOICE_DOWNLOAD_MODAL})} backdrop="static" header="Bulk Invoice Download" handleSubmit={handleSubmit}>
                <div className="d-flex justify-content-around ">
                    <div className="d-flex align-items-center me-3   ">
                            <div className="me-2">
                                <label>Start Date:</label>
                            </div>
                            <div className="">
                                <input
                                    type="date"
                                    className="form-control ms-1"
                                    id="startDateTimeStamp"
                                    onChange={(e) => setSelectedDate((prev) => ({
                                        ...prev,
                                        startDate: e.target.value
                                    }))}
                                    // value={record.startDateTimeStamp}
                                />
                            </div>
                            </div>
                    <div className="d-flex align-items-center me-3  ">
                                <div className="me-2">
                                    <label>End Date:</label>
                                </div>
                                <div className="">
                                    <input
                                        type="date"
                                        className="form-control ms-1"
                                        id="endDateTimeStamp"
                                        onChange={(e) => setSelectedDate((prev) => ({
                                            ...prev,
                                            endDate: e.target.value
                                        }))}
                                    />
                            </div>
                    </div>
                </div>
            </ModalWrapper>
        </>
    );
};

export default Dashboard;
