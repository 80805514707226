import { useCallback, useEffect, useMemo, useState } from "react";

import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import {
  All_Order_List_Post,
  Key_Value_List_Restaurant_Aggregator_POST,
  Order_Complementary_Head_Key_Pair_List_Post,
  Remove_Coupon_Code_Put,
  Update_Order_Status_Put,
} from "api/ApiConst";
import { callPostApi, callPutApi } from "api/ApiCaller";

import { capitalizeText, getOrderedCustomerDetails, insertSpaceInKeyDirect } from "utils/TextUtils";
import { API_SUCCESS } from "api/ResponseCodes";
import dateUtilities from "utils/DateUtilities";
import {
  ORDER_PAYMENT_STATUS_COD,
  ORDER_PAYMENT_STATUS_PrePaid,
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_ORDERED,
  ORDER_STATUS_PREPARING,
  ORDER_STATUS_READY,
  ORDER_STATUS_REJECTED,
  ORDER_STATUS_SERVED,
} from "utils/constants";
import { handleUpdateOrderStatus } from "utils/UserUtils";

import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import OrderShortDetails from "../common/OrderShortDetails";
import ApplyNewCoupon from "../common/ApplyNewCoupon";
import MarkComplementaryHead from "../common/MarkComplementaryHead";
import SettleBill from "../common/SettleBill";
import Cookies from "universal-cookie";

const DineInOrderMaster = () => {
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [recordID, setRecordID] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showApplyCouponModal, setShowApplyCouponModal] = useState(false);
  const [showComplementaryModal, setShowComplementaryModal] = useState(false);
  const [showSettleBillModal, setShowSettleBillModal] = useState(false);
  const [complementaryHeadKeyPairList, setComplementaryHeadKeyPairList] =
    useState([]);
  const [refreshOrderDtls, setRefreshOrderDtls] = useState(0);
  const [selectedOrderDtls, setSelectedOrderDtls] = useState(null);
  const [aggregatorOptionsList, setAggregatorOptionsList] = useState([]);

  const currencyDetail = new Cookies().get("currencyDetail");
  useEffect(() => {
    if (
      !showDetailsModal &&
      !showApplyCouponModal &&
      !showComplementaryModal &&
      !showSettleBillModal
    ) {
      setRecordID(null);
      setSelectedOrderDtls(null);
      setRefreshOrderDtls(0);
    }
  }, [
    showDetailsModal,
    showApplyCouponModal,
    showComplementaryModal,
    showSettleBillModal,
  ]);
  useEffect(() => {
    // get Complementary head key-Pair list
    callPostApi(
      null,
      Order_Complementary_Head_Key_Pair_List_Post,
      {},
      {},
      (response) => {
        if (response.errorCode === API_SUCCESS) {
          const res = response.responsePacket;
          const options = res.map((data) => ({
            key: data.uuid,
            value: data.uuid,
            label: data.title + (data.owned ? " (own)" : ""),
          }));
          setComplementaryHeadKeyPairList(options);
        }
      }
    );
    getAggregatorKeyValuePairList();
  }, []);

  const getAggregatorKeyValuePairList = () => {
    const rUrl = Key_Value_List_Restaurant_Aggregator_POST;
    const rObj = {};
    callPostApi("", rUrl, rObj, {}, response => {
      if (response.errorCode === API_SUCCESS) {
        const res = response.responsePacket;
        const options = res.map((data) => ({
          key: data.key,
          value: data.value,
          label: data.label,
        }));
        setAggregatorOptionsList(options);
      } else {
        toast.error(response.message, { id: "toast" });
      }
    })
  }

  const handleRefresh = useCallback(() => {
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag]);

  const handleRefreshOrderDtls = useCallback(() => {
    setRefreshOrderDtls(refreshOrderDtls + 1);
  }, [refreshOrderDtls]);

  const columns = useMemo(
    () => [
      {
        id: "status",
        name: "",
        selector: (row) => (
          <div
            className={row.active ? "led-green" : "led-red"}
            title={row.active ? "Active" : "Inactive"}
          />
        ),
        width: "60px",
        sortable: false,
      },
      {
        id: "orderId",
        name: "Invoice No.",
        selector: (row) => (
          <Link to={`/restaurant-order-details/${row.uuid}`}>
            {row?.restaurantOrderNumber && row?.restaurantOrderNumber ?
              row?.restaurantOrderNumber : row.orderId}
          </Link>
        ),
        sortable: false,
      },
      {
        id: "customer",
        name: "Customer Details",
        selector: (row) => getOrderedCustomerDetails(row),
        sortable: false,
        grow: 2,
        wrap: true
      },
      {
        id: "tableNumber",
        name: "Table Number",
        selector: (row) => row?.tableNumber,
        sortable: false,
        right: true,
        wrap: 2,
      },
      {
        id: "createdAt",
        name: "Order Time",
        selector: (row) =>
          dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
        sortable: false,
      },
      {
        id: "orderTotal",
        name: "Total Amount",
        selector: (row) =>
          row.orderTotal.toFixed(2) + " " + currencyDetail?.currencySymbol,
        sortable: false,
        right: true,
      },
      {
        id: "orderStatus",
        name: "Status",
        selector: (row) => insertSpaceInKeyDirect(row.orderStatus),
        sortable: false,
        center: true,
      },
      {
        id: "action",
        name: "",
        selector: (row) => getActionButtons(row),
        // width: "80px",
        sortable: false,
      },
    ],
    [handleRefresh]
  );

  // Action Button
  const getActionButtons = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        {!row.deleted && (
          <button
            className="listBtn me-2"
            onClick={() => handleViewDetails(row)}
          >
            <i className="mdi mdi-eye-outline" />
          </button>
        )}
        {getOrderStatusUpdateBtn(row)}
      </div>
    );
  };

  // Get Order Status Update Buttons
  const getOrderStatusUpdateBtn = (row) => {
    const orderStatus = row.orderStatus.toUpperCase();
    if (orderStatus === ORDER_STATUS_ORDERED) {
      return (
        <>
          <button
            className="listBtn me-2 bg-success"
            title="Accept Order"
            onClick={() => handleOrderStatus(ORDER_STATUS_ACCEPTED, row)}
          >
            <i className="bx bx-check" />
          </button>
          <button
            className="listBtn bg-danger text-white"
            title="Reject Order"
            onClick={() => handleOrderStatus(ORDER_STATUS_REJECTED, row)}
          >
            <i className="bx bx-x" />
          </button>
        </>
      );
    } else if (orderStatus === ORDER_STATUS_ACCEPTED) {
      return (
        <button
          className="listBtn me-2 bg-success"
          title="Prepare Order"
          onClick={() => handleOrderStatus(ORDER_STATUS_PREPARING, row)}
        >
          <i className="bx bx-check" />
        </button>
      );
    } else if (orderStatus === ORDER_STATUS_PREPARING) {
      return (
        <button
          className="listBtn me-2 bg-info"
          title="Ready Order"
          onClick={() => handleOrderStatus(ORDER_STATUS_READY, row)}
        >
          <i className="bx bx-check" />
        </button>
      );
    } else if (
      orderStatus === ORDER_STATUS_READY &&
      row.paymentStatus === ORDER_PAYMENT_STATUS_COD
    ) {
      return (
        <button
          className="listBtn me-2 bg-info"
          title="Serve Order"
          onClick={() => handleOrderStatus(ORDER_STATUS_SERVED, row)}
        >
          <i className="bx bx-check" />
        </button>
      );
    } else if (
      orderStatus === ORDER_STATUS_READY &&
      row.paymentStatus === ORDER_PAYMENT_STATUS_PrePaid
    ) {
      return (
        <button
          className="listBtn me-2 bg-info"
          title={`Deliver Order || ${row.paymentStatus}`}
          onClick={() => handleOrderStatus(ORDER_STATUS_DELIVERED, row)}
        >
          <i className="bx bx-check" />
        </button>
      );
    } else if (
      orderStatus === ORDER_STATUS_SERVED &&
      row.paymentStatus === ORDER_PAYMENT_STATUS_COD
    ) {
      return (
        <button
          className="listBtn me-2 bg-info"
          title={`Deliver Order || ${row.paymentStatus}`}
          onClick={() => {
            toggleSettleBillModal()
            setRecordID(row.uuid)
            // handleOrderStatus(ORDER_STATUS_DELIVERED, row)
          }}
        >
          <i className="bx bx-check" />
        </button>
      );
    }
  };

  // Update Order Status(Accept | Reject | Preparing | Ready | Served)
  const handleOrderStatus = (orderStatus, row) => {

    const rUrl = Update_Order_Status_Put.replaceAll(
      "{recordID}",
      row.uuid
    ).replaceAll("{orderStatus}", capitalizeText(orderStatus));
    handleUpdateOrderStatus(rUrl, handleRefresh);
  };

  // view order details
  const handleViewDetails = (row) => {
    setRecordID(row.uuid);
    setShowDetailsModal(true);
    // getOrderCouponCodeList(row.uuid);
  };

  // Toggle Apply New Coupon Modal Box
  const toggleApplyCouponModal = () => {
    // setShowDetailsModal(!showDetailsModal);
    setShowApplyCouponModal(!showApplyCouponModal);
  };

  // Toggle Complementary Head Modal Box
  const toggleShowComplementaryHeadModal = () => {
    setShowDetailsModal(false);
    setShowComplementaryModal(!showComplementaryModal);
  };

  // Toggle Settle Bill Modal Box
  const toggleSettleBillModal = () => {
    setShowDetailsModal(false);
    setShowSettleBillModal(!showSettleBillModal);
    handleRefresh();
  };

  // Remove Apply Coupon
  const handleRemoveApplyCoupon = () => {
    return new Promise((resolve, reject) => {
      callPutApi(
        null,
        Remove_Coupon_Code_Put.replaceAll("{recordID}", recordID),
        {},
        {},
        (response) => {
          if (response.errorCode === API_SUCCESS) {
            resolve(true);
            handleRefreshOrderDtls();
            toast.success(response.message, { id: "toast" });
          } else {
            resolve(false);
            toast.error(response.message, { id: "toast" });
          }
        },
        (error) => resolve(false)
      );
    });
  };

  return (
    <>
      {PageHeading("DineIn Order List", "Dashboard", "/")}
      <div className="card searchOnlyBlock">
        <div className="card-body tableView">
          <PaginationDataTable
            paginationUrl={All_Order_List_Post.replaceAll(
              "{orderType}",
              "DineIn"
            )}
            serverSidePagination={true}
            search={true}
            pagination={false}
            columns={columns}
          />
        </div>
      </div>
      {showDetailsModal && (
        <OrderShortDetails
          show={showDetailsModal}
          hideModal={() => setShowDetailsModal(false)}
          recordID={recordID}
          toggleApplyCouponModal={toggleApplyCouponModal}
          toggleShowComplementaryHeadModal={toggleShowComplementaryHeadModal}
          toggleSettleBillModal={toggleSettleBillModal}
          handleRemoveApplyCoupon={handleRemoveApplyCoupon}
          handleRefreshOrderDtls={handleRefreshOrderDtls}
          refreshOrderDtls={refreshOrderDtls}
          handleRefresh={handleRefresh}
          setSelectedOrderDtls={setSelectedOrderDtls}
        />
      )}
      {showApplyCouponModal && (
        <ApplyNewCoupon
          show={showApplyCouponModal}
          hideModal={toggleApplyCouponModal}
          recordID={recordID}
          handleRemoveApplyCoupon={handleRemoveApplyCoupon}
          handleRefreshOrderDtls={handleRefreshOrderDtls}
          handleRefresh={handleRefresh}
        />
      )}
      {showComplementaryModal && (
        <MarkComplementaryHead
          show={showComplementaryModal}
          hideModal={toggleShowComplementaryHeadModal}
          recordID={recordID}
          complementaryHeadKeyPairList={complementaryHeadKeyPairList}
          handleRefreshOrderDtls={handleRefreshOrderDtls}
          handleRefresh={handleRefresh}
        />
      )}
      {showSettleBillModal && (
        <SettleBill
          show={showSettleBillModal}
          hideModal={toggleSettleBillModal}
          recordID={recordID}
          handleRefresh={handleRefresh}
          selectedOrderDtls={selectedOrderDtls}
          handleRefreshOrderDtls={handleRefreshOrderDtls}
          aggregatorOptionsList={aggregatorOptionsList}
        />
      )}
    </>
  );
};

export default DineInOrderMaster;
