import React from 'react'

import ImageSelector from 'components/common/ImageSelector';

const RestaurantMultiImageSelector = (props) => {
    const { selectedImage, index, handleImageSelect, handleRemoveBannerImage, owned = true, remove = true } = props;

    const handleImageChange = (imageRefId) => {
        handleImageSelect(imageRefId, index)
    };
    //ToDo >> Image loader
    return (
        <div className="restaurant-images p-2 card border mb-0 col-4">
            {/* {index !== 0 && <span className="img-delete-btn" onClick={() => handleRemoveBannerImage(index)}>x</span>} */}
            {remove && <span className="img-delete-btn" onClick={() => handleRemoveBannerImage(index)}>x</span>}
            <ImageSelector
                style={{
                    height: "40px",
                    width: "100%",
                    // borderRadius: "50%",
                    overflow: "hidden",
                }}
                handleImageChange={handleImageChange}
                selectedImage={selectedImage}
                owned={owned}
            />
        </div>
    )
}

export default RestaurantMultiImageSelector