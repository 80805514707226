import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import Select from "react-select";

import { Add_LocalState_POST, Get_LocalState_Detail_GET, Update_LocalState_PUT } from "api/ApiConst";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import { MODAL_LOADER } from "utils/constants";

import ModalWrapper from "components/common/ModalWrapper";
import { isChar, isEmpty } from "utils/TextUtils";

const LocalStateAddEdit = ({ show, hideModal, recordID, handleRefresh, countryOptionsList }) => {
    const defaultRecordObj = {
        title: "",
        countryUuid: "",
    };
    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Get_LocalState_Detail_GET.replaceAll("{recordID}", recordID), {}, (response) => {
                const res = response.responsePacket;
                res.countryUuid = countryOptionsList.find((country) => country.value === res.countryUuid);
                setRecord(res);
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, type, value } = e.target;

        if( id === "title" ){
            if( !isChar(value)  && value.length !== 0){
                return;
            }
        }
        setRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    // Option Select Event
    const handleSelect = (e) => {
        setRecord((prev) => ({ ...prev, [e.key]: e }));
    };

    const checkInputValidation = () => {
        if (isEmpty(record.countryUuid)) {
            toast.error(`Country must not be empty`,{id: "toast"});
            return false;
        } else if (isEmpty(record.title)) {
            toast.error(`State name must not be empty`,{id: "toast"});
            return false;
        } else if ( record.title.length <= 3) {
            toast.error('State name must be more than 3 characters',{id: "toast"});
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        try {
            if (!checkInputValidation()) {
                return;
            }
            let recordObj = record;
            recordObj.countryUuid = recordObj.countryUuid.value;
            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Add_LocalState_POST, recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            } else {
                callPutApi(MODAL_LOADER, Update_LocalState_PUT.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    toast.success(response.message, {id: "toast"});
                    hideModal();
                    handleRefresh();
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`${recordID ? "Update" : "Add"} State`} size="ms" handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Select Country *</label>
                    <Select options={countryOptionsList} onChange={handleSelect} value={record.countryUuid} />
                    {/* <input type="text" className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} /> */}
                </div>
                <div className="col-lg-12">
                    <label>State Name *</label>
                    <input type="text" className="form-control input-default"
                    maxLength={20}
                     id="title" onChange={handleTextChange} value={record.title} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default LocalStateAddEdit;
