export const PAGE_LOADER = "PAGE_LOADER";
export const SECTION_LOADER = "SECTION_LOADER";
export const MODAL_LOADER = "MODAL_LOADER";

export const ADMIN_LOGIN = "Admin";
export const CLIENT_LOGIN = "Client";
export const RESTAURANT_LOGIN = "Restaurant";

export const TAKEAWAY = "TakeAway";
export const HOME_DELIVERY = "HomeDelivery";
export const DINE_IN = "DineIn";
export const STEAL_DEAL = "StealDeal";
export const RESERVATION = "Reservation";
export const EVENT_LIST = "EventList";

export const ZOMATO = "Zomato";
export const SWIGGY = "Swiggy";

export const TYPE_AVAILABILITIES = [
  "DineIn",
  "HomeDelivery",
  "TakeAway",
  "StealDeal",
];
export const DELIVERY_PARTNERS_AVAILABILITIES = ["Zomato", "Swiggy"];

export const DOLLAR_SYMBOL = "$";
export const QUANTITY_SYMBOL = "pcs.";
export const tablePriceCellStyle = {
  textAlign: "end",
};

export const defaultCouponObj = {
  couponCode: null,
  couponDiscountAmount: 0,
  taxDiscountAmount: 0,
};

export const ORDER_STATUS_ORDERED = "ORDERED";
export const ORDER_STATUS_ACCEPTED = "ACCEPTED";
export const ORDER_STATUS_PREPARING = "PREPARING";
export const ORDER_STATUS_READY = "READY";
export const ORDER_STATUS_SERVED = "SERVED";
export const ORDER_STATUS_REJECTED = "REJECTED";
export const ORDER_STATUS_DELIVERED = "DELIVERED";
export const ORDER_STATUS_DISPATCHED = "DISPATCHED";

export const ORDER_PAYMENT_STATUS_COD = "CashOnDelivery";
export const ORDER_PAYMENT_STATUS_PrePaid = "Prepaid";

export const ORDER_POINT_OF_SELL_CUSTOMER_WEB = "CustomerWeb";
export const ORDER_POINT_OF_SELL_CUSTOMER_APP = "CustomerApp";
export const ORDER_POINT_OF_SELL_POS_WEB = "PosWeb";
export const ORDER_POINT_OF_SELL_POS_APP = "PosApp";

export const ORDER_ITEM_STATUS_PENDING = "Pending";
export const ORDER_ITEM_STATUS_ACCEPTED = "Accepted";
export const ORDER_ITEM_STATUS_READY = "Ready";

export const ORDER_COUNT_DELAY = 1000 * 60 * 5;