import React, { useCallback, useEffect, useMemo, useState } from "react";

import PaginationDataTable from "components/common/PaginationDataTable";
import { All_Currency_List_POST } from "api/ApiConst";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import CurrencyAddEdit from "./CurrencyAddEdit";

const CurrencyMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "currencyTitle",
                name: "Title",
                selector: (row) => row.currencyTitle,
                sortable: false,
            },
            {
                id: "currencySubUnitTitle",
                name: "Sub Title",
                selector: (row) => row?.currencySubUnitTitle,
                sortable: false,
            },
            {
                id: "currencyCode",
                name: "Currency Code",
                selector: (row) => row?.currencyCode,
                sortable: false,
            },
            {
                id: "currencySymbol",
                name: "Currency Symbol",
                selector: (row) => row?.currencySymbol,
                sortable: false,
            },
            {
                id: "unitSubdivision",
                name: "Unit Sub Division",
                selector: (row) => row?.unitSubdivision,
                sortable: false,
            },
            // {
            //     id: "action",
            //     name: "",
            //     selector: (row) => getActionButtons(row),
            //     width: "80px",
            //     sortable: false,
            // },
        ],
        [handleRefresh]
    );

    return (
        <>
            {PageHeading("Currency List", "Dashboard", "/")}
            <div className="card searchOnlyBlock">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable paginationUrl={All_Currency_List_POST} serverSidePagination={false} search={true} pagination={false} columns={columns} />
                </div>
            </div>
            {showAddUpdateModal && <CurrencyAddEdit show={showAddUpdateModal} hideModal={() => setShowAddUpdateModal(false)} recordID={recordID} handleRefresh={handleRefresh} />}
        </>
    );
};

export default CurrencyMaster;
