import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
    Activate_Restaurant_Printer_Put,
    All_Restaurant_Printer_List_Post,
    DeActivate_Restaurant_Printer_Put,
    Delete_Restaurant_Printer_Delete,
    Deleted_Restaurant_Printer_List_POST,
    Get_Restaurant_Printer_Key_Value_List_Post,
    Mark_Restaurant_Default_Printer_PUT,
    Revive_Restaurant_Printer_Put,
} from "api/ApiConst";
import { handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import dateUtilities from "utils/DateUtilities";

import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import PaginationDataTable from "components/common/PaginationDataTable";
import RestaurantPrinterAddEdit from "./RestaurantPrinterAddEdit";
import { MODAL_LOADER, PAGE_LOADER } from "utils/constants";
import { callGetApi, callPutApi } from "api/ApiCaller";
import { toast } from "react-hot-toast";
import { API_SUCCESS } from "api/ResponseCodes";
import ModalWrapper from "components/common/ModalWrapper";
import Select from "react-select";

export const RestaurantPrinterMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [printerKeyValuePairList, setPrinterKeyValuePairList] = useState(null);
    const [selectedDefaultPrinter, setSelectedDefaultPrinter] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [showSetDefaultPrinterModal, setShowSetDefaultPrinterModal] = useState(false);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
        }
    }, [showAddUpdateModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    useEffect(() => {
        callGetApi(PAGE_LOADER, Get_Restaurant_Printer_Key_Value_List_Post, {}, (response) => {
            setPrinterKeyValuePairList(response.responsePacket);
        });
    }, []);

    const columns = useMemo(
        () => [
            {
                id: "Status",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active" />;
                    } else {
                        return <div className="led-red" title="Inactive" />;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                id: "name",
                name: "Name",
                selector: (row) => (
                    <span className="parent-owned-flag">
                        {row.defaultPrinter && (
                            <span className="badge bg-primary owned-flag" title="Default Printer">
                                D
                            </span>
                        )}{" "} {row.name}

                    </span>
                ),
                sortable: false,
            },
            {
                id: "createdAt",
                name: "Created Time",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy, hh:mm aa"),
                sortable: false,
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "120px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )}
                <button className={`listBtn me-2 ${row.active ? "bg-danger" : "bg-success"}`} title={row.active ? "Inactive" : "Active"} onClick={() => handleActiveInactive(row)}>
                    <i className={`bx ${row.active ? "bx-x-circle" : "bx-check-circle"}`} />
                </button>
                {/* <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div> */}
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(DeActivate_Restaurant_Printer_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            handleActive(Activate_Restaurant_Printer_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        handleDelete(Delete_Restaurant_Printer_Delete.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        handleRevive(Revive_Restaurant_Printer_Put.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    const handleSubmitMarkDefaultPrinter = () => {
        if (selectedDefaultPrinter.value === "") {
            toast.error(`Please select a printer`, { id: "toast" });
            return false;
        }
        callPutApi(MODAL_LOADER, Mark_Restaurant_Default_Printer_PUT.replaceAll("{recordID}", selectedDefaultPrinter.key), {}, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                toast.success(response.message, {id: "toast"});
                setShowSetDefaultPrinterModal(false);
                handleRefresh();
            } else {
                toast.error(response.message);
            }
        });
    };

    const markDefaultPrinterModal = () => {
        return (
            <ModalWrapper
                show={showSetDefaultPrinterModal}
                hide={() => setShowSetDefaultPrinterModal(false)}
                header={`Select a Default Printer`}
                size={"md"}
                backdrop="static" 
                handleSubmit={handleSubmitMarkDefaultPrinter}
            >
                <div className="form-group row mb-3">
                    <div className="col-lg-12 mb-3">
                        <label>Select a Printer to mark default</label>
                        <Select options={printerKeyValuePairList} value={selectedDefaultPrinter} onChange={(e) => setSelectedDefaultPrinter(e)} />
                    </div>
                </div>
            </ModalWrapper>
        );
    };

    return (
        <>
            {PageHeading("Printer", "Dashboard", "/")}
            <div className="card searchOnlyBlock">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <CustomButton handleClick={() => setShowSetDefaultPrinterModal(true)} style={{ left: "150px" }} st title="Mark Default Printer" iconClass="bx bx-bookmark" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_Restaurant_Printer_List_POST : All_Restaurant_Printer_List_Post}
                        serverSidePagination={false}
                        search={true}
                        pagination={false}
                        columns={columns}
                    />
                    {/* <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" /> */}
                </div>
            </div>
            {showAddUpdateModal && <RestaurantPrinterAddEdit show={showAddUpdateModal} hideModal={() => setShowAddUpdateModal(false)} recordID={recordID} handleRefresh={handleRefresh} />}
            {showSetDefaultPrinterModal && markDefaultPrinterModal()}
        </>
    );
};

export default RestaurantPrinterMaster;
