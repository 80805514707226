import { useCallback, useMemo, useState } from "react";

import {
  Activate_Client_Event_Ticket_Put,
  All_Client_Event_Ticket_List_Post,
  DeActivate_Client_Event_Ticket_Put,
  Delete_Client_Event_Ticket_Delete,
  Deleted_Client_Event_Ticket_List_Post,
  Revive_Client_Event_Ticket_Put,
} from "api/ApiConst";
import {
  handleActive,
  handleDelete,
  handleInactive,
  handleRevive,
} from "utils/UserUtils";

import CustomButton from "components/common/CustomButton";
import CheckboxButton from "components/common/CheckboxButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import EventTicketAddEdit from "./EventTicketAddEdit";
import Cookies from "universal-cookie";

const EventTicketMaster = ({ eventID }) => {
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [viewDeletedList, setViewDeletedList] = useState(false);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [recordID, setRecordID] = useState(null);

  const currencyDetail = new Cookies().get("currencyDetail");

  const handleRefresh = useCallback(() => {
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag]);

  const columns = useMemo(
    () => [
      {
        id: "status",
        name: "",
        selector: (row) => (
          <div
            className={row.active ? "led-green" : "led-red"}
            title={row.active ? "Active" : "Inactive"}
          />
        ),
        width: "60px",
        sortable: false,
      },
      {
        id: "ticketTitle",
        name: "Title",
        selector: (row) => <span className="text-wrap">{row.ticketName}</span>,
        sortable: false,
      },
      {
        id: "ticketPrice",
        name: "Price",
        selector: (row) => (
          <>
            {row.ticketPrice.toFixed(2) + " " + currencyDetail?.currencySymbol}{" "}
          </>
        ),
        sortable: false,
      },
      {
        id: "maxNumberOfTickets",
        name: "Sold / Max Tickets",
        selector: (row) =>
          row.soldTicketCount
            ? row.soldTicketCount
            : 0 + " / " + row.maxNumberOfTickets,
        sortable: false,
      },
      {
        id: "giftCouponAmount",
        name: "Coupon Amount",
        selector: (row) => (
          <>
            {row.giftCouponAmount.toFixed(2) +
              " " +
              currencyDetail?.currencySymbol}{" "}
          </>
        ),
        sortable: false,
      },
      {
        id: "action",
        name: "",
        selector: (row) => getActionButtons(row),
        width: "80px",
        sortable: false,
      },
    ],
    [handleRefresh]
  );

  const getActionButtons = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        {!row.deleted && (
          <button className="listBtn me-2" onClick={() => handleEdit(row)}>
            <i className="bx bx-pencil"></i>
          </button>
        )}
        <div className="dropdown">
          <button
            className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle"
            title="More"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ padding: "5px 10px 8px 10px" }}
          >
            <i className="bx bx-chevron-down"></i>
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            <>
              {row.deleted ? (
                <li onClick={() => handleReviveData(row)}>
                  <i className="bx bx-log-out-circle"></i> Revive
                </li>
              ) : (
                <>
                  <li onClick={() => handleDeleteData(row)}>
                    <i className="bx bx-trash"></i> Delete
                  </li>
                  <li onClick={() => handleActiveInactive(row)}>
                    {row.active ? (
                      <>
                        <i className="bx bx-x-circle"></i> Inactive
                      </>
                    ) : (
                      <>
                        <i className="bx bx-check-circle"></i> Active
                      </>
                    )}
                  </li>
                </>
              )}
            </>
          </ul>
        </div>
      </div>
    );
  };

  const handleEdit = (row) => {
    setRecordID(row.uuid);
    setShowAddUpdateModal(true);
  };

  const handleActiveInactive = (row) => {
    if (row.active) {
      handleInactive(
        DeActivate_Client_Event_Ticket_Put.replaceAll(
          "{recordID}",
          row.uuid
        ).replaceAll("{eventID}", eventID),
        handleRefresh
      );
    } else {
      handleActive(
        Activate_Client_Event_Ticket_Put.replaceAll(
          "{recordID}",
          row.uuid
        ).replaceAll("{eventID}", eventID),
        handleRefresh
      );
    }
  };

  //Delete Record
  const handleDeleteData = (row) => {
    handleDelete(
      Delete_Client_Event_Ticket_Delete.replaceAll(
        "{recordID}",
        row.uuid
      ).replaceAll("{eventID}", eventID),
      handleRefresh
    );
  };

  //Revive Record
  const handleReviveData = (row) => {
    handleRevive(
      Revive_Client_Event_Ticket_Put.replaceAll(
        "{recordID}",
        row.uuid
      ).replaceAll("{eventID}", eventID),
      handleRefresh
    );
  };

  const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);
  return (
    <>
      <div className="card">
        <CustomButton
          handleClick={() => setShowAddUpdateModal(true)}
          title="Create New"
          iconClass="bx bx-plus"
          buttonClass="createButton"
        />
        <div className="card-body tableView">
          <PaginationDataTable
            paginationUrl={
              viewDeletedList
                ? Deleted_Client_Event_Ticket_List_Post.replaceAll(
                    "{eventID}",
                    eventID
                  )
                : All_Client_Event_Ticket_List_Post.replaceAll(
                    "{eventID}",
                    eventID
                  )
            }
            serverSidePagination={true}
            search={true}
            pagination={false}
            columns={columns}
            modalLoader={true}
          />
          <CheckboxButton
            title="Deleted List"
            handleClick={handleToggleDeletedView}
            additionalClass="deletedBtn ml-auto"
          />
        </div>
      </div>
      {showAddUpdateModal && (
        <EventTicketAddEdit
          show={showAddUpdateModal}
          hideModal={() => setShowAddUpdateModal(false)}
          recordID={recordID}
          eventID={eventID}
          handleRefresh={handleRefresh}
        />
      )}
    </>
  );
};

export default EventTicketMaster;
