import { useCallback, useEffect, useMemo, useState } from "react";

import { toast } from "react-hot-toast";

import {
    Activate_Restaurant_Menu_Item_Put,
    All_Restaurant_Menu_Item_List_Post,
    DeActivate_Restaurant_Menu_Item_Put,
    Delete_Restaurant_Menu_Item_Delete,
    Deleted_Restaurant_Menu_Item_List_POST,
    GET_Restaurant_Steal_Deal_Category_Key_Value_List_Post,
    GET_Client_Sticker_Key_Value_List_Post,
    Get_Restaurant_Condiment_Key_Value_List_Post,
    Get_Restaurant_Item_Customization_Key_Value_List_Post,
    Get_Restaurant_Tax_Key_Value_List_Post,
    Get_Restaurant_Timing_Group_Key_Value_Pair_List_Post,
    Revive_Restaurant_Menu_Item_Put,
    Revive_Rest_Menu_Restaurant_Menu_Item_Put,
    Delete_Rest_Menu_Restaurant_Menu_Item_Delete,
    Activate_Rest_Menu_Restaurant_Menu_Item_Put,
    DeActivate_Rest_Menu_Restaurant_Menu_Item_Put,
} from "api/ApiConst";
import { callPostApi } from "api/ApiCaller";
import { SECTION_LOADER } from "utils/constants";
import { getImageSrcViaRefId, handleActive, handleDelete, handleInactive, handleRevive } from "utils/UserUtils";
import { API_SUCCESS } from "api/ResponseCodes";

import CheckboxButton from "components/common/CheckboxButton";
import PaginationDataTable from "components/common/PaginationDataTable";
import CustomButton from "components/common/CustomButton";
import PageHeading from "components/common/PageHeading";
import RestaurantMenuItemAddEdit from "./RestaurantMenuItemAddEdit";
import Cookies from "universal-cookie";

export const RestaurantStealDealItemMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [timingGroupKeyPairList, setTimingGroupKeyPairList] = useState([]);
    const [taxKeyPairList, setTaxKeyPairList] = useState([]);
    const [condimentsKeyValuePairs, setCondimentsKeyValuePairs] = useState([]);
    const [customizationsKeyValuePairs, setCustomizationsKeyValuePairs] = useState([]);
    const [categoryKeyValuePairList, setCategoryKeyValuePairList] = useState([]);
    const [stickerKeyValuePairList, setStickerKeyValuePairList] = useState([]);
    const [ownedItem, setOwnedItem] = useState(true);

    const currencyDetail = new Cookies().get("currencyDetail");
    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordID(null);
            setOwnedItem(true);
        }
    }, [showAddUpdateModal]);

    useEffect(() => {
        getCategoryKeyValuePairs();
        getTaxKeyPairList();
        getCondimentsKeyValuePairs();
        getCustomizationsKeyValuePairs();
        getTimingGroupKeyPairList();
        getStickerKeyValuePairs();
    }, []);

    // Get Steal deal category key Pair List
    const getCategoryKeyValuePairs = () => {
        const rUrl = GET_Restaurant_Steal_Deal_Category_Key_Value_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setCategoryKeyValuePairList(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    };

    // Get Tax Key Pair List
    const getTaxKeyPairList = () => {
        let rUrl = Get_Restaurant_Tax_Key_Value_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setTaxKeyPairList(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    };

    // Get Condiments Key Pair List
    const getCondimentsKeyValuePairs = () => {
        const rUrl = Get_Restaurant_Condiment_Key_Value_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setCondimentsKeyValuePairs(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    };

    // Get Customizations Key Pair List
    const getCustomizationsKeyValuePairs = () => {
        const rUrl = Get_Restaurant_Item_Customization_Key_Value_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setCustomizationsKeyValuePairs(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    };

    // Get Timing Group Key Pair List
    const getTimingGroupKeyPairList = () => {
        let rUrl = Get_Restaurant_Timing_Group_Key_Value_Pair_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setTimingGroupKeyPairList(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    };

    // Get Sticker Key Value Pair
    const getStickerKeyValuePairs = () => {
        const rUrl = GET_Client_Sticker_Key_Value_List_Post;
        const rObj = {};
        callPostApi(SECTION_LOADER, rUrl, rObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                setStickerKeyValuePairList(response.responsePacket);
            } else {
                toast.error(response.message);
            }
        });
    };

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "Status",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active" />;
                    } else {
                        return <div className="led-red" title="Inactive" />;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                id: "itemImageRefId",
                name: "",
                selector: (row) => (
                    <div className="list-img">
                        <img src={getImageSrcViaRefId(row.itemImageRefId)} alt="category icon" />
                    </div>
                ),
                width: "70px",
                sortable: false,
            },
            {
                id: "title",
                name: "Title",
                selector: (row) => (
                    <span className="parent-owned-flag">
                        {
                            <img
                                src={`/assets/images/${row.vegNonVeg.toLowerCase() === "veg" ? "veg" : row.vegNonVeg.toLowerCase() === "nonveg" ? "nonVeg" : row.vegNonVeg.toLowerCase() === "vegan" ? "vegan" : "none"}.png`}
                                className="me-1"
                                alt=""
                                style={{ height: "15px", width: "auto" }}
                            />
                        }{" "}
                        {row.title} {" "}
                        {row.owned && <span className="badge bg-success owned-flag" title="Owned">O</span>}
                    </span>
                ),
                // wrap: true,
                sortable: false,
            },
            {
                id: "categoryTitle",
                name: "Category",
                wrap: true,
                selector: (row) => row.categoryTitle,
                // wrap: true,
            },
            {
                id: "maximumRetailPrice",
                name: "MRP",
                selector: (row) => <>{row.maximumRetailPrice.toFixed(2)+" " + currencyDetail?.currencySymbol} </>,
                sortable: false,
                right: true,
                width: "100px"
            },
            {
                id: "action",
                name: "",
                selector: (row) => getActionButtons(row),
                width: "100px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                {!row.deleted && (
                    <button className="listBtn me-2" onClick={() => handleEdit(row)}>
                        <i className="bx bx-pencil"></i>
                    </button>
                )}
                <div className="dropdown">
                    <button className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle" title="More" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "5px 10px 8px 10px" }}>
                        <i className="bx bx-chevron-down"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <>
                            {row.deleted ? (
                                <li onClick={() => handleReviveData(row)}>
                                    <i className="bx bx-log-out-circle"></i> Revive
                                </li>
                            ) : (
                                <>
                                    <li onClick={() => handleDeleteData(row)}>
                                        <i className="bx bx-trash"></i> Delete
                                    </li>
                                    <li onClick={() => handleActiveInactive(row)}>
                                        {row.active ? (
                                            <>
                                                <i className="bx bx-x-circle"></i> Inactive
                                            </>
                                        ) : (
                                            <>
                                                <i className="bx bx-check-circle"></i> Active
                                            </>
                                        )}
                                    </li>
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row.uuid);
        setOwnedItem(row.owned);
        setShowAddUpdateModal(true);
    };

    const handleActiveInactive = (row) => {
        if (row.active) {
            const rUrl = row.owned ? DeActivate_Restaurant_Menu_Item_Put : DeActivate_Rest_Menu_Restaurant_Menu_Item_Put;
            handleInactive(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
        } else {
            const rUrl = row.owned ? Activate_Restaurant_Menu_Item_Put : Activate_Rest_Menu_Restaurant_Menu_Item_Put;
            handleActive(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
        }
    };

    //Delete Record
    const handleDeleteData = (row) => {
        const rUrl = row.owned ? Delete_Restaurant_Menu_Item_Delete : Delete_Rest_Menu_Restaurant_Menu_Item_Delete;
        handleDelete(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    //Revive Record
    const handleReviveData = (row) => {
        const rUrl = row.owned ? Revive_Restaurant_Menu_Item_Put : Revive_Rest_Menu_Restaurant_Menu_Item_Put;
        handleRevive(rUrl.replaceAll("{recordID}", row.uuid), handleRefresh);
    };

    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    return (
        <>
            {PageHeading("Steal Deal Item List", "Menu", "/")}
            <div className="card">
                <CustomButton handleClick={() => setShowAddUpdateModal(true)} title="Create New" iconClass="bx bx-plus" buttonClass="createButton" />
                <div className="card-body tableView">
                    <PaginationDataTable
                        paginationUrl={viewDeletedList ? Deleted_Restaurant_Menu_Item_List_POST + "/StealDeal" : All_Restaurant_Menu_Item_List_Post + "/StealDeal"}
                        serverSidePagination={false}
                        search={true}
                        pagination={false}
                        columns={columns}
                        filterColumn={"title"}
                        filter={true}
                    />
                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                </div>
            </div>
            {showAddUpdateModal && (
                <RestaurantMenuItemAddEdit
                    show={showAddUpdateModal}
                    hideModal={() => setShowAddUpdateModal(false)}
                    recordID={recordID}
                    handleRefresh={handleRefresh}
                    categoryKeyValuePairList={categoryKeyValuePairList}
                    taxKeyPairList={taxKeyPairList}
                    condimentsKeyValuePairs={condimentsKeyValuePairs}
                    customizationsKeyValuePairs={customizationsKeyValuePairs}
                    timingGroupKeyPairList={timingGroupKeyPairList}
                    stickerKeyValuePairList={stickerKeyValuePairList}
                    ownedItem={ownedItem}
                    isStealDealItem={true}
                />
            )}
        </>
    );
};

export default RestaurantStealDealItemMaster;
