import { callGetApi, callPostApi, callPutApi } from 'api/ApiCaller';
import { Details_Client_Sticker_Get, Save_Client_Sticker_Post, Update_Client_Sticker_Put } from 'api/ApiConst';
import { API_SUCCESS } from 'api/ResponseCodes';
import ModalWrapper from 'components/common/ModalWrapper';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { isAlphaNumericSpc, isEmpty } from 'utils/TextUtils';
import { MODAL_LOADER } from 'utils/constants';
import Select from "react-select";
import { useSelector } from 'react-redux';


const StickerAddEdit = (props) => {
    const { show, hideModal, recordID, handleRefresh } = props;
    const { brandKeyValuePairs } = useSelector((state) => state.utility);
    let defaultRecordObj = {
        title: "",
        brandUuid: brandKeyValuePairs.length === 1 ? brandKeyValuePairs[0] : "",
    };

    const [record, setRecord] = useState(defaultRecordObj);

    useEffect(() => {
        if (recordID) {
            callGetApi(MODAL_LOADER, Details_Client_Sticker_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                if (response.errorCode === API_SUCCESS) {
                    const res = response.responsePacket;
                    setRecord(res);
                } else {
                    toast.error(response.message);
                }
            });
        }
    }, [recordID]);

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (id === "title") {
             if (!isAlphaNumericSpc(value)  && value.length!== 0) {
             return;
             }
         }
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    //Select Text Change Event
    const handleSelectChange = (e, id) => {
        setRecord((prev) => ({ ...prev, [id]: e }));
    };

    // Check validation
    const checkInputValidation = (recordObj) => {
        if (!recordID && isEmpty(record.brandUuid)) {
            toast.error(`Brand must not be empty`, { id: "toast" });
            return false;
        } else if (isEmpty(recordObj.title)) {
            toast.error(`Title must not be empty`, { id: "toast" });
            return false;
        } else if (recordObj.title.length < 3) {
            toast.error(`Title must be more then 2 character`, { id: "toast" });
            return false;
        } 
        return true;
    };

    //Submit Item Customization Form
    const handleSubmit = async () => {
        try {
            let recordObj = record;
            recordObj.brandUuid = record.brandUuid?.key || "";
            if (!checkInputValidation(recordObj)) {
                return;
            }

            if (!recordID || recordID === "") {
                callPostApi(MODAL_LOADER, Save_Client_Sticker_Post, recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            } else {
                delete recordObj.brandUuid; // delete brandUuid at update
                callPutApi(MODAL_LOADER, Update_Client_Sticker_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
                    if (response.errorCode === API_SUCCESS) {
                        toast.success(response.message, {id: "toast"});
                        hideModal();
                        handleRefresh();
                    } else {
                        toast.error(response.message, {id: "toast"});
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    return (
        <ModalWrapper show={show} hide={hideModal}  backdrop="static" header={`${recordID ? "Update" : "Add"} Sticker`} size={"md"} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                {!recordID &&
                    <div className="col-lg-12 my-2">
                        <label>Brand *</label>
                        <Select options={brandKeyValuePairs} value={record.brandUuid} onChange={(e) => handleSelectChange(e, "brandUuid")} />
                    </div>
                }
                <div className="col-lg-12">
                    <label>Title *</label>
                    <input type="text" maxLength={20} className="form-control input-default" id="title" onChange={handleTextChange} value={record.title} />
                </div>
            </div>
        </ModalWrapper>
    )
}

export default StickerAddEdit