import React, { useEffect, useState } from 'react'
import { getQRCodeImage } from 'utils/UserUtils'

const QrCodeImage = ({ rUrl }) => {
    const [image, setImage] = useState("");
    useEffect(() => {
        getQRCodeImage(rUrl)
            .then((data) => {
                setImage(data);
            })
            .catch((error) => {
                return;
            })
    }, [])
    return (
        <img src={`data:image/png;base64, ${image}`} alt="QR Code" className="my-2" style={{ height: "92px", width: "auto" }} />
    )
}

export default QrCodeImage