import { useState } from 'react'

import { toast } from 'react-hot-toast';

import { callPutApi } from 'api/ApiCaller';
import { Send_Message_Client_Voucher_Put } from 'api/ApiConst';
import { isEmpty, isMobile } from 'utils/TextUtils';
import { MODAL_LOADER } from 'utils/constants';
import { API_SUCCESS } from 'api/ResponseCodes';

import ModalWrapper from 'components/common/ModalWrapper';

let defaultRecordObj = {
    mobileNumber: "",
    voucherUuid: "",
}

const SendMessage = (props) => {
    const { show, hideModal, recordID, handleRefresh } = props;

    const [record, setRecord] = useState({ ...defaultRecordObj });

    //Text Change Event
    const handleTextChange = (e) => {
        const { id, value, type } = e.target;
        if (id === "mobileNumber") {
            if (value.length > 10) {
                return;
            }
        }
        if (type === "number") {
            setRecord((prev) => ({ ...prev, [id]: parseInt(value) }));
        } else {
            setRecord((prev) => ({ ...prev, [id]: value }));
        }
    };

    const checkInputValidation = () => {
        if (isEmpty(record.mobileNumber)) {
            toast.error("Mobile Number must not be empty.", { id: "toast" })
            return false;
        } else if (!isMobile(record.mobileNumber)) {
            toast.error("Please Provide Valid Mobile Number", { id: "toast" })
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        if (!checkInputValidation()) {
            return;
        }

        let recordObj = record;
        recordObj.voucherUuid = recordID;
        callPutApi(MODAL_LOADER, Send_Message_Client_Voucher_Put.replaceAll("{recordID}", recordID), recordObj, {}, (response) => {
            if (response.errorCode === API_SUCCESS) {
                toast.success(response.message, {id: "toast"});
                hideModal();
                handleRefresh();
            } else {
                toast.error(response.message);
            }
        });
    }

    return (
        <ModalWrapper show={show} hide={hideModal} backdrop="static"  header={`Send Message`} size={"md"} handleSubmit={handleSubmit}>
            <div className="form-group row mb-3">
                <div className="col-lg-12">
                    <label>Mobile Number *</label>
                    <input type="number" className="form-control input-default" id="mobileNumber" onChange={handleTextChange} value={record.mobileNumber} />
                </div>
            </div>
        </ModalWrapper>
    )
}

export default SendMessage